
import { Component, Vue } from 'vue-property-decorator';
import noPermission from '@/modules/NoPermission';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import FileViewer from '@/projectComponents/fileViewer/index.vue';

@Component({
    components: {
        FileViewer,
    },
})
export default class NoPermissionTermsAndConditions extends Vue {
    @Get(noPermission) private termsAndConditions!: AssetType|null;

    @Get(noPermission) private termsAndConditionsLoaded!: boolean;

    @Get(noPermission) private visibleLoaderLoading!: boolean;

    @Get(noPermission) private tncErrors!: string;

    @Sync(noPermission) private termsAndConditionsAgreed!: boolean;

    mounted() {
        if (!this.termsAndConditionsLoaded) {
            noPermission.loadTermsAndConditions();
        }
    }

    async handleSubmit() {
        try {
            await noPermission.updateTermsAndConditions();
            if (this.tncErrors === '') {
                this.$router.push({ name: 'dashboard' })
                    .catch(() => {});
            }
        // eslint-disable-next-line no-empty
        } finally {}
    }

    beforeDestroy() {
        noPermission.setTermsAndConditionsAgreed(false);
    }
}

