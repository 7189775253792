
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import profile from '@/modules/Profile';
import profilePicture from '@/modules/Profile/profilePicture';
import LinkInvite from '@/projectComponents/linkInvite/index.vue';
import Skeleton from '@/projectComponents/skeleton/Profile/index.vue';
import auth from '@/modules/Auth';
import countries from '@/modules/Countries';
import Content from './Content/index.vue';

@Component({
    components: {
        Content,
        LinkInvite,
        Skeleton,
    },
})
export default class Profile extends Vue {
    @Get(profile) private loading!: boolean;

    @Get(countries, 'loading') private countriesLoading!: boolean;

    @Get(profile) private loadingRewards!: boolean;

    get isHostess() {
        return auth.isHostess;
    }

    beforeDestroy() {
        profilePicture.clearImageData();
        profile.clearFormErrors();
        profile.resetDataToOriginal();
    }

    mounted() {
        profilePicture.clearImageData();
        profile.clearFormErrors();
        profile.getProfile({ isImpersonating: false, isHostess: this.isHostess });
        profile.setImageDialogOpen(false);
        profile.setCameraDialogOpen(false);
        countries.getCountries();
    }

    get contentLoaded(): boolean {
        return !this.loading && !this.loadingRewards && !this.countriesLoading;
    }
}
