import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { GetDashboardResultType } from '@/api/graphQL/graphNodes/GetDashboardQuery';
import {
    CommissionLevelType,
    DashboardRecruitmentType, DashboardType,
} from '@/api/graphQL/graphNodes/types';
import { NewStarters } from '@/modules/NewStarters';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import dateManager from '@/utils/time';
import dashboardRepository from './services/dashboardRepository';
import { initialDashboardData } from './defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'dashboard',
})
@AutoMutations
export class Dashboard extends VuexModule {
    private data: DashboardType = initialDashboardData();

    private recruitmentData: DashboardRecruitmentType = {};

    private loaded: boolean = false;

    private loading: boolean = false;

    private activeTab: string = '';

    private commissionLevels: CommissionLevelType[] = [];

    get getActiveTab() {
        return this.activeTab;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setRecruitmentData(val: DashboardRecruitmentType) {
        this.recruitmentData = { ...val };
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setDashboardData(val: DashboardType) {
        this.data = { ...val };
    }

    @Mutation
    public setActiveTab(activeTab: string) {
        this.activeTab = activeTab;
    }

    @Mutation
    public setCommissionLevels(val: CommissionLevelType[]) {
        this.commissionLevels = [...val];
    }

    @Action()
    public async getDashboardData() {
        try {
            this.setLoading(true);
            const res: GetDashboardResultType = await dashboardRepository.getData({
                dateFrom: dateManager.getFirstDayOfMonth(),
                dateTo: dateManager.getLastDayOfMonth(),
                lastMonthDateFrom: dateManager.getFirstDayOfMonth(-1),
                lastMonthDateTo: dateManager.getLastDayOfMonth(-1),
            });

            const recruitersData: DashboardRecruitmentType = {};

            res.profile.newStartersCountByLevel
                .filter((item) => {
                    if (item.metric.includes(`${NewStarters.NEW_STARTERS_TYPE.personal}`)) {
                        return true;
                    }
                    return false;
                })
                .forEach((item) => {
                    const levelKey = item.metric.replace(`_${NewStarters.NEW_STARTERS_TYPE.personal}`, '');
                    const level = res.newStartersLevels
                        .find((levelItem) => levelItem.key === levelKey)!;

                    recruitersData[levelKey] = {
                        level,
                        personalCount: item.value,
                    };
                });

            res.profile.newStartersCountByLevel
                .filter((item) => {
                    if (item.metric.includes(`${NewStarters.NEW_STARTERS_TYPE.organisational}`)) {
                        return true;
                    }
                    return false;
                })
                .forEach((item) => {
                    const levelKey = item.metric.replace(`_${NewStarters.NEW_STARTERS_TYPE.organisational}`, '');

                    recruitersData[levelKey] = {
                        ...recruitersData[levelKey],
                        organisationalCount: item.value,
                    };
                });

            this.setRecruitmentData(recruitersData);

            this.setDashboardData({
                ...res.profile,
                performanceData: {
                    ...res.thisMonth,
                    lastMonthFinishedEvents: res.lastMonth.finishedEvents,
                },
            });

            this.setCommissionLevels(res.risingStarsCommissionLevels);

            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(Dashboard);
