import { AssetCategoryType } from '@/api/graphQL/graphNodes/types';

const trainingAssetCategories: AssetCategoryType[] = [
    {
        id: '0',
        name: 'Superstarter Academy',
        priority: 0,
        icon: null,
    },
    {
        id: '1',
        name: 'Product Expert',
        priority: 1,
        icon: null,
    },
    {
        id: '2',
        name: 'Social Media Success',
        priority: 2,
        icon: null,
    },
    {
        id: '3',
        name: 'VIP Links',
        priority: 3,
        icon: null,
    },
    {
        id: '4',
        name: 'Bra Fit Consultant',
        priority: 4,
        icon: null,
    },
    {
        id: '5',
        name: 'Create your own Connect with Sponsoring',
        priority: 5,
        icon: null,
    },
    {
        id: '6',
        name: 'Useful documents',
        priority: 6,
        icon: null,
    },
    {
        id: '7',
        name: 'Leader Exclusive Zone',
        priority: 7,
        icon: null,
    },
];

const marketingAssetCategories: AssetCategoryType[] = [
    {
        id: '8',
        name: 'Catalogs',
        priority: 1,
        icon: null,
    },
    {
        id: '9',
        name: 'Promo videos',
        priority: 2,
        icon: null,
    },
    {
        id: '10',
        name: 'Promotion material',
        priority: 3,
        icon: null,
    },
];

const assetCategories: (type: string) => AssetCategoryType[] = (type) => {
    if (type === 'marketing') {
        return marketingAssetCategories;
    }

    if (type === 'help_support') {
        return [];
    }

    return trainingAssetCategories;
};

export { assetCategories, trainingAssetCategories, marketingAssetCategories };
