
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import invite from '@/modules/Invite';
import env from '@/environment';
import { InvitationFormType } from '@/modules/Invite/types';
import { ErrorType } from '@plumtreesystems/utils';
import LinkInvite from '@/projectComponents/linkInvite/index.vue';
import EmailInvite from '@/projectComponents/emailInvite/index.vue';
import profile from '@/modules/Profile';
import Skeleton from './InviteSkeleton/index.vue';
import QrCodeInvite from './QrCodeInvite/index.vue';

@Component({
    components: {
        EmailInvite,
        LinkInvite,
        QrCodeInvite,
        Skeleton,
    },
})
export default class Invite extends Vue {
    @Sync(invite) private formData!: InvitationFormType;

    @Get(invite) private loading!: boolean;

    @Get(invite) private inviteLoader!: boolean;

    @Get(invite) private formErrors!: ErrorType;

    @Get(invite) private invitationLink!: string;

    @Get(invite) private displayTooltip!: boolean;

    @Get(profile) private vanityUrl!: string;

    mounted() {
        invite.getInvitationLink();
        invite.clearFormData();
        invite.clearFormErrors();
    }

    handleSendInvite() {
        invite.inviteAmbassador();
    }

    beforeDestroy() {
        invite.setDisplayTooltip(false);
    }

    get contentLoaded(): boolean {
        return !this.loading;
    }

    get fullPath(): string {
        return env.VUE_APP_FULL_PATH;
    }

    get inviteLink() {
        return `${this.fullPath}${this.$router.resolve({ name: 'sendRegistration', params: { vanityUrl: this.vanityUrl } }).href}`;
    }

    get path() {
        return env.VUE_APP_FULL_PATH;
    }
}
