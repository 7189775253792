
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import PersonalDetails from '@/projectComponents/personalDetails/index.vue';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { gbLabels, ieLabels } from '@/modules/labels';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import myHosts from '@/modules/MyHosts/index';
import Skeleton from '@/projectComponents/skeleton/Profile/index.vue';
import { DownlineProfileType } from '@/api/graphQL/graphNodes/types';
import auth from '@/modules/Auth';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { CountriesType } from '@/modules/Event/types';
import countries from '@/modules/Countries';

@Component({
    components: {
        PersonalDetails,
        FormErrorTooltip,
        Skeleton,
    },
})
export default class EditHost extends Vue {
    @Get(myHosts) private host!: DownlineProfileType;

    @Get(myHosts) private originalHost!: DownlineProfileType;

    @Get(myHosts) private formErrors!: ErrorType;

    @Get(myHosts) private hostLoading!: boolean;

    @Get(myHosts) private offset!: number;

    @Get(myHosts) private displayTooltip!: boolean;

    @Get(countries) private countries!: CountriesType[];

    @Get(countries, 'loading') private countriesLoading!: boolean;

    get subsceneTitle() {
        if (this.hostLoading) {
            return 'VIP Customer personal details';
        }
        return `${this.originalHost.firstName} ${this.originalHost.lastName}'s personal details`;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    async handleProfileUpdate() {
        const { id } = this.$route.params;
        const { offset } = this;
        try {
            await myHosts.updateHost(id);
            myHosts.getMyHosts({ loadPage: true, offset });
        // eslint-disable-next-line no-empty
        } finally {}
    }

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.host);

        if (key === 'country') {
            this.handleCountryChange(value);
        }

        if (key === 'country' && value === COUNTRY_SELECT_OPTIONS.ie) {
            myHosts.removeFormError('postCode');
        }

        myHosts.removeFormError(key);
        myHosts.setHost(res);
    }

    handleCountryChange(val: string) {
        if (val === COUNTRY_SELECT_OPTIONS.ie) {
            myHosts.setLabels(ieLabels());
        } else {
            myHosts.setLabels(gbLabels());
        }
    }

    handleOpen() {
        const { id } = this.$route.params;
        myHosts.getMyHost(id);
        countries.getCountries();
    }

    handleClose() {
        myHosts.clearFormErrors();
    }
}
