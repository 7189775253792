
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { CommissionLevelType, RankRequirementsType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class Commission extends Vue {
    @Get(dashboard, 'data.stats') private stats!: RankRequirementsType[];

    @Get(dashboard) private commissionLevels!: CommissionLevelType[];

    get title(): string {
        const level = this.requirement ? ` Level ${this.requirement?.level}` : '';
        return `Variable Commission${level}`;
    }

    get pv(): number {
        const pvStat = this.stats.find((stat) => stat.metric === RankRequirementManager
            .rankRequirementMetricConstants.pv);

        return pvStat ? Number(pvStat.value) : 0;
    }

    get requirement(): CommissionLevelType|null {
        let current: CommissionLevelType|null = null;

        this.commissionLevels.forEach((req) => {
            if (req.sales < this.pv) {
                current = req;
            }
        });

        return current;
    }

    get progressValue(): number|null {
        if (!this.requirement) {
            return null;
        }

        if (Number(this.requirement.sales) === 0) {
            return 0;
        }

        const x = this.pv * 100;

        return x / Number(this.requirement.sales);
    }

    get tooltip() {
        const text = `

        `;

        return text;
    }

    get displayValue() {
        const { requirement } = this;

        return requirement ? `${requirement.rate}%` : '0%';
    }
}
