
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import eventCreate from '@/modules/Event/EventCreate';
import eventHostesses from '@/modules/Event/Hostesses';
import hostessCreate from '@/modules/Event/HostessCreate';
import { MyHostsType, EnrolleeType } from '@/api/graphQL/graphNodes/types';
import HostessSelect from '@/views/Events/Components/HostContent/hostessSelect.vue';
import EventHostessForm from '@/views/Events/Components/HostessForm/index.vue';
import { CreateEventHostessType } from '@/modules/Event/Events/types';
import { ErrorType } from '@plumtreesystems/utils';
import { defaultEnrollee } from '@/modules/Calendar/defaults';
import { EVENT_HOST_TYPE } from '@/modules/Event/constants';
import Skeleton from '../../EventsSkeleton/Create/Host/index.vue';

@Component({
    components: {
        HostessSelect,
        Skeleton,
        EventHostessForm,
    },
})
export default class CreateEventKnownHostessList extends Vue {
    @Get(eventHostesses) private loading!: boolean;

    @Get(hostessCreate, 'loading') private hostessCreateLoading!: boolean;

    @Get(eventCreate) private eventHostType!: number;

    @Get(eventHostesses) private hostesses!: MyHostsType[];

    @Get(hostessCreate) private formErrors!: ErrorType;

    @Sync(hostessCreate) private hostess!: EnrolleeType;

    @Sync(eventCreate) private hostessFilter!: string;

    get filteredList() {
        return this.hostesses.filter((hostess) => (`${hostess.firstName} ${hostess.lastName}`)
            .toLowerCase()
            .includes(this.hostessFilter.toLowerCase()))
            .sort((a, b) => (`${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}` ? -1 : 1));
    }

    get groupedList() {
        return this.filteredList.reduce((groups, hostess) => {
            const groupName = (`${hostess.firstName} ${hostess.lastName}`).charAt(0).toUpperCase();
            if (!groups[groupName]) {
                // eslint-disable-next-line no-param-reassign
                groups[groupName] = [];
            }
            const group = groups[groupName];
            group.push(hostess);

            return groups;
        }, {});
    }

    get allHostessFormErrors() {
        const { formErrors } = this;
        return { ...formErrors };
    }

    async beforeMount() {
        // Required because hostes type screen was removed.
        eventCreate.clearEventCreate();
        eventCreate.setEventHostType(EVENT_HOST_TYPE.undefined);
        eventCreate.clearHostess();
        hostessCreate.clearHostess();
        //---------------------------------------------------

        eventCreate.setHostess(defaultEnrollee());
        await eventHostesses.getHostesses();
    }

    handleInputUpdate(val: CreateEventHostessType) {
        hostessCreate.setHostess(val);
    }

    handleSelect(hostess) {
        eventCreate.setHostess(hostess);
        this.$router.push({ name: 'createEventType' });
    }

    async handleCreate() {
        await hostessCreate.createHostess();

        if (Object.keys(this.formErrors).length === 0) {
            this.$router.push({ name: 'createEventType' });
        }
    }
}
