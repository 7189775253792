
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

enum ButtonTypeEnum {
    'contained',
    'text',
    'outlined'
}

@Component
export default class IconButton extends Vue {
    @Prop({ default: '' }) private id!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({
        default: ButtonTypeEnum.contained,
        // @ts-ignore
        validator: (val) => ButtonTypeEnum[val] !== undefined,
    }) private type!: ButtonTypeEnum;

    @Prop({ default: '' }) private svgSrc!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: false }) private invertedColors!: boolean;

    @Prop({ required: false }) private ariaLabel!: string;

    @Prop({ default: true }) private ariaHidden!: boolean;

    @Prop({ default: '' }) private notification!: string|number

    get elementClasses() {
        return [
            'IconButton',
            this.disabled ? 'IconButton--disabled' : 'IconRipple',
            { 'IconButton--inverted': this.invertedColors },
            this.type ? `IconButton--${this.type}` : '',
        ];
    }

    handleClick() {
        this.$emit('click');
    }
}
