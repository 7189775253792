
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import myCustomers from '@/modules/MyCustomers';
import customersOrders from '@/modules/MyCustomers/customersOrders';
import system from '@/modules/System';
import profile from '@/modules/Profile';
import { CUSTOMER_LIST_TYPE } from '@/modules/MyCustomers/constants';
import DirectContainer from './DirectContainer/index.vue';
import CustomerTypeContainer from './CustomerTypeContainer/index.vue';
import CustomerSearch from './CustomerSearch/index.vue';
import CustomerOrdersSearch from './OrderSearch/index.vue';

@Component({
    components: {
        DirectContainer,
        CustomerTypeContainer,
        CustomerSearch,
        CustomerOrdersSearch,
    },
})
export default class MyCustomersHeader extends Vue {
    @Prop() private ordersOffset!: number;

    @Get(myCustomers) private customersSearchLoading!: boolean;

    @Get(customersOrders, 'searchLoading') private ordersSearchLoading!: boolean;

    @Get(customersOrders, 'displaySearchResults') private displayOrdersSearchResults!: boolean;

    @Get(myCustomers) private displayCustomersSearchResults!: boolean;

    @Get(myCustomers) private displayCustomersSearch!: boolean;

    @Get(myCustomers) private selectedCustomerList!: string;

    @Get(system) private screenType!: string;

    @Get(profile, 'originalData.id') private myId!: string;

    get displayCustomers(): boolean {
        return this.selectedCustomerList === this.customerListBook;
    }

    get customerListBook() {
        return CUSTOMER_LIST_TYPE.addressBook;
    }

    get seachLoading(): boolean {
        return this.ordersSearchLoading || this.customersSearchLoading;
    }

    get showSearch() {
        return this.screenType !== 'mobile' || this.displayCustomersSearch;
    }

    get headerContainerClasses() {
        return [
            'pts-layout',
            'pts-gutter',
            'MyCustomersHeader__HeaderContainer',
            { 'MyCustomersHeader__HeaderContainer--show': this.showSearch },
        ];
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-50',
            'pts-small-size-100',
            'MyCustomersHeader__Container',
            { 'MyCustomersHeader__Container--show': this.showSearch },
        ];
    }

    handleSelect() {
        this.searchCustomers();
    }

    handleCustomerTypeSelect() {
        myCustomers.setCustomerSearchQuery('');
        myCustomers.setDisplayCustomersSearchResults(false);
        myCustomers.setSearchedCustomersOptions([]);
        customersOrders.setSearchQuery('');
        customersOrders.setDisplaySearchResults(false);
        customersOrders.setSearchedOrdersOptions([]);
        this.searchCustomers();
    }

    searchCustomers() {
        if (this.displayCustomers) {
            myCustomers.setOffset(0);

            const { displayCustomersSearchResults } = this;
            if (displayCustomersSearchResults) {
                myCustomers.searchCustomers({
                    selectedSearch: true,
                    sellerId: this.myId,
                });
            } else {
                myCustomers
                    .getMyCustomers({
                        sellerId: this.myId,
                    });
            }
        } else {
            customersOrders.setOffset(0);
            const { displayOrdersSearchResults } = this;
            if (displayOrdersSearchResults) {
                customersOrders.searchOrders({ selectedSearch: true });
            } else {
                customersOrders.getCustomerOrders({});
            }
        }
    }
}
