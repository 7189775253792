
import { Component, Vue } from 'vue-property-decorator';
import { QUALIFIED_COMMISSION_PS } from '@/modules/RisingStars/constants';
import currencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class RisingStarsStatusTooltipContent extends Vue {
    get currencyPrefix() {
        return currencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get psRequirement(): string {
        return `${this.currencyPrefix}${QUALIFIED_COMMISSION_PS}`;
    }
}
