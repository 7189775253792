import { StatsType, TransactionsType } from '@/api/graphQL/graphNodes/types';

const initialBalanceData: () => StatsType = () => ({
    metric: '',
    value: '',
});

const initialTransaction: () => TransactionsType = () => ({
    date: '',
    refId: '',
    description: '',
    type: '',
    amount: 0,
    currency: '',
    sale: null,
});

export { initialBalanceData, initialTransaction };
