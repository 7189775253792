
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import PersonalDetails from '@/projectComponents/personalDetails/index.vue';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { gbLabels, ieLabels } from '@/modules/labels';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import myCustomers from '@/modules/MyCustomers/index';
import Skeleton from '@/projectComponents/skeleton/Profile/index.vue';
import { DownlineProfileType } from '@/api/graphQL/graphNodes/types';
import auth from '@/modules/Auth';
import profile from '@/modules/Profile';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { CountriesType } from '@/modules/Event/types';
import countries from '@/modules/Countries';

@Component({
    components: {
        PersonalDetails,
        FormErrorTooltip,
        Skeleton,
    },
})
export default class EditCustomer extends Vue {
    @Get(myCustomers) private customer!: DownlineProfileType;

    @Get(myCustomers) private originalCustomer!: DownlineProfileType;

    @Get(myCustomers) private formErrors!: ErrorType;

    @Get(myCustomers) private offset!: number;

    @Get(myCustomers) private customerLoading!: boolean;

    @Get(myCustomers) private displayTooltip!: boolean;

    @Get(profile, 'originalData.id') private myId!: string;

    @Get(countries) private countries!: CountriesType[];

    @Get(countries, 'loading') private countriesLoading!: boolean;

    get subsceneTitle() {
        if (this.customerLoading) {
            return 'Customer personal details';
        }
        return `${this.originalCustomer.firstName} ${this.originalCustomer.lastName}'s personal details`;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    async handleProfileUpdate() {
        const { id } = this.$route.params;
        const { offset } = this;
        try {
            await myCustomers.updateCustomer(id);

            if (Object.keys(this.formErrors).length) {
                myCustomers.getMyCustomers({
                    loadPage: true,
                    offset,
                    sellerId: this.myId,
                });
            }
        // eslint-disable-next-line no-empty
        } finally {}
    }

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.customer);

        if (key === 'country') {
            this.handleCountryChange(value);
        }

        if (key === 'country' && value === COUNTRY_SELECT_OPTIONS.ie) {
            myCustomers.removeFormError('postCode');
        }

        myCustomers.removeFormError(key);
        myCustomers.setCustomer(res);
    }

    handleCountryChange(val: string) {
        if (val === COUNTRY_SELECT_OPTIONS.ie) {
            myCustomers.setLabels(ieLabels());
        } else {
            myCustomers.setLabels(gbLabels());
        }
    }

    handleOpen() {
        const { id } = this.$route.params;
        myCustomers.getMyCustomer(id);
        countries.getCountries();
    }

    handleClose() {
        myCustomers.clearFormErrors();
    }
}
