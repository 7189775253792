import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { SponsorType } from './types';
import { initialSponsorData } from './defaults';
import HelpAndSupportRepository from './services/HelpAndSupportRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'helpAndSupport',
})
@AutoMutations
export class HelpAndSupport extends VuexModule {
    private sponsor: SponsorType = initialSponsorData();

    private loaded: boolean = false;

    private loading: boolean = false;

    @Mutation
    public setSponsorData(val: SponsorType) {
        this.sponsor = { ...val };
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Action()
    public async getSponsorData() {
        try {
            this.setLoading(true);
            const data = await HelpAndSupportRepository.getSponsor();
            const { parent } = data.profile;

            if (parent) {
                this.setSponsorData(parent);
            }

            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        }

        this.setLoading(false);
    }
}

export default getModule(HelpAndSupport);
