import { EventType, EventViewType } from '@/api/graphQL/graphNodes/types';
import { defaultEnrollee } from '@/modules/Calendar/defaults';
import { CreateEventType } from './types';

const initialEventData: () => EventType = () => ({
    id: '',
    title: '',
    eventDateFrom: '',
    eventDateTo: '',
    campaignDateFrom: '',
    campaignDateTo: '',
    address: '',
    videoUrl: '',
    description: '',
    allowGuestsToInvite: false,
    ambassador: defaultEnrollee(),
    hostess: defaultEnrollee(),
    invitations: [],
    inviteLink: '',
    eventOrders: [],
    confirmed: false,
    canceled: false,
    type: '',
    totalSales: 0,
});

const initialEventViewData: () => EventViewType = () => ({
    ...initialEventData(),
    shopUrl: '',
    eventCode: '',
    customerShopUrl: '',
    calendarEventPerformance: {
        eventTotal: '0',
        tokens: '0',
    },
});

const initialEventCreateData: () => CreateEventType = () => ({
    title: '',
    eventDateFrom: '',
    eventDateTo: '',
    campaignDateFrom: '',
    campaignDateTo: '',
    type: '',
    address: '',
    videoUrl: '',
    description: {
        ops: '',
    },
    allowGuestsToInvite: false,
    hostess: null,
});

export { initialEventCreateData, initialEventViewData, initialEventData };
