import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType } from '@plumtreesystems/utils';
import store from '@/store';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import { defaultEnrollee } from '@/modules/Calendar/defaults';
import { CreateEventHostessType, CreateEventType } from '@/modules/Event/Events/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { CreateEventParamsType, CreateEventResultType } from '@/api/graphQL/graphNodes/CreateEventQuery';
import profile from '@/modules/Profile/index';
import { HostessInvitationInputType, KnownHostessInvitationInputType } from '@/api/graphQL/graphNodes/types';
import { ObjectPropertyType } from '@/modules/types';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import { initialEventCreateData } from '../Events/defaults';
import EventRepository from '../Events/services/eventRepository';
import formEventValidation from '../services/formEventValidation';
import formHostessValidation from '../services/formHostessValidation';
import { EVENT_HOST_TYPE } from '../constants';
import { FORM_ERROR_TOOLTIP } from '../../constants';

@Module({
    namespaced: true, dynamic: true, store, name: 'eventCreate',
})
@AutoMutations
export class EventCreate extends VuexModule {
    private eventHostType: number = EVENT_HOST_TYPE.undefined;

    private eventData: CreateEventType = initialEventCreateData();

    private eventFormErrors: ErrorType = {};

    private hostess: null|CreateEventHostessType = defaultEnrollee();

    private hostessFormErrors: ErrorType = {};

    private loading: boolean = false;

    private hostessFilter: string = '';

    private displayTooltip: boolean = false;

    get dateWithOffset() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true'
            ? mockedDateManager.getCurrentDateWithOffset(10, dateManager.getDayTimeFormatUtc(), 'day')
            : dateManager.getCurrentDateWithOffset(10, dateManager.getDayTimeFormatUtc(), 'day');
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public setEventHostType(val: number) {
        this.eventHostType = val;
    }

    @Mutation
    public setHostessFormError(payload: ObjectPropertyType) {
        this.hostessFormErrors[payload.key] = payload.val;
    }

    @Mutation
    public setEventFormError(payload: ObjectPropertyType) {
        this.eventFormErrors[payload.key] = payload.val;
    }

    @Mutation
    public setEventFormErrors(errors: any) {
        this.eventFormErrors = { ...errors };
    }

    @Mutation
    public clearHostessFormError() {
        this.hostessFormErrors = {};
    }

    @Mutation
    public clearEventFormError() {
        this.eventFormErrors = {};
    }

    @Mutation
    public setHostess(hostess: CreateEventHostessType) {
        this.hostess = { ...hostess };
    }

    @Mutation
    public clearHostess() {
        this.hostess = null;
    }

    @Mutation
    public clearEventData() {
        this.eventData = { ...initialEventCreateData() };
    }

    @Mutation
    public setEventData(val: CreateEventType) {
        this.eventData = { ...val };
    }

    @Mutation
    public setEventProperty(value: ObjectPropertyType) {
        const { key, val } = value;
        this.eventData[key] = val;
    }

    @Mutation
    public setEventHostessData(val: CreateEventHostessType) {
        this.eventData.hostess = { ...val };
    }

    @Mutation
    public setEventType(val: string) {
        this.eventData.type = val;
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public async eventFormSubmit(): Promise<CreateEventResultType> {
        this.setLoading(true);

        try {
            this.validateEventForm();

            if (this.eventHostType === EVENT_HOST_TYPE.new) {
                this.validateHostessForm();
            }

            if (this.eventHostType !== EVENT_HOST_TYPE.no) {
                this.setHostessToEvent();
            }

            if (Object.keys(this.eventFormErrors).length === 0
             && (this.eventHostType !== EVENT_HOST_TYPE.new
                || Object.keys(this.hostessFormErrors).length === 0
             )
            ) {
                const res: CreateEventResultType = await this.createEvent();
                return res;
            }
            this.displayFormErrorsTooltip();

            this.setLoading(false);
            throw new Error();
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public clearEventCreate() {
        this.clearHostessFormError();
        this.clearEventFormError();
        this.setEventData({
            ...initialEventCreateData(),
            type: this.eventData.type,
        });
    }

    @Action()
    public async setHostessMe() {
        const data = profile.getProfileData;
        this.setHostess(data as CreateEventHostessType);
    }

    @Action()
    public validateEventForm() {
        this.clearEventFormError();

        const errorList = formEventValidation(this.eventData);

        errorList.forEach((error) => this.setEventFormError(error));
    }

    @Action()
    public validateHostessForm() {
        this.clearHostessFormError();

        const errorList = formHostessValidation(this.hostess);
        errorList.forEach((error) => this.setHostessFormError(error));
    }

    @Action()
    async createEvent(): Promise<CreateEventResultType> {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { hostess, description, ...eventData } = this.eventData;

            let eventHostess: null|HostessInvitationInputType
                |KnownHostessInvitationInputType = null;

            if (this.hostess) {
                if (this.hostess.id) {
                    eventHostess = {
                        id: this.hostess.id,
                    };
                } else {
                    eventHostess = {
                        email: this.hostess.email,
                        address: this.hostess.address,
                        firstName: this.hostess.firstName,
                        lastName: this.hostess.lastName,
                        phoneNumber: this.hostess.phoneNumber,
                    };
                }
            } else {
                throw Error("VIP customer doesn't exist");
            }

            const data = {
                ...eventData,
                hostessInvitation: eventHostess,
                description: JSON.stringify(description.ops),
                eventDateFrom: null,
                eventDateTo: null,
                campaignDateFrom: null,
                campaignDateTo: null,
            } as CreateEventParamsType;

            const resData: CreateEventResultType = await EventRepository
                .createEvent(data);

            return resData;
        } catch (e) {
            this.displayFormErrorsTooltip();

            const errors = ErrorsProcessor.process(e);
            this.setEventFormErrors(errors.form);
            throw e;
        }
    }

    @Action()
    setHostessToEvent() {
        if (this.hostess) {
            const {
                address, email, id, firstName, lastName, phoneNumber,
            } = this.hostess;

            this.setEventHostessData({
                address,
                email,
                id,
                firstName,
                lastName,
                phoneNumber,
            });
        }
    }
}

export default getModule(EventCreate);
