
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import profile from '@/modules/Profile';

@Component
export default class BecomeAmbassador extends Vue {
    @Get(profile) private shopUrl!: string;

    handleInvitation() {
        window.open(`${this.shopUrl}/party-plan-be-an-ambassador-pp.html`);
    }
}
