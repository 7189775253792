import {
    ResolverAssetType, ResolverSearchAssetsCountType,
    ResolverSearchAssetsType,
} from '../types';

const assets: ResolverAssetType[] = [
    {
        id: '0',
        link: 'https://vimeo.com/200821149',
        fileName: '#BeSeen',
        originalFileName: '#BeSeen',
        title: '#BeSeen',
        category: 'marketing',
        priority: 0,
        subCategory: '8',
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/200821149?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '1',
        link: 'https://vimeo.com/202056033',
        fileName: 'Ann Summers 2017 Valentines Commercial',
        originalFileName: 'Ann Summers 2017 Valentines Commercial',
        title: null,
        category: 'training',
        subCategory: '3',
        priority: 1,
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/202056033?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '2',
        link: 'https://vimeo.com/200821149',
        fileName: '#BeSeen',
        originalFileName: '#BeSeen',
        title: '#BeSeen',
        category: 'marketing',
        priority: 2,
        subCategory: '10',
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/200821149?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '3',
        link: 'https://vimeo.com/373885836',
        fileName: 'Ann Summers 19',
        title: null,
        originalFileName: 'Ann Summers 19',
        category: 'marketing',
        priority: 3,
        subCategory: '9',
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/373885836?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '4',
        link: 'https://vimeo.com/373885836',
        fileName: 'Ann Summers 19',
        title: null,
        originalFileName: 'Ann Summers 19',
        category: 'marketing',
        priority: 4,
        subCategory: '9',
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/373885836?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '5',
        link: 'https://vimeo.com/202056033',
        fileName: 'Ann Summers 2017 Valentines Commercial',
        title: null,
        originalFileName: 'Ann Summers 2017 Valentines Commercial',
        category: 'training',
        subCategory: '3',
        priority: 5,
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/202056033?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '6',
        link: 'https://vimeo.com/373885836',
        fileName: 'Ann Summers 19',
        title: 'Ann Summers 19',
        originalFileName: 'Ann Summers 19',
        category: 'marketing',
        priority: 6,
        subCategory: '8',
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/373885836?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '7',
        link: 'https://vimeo.com/298986223',
        fileName: 'Ann Summers Christmas 2018',
        originalFileName: 'Ann Summers Christmas 2018',
        title: null,
        category: 'training',
        subCategory: '3',
        priority: 7,
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/298986223?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '8',
        link: 'https://vimeo.com/200821149',
        fileName: '#BeSeen',
        originalFileName: '#BeSeen',
        title: '#BeSeen',
        category: 'marketing',
        priority: 8,
        subCategory: '8',
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/200821149?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '9',
        link: 'https://vimeo.com/202056033',
        fileName: 'Ann Summers 2017 Valentines Commercial',
        originalFileName: 'Ann Summers 2017 Valentines Commercial',
        title: 'Ann Summers 2017 Valentines Commercial',
        category: 'training',
        subCategory: '3',
        priority: 9,
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/202056033?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '10',
        link: 'https://vimeo.com/202056033',
        fileName: 'Ann Summers 2017 Valentines Commercial',
        originalFileName: 'Ann Summers 2017 Valentines Commercial',
        title: 'Ann Summers 2017 Valentines Commercial',
        category: 'help_support',
        subCategory: '11',
        priority: 0,
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/202056033?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '11',
        link: 'https://vimeo.com/202056033',
        fileName: 'Ann Summers 2017 Commercial',
        originalFileName: 'Ann Summers 2017 Commercial',
        title: 'Ann Summers 2017 Commercial',
        category: 'help_support',
        subCategory: '11',
        priority: 1,
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/202056033?app_id=122963',
            },
        ],
        uploadDate: '',
    },
    {
        id: '12',
        link: 'https://vimeo.com/202056033',
        fileName: 'Terms & Conditions',
        originalFileName: 'Terms & Conditions',
        title: 'Terms & Conditions',
        category: 'terms_and_conditions',
        subCategory: '',
        priority: 1,
        context: [
            {
                value: 'external/video',
                metric: 'Content-Type',
            },
            {
                metric: 'Embed-Link',
                value: 'https://player.vimeo.com/video/202056033?app_id=122963',
            },
        ],
        uploadDate: '',
    },
];

export const filteredAssetList = (category) => (category !== ''
    ? assets.filter((item) => item.category === category) : assets);

const searchedAssets: (query: string, category: string, subCategoryId: string|null)
 => ResolverAssetType[] = (query, category, subCategoryId) => assets
     .filter((item) => ((item.category === category)
    && (subCategoryId ? item.subCategory === subCategoryId : true)
    && (query === '' ? true : item.fileName.includes(query))));

export const searchAssets: ResolverSearchAssetsType = (params = {
    query: '',
    category: '',
    subCategoryId: null,
    limit: 0,
    offset: 0,
}) => {
    const {
        query, category, subCategoryId, offset, limit,
    } = params;
    const filteredAssets = searchedAssets(query, category, subCategoryId);

    return filteredAssets.splice(offset, limit);
};

export const searchAssetsCount: ResolverSearchAssetsCountType = (params = {
    query: '', category: '', subCategoryId: '',
}) => {
    const { query, category, subCategoryId } = params;
    return searchedAssets(query, category, subCategoryId).length;
};

export { assets };
