export default class RankRequirementManager {
    static readonly rankRequirementMetricConstants = {
        ambassadorKit: 'ambassadorKit',
        personalRecruits: 'qualified_personal_legs',
        pv: 'pv',
        gv: 'gv',
        spagv: 'spa_gv',
        ov: 'ov',
        leaderLegs: 'qualified_leader_branches',
        ambassadorLegs: 'qualified_group_legs',
        qualifiedLeaders: 'qualified_leaders',
        qnv: 'qnv',
    }

    public static getRankRequirementLabel(val: string, amount: number = 0) {
        switch (val) {
        case RankRequirementManager.rankRequirementMetricConstants.ambassadorKit: {
            return {
                title: 'Ambassador kit',
                withValue: false,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.personalRecruits: {
            return {
                title: 'Qualified Network Consultants',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.pv: {
            return {
                title: 'Own Sales',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.gv: {
            return {
                title: 'Central Team Sales',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.ov: {
            return {
                title: 'Organisational Team Sales',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.leaderLegs: {
            return {
                title: 'Qualified Leader Legs',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.ambassadorLegs: {
            return {
                title: `Qualified Network ${amount === 1 ? 'Consultant' : 'Consultants'}`,
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.qualifiedLeaders: {
            return {
                title: 'Qualified Leaders',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.spagv: {
            return {
                title: 'SPA Network Sales',
                withValue: true,
            };
        }
        case RankRequirementManager.rankRequirementMetricConstants.qnv: {
            return {
                title: 'Qualified Network Sales',
                withValue: true,
            };
        }
        default: {
            return {
                title: '',
                withValue: false,
            };
        }
        }
    }
}
