import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import { GetHostsParamsType, GetHostsResultType } from '@/api/graphQL/graphNodes/GetHostsQuery';
import store from '@/store';
import { DownlineProfileType, MyHostsType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetHostResultType, GetHostParamsType } from '@/api/graphQL/graphNodes/GetHostQuery';
import { UpdateDownlineProfileResultType } from '@/api/graphQL/graphNodes/UpdateDownlineProfileQuery';
import componentsControl from '@/modules/ComponentsControls';
import { UPDATED_SUCCESSFULLY } from '@/utils/messages/formValidation';
import { profile as defaultProfile } from './defaults';
import MyHostsRepository from './services/MyHostsRepository';
import { gbLabels } from '../labels';
import { LabelType, ObjectPropertyType } from '../types';
import { FORM_ERROR_TOOLTIP } from '../constants';
import hostModule, { Host } from './host';

@Module({
    namespaced: true, dynamic: true, store, name: 'myHosts',
})
@AutoMutations
export class MyHosts extends VuexModule {
    private host: DownlineProfileType = defaultProfile();

    private originalHost: DownlineProfileType = defaultProfile();

    private hosts: string[] = [];

    private hostLoading: boolean = false;

    private displayTooltip: boolean = false;

    private searchQuery: string = '';

    private searchLoading: boolean = false;

    private activeSponsorDetailsModal: string = '';

    private displaySearchResults: boolean = false;

    private displaySearch: boolean = false;

    private expandedHosts: string[] = [];

    private offset: number = 0;

    private searchedOptions: MyHostsType[] = [];

    private limit: number = 10;

    private formErrors: ErrorType = {};

    private total: number = 0;

    private minSearchLength: number = 3;

    private loadingInBackground: boolean = false;

    private searchedHosts: string[] = [];

    private labels: LabelType = { ...gbLabels() };

    @Mutation
    public setOffset(val: number) {
        this.offset = val;
    }

    @Mutation
    public setLabels(payload: LabelType) {
        this.labels = { ...payload };
    }

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public removeFormError(key) {
        const { formErrors } = this;
        delete formErrors[key];
        this.formErrors = { ...formErrors };
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors[payload.key] = payload.val;
    }

    @Mutation
    public setLimit(val: number) {
        this.limit = val;
    }

    @Mutation
    public setTotal(val: number) {
        this.total = val;
    }

    @Mutation
    setDisplaySearchResults(val: boolean) {
        this.displaySearchResults = val;
    }

    @Mutation
    setSearchedOptions(val: MyHostsType[]) {
        this.searchedOptions = val;
    }

    @Mutation
    public setHost(data: Partial<DownlineProfileType>) {
        this.host = { ...defaultProfile(), ...data };
    }

    @Mutation
    public setOriginalHost(data: Partial<DownlineProfileType>) {
        this.originalHost = { ...defaultProfile(), ...data };
    }

    @Mutation
    addSearchedHosts(val: string) {
        this.searchedHosts.push(val);
    }

    @Mutation
    clearSearchedHosts() {
        this.searchedHosts = [];
    }

    @Mutation
    setSearchLoading(val: boolean) {
        this.searchLoading = val;
    }

    @Mutation
    setSearchQuery(val: string) {
        this.searchQuery = val;
    }

    @Mutation
    setActiveSponsorDetailsModal(val: string) {
        this.activeSponsorDetailsModal = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setHostLoading(val: boolean) {
        this.hostLoading = val;
    }

    @Mutation
    public addHost(val: string) {
        this.hosts.push(val);
    }

    @Mutation
    public clearHosts() {
        this.hosts = [];
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedHosts.find((item) => item === val)) {
            const index = this.expandedHosts.findIndex((item) => item === val);
            this.expandedHosts.splice(index, 1);
        } else {
            this.expandedHosts.push(val);
        }
    }

    @Mutation
    toggleShowSearch() {
        this.displaySearch = !this.displaySearch;
    }

    @Mutation
    toggleShowHostSearch() {
        this.displaySearch = !this.displaySearch;
    }

    @Mutation
    clearExpandedHostsList() {
        this.expandedHosts = [];
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public async getMyHost(id: string) {
        try {
            this.setHostLoading(true);

            const params: GetHostParamsType = {
                id,
            };

            const data: GetHostResultType = await MyHostsRepository
                .getHost(params);

            const formattedData = ObjectProcessor.removeEmptyProperties(data.enrollee);
            this.setHost(formattedData);
            this.setOriginalHost(formattedData);
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setHostLoading(false);
        }
    }

    @Action()
    public async updateHost(enrolleeId: string) {
        try {
            if (Object.keys(this.formErrors).length === 0) {
                this.setHostLoading(true);

                const {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    id, parentId, rank, email, country, birthDate,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    profilePicture, ...data
                } = this.host;

                const result: UpdateDownlineProfileResultType = await MyHostsRepository
                    .updateHost(enrolleeId, data);

                const formattedData = ObjectProcessor
                    .removeEmptyProperties(result.updateDownlineProfile);
                this.setHost(formattedData);
                this.setOriginalHost(formattedData);
                componentsControl.showSuccessMessage({ message: UPDATED_SUCCESSFULLY });
            } else {
                this.displayFormErrorsTooltip();
            }
        } catch (e) {
            this.displayFormErrorsTooltip();
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
            throw e;
        } finally {
            this.setHostLoading(false);
        }
    }

    @Action()
    public async getMyHosts(payload: {
        loadPage?: boolean,
        offset?: number,
    }) {
        const { loadPage = false, offset = 0 } = payload;
        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setHostLoading(true);
            }

            const params: GetHostsParamsType = {
                limit: this.limit,
                offset,
                query: '',
            };

            const result = await MyHostsRepository.getHosts(params);

            this.clearHosts();

            this.setTotal(result.profile.searchHostsCount);
            result.profile.searchHosts.forEach((item) => {
                this.handleHostAdd(item);
                this.addHost(item.id);
            });

            this.setOffset(offset);
            this.clearExpandedHostsList();
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setHostLoading(false);
            this.setLoadingInBackground(false);
        }
    }

    @Action()
    handleHostAdd(host: MyHostsType) {
        if (hostModule.collection[host.id]) {
            hostModule.setData({
                id: host.id,
                val: {
                    ...hostModule.collection[host.id].data!,
                    ...host,
                },
            });
        } else {
            const newHost = new Host();
            newHost.id = host.id;
            newHost.data = { ...newHost.data, ...host };

            hostModule.addElement(newHost);
        }
    }

    @Action()
    async searchHosts(data: {
        query?: string|null,
        selectedSearch?: boolean,
        offset?: number,
    }
    = { query: null, selectedSearch: false }) {
        const {
            query, selectedSearch, offset = 0,
        } = data;

        try {
            if (selectedSearch) {
                this.setSearchLoading(true);
            }

            const params: GetHostsParamsType = {
                limit: this.limit,
                offset,
                query: query || query === '' ? query : this.searchQuery,
            };

            const result: GetHostsResultType = await MyHostsRepository
                .getHosts(params) as GetHostsResultType;

            const { searchHosts, searchHostsCount } = result.profile;

            if (selectedSearch) {
                searchHosts.forEach((item) => {
                    this.addSearchedHosts(item.id);
                    this.handleHostAdd(item);
                });
                this.setTotal(searchHostsCount);
            } else {
                this.setSearchedOptions(searchHosts);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setSearchLoading(false);
            }
        }
    }
}

export default getModule(MyHosts);
