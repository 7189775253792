// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { StatsType } from './types';

export default class GetIncomeSummaryRunTotalsQuery extends
    AbstractQueryResource<GetIncomeSummaryRunTotalsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($year: Int!) { 
            profile {
                runTotalYearPVBreakdown(year: $year) {
                    metric
                    value
                    label
                },
                runTotalYearOVBreakdown(year: $year) {
                    metric
                    value
                    label
                },
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getIncomeSummaryRunTotals';
    }
}

export type GetIncomeSummaryRunTotalsResultType = {
    profile: {
        runTotalYearPVBreakdown: StatsType[];
        runTotalYearOVBreakdown: StatsType[];
    }
}

export type GetIncomeSummaryRunTotalsParamsType = {
    year: number;
}
