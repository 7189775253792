
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import eventView from '@/modules/Event/EventView';
import system from '@/modules/System';
import { EventViewType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';
import auth from '@/modules/Auth';
import profile from '@/modules/Profile';
import tokenThreshold from '@/modules/TokenThreshold';
import impersonate from '@/modules/Impersonation';
import Skeleton from '../EventsSkeleton/Event/index.vue';
import EventInfo from './Info/index.vue';
import EventPersonDetails from './personDetails.vue';
import EventOrders from './orders.vue';

@Component({
    components: {
        EventInfo,
        EventPersonDetails,
        EventOrders,
        Skeleton,
    },
})
export default class EventView extends Vue {
    @Get(eventView) private loading!: boolean;

    @Get(eventView) private eventData!: EventViewType;

    @Get(eventView) private cancelDialogOpen!: boolean;

    @Get(system) private screenType!: string;

    @Get(eventView) private inviteLinkLoading!: boolean;

    @Get(profile, 'originalData.id') private myId!: string;

    @Get(tokenThreshold) private tokenThresholdLoading!: boolean;

    get partyStarted(): boolean {
        return dateManager.isBefore(this.eventData.eventDateFrom, this.currentDate);
    }

    get isEventEnded(): boolean {
        return dateManager.isAfter(this.currentDate, this.eventData.eventDateTo);
    }

    get invitations() {
        return eventView.invitations;
    }

    get currentDate() {
        return eventView.currentDayTime;
    }

    get canSeeShare(): boolean {
        const { myId } = this;
        const { ambassador, hostess, invitations } = this.eventData;

        return myId === ambassador.id || (hostess && myId === hostess.id)
            || !!invitations.find((invite) => invite.enrollee.id === myId);
    }

    get showAmbassador(): boolean {
        return this.eventData.ambassador.id !== this.myId;
    }

    get displayInvite(): boolean {
        const {
            eventData, eventCampaign, canSeeShare,
        } = this;

        return !eventData.canceled && !eventCampaign && eventData.confirmed
         && canSeeShare;
    }

    get eventCampaign() {
        return eventView.eventCampaignEnded;
    }

    get contentLoaded(): boolean {
        return !this.loading && !this.inviteLinkLoading && !this.tokenThresholdLoading;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    get selfHosted(): boolean {
        const { ambassador, hostess } = this.eventData;
        return hostess && ambassador.id === hostess.id;
    }

    get impersonating(): boolean {
        return impersonate.impersonating;
    }

    created() {
        const { $route, myId } = this;
        eventView.getEvent({ id: $route.params.id, myId });
        // eventView.getHostessRewards();
        tokenThreshold.getTokenThreshold();
    }

    handleEdit() {
        this.$router.push({ name: 'editEvent', params: { id: this.$route.params.id } });
    }

    // handleCancelModal() {
    //     eventView.setCancelDialogOpen(!this.cancelDialogOpen);
    // }

    // async handleCancel() {
    //     try {
    //         await eventView.cancelEvent();
    //         this.$router.push({ name: 'eventCalendar' });
    //     // eslint-disable-next-line no-empty
    //     } finally {}
    // }
}
