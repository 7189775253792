
import { Vue, Component, Prop } from 'vue-property-decorator';
import newStarters, { NewStarters } from '@/modules/NewStarters';
import newStartersLevels from '@/modules/NewStarters/levels';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import { NewStartersLevelsType, NewStartersType } from '@/api/graphQL/graphNodes/types';
import system from '@/modules/System';
import { Debounced } from '@/utils/debounced';

@Component({
    components: {},
})
export default class NewStartersHeader extends Vue {
    @Sync(newStarters) private searchQuery!: string;

    @Get(newStarters) private searchLoading!: boolean;

    @Get(newStarters) private minSearchLength!: number;

    @Get(newStarters) private searchedLegsOptions!: NewStartersType[];

    @Get(newStarters) private displaySearchResults!: boolean;

    @Get(newStarters) private displaySearch!: boolean;

    @Sync(newStarters) newStartersTimeFrame!: string;

    @Get(newStartersLevels) private levels!: NewStartersLevelsType[];

    @Get(system) private screenType!: string;

    @Prop() private loading!: boolean;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedLegsOptions.map((leg) => ({
            name: `${leg.firstName} ${leg.lastName}`,
            value: `${leg.firstName} ${leg.lastName}`,
        }));
    }

    get showSearch() {
        return this.screenType !== 'mobile' || this.displaySearch;
    }

    get headerContainerClasses() {
        return [
            'NewStartersHeader__HeaderContainer',
            { 'NewStartersHeader__HeaderContainer--hidden': !this.showSearch },
        ];
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-50',
            'pts-xsmall-size-100',
            'NewStartersHeader__Container',
            { 'NewStartersHeader__Container--show': this.showSearch },
        ];
    }

    get personalType(): string {
        return NewStarters.NEW_STARTERS_TYPE.personal;
    }

    get organisationalType(): string {
        return NewStarters.NEW_STARTERS_TYPE.organisational;
    }

    get timeFrameOptions(): SelectOptionsType[] {
        return this.levels.map((item) => ({
            name: item.label,
            value: item.key,
        }));
    }

    async handleNewStartersTimeFrameChange(data: string) {
        newStarters.setNewStartersTimeFrame(data);
        await newStarters.getData();
    }

    handleSelect(val) {
        newStarters.setSearchQuery(val.value);
        newStarters.setDisplaySearchResults(true);
        newStarters.search({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            newStarters.setDisplaySearchResults(false);
            newStarters.setSearchedOptionsLegs([]);
            newStarters.setSearchedLegs([]);
        }
        if (this.searchQuery.length >= this.minSearchLength) { newStarters.search(); }
    }
}
