
import { Vue, Component } from 'vue-property-decorator';
import marketingMaterial from '@/modules/MarketingMaterial';
import Content from '../Content/index.vue';
import Header from './Header/index.vue';

@Component({
    components: {
        Content,
        Header,
    },
})
export default class MarketingMaterial extends Vue {
    handleOpen() {
        const { id } = this.$route.params;
        marketingMaterial.getFiles({ subCategoryId: id });
    }

    beforeDestroy() {
        marketingMaterial.clearData();
    }
}
