import dateManager from '@/utils/time';
import { EventTotalsType, HostessRewardsType } from '@/api/graphQL/graphNodes/types';
import mockedDateManager from '@/utils/mocked-date-manager';
import { EVENT_TYPE, EVENT_ATTENDANCE_STATUS, EVENT_INVITATION_TYPE } from '@/modules/Event/constants';
import { enrollee as enrolleeData } from './enrollee';
import { ResolverCustomerOrderType, ResolverEventType } from '../types';
import { orderTotal, eventOrders } from './sales';
import { hostessRewards } from './hostessReward';
import getToken from '../services/tokenResolver';

const dayTimeFormatWithoutTime = 'YYYY-MM-DD';

const currentDate = mockedDateManager.getCurrentDate(dateManager.getDateFormat());

const events: ResolverEventType[] = [
    {
        id: '0',
        address: 'GVCC+29 Bloomsbury, London, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(-23, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(-17, currentDate),
        hostess: '3',
        eventDateFrom: `${dateManager.getDateWithOffset(-19, currentDate, dayTimeFormatWithoutTime)} 14:30`,
        eventDateTo: `${dateManager.getDateWithOffset(-19, currentDate, dayTimeFormatWithoutTime)} 20:00`,
        title: 'Isabella\'s party',
        description: '[{ "insert": "Whether you’re celebrating a birthday, " }, { "insert": "a wedding anniversary or Christmas,", "attributes": { "bold": true } }, { "insert": " the process of creating your printable party invitations is quick and easy. Click the menu icon and select the “Post” option. You will be prompted to enter an initial message, but you will be able to change this later on during the design process. Once you’ve typed your message, you will be taken to the main design screen — where the fun really starts." }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '1',
                enrollee: '1',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '2',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '3',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '4',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '5',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '6',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=0',
        eventCode: '',
    },
    {
        id: '1',
        address: '129-137 Drummond St, Kings Cross, London NW1 2NB, UK',
        ambassador: '5',
        campaignDateFrom: dateManager.getDateWithOffset(-23, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(-14, currentDate),
        hostess: null,
        eventDateFrom: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 17:30`,
        eventDateTo: `${dateManager.getDateWithOffset(-15, currentDate, dayTimeFormatWithoutTime)} 18:00`,
        title: 'Party title',
        description: '[{ "insert": "Please come to my party", "attributes": { "underline": true } }]',
        canceled: false,
        type: EVENT_TYPE.braFit,
        invitations: [
            {
                id: '7',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '8',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '9',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '10',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=1',
        eventCode: '',
    },
    {
        id: '2',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '3',
        campaignDateFrom: dateManager.getDateWithOffset(-20, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(-17, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(-18, currentDate, dayTimeFormatWithoutTime)} 20:00`,
        eventDateTo: `${dateManager.getDateWithOffset(-18, currentDate, dayTimeFormatWithoutTime)} 21:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '11',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '12',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=2',
        eventCode: '',
    },
    {
        id: '3',
        address: '129-137 Drummond St, Kings Cross, London NW1 2NB, UK',
        ambassador: '0',
        campaignDateFrom: dateManager.getDateWithOffset(-10, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(-7, currentDate),
        hostess: '2',
        eventDateFrom: `${dateManager.getDateWithOffset(-7, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        eventDateTo: `${dateManager.getDateWithOffset(-7, currentDate, dayTimeFormatWithoutTime)} 20:20`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '13',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=3',
        eventCode: '',
    },
    {
        id: '4',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '4',
        campaignDateFrom: dateManager.getDateWithOffset(-9, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(-8, currentDate),
        hostess: '5',
        eventDateFrom: `${dateManager.getDateWithOffset(-8, currentDate, dayTimeFormatWithoutTime)} 18:00`,
        eventDateTo: `${dateManager.getDateWithOffset(-8, currentDate, dayTimeFormatWithoutTime)} 22:30`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '14',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=4',
        eventCode: '',
    },
    {
        id: '5',
        address: '84-56 A1207, Hackney, London, UK',
        ambassador: '0',
        campaignDateFrom: `${dateManager.getDateWithOffset(-5, currentDate, dayTimeFormatWithoutTime)} 20:00`,
        campaignDateTo: dateManager.getDateWithOffset(4, currentDate),
        hostess: '0',
        eventDateFrom: `${dateManager.getDateWithOffset(4, currentDate, dayTimeFormatWithoutTime)} 20:00`,
        eventDateTo: `${dateManager.getDateWithOffset(4, currentDate, dayTimeFormatWithoutTime)} 21:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '15',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '16',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '17',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '18',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '19',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '20',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: true,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=5',
        eventCode: '',
    },
    {
        id: '6',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(0, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(1, currentDate),
        hostess: '3',
        eventDateFrom: `${dateManager.getDateWithOffset(1, currentDate, dayTimeFormatWithoutTime)} 16:40`,
        eventDateTo: `${dateManager.getDateWithOffset(1, currentDate, dayTimeFormatWithoutTime)} 23:30`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '21',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '22',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '23',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=6',
        eventCode: '',
    },
    {
        id: '7',
        address: '84-56 A1207, Hackney, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(1, currentDate),
        hostess: '5',
        eventDateFrom: `${dateManager.getDateWithOffset(1, currentDate, dayTimeFormatWithoutTime)} 15:00`,
        eventDateTo: `${dateManager.getDateWithOffset(1, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '24',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '25',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '26',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: false,
        shopUrl: 'https://www.google.com/search?q=7',
        eventCode: '',
    },
    {
        id: '8',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(2, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(3, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 20:00`,
        eventDateTo: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 21:45`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '27',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '28',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '29',
                enrollee: '9',
                attending: null,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '30',
                enrollee: '13',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=8',
        eventCode: '',
    },
    {
        id: '9',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '3',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(1, currentDate),
        hostess: '9',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 23:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '31',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '32',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '33',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '34',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '35',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '36',
                enrollee: '10',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=9',
        eventCode: '',
    },
    {
        id: '10',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '3',
        campaignDateFrom: dateManager.getDateWithOffset(4, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(7, currentDate),
        hostess: '7',
        eventDateFrom: `${dateManager.getDateWithOffset(5, currentDate, dayTimeFormatWithoutTime)} 17:00`,
        eventDateTo: `${dateManager.getDateWithOffset(5, currentDate, dayTimeFormatWithoutTime)} 21:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '37',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '38',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=10',
        eventCode: '',
    },
    {
        id: '11',
        address: '84-56 A1207, Hackney, London, UK',
        ambassador: '0',
        campaignDateFrom: dateManager.getDateWithOffset(5, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(7, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(7, currentDate, dayTimeFormatWithoutTime)} 15:00`,
        eventDateTo: `${dateManager.getDateWithOffset(7, currentDate, dayTimeFormatWithoutTime)} 20:40`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '39',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: false,
        shopUrl: 'https://www.google.com/search?q=11',
        eventCode: '',
    },
    {
        id: '12',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '0',
        campaignDateFrom: dateManager.getDateWithOffset(6, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(9, currentDate),
        hostess: '1',
        eventDateFrom: `${dateManager.getDateWithOffset(7, currentDate, dayTimeFormatWithoutTime)} 16:00`,
        eventDateTo: `${dateManager.getDateWithOffset(7, currentDate, dayTimeFormatWithoutTime)} 17:45`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '40',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '41',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '42',
                enrollee: '11',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '43',
                enrollee: '1',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=12',
        eventCode: '',
    },
    {
        id: '13',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '0',
        campaignDateFrom: dateManager.getDateWithOffset(6, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(7, currentDate),
        hostess: '2',
        eventDateFrom: `${dateManager.getDateWithOffset(6, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        eventDateTo: `${dateManager.getDateWithOffset(6, currentDate, dayTimeFormatWithoutTime)} 21:20`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '44',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=13',
        eventCode: '',
    },
    {
        id: '14',
        address: '84-56 A1207, Hackney, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(7, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(8, currentDate),
        hostess: '9',
        eventDateFrom: `${dateManager.getDateWithOffset(8, currentDate, dayTimeFormatWithoutTime)} 18:10`,
        eventDateTo: `${dateManager.getDateWithOffset(8, currentDate, dayTimeFormatWithoutTime)} 20:15`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '45',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '46',
                enrollee: '8',
                attending: null,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '47',
                enrollee: '13',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=14',
        eventCode: '',
    },
    {
        id: '15',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(0, currentDate),
        hostess: '3',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 16:15`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 20:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '48',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=15',
        eventCode: '',
    },
    {
        id: '16',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(9, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(10, currentDate),
        hostess: '6',
        eventDateFrom: `${dateManager.getDateWithOffset(9, currentDate, dayTimeFormatWithoutTime)} 14:00`,
        eventDateTo: `${dateManager.getDateWithOffset(9, currentDate, dayTimeFormatWithoutTime)} 14:50`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '49',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=16',
        eventCode: '',
    },
    {
        id: '17',
        address: '84-56 A1207, Hackney, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(11, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(11, currentDate),
        hostess: '5',
        eventDateFrom: `${dateManager.getDateWithOffset(11, currentDate, dayTimeFormatWithoutTime)} 17:00`,
        eventDateTo: `${dateManager.getDateWithOffset(11, currentDate, dayTimeFormatWithoutTime)} 22:50`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '50',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '51',
                enrollee: '1',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '52',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '53',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '54',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '55',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=17',
        eventCode: '',
    },
    {
        id: '18',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '3',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(0, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(-1, currentDate, dayTimeFormatWithoutTime)} 04:30`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 03:20`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '56',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=18',
        eventCode: '',
    },
    {
        id: '19',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(11, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(13, currentDate),
        hostess: '2',
        eventDateFrom: `${dateManager.getDateWithOffset(12, currentDate, dayTimeFormatWithoutTime)} 17:00`,
        eventDateTo: `${dateManager.getDateWithOffset(12, currentDate, dayTimeFormatWithoutTime)} 21:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '57',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '58',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=19',
        eventCode: '',
    },
    {
        id: '20',
        address: '84-56 A1207, Hackney, London, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(11, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(14, currentDate),
        hostess: '3',
        eventDateFrom: `${dateManager.getDateWithOffset(13, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        eventDateTo: `${dateManager.getDateWithOffset(13, currentDate, dayTimeFormatWithoutTime)} 21:30`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '59',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: false,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=20',
    },
    {
        id: '21',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(11, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(12, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(12, currentDate, dayTimeFormatWithoutTime)} 19:45`,
        eventDateTo: `${dateManager.getDateWithOffset(12, currentDate, dayTimeFormatWithoutTime)} 22:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '60',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=21',
    },
    {
        id: '22',
        address: '84-56 A1207, Hackney, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(13, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(15, currentDate),
        hostess: '3',
        eventDateFrom: `${dateManager.getDateWithOffset(14, currentDate, dayTimeFormatWithoutTime)} 15:00`,
        eventDateTo: `${dateManager.getDateWithOffset(14, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '61',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '62',
                enrollee: '11',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '63',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=22',
    },
    {
        id: '23',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '5',
        campaignDateFrom: dateManager.getDateWithOffset(13, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(17, currentDate),
        hostess: '7',
        eventDateFrom: `${dateManager.getDateWithOffset(16, currentDate, dayTimeFormatWithoutTime)} 18:00`,
        eventDateTo: `${dateManager.getDateWithOffset(16, currentDate, dayTimeFormatWithoutTime)} 19:50`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '64',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=23',
    },
    {
        id: '24',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(16, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(18, currentDate),
        hostess: '3',
        eventDateFrom: `${dateManager.getDateWithOffset(18, currentDate, dayTimeFormatWithoutTime)} 20:00`,
        eventDateTo: `${dateManager.getDateWithOffset(18, currentDate, dayTimeFormatWithoutTime)} 21:50`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '65',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '66',
                enrollee: '6',
                attending: null,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '67',
                enrollee: '12',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=24',
    },
    {
        id: '25',
        address: '84-56 A1207, Hackney, London, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(17, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(18, currentDate),
        hostess: '2',
        eventDateFrom: `${dateManager.getDateWithOffset(18, currentDate, dayTimeFormatWithoutTime)} 15:00`,
        eventDateTo: `${dateManager.getDateWithOffset(18, currentDate, dayTimeFormatWithoutTime)} 16:40`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '68',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=25',
    },
    {
        id: '26',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '4',
        campaignDateFrom: dateManager.getDateWithOffset(19, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(20, currentDate),
        hostess: '5',
        eventDateFrom: `${dateManager.getDateWithOffset(20, currentDate, dayTimeFormatWithoutTime)} 17:00`,
        eventDateTo: `${dateManager.getDateWithOffset(20, currentDate, dayTimeFormatWithoutTime)} 20:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '69',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: false,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=26',
    },
    {
        id: '27',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(20, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(21, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(20, currentDate, dayTimeFormatWithoutTime)} 19:30`,
        eventDateTo: `${dateManager.getDateWithOffset(20, currentDate, dayTimeFormatWithoutTime)} 22:45`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '70',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=27',
    },
    {
        id: '28',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(23, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(24, currentDate),
        hostess: '3',
        eventDateFrom: `${dateManager.getDateWithOffset(24, currentDate, dayTimeFormatWithoutTime)} 20:00`,
        eventDateTo: `${dateManager.getDateWithOffset(24, currentDate, dayTimeFormatWithoutTime)} 22:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '71',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: false,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=28',
    },
    {
        id: '29',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(25, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(25, currentDate),
        hostess: '2',
        eventDateFrom: `${dateManager.getDateWithOffset(26, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        eventDateTo: `${dateManager.getDateWithOffset(26, currentDate, dayTimeFormatWithoutTime)} 23:50`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '72',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '73',
                enrollee: '11',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '74',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '75',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '76',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '77',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '78',
                enrollee: '13',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '79',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '80',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=29',
    },
    {
        id: '30',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(27, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(30, currentDate),
        hostess: '9',
        eventDateFrom: `${dateManager.getDateWithOffset(28, currentDate, dayTimeFormatWithoutTime)} 19:30`,
        eventDateTo: `${dateManager.getDateWithOffset(29, currentDate, dayTimeFormatWithoutTime)} 12:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '81',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=30',
    },
    {
        id: '31',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '4',
        campaignDateFrom: dateManager.getDateWithOffset(29, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(30, currentDate),
        hostess: '6',
        eventDateFrom: `${dateManager.getDateWithOffset(30, currentDate, dayTimeFormatWithoutTime)} 16:00`,
        eventDateTo: `${dateManager.getDateWithOffset(30, currentDate, dayTimeFormatWithoutTime)} 21:45`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '82',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '83',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '84',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '85',
                enrollee: '1',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '86',
                enrollee: '9',
                attending: null,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '87',
                enrollee: '10',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '88',
                enrollee: '12',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=31',
    },
    {
        id: '32',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '5',
        campaignDateFrom: dateManager.getDateWithOffset(30, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(32, currentDate),
        hostess: '12',
        eventDateFrom: `${dateManager.getDateWithOffset(31, currentDate, dayTimeFormatWithoutTime)} 18:00`,
        eventDateTo: `${dateManager.getDateWithOffset(31, currentDate, dayTimeFormatWithoutTime)} 22:00`,
        title: 'Emily\'s night',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '89',
                enrollee: '11',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '90',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '91',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '92',
                enrollee: '12',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=32',
    },
    {
        id: '33',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(34, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(35, currentDate),
        hostess: '5',
        eventDateFrom: `${dateManager.getDateWithOffset(35, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        eventDateTo: `${dateManager.getDateWithOffset(35, currentDate, dayTimeFormatWithoutTime)} 22:45`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '93',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=33',
    },
    {
        id: '34',
        address: '84-56 A1207, Hackney, London, UK',
        ambassador: '4',
        campaignDateFrom: dateManager.getDateWithOffset(36, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(36, currentDate),
        hostess: '5',
        eventDateFrom: `${dateManager.getDateWithOffset(36, currentDate, dayTimeFormatWithoutTime)} 19:30`,
        eventDateTo: `${dateManager.getDateWithOffset(36, currentDate, dayTimeFormatWithoutTime)} 21:00`,
        title: 'Good evening show',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '94',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=34',
    },
    {
        id: '35',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(38, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(41, currentDate),
        hostess: '3',
        eventDateFrom: `${dateManager.getDateWithOffset(40, currentDate, dayTimeFormatWithoutTime)} 15:10`,
        eventDateTo: `${dateManager.getDateWithOffset(40, currentDate, dayTimeFormatWithoutTime)} 19:20`,
        title: 'Isabella\'s party',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '95',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },

            {
                id: '96',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '97',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '98',
                enrollee: '1',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '99',
                enrollee: '11',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=35',
    },
    {
        id: '36',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(53, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(56, currentDate),
        hostess: '5',
        eventDateFrom: `${dateManager.getDateWithOffset(54, currentDate, dayTimeFormatWithoutTime)} 17:45`,
        eventDateTo: `${dateManager.getDateWithOffset(54, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        title: 'Amelia\'s party',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '100',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=36',
    },
    {
        id: '37',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(65, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(71, currentDate),
        hostess: '7',
        eventDateFrom: `${dateManager.getDateWithOffset(70, currentDate, dayTimeFormatWithoutTime)} 19:40`,
        eventDateTo: `${dateManager.getDateWithOffset(70, currentDate, dayTimeFormatWithoutTime)} 22:00`,
        title: 'Charlotte\'s private party',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '101',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '102',
                enrollee: '12',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '103',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=37',
    },
    {
        id: '38',
        address: '48-2 Pinehurst Rd, Swindon SN2 1RH, UK',
        ambassador: '5',
        campaignDateFrom: dateManager.getDateWithOffset(-3, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(2, currentDate),
        hostess: '7',
        eventDateFrom: `${dateManager.getDateWithOffset(-1, currentDate, dayTimeFormatWithoutTime)} 19:45`,
        eventDateTo: `${dateManager.getDateWithOffset(-1, currentDate, dayTimeFormatWithoutTime)} 23:00`,
        title: 'Charlotte\'s party',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '104',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '105',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '106',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '107',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '108',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=38',
    },
    {
        id: '39',
        address: '185-59 Elizabeth Ave, London Y5 5FA, UK',
        ambassador: '7',
        campaignDateFrom: dateManager.getDateWithOffset(-2, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(1, currentDate),
        hostess: '5',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 16:20`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 21:20`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '109',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '110',
                enrollee: '11',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '111',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=39',
    },
    {
        id: '40',
        address: 'Ffoss Rd, Llanwrtyd Wells LD5 4RG, UK',
        ambassador: '4',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(1, currentDate),
        hostess: '6',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 13:25`,
        eventDateTo: `${dateManager.getDateWithOffset(1, currentDate, dayTimeFormatWithoutTime)} 04:00`,
        title: 'Emily\'s party',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '112',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '113',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '114',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '115',
                enrollee: '12',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '116',
                enrollee: '13',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=40',
    },
    {
        id: '41',
        address: 'St Pauls House, 23 Park Square S, Leeds LS1 2ND, UK',
        ambassador: '3',
        campaignDateFrom: dateManager.getDateWithOffset(-5, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(2, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 18:15`,
        eventDateTo: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 23:00`,
        title: 'Fun fun fun!!!',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '117',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '118',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '119',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '120',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '121',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=41',
    },
    {
        id: '42',
        address: '73A Elizabeth Ave, London N1 3BQ, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(0, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(1, currentDate),
        hostess: '2',
        eventDateFrom: `${dateManager.getDateWithOffset(1, currentDate, dayTimeFormatWithoutTime)} 19:40`,
        eventDateTo: `${dateManager.getDateWithOffset(1, currentDate, dayTimeFormatWithoutTime)} 22:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '122',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '123',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '124',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '125',
                enrollee: '13',
                attending: null,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=42',
    },
    {
        id: '43',
        address: '17 Rose Ave, Whitby YO21 3JA, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(0, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(2, currentDate),
        hostess: '3',
        eventDateFrom: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 13:20`,
        eventDateTo: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 17:15`,
        title: 'No man allowed night',
        description: '[{ "insert": "Party just for friends." }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '126',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '127',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '128',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '129',
                enrollee: '10',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '130',
                enrollee: '12',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=43',
    },
    {
        id: '44',
        address: '40-1 York Rd, Consett, UK',
        ambassador: '0',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(3, currentDate),
        hostess: '1',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 18:30`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 23:00`,
        title: 'Pink night',
        description: '[{ "insert": "Pink themed party" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '131',
                enrollee: '1',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '132',
                enrollee: '12',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '133',
                enrollee: '10',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: false,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=44',
    },
    {
        id: '45',
        address: '3-9 Howick St, Alnwick, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(-6, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(0, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 18:10`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 23:55`,
        title: 'Sophia\'s party',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '134',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '135',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '136',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '137',
                enrollee: '11',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '138',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '139',
                enrollee: '1',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '140',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '141',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '142',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=45',
    },
    {
        id: '46',
        address: '57-14 Gerrard Rd, The Angel, London, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(-3, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(3, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 21:15`,
        eventDateTo: `${dateManager.getDateWithOffset(3, currentDate, dayTimeFormatWithoutTime)} 03:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '143',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '144',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '145',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: false,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=46',
    },
    {
        id: '47',
        address: '15 North St, Plymouth, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(3, currentDate),
        hostess: '5',
        eventDateFrom: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 12:00`,
        eventDateTo: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 14:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '146',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '147',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '148',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '149',
                enrollee: '7',
                attending: null,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=47',
    },
    {
        id: '48',
        address: '125C Elizabeth Ave, London A7 5HR, UK',
        ambassador: '3',
        campaignDateFrom: dateManager.getDateWithOffset(-2, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(1, currentDate),
        hostess: '7',
        eventDateFrom: `${dateManager.getDateWithOffset(-1, currentDate, dayTimeFormatWithoutTime)} 14:15`,
        eventDateTo: `${dateManager.getDateWithOffset(-1, currentDate, dayTimeFormatWithoutTime)} 19:20`,
        title: 'Good evening with AS',
        description: '[{ "insert": "Don\'t be late!" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '150',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '151',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '152',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '153',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '154',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '155',
                enrollee: '10',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '156',
                enrollee: '12',
                attending: null,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=48',
    },
    {
        id: '49',
        address: '49-41 Gerrard Rd, The Angel, London, UK',
        ambassador: '5',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(2, currentDate),
        hostess: '6',
        eventDateFrom: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 18:15`,
        eventDateTo: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 21:35`,
        title: 'Evening show',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '157',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '158',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '159',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=49',
    },
    {
        id: '50',
        address: '16-4 Treventon Rise, St Columb Major, Saint Columb TR9 6AY, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(-3, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(0, currentDate),
        hostess: '2',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 11:30`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 14:30`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '160',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '161',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '162',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '163',
                enrollee: '10',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=50',
    },
    {
        id: '51',
        address: '14 Rolle St, Barnstaple EX31 1JN, UK',
        ambassador: '5',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(3, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 14:20`,
        eventDateTo: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 21:50`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '164',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '165',
                enrollee: '1',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '166',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '167',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '168',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '169',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '170',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        eventCode: '',
        confirmed: true,
        shopUrl: 'https://www.google.com/search?q=51',
    },
    {
        id: '52',
        address: 'A4066, St Clears, Carmarthen SA33 4BJ, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(4, currentDate),
        hostess: '3',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 16:00`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '171',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '172',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '173',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '174',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '175',
                enrollee: '12',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '176',
                enrollee: '1',
                attending: null,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '177',
                enrollee: '13',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=52',
    },
    {
        id: '53',
        address: 'Isle of North Uist HS6 5BU, UK',
        ambassador: '3',
        campaignDateFrom: dateManager.getDateWithOffset(-2, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(1, currentDate),
        hostess: '6',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 14:00`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 21:00`,
        title: 'Friends night',
        description: '[{ "insert": "Girls go wild!" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '178',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '179',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '180',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '181',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=53',
    },
    {
        id: '54',
        address: '1-45 Cambridge Rd, Thornaby, Stockton-on-Tees, UK',
        ambassador: '2',
        campaignDateFrom: dateManager.getDateWithOffset(-2, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(3, currentDate),
        hostess: '5',
        eventDateFrom: `${dateManager.getDateWithOffset(1, currentDate, dayTimeFormatWithoutTime)} 17:00`,
        eventDateTo: `${dateManager.getDateWithOffset(1, currentDate, dayTimeFormatWithoutTime)} 22:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '182',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '183',
                enrollee: '1',
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '184',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=54',
    },
    {
        id: '55',
        address: '109 Chichester Rd, Hulme, Manchester M15 5UP, UK',
        ambassador: '4',
        campaignDateFrom: dateManager.getDateWithOffset(-3, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(1, currentDate),
        hostess: '7',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 11:00`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 16:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '185',
                enrollee: '7',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '186',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '187',
                enrollee: '5',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=55',
    },
    {
        id: '56',
        address: '54 Y Vaarney Yiarg, Castletown, Isle of Man',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(0, currentDate),
        hostess: '2',
        eventDateFrom: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 14:00`,
        eventDateTo: `${dateManager.getDateWithOffset(0, currentDate, dayTimeFormatWithoutTime)} 23:00`,
        title: 'Party title',
        description: '[{ "insert": "Party description" }]',
        canceled: false,
        type: EVENT_TYPE.party,
        invitations: [
            {
                id: '188',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '189',
                enrollee: '3',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '190',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '191',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.declined,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=56',
    },
    {
        id: '57',
        address: '26 Lochancroft Ln, Wigtown, Newton Stewart DG8 9JA, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(-1, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(2, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 10:00`,
        eventDateTo: `${dateManager.getDateWithOffset(2, currentDate, dayTimeFormatWithoutTime)} 18:15`,
        title: 'Girls night!',
        description: '[{ "insert": "Girls go wild" }]',
        canceled: false,
        type: EVENT_TYPE.vipLinkShare,
        invitations: [
            {
                id: '192',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '193',
                enrollee: '9',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '194',
                enrollee: '13',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '195',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=57',
    },
    {
        id: '58',
        address: '4470  Lang Avenue, London, UK',
        ambassador: '1',
        campaignDateFrom: dateManager.getDateWithOffset(-7, currentDate),
        campaignDateTo: dateManager.getDateWithOffset(-3, currentDate),
        hostess: '4',
        eventDateFrom: `${dateManager.getDateWithOffset(-6, currentDate, dayTimeFormatWithoutTime)} 19:00`,
        eventDateTo: `${dateManager.getDateWithOffset(-6, currentDate, dayTimeFormatWithoutTime)} 21:00`,
        title: "The Dream's Flames",
        description: '[{ "insert": "Join us for our wonderful event. Hear our speaker discuss amazing things. Drinks and appetizers will be provided afterwards." }]',
        canceled: false,
        type: EVENT_TYPE.oneOnOne,
        invitations: [
            {
                id: '196',
                enrollee: '4',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.hostess,
            },
            {
                id: '197',
                enrollee: '6',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '198',
                enrollee: '8',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
            {
                id: '199',
                enrollee: '2',
                attending: EVENT_ATTENDANCE_STATUS.yes,
                type: EVENT_INVITATION_TYPE.guest,
            },
        ],
        allowGuestsToInvite: false,
        confirmed: true,
        eventCode: '',
        shopUrl: 'https://www.google.com/search?q=58',
    },
];

const eventInTimeFrame: (from: string, to: string, el:ResolverEventType)
    => boolean = (from, to, el) => {
        const realFrom = `${dateManager.getDateWithOffset(-1, from, dateManager.getDateFormat())} 23:59`;
        const realTo = `${dateManager.getDateTime(to, dateManager.getDateFormat())} 23:59`;

        if ((dateManager.isBefore(el.campaignDateFrom, realFrom)
    && dateManager.isAfter(el.campaignDateTo, realFrom))
    || (dateManager.isAfter(el.campaignDateFrom, realFrom)
    && dateManager.isBefore(el.campaignDateTo, realTo))
    || (dateManager.isBefore(el.campaignDateFrom, realTo)
    && dateManager.isAfter(el.campaignDateTo, realTo))) {
            return true;
        }
        return false;
    };

const findEvents:(
    date:string, offset:number, limit:number, type: string) =>
    ResolverEventType[] = (date, offset, limit, type) => {
        const enrollee = enrolleeData(getToken());

        if (!enrollee) {
            return [];
        }

        let ids: string[] = [];

        switch (type) {
        case ('personal'): {
            ids = enrollee.personal;
            break;
        }
        case ('centralTeam'): {
            ids = enrollee.centralTeam;
            break;
        }
        case ('organisational'): {
            ids = enrollee.organisational;
            break;
        }
        default: {
            return [];
        }
        }

        const eventsInTimeFrame: ResolverEventType[] = [];
        ids.forEach((item) => {
            const res = events.find((event) => event.id === item);
            if (res) {
                eventsInTimeFrame.push(res);
            }
        });

        const result = eventsInTimeFrame.filter((event) => {
            if (eventInTimeFrame(date, date, event)) {
                return true;
            }
            return false;
        });

        result.sort((a: ResolverEventType, b: ResolverEventType) => {
            if (dateManager.isAfter(b.campaignDateFrom, a.campaignDateFrom)) {
                return -1;
            } if (!dateManager.isBefore(b.campaignDateFrom, a.campaignDateFrom)) {
                if (dateManager.isAfter(b.eventDateFrom, a.eventDateFrom)) {
                    return -1;
                }
            }
            return 1;
        });

        return result.splice(offset, limit);
    };

const event: (id: string) => ResolverEventType = (id) => events
    .find((item: ResolverEventType) => item.id === id)!;

const getPartiesInTimeFrame: (from: string, to: string, type: string) =>
    ResolverEventType[] = (from = '', to = '', type) => {
        const enrollee = enrolleeData(getToken());
        const result: ResolverEventType[] = [];
        const withTimeframe = !!(from !== '' && to !== '');
        if (type === 'personal') {
            enrollee.personal.forEach((item) => {
                const el: ResolverEventType|undefined = events
                    .find((eventItem) => eventItem.id === item);
                if (el) {
                    if (!withTimeframe || eventInTimeFrame(from, to, el)) {
                        result.push(el);
                    }
                }
            });
        } else if (type === 'centralTeam') {
            enrollee.centralTeam.forEach((item) => {
                const el: ResolverEventType|undefined = events
                    .find((eventItem) => eventItem.id === item);
                if (el) {
                    if (!withTimeframe || eventInTimeFrame(from, to, el)) {
                        result.push(el);
                    }
                }
            });
        } else if (type === 'organisational') {
            enrollee.organisational.forEach((item) => {
                const el: ResolverEventType|undefined = events
                    .find((eventItem) => eventItem.id === item);
                if (el) {
                    if (!withTimeframe || eventInTimeFrame(from, to, el)) {
                        result.push(el);
                    }
                }
            });
        } else if (type === 'organisational_and_personal') {
            enrollee.organisational.forEach((item) => {
                const el: ResolverEventType|undefined = events
                    .find((eventItem) => eventItem.id === item);
                if (el) {
                    if (!withTimeframe || eventInTimeFrame(from, to, el)) {
                        result.push(el);
                    }
                }
            });
            enrollee.personal.forEach((item) => {
                const el: ResolverEventType|undefined = events
                    .find((eventItem) => eventItem.id === item);
                if (el) {
                    if (!withTimeframe || eventInTimeFrame(from, to, el)) {
                        result.push(el);
                    }
                }
            });
        }

        return result;
    };

const getPartiesTotalSales: (givenEvents: ResolverEventType[]) => number = (givenEvents) => {
    let totalSales: number = 0;

    givenEvents.forEach((eventItem) => {
        const eventOrdersList = eventOrders(eventItem.id);

        eventOrdersList.forEach((orderItem) => {
            const orderItemTotal: number = orderTotal(orderItem.id);
            totalSales += orderItemTotal;
        });
    });

    return (totalSales / 100);
};

const getAverageSalesPerEvent: (totalSales: number, eventsCount: number)
    => string = (totalSales, eventsCount) => (eventsCount === 0 ? '0' : String(
        (((totalSales * 100) / eventsCount) / 100),
    ));

const getAverageCustomersPerEvent: (items: ResolverEventType[]) => string = (items) => {
    const eventsCount: number = items.length;
    const customers: string[] = [];

    items.forEach((eventItem: ResolverEventType) => {
        const eventOrdersList = eventOrders(eventItem.id);
        const eventCustomers: string[] = eventOrdersList
            .reduce((ordersCustomers: string[], orderItem: ResolverCustomerOrderType) => {
                if (!ordersCustomers.includes(orderItem.customer)) {
                    ordersCustomers.push(orderItem.customer);
                }
                return ordersCustomers;
            }, []);

        eventCustomers.map((item) => customers.push(item));
    });

    return String((customers.length / eventsCount));
};

const getFinishedEvents: (givenEvents: ResolverEventType[]) => number = (givenEvents) => {
    const finishedEvents = givenEvents
        .filter((eventItem) => dateManager.isBefore(eventItem.campaignDateTo, currentDate));

    return finishedEvents.length;
};

const getBookedEvents: (givenEvents: ResolverEventType[]) => number = (givenEvents) => {
    const finishedEvents = givenEvents
        .filter((eventItem) => dateManager.isAfter(eventItem.campaignDateTo, currentDate));

    return finishedEvents.length;
};

const getProjectedSales: (averageSales: string, bookedParties: number, totalSales: number)
    => string = (averageSales, bookedParties, totalSales) => {
        const bookedPartiesSales = (Number(averageSales) * 100) * bookedParties;
        return String((bookedPartiesSales + (totalSales * 100)) / 100);
    };

const eventsStatistics: (
    data: { dateFrom?: string, dateTo?: string, type: string },
    dashboardQueryParams?: { month: string },
) => EventTotalsType = (data, dashboardQueryParams) => {
    let { dateFrom, dateTo } = data;
    const { type } = data;

    const withInterval = !!(dateFrom && dateTo);

    if (dashboardQueryParams) {
        if (dashboardQueryParams.month === 'thisMonth') {
            dateFrom = mockedDateManager.getFirstDayOfThisMonth();
            dateTo = mockedDateManager.getLastDayOfThisMonth();
        } else if (dashboardQueryParams.month === 'lastMonth') {
            dateFrom = mockedDateManager.getFirstDayOfLastMonth();
            dateTo = mockedDateManager.getLastDayOfLastMonth();
        }
    }

    const eventsData:
        ResolverEventType[] = getPartiesInTimeFrame(dateFrom || '', dateTo || '', type);

    const defaultEventTotals: EventTotalsType = {
        avgCustomersPerEvent: '0',
        avgSalesPerEvent: '0',
        totalEvents: 0,
        totalSales: '0',
        bookedEvents: 0,
        finishedEvents: 0,
        projectedSales: '0',
    };

    let eventStatistics: EventTotalsType = { ...defaultEventTotals };

    if (!withInterval) {
        const allTimeEventsData:
            ResolverEventType[] = getPartiesInTimeFrame('', '', type);
        const totalSales: number = getPartiesTotalSales(eventsData);
        const bookedEvents: number = getBookedEvents(eventsData);
        const allTimeTotalSales: number = getPartiesTotalSales(allTimeEventsData);
        const avgSalesPerEvent: string = getAverageSalesPerEvent(
            allTimeTotalSales, allTimeEventsData.length,
        );

        eventStatistics = {
            avgSalesPerEvent,
            avgCustomersPerEvent: getAverageCustomersPerEvent(eventsData),
            totalEvents: eventsData.length,
            totalSales: String(totalSales),
            bookedEvents,
            finishedEvents: getFinishedEvents(eventsData),
            projectedSales: String(totalSales + (bookedEvents * Number(avgSalesPerEvent))),
        };
    } else if (eventsData.length !== 0) {
        const totalSales: number = getPartiesTotalSales(eventsData);
        const bookedEvents: number = getBookedEvents(eventsData);
        const avgSalesPerEvent: string = getAverageSalesPerEvent(
            totalSales, eventsData.length,
        );

        eventStatistics = {
            avgSalesPerEvent,
            avgCustomersPerEvent: getAverageCustomersPerEvent(eventsData),
            totalEvents: eventsData.length,
            totalSales: String(totalSales),
            bookedEvents,
            finishedEvents: getFinishedEvents(eventsData),
            projectedSales: getProjectedSales(avgSalesPerEvent, bookedEvents, totalSales),
        };
    }

    return eventStatistics;
};

const calculateCalendarEventPerformance = (id: string) => {
    const orders = eventOrders(id);
    let total = 0;

    orders.forEach((order) => {
        total += orderTotal(order.id);
    });

    const rewards = hostessRewards()
        .filter((item: HostessRewardsType) => {
            const threshold = item.thresholdEUR * 100;
            return threshold < total;
        });

    const { length } = rewards;

    const reward = rewards[length - 1];

    return {
        eventTotal: `${total}`,
        tokens: length ? reward.bonusTokens : '0',
    };
};

export {
    events, event, findEvents, eventsStatistics, getPartiesTotalSales,
    calculateCalendarEventPerformance,
};
