
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { TransactionsType } from '@/api/graphQL/graphNodes/types';
import { TRANSACTION_CHANGE_TYPE } from '@/modules/SisterCredit/constants';
import sisterCredit from '@/modules/SisterCredit';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import system from '@/modules/System';
import dateManager from '@/utils/time';
// import { currencySign } from '@/modules/SisterCredit/helpers';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import currencyPrefix from '@/utils/currencyPrefix';

@Component
export default class SisterCreditTransactions extends Vue {
    @Get(sisterCredit) private transactions!: TransactionsType[];

    @Get(sisterCredit) private offset!: number;

    @Get(sisterCredit) private limit!: number;

    @Get(sisterCredit) private total!: number;

    @Get(sisterCredit) private loadingInBackground!: number;

    @Get(system) private screenType!: string;

    get isMobile() {
        return this.screenType === 'mobile';
    }

    get displayPagination() {
        return this.total > this.limit;
    }

    handleSubscene(transaction) {
        sisterCredit.setTransaction(transaction);
        this.$router.push({ name: 'sisterCreditTransaction' });
    }

    tableColumnClasses(transactionType) {
        return [
            'Ui__TableColumn--right',
            { 'SisterCredit__TableColumnChange--deposit': transactionType === TRANSACTION_CHANGE_TYPE.deposit },
            { 'SisterCredit__TableColumnChange--charge': transactionType === TRANSACTION_CHANGE_TYPE.charge },
        ];
    }

    formatDate(date) {
        return dateManager.getDateTime(date, dateManager.getDayTimeFormatUK());
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transactionCurrency(currency) {
        return currencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    transactionSign(type: string) {
        switch (type) {
        case TRANSACTION_CHANGE_TYPE.deposit:
            return '+';
        case TRANSACTION_CHANGE_TYPE.charge:
            return '-';
        default:
            return '';
        }
    }

    formatCents(unformatted) {
        return Number(unformatted).toFixed(2);
    }

    resolveId(transaction: TransactionsType) {
        const { sale } = transaction;

        if (sale && sale.id) {
            return sale.id;
        }
        return transaction.refId;
    }

    async handlePagination(params: PaginationParamsType) {
        try {
            const { offset } = params;
            await sisterCredit.getSisterCreditData({ loadPage: true, offset });
            scrollToTop('#app > div.App__Layout > div > div.Scene__Content.Scene__Content--noPadding');
        // eslint-disable-next-line no-empty
        } finally {}
    }
}
