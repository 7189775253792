
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ProductType } from '@/api/graphQL/graphNodes/types';
import CurrencyPrefix from '@/utils/currencyPrefix';

@Component
export default class OrdersListItemProduct extends Vue {
    @Prop() private product!: ProductType;

    @Prop() private country!: string;

    @Prop() private commissionableOrder!: boolean;

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(this.country);
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }
}
