
import { Vue, Component, Prop } from 'vue-property-decorator';
import { HostessRewardsType } from '@/api/graphQL/graphNodes/types';
import CurrencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class HostessRewards extends Vue {
    @Prop() private hostessRewardsSorted!: HostessRewardsType[];

    get currency(): string {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    formattedRewardRange(index) {
        const current = this.hostessRewardsSorted[index];
        const next = this.hostessRewardsSorted[index + 1];

        if (next) {
            const toPrice = `${next.thresholdGBP - 1}.99`;
            return `${this.currency}${current.thresholdGBP} - ${this.currency}${toPrice}`;
        }

        return `${this.currency}${current.thresholdGBP}+`;
    }
}
