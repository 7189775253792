import { GetOrdersParamsType } from '@/api/graphQL/graphNodes/GetOrdersQuery';
import { OrdersFilterType } from '@/api/graphQL/graphNodes/types';
import { ObjectProcessor } from '@plumtreesystems/utils';
import dateManager from '@/utils/time';

const resolveFilters = (
    filters: OrdersFilterType,
    query: string,
    limit: number,
    offset: number,
): GetOrdersParamsType => {
    const { onlyPurchased, month, ...otherFilters } = filters;
    let formattedMonth = {};

    if (month) {
        const diff = dateManager.getDifference(dateManager.getCurrentDate(), `${month}-01`, 'month');

        formattedMonth = {
            from: `${month}-01 00:00:00`,
            to: `${dateManager.getLastDayOfMonth(diff)} 23:59:59`,
        };
    }

    return {
        onlyPurchased,
        query,
        limit,
        offset,
        ...ObjectProcessor.removeEmptyProperties({ ...formattedMonth, ...otherFilters }),
    };
};

export default resolveFilters;
