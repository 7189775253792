import { MyCustomersType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import parentObject from './reusable/listItemEnrolleeParent';

export default class GetCustomersQuery extends AbstractQueryResource<GetMyCustomersResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($query: String!, $offset: Int, $limit: Int, $direct: Boolean, $type: String!, $sellerId: String!) {
            profile {
                searchCustomers(query: $query, offset: $offset, limit: $limit, direct: $direct, type: $type) {
                    id,
                    firstName,
                    lastName,
                    rank {
                        label,
                    },
                    phoneNumber,
                    secondPhoneNumber,
                    address,
                    secondAddress,
                    county,
                    country,
                    town,
                    postCode,
                    shadow,
                    email,
                    editable,
                    totalSales(sellerId: $sellerId),
                    searchOrdersCount(query: "", onlyPurchased: true),
                    activeEventsCount,
                    ${parentObject.getQuery()},
                    ownedEvents {
                        id,
                        title,
                        totalCommissionableValue,
                    }
                },
                customerSpend(type: $type),
                searchCustomersCount(query: $query, direct: $direct, type: $type)
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getMyCustomers';
    }
}

export type GetMyCustomersResultType = {
    profile: {
        searchCustomers: MyCustomersType[];
        searchCustomersCount: number;
        customerSpend: number;
    };
}

export type GetMyCustomersParamsType = {
    offset: number|null;
    limit: number|null;
    query: string;
    direct: boolean|null;
    type: string;
    sellerId: string;
}
