import API from '@/api/graphQL';
import { GetDashboardParamsType } from '@/api/graphQL/graphNodes/GetDashboardQuery';

export default class DashboardRepository {
    static getData(data: GetDashboardParamsType) {
        return API
            .getDashboard()
            .query(data);
    }

    static getHostessData() {
        return API
            .getHostessDashboard()
            .query();
    }
}
