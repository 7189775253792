
import { Component, Vue } from 'vue-property-decorator';
import noPermission from '@/modules/NoPermission';
import { Get } from '@/utils/vuex-module-mutators';
import { ErrorType } from '@plumtreesystems/utils';
import {
    ACCESS_FORBIDDEN_TNC, ACCESS_FORBIDDEN_KIT, ACCESS_FORBIDDEN_ID_CHECK,
} from '@/utils/responseErrorsProcessor/constants';
import AmbassadorKit from './ambassadorKit/index.vue';
import TermsAndConditions from './termsAndConditions/index.vue';
import UnderInspection from './underInspection/index.vue';

@Component({
    components: {
        AmbassadorKit,
        TermsAndConditions,
        UnderInspection,
    },
})
export default class NoPermission extends Vue {
    @Get(noPermission) private errors!: ErrorType;

    @Get(noPermission) private loading!: boolean;

    get type(): null|string {
        if (this.errors[ACCESS_FORBIDDEN_TNC]) {
            return ACCESS_FORBIDDEN_TNC;
        } if (this.errors[ACCESS_FORBIDDEN_KIT]) {
            return ACCESS_FORBIDDEN_KIT;
        } if (this.errors[ACCESS_FORBIDDEN_ID_CHECK]) {
            return ACCESS_FORBIDDEN_ID_CHECK;
        }
        return null;
    }

    get isTNC(): boolean {
        return this.type === ACCESS_FORBIDDEN_TNC;
    }

    get isAmbassadorKit(): boolean {
        return this.type === ACCESS_FORBIDDEN_KIT;
    }

    get isIdCheck(): boolean {
        return this.type === ACCESS_FORBIDDEN_ID_CHECK;
    }

    mounted() {
        if (this.isAmbassadorKit) {
            noPermission.getProfileInfo();
        }
    }

    created() {
        noPermission.clearData();
    }
}

