import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import {
    BaseOrderType, EventOrderType, EnrolleeCustomerOrderType,
} from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetCustomersOrdersParamsType, GetCustomersOrdersResultType } from '@/api/graphQL/graphNodes/GetCustomerOrdersQuery';
import MyCustomersRepository from './services/MyCustomersRepository';
import { initialCustomerOrdersData, initialEnrolleeCustomerOrderData } from './defaults';
import myCustomers from './index';

@Module({
    namespaced: true, dynamic: true, store, name: 'myCustomersOrders',
})
@AutoMutations
export class MyCustomersOrders extends VuexModule {
    private ordersObject: EnrolleeCustomerOrderType = initialEnrolleeCustomerOrderData();

    private searchQuery: string = '';

    private searchLoading: boolean = false;

    private loading: boolean = false;

    private activeSponsorDetailsModal: string = '';

    private displaySearchResults: boolean = false;

    private orderDetailsDialogOpen: boolean = false;

    private provideOrderDetails: EventOrderType = initialCustomerOrdersData();

    private displaySearch: boolean = false;

    private expandedOrders: string[] = [];

    private offset: number = 0;

    private searchedOrdersOptions: BaseOrderType[] = [];

    private limit: number = 10;

    private minSearchLength: number = 3;

    private orderTotal: number = 0;

    private loadingInBackground: boolean = false;

    private searchedOrders: BaseOrderType[] = [];

    @Mutation
    public setOffset(val: number = 0) {
        this.offset = val;
    }

    @Mutation
    public setLimit(val: number) {
        this.limit = val;
    }

    @Mutation
    setDisplaySearchResults(val: boolean) {
        this.displaySearchResults = val;
    }

    @Mutation
    setSearchedOrdersOptions(val: BaseOrderType[]) {
        this.searchedOrdersOptions = [...val];
    }

    @Mutation
    setSearchedOrders(val: BaseOrderType[]) {
        this.searchedOrders = [...val];
    }

    @Mutation
    setSearchLoading(val: boolean) {
        this.searchLoading = val;
    }

    @Mutation
    public setOrderTotal(val: number) {
        this.orderTotal = val;
    }

    @Mutation
    setSearchQuery(val: string) {
        this.searchQuery = val;
    }

    @Mutation
    setActiveSponsorDetailsModal(val: string) {
        this.activeSponsorDetailsModal = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public toggleOrderDetailsDialog() {
        this.orderDetailsDialogOpen = !this.orderDetailsDialogOpen;
    }

    @Mutation
    public setProvideOrderDetails(order: EventOrderType) {
        this.provideOrderDetails = { ...order };
    }

    @Mutation
    public setCustomerOrdersData(val: EnrolleeCustomerOrderType
    = initialEnrolleeCustomerOrderData()) {
        this.ordersObject = { ...val };
    }

    @Mutation
    toggleExpandedOrdersList(val: string) {
        if (this.expandedOrders.find((item) => item === val)) {
            const index = this.expandedOrders.findIndex((item) => item === val);
            this.expandedOrders.splice(index, 1);
        } else {
            this.expandedOrders.push(val);
        }
    }

    @Mutation
    clearExpandedOrdersList() {
        this.expandedOrders = [];
    }

    @Action()
    public async getCustomerOrders(data: {
        loadPage?: boolean,
        offset?: number,
    }) {
        const { loadPage = false, offset = 0 } = data;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const params: GetCustomersOrdersParamsType = {
                limit: this.limit,
                offset,
                query: '',
                type: myCustomers.getCustomerType,
            };

            const result = await MyCustomersRepository
                .getCustomersOrders(params, 'allCustomersOrder') as GetCustomersOrdersResultType;
            const enrollee = result.profile as EnrolleeCustomerOrderType;
            this.setCustomerOrdersData(enrollee);
            this.setOffset(offset);
            this.setOrderTotal(enrollee.searchCustomersOrdersCount);
            this.clearExpandedOrdersList();
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingInBackground(false);
            this.setLoading(false);
        }
    }

    @Action()
    async searchOrders(data: {
        query?: string|null,
        selectedSearch?: boolean,
        offset?: number,
    }
    = { query: null, selectedSearch: false }) {
        const {
            query, selectedSearch, offset = 0,
        } = data;

        try {
            if (selectedSearch) {
                this.setSearchLoading(true);
            }

            const params: GetCustomersOrdersParamsType = {
                limit: this.limit,
                offset,
                query: query || query === '' ? query : this.searchQuery.replace('#', ''),
                type: myCustomers.getCustomerType,
            };

            const result = await MyCustomersRepository
                .getCustomersOrders(params, 'allCustomersOrder') as GetCustomersOrdersResultType;

            const { searchCustomersOrders, searchCustomersOrdersCount } = result.profile;

            if (selectedSearch) {
                this.setSearchedOrders(searchCustomersOrders);
                this.setOrderTotal(searchCustomersOrdersCount);
            } else {
                this.setSearchedOrdersOptions(searchCustomersOrders);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setSearchLoading(false);
            }
        }
    }
}

export default getModule(MyCustomersOrders);
