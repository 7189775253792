import { AssetType, GenealogyEnrolleeType } from '@/api/graphQL/graphNodes/types';

export const defaultLeg: () => GenealogyEnrolleeType = () => ({
    ambassadorLegsCount: 0,
    email: '',
    firstName: '',
    lastName: '',
    id: '',
    leaderLegsCount: 0,
    personalRecruitsCount: 0,
    address: '',
    secondAddress: '',
    postCode: '',
    country: '',
    county: '',
    town: '',
    secondPhoneNumber: '',
    phoneNumber: '',
    parent: {
        address: '',
        firstName: '',
        lastName: '',
        secondAddress: '',
        postCode: '',
        country: '',
        county: '',
        town: '',
        secondPhoneNumber: '',
        email: '',
        id: '',
        phoneNumber: '',
        rank: {
            id: '',
            label: '',
            power: 0,
        },
        stats: [],
    },
    rank: {
        id: '',
        label: '',
        power: 0,
    },
    stats: [],
    monthsSincePv: 0,
});

export const defaultAsset: () => AssetType = () => ({
    category: '',
    fileName: '',
    id: '',
    title: '',
    uploadDate: '',
    link: '',
    priority: -1,
    context: [],
    originalFileName: '',
    subCategory: null,
});
