
import { Vue, Component, Prop } from 'vue-property-decorator';
import LegItem from '@/projectComponents/enrolleeLegItem/Item/index.vue';
import { CGet, Get } from '@/utils/vuex-module-mutators';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import risingStars from '@/modules/RisingStars';
import { QUALIFIED_PERSONAL_RECRUIT_PS } from '@/modules/RisingStars/constants';
import risingStarsLegs from '@/modules/RisingStars/leg';
import {
    CommissionLevelType, GenealogyEnrolleeType, RankType,
    RisingStarsType,
} from '@/api/graphQL/graphNodes/types';
import rank from '@/modules/Rank';
import RisingStarsStatusTooltipContent from '@/projectComponents/risingStarsStatusTooltipContent/index.vue';
import currencyPrefix from '@/utils/currencyPrefix';
import TrackCommission from './TrackCommission/index.vue';
import calculateStatus from '../services/calculateStatus';

@Component({
    components: {
        LegItem,
        RisingStarsStatusTooltipContent,
        TrackCommission,
    },
})
export default class LegsList extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ required: true }) private impersonating!: boolean;

    @CGet(risingStarsLegs) private data!: GenealogyEnrolleeType;

    @CGet(risingStarsLegs) private loading!: boolean;

    @Get(risingStars) private expandedRisingStars!: string[];

    @Get(rank) private ranks!: RankType[];

    @Get(risingStars) private activeSponsorDetailsModal!: string;

    @Get(risingStars) private sortType!: string;

    @Get(risingStars) private commissionLevels!: CommissionLevelType[];

    get calculatedStatus(): string {
        return calculateStatus(this.data);
    }

    get displayInactive(): boolean {
        return this.data.monthsSincePv > 0;
    }

    get inactiveMonthLabel(): string {
        if (this.data.monthsSincePv === 1) {
            return 'month';
        }
        return 'months';
    }

    get currencyPrefix() {
        return currencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get psRequirement(): string {
        return `${this.currencyPrefix}${QUALIFIED_PERSONAL_RECRUIT_PS}`;
    }

    getRisingStarsExpanded(leg: RisingStarsType) {
        return !!this.expandedRisingStars.find((item) => item === leg.id);
    }

    handleExpandedContentToggle(val: string) {
        risingStars.toggleExpandedList(val);
    }

    handleSponsorDetailDialog(val: string) {
        risingStars.setActiveSponsorDetailsModal(val);
    }
}
