
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import incomeSummary from '@/modules/IncomeSummary';
import { IncomeSummaryCommissionRunsType, StatsType } from '@/api/graphQL/graphNodes/types';
import RunTotalYearOVBreakdownChart from './RunTotalYearOVBreakdownChart/index .vue';
import RunTotalYearPVBreakdownChart from './RunTotalYearPVBreakdownChart/index .vue';

@Component({
    components: {
        RunTotalYearOVBreakdownChart,
        RunTotalYearPVBreakdownChart,
    },
})
export default class RunTotal extends Vue {
    @Get(incomeSummary) private yearSelected!: string;

    @Get(incomeSummary) private commissionRuns!: IncomeSummaryCommissionRunsType[];

    @Get(incomeSummary) private runTotalYearPVBreakdown!: StatsType[];

    get getOptions(): SelectOptionsType[] {
        return incomeSummary.yearsOptions.map((item) => ({
            name: `${item}`,
            value: item,
        }));
    }

    get showNetworkSales(): boolean {
        return this.runTotalYearPVBreakdown.length > 0;
    }

    handleSelect(val: number) {
        incomeSummary.setYearSelected(val);
        incomeSummary.getRunTotals();
    }
}
