import API from '@/api/graphQL';
import { GetHostsParamsType } from '@/api/graphQL/graphNodes/GetHostsQuery';
import { GetHostParamsType } from '@/api/graphQL/graphNodes/GetHostQuery';
import { DownlineInputProfileType } from '@/api/graphQL/graphNodes/types';
import { GetHostDetailsParamsType } from '@/api/graphQL/graphNodes/GetHostDetailsQuery';

export default class MyHostsRepository {
    static getHosts(params: GetHostsParamsType) {
        return API
            .getHosts()
            .query(params);
    }

    static getHost(params: GetHostParamsType) {
        return API
            .getHost()
            .query(params);
    }

    static getHostDetails(params: GetHostDetailsParamsType) {
        return API
            .getHostDetails()
            .query(params);
    }

    static updateHost(id: string, profile: DownlineInputProfileType) {
        return API
            .updateDownline()
            .query({ id, profile });
    }
}
