// eslint-disable-next-line import/no-cycle
import { RisingStars } from '@/modules/RisingStars';
import { ResolverEnrolleeType } from '../types';
import { enrollee } from './enrollee';
import { ranks } from './ranks';
import { stats } from './stats';
import { rankRequirement } from './rankRequirements';

const risingStars: (enrolleeId: string, params: { sort?: string,
     limit?: number, offset?: number, type?: string})
 => ResolverEnrolleeType[] = (enrolleeId, params) => {
     const { limit, offset, type } = params;

     const enrolleeItem: ResolverEnrolleeType = enrollee(enrolleeId);

     let res = enrolleeItem.risingStars.map((element) => enrollee(element));

     if (type === RisingStars.SORT_TYPES.top_sellers) {
         res = res.filter((item) => item.totals.pv > 500);
     } else if (type === RisingStars.SORT_TYPES.team_builders) {
         res = res.filter((item) => item.totals.ov > 0);
     } else if (type === RisingStars.SORT_TYPES.inactives) {
         return [];
     }

     if (params.sort === '') {
         res = res.sort((a, b) => b.totals.pv - a.totals.pv);

         if ((!!offset || offset === 0) && limit) {
             return res.splice(offset, limit);
         }

         return res;
     }

     const taRank = ranks.find((rank) => rank.label === 'Affiliate');
     const taReq = taRank ? rankRequirement(taRank!.id) : [];
     const resWithTa = res.map((item) => {
         const itemStats = stats(item.id);
         let total = 0;
         let totalVal = 0;
         taReq.forEach((requirement) => {
             const stat = itemStats.find((statItem) => statItem.metric === requirement.metric);
             if (Number(stat!.value) >= Number(requirement.value)) {
                 totalVal += 100;
             } else {
                 totalVal += Math.floor((Number(stat!.value) * 100) / Number(requirement.value));
             }

             ++total;
         });

         return {
             enrollee: item, cof: totalVal / total,
         };
     });

     return resWithTa.sort((a, b) => b.cof - a.cof).map((item) => item.enrollee);
 };

export {
    risingStars,
};
