// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { NewStartersLevelsType } from './types';

export default class GetNewStartersLevelsQuery
    extends AbstractQueryResource<GetNewStartersLevelsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            newStartersLevels {
                key,
                label,
                days,
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getNewStartersLevels';
    }
}

export type GetNewStartersLevelsResultType = {
    newStartersLevels: NewStartersLevelsType[];
}
