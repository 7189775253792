var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.elementClasses},[_c('div',{staticClass:"SearchSelect__Control"},[_c('div',{ref:"searchInputContainer",staticClass:"SearchSelect__Slot Ripple",attrs:{"aria-haspopup":"listbox","tabindex":"0","aria-expanded":_vm.open},on:{"click":_vm.openSelect,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeWithEsc.apply(null, arguments)}}},[(_vm.label)?_c('label',{staticClass:"SearchSelect__Label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('input',{staticClass:"SearchSelect__Input",attrs:{"id":_vm.id,"type":"text","disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"blur":function($event){return _vm.elementFocused(false)},"focus":function($event){return _vm.elementFocused(true)},"input":function($event){return _vm.handleInput($event.target.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSubmit.apply(null, arguments)}}})]),(_vm.options)?_c('div',{class:[
                'SearchSelect__SelectOptionsContainer',
                { 'SearchSelect__SelectOptionsContainer--open' : _vm.open }
            ],on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeWithEsc.apply(null, arguments)}}},[_c('ul',{attrs:{"role":"listbox","tabindex":_vm.open ? 0 : -1}},_vm._l((_vm.options),function(option){return _c('li',{key:option.value,attrs:{"role":"option","aria-selected":option.value === _vm.value,"tabindex":"0"},on:{"click":function($event){return _vm.handleSelect(option)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleKeyboardSelect(option)}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)]):_vm._e(),_c('div',{class:[
                'SearchSelect__InfoContainer',
                { 'SearchSelect__InfoContainer--open' : _vm.infoTextOpen }
            ],on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeWithEsc.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.noOptionsText)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }