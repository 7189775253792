const enrollee = `type Enrollee {
    id: String!,
    parentId: String,
    totals: Volume!,
    qualifiedLeaderLegsCount: Int!,
    leaderLegsCount: Int!,
    risingStarsCount(type: String): Int!,
    ambassadorLegsCount: Int!,
    personalRecruitsCount: Int!,
    directDownline(limit: Int!, offset: Int!): [Enrollee],
    searchDirectDownline(query: String!): [Enrollee],
    directDownlineCount(aofFilter: String, from: DateTime, to: DateTime): Int!,
    leaderLegs(limit: Int!, offset: Int!): [Enrollee],
    searchLeaderLegs(query: String!): [Enrollee],
    risingStars(sort: String, limit: Int!, offset: Int!, type: String): [Enrollee],
    searchRisingStars(query: String!, type: String): [Enrollee],
    stats(from: DateTime, to: DateTime, runId: String, rankId: String): [Stats],
    rankId: String!,
    rank: Rank!,
    payRank: Rank,
    email: String,
    phoneNumber: String!,
    secondPhoneNumber: String,
    address: String!,
    secondAddress: String,
    town: String,
    county: String,
    postCode: String,
    country: String,
    searchCustomers(query: String!, offset: Int, limit: Int, direct: Boolean, type: String): [Enrollee],
    searchCustomersCount(query: String!, direct: Boolean, type: String): Int!,
    uplineLeader: Enrollee,
    totalSales(sellerId: String): String!,
    additionalCommission: String!,
    ctb: String!,
    leadershipBonus: String!,
    centralTeamBonus: String!,
    paidForKit: Boolean,
    agreedToTermsAndConditions: Boolean!,
    searchHosts(query: String!, offset: Int, limit: Int): [Enrollee],
    searchHostsCount(query: String!): Int!,
    activeAmbassadorsCountByLevel (from: DateTime, to: DateTime): [Stats],
    qualifiedAmbassadorsCountByLevel (from: DateTime, to: DateTime): [Stats],
    bankDetails: BankDetails,
    parent: Enrollee,
    joinDate: String,
    kitPurchaseDate: String,
    newCustomersInCentralTeam (from: DateTime, to: DateTime): Int!,
    newStarters (date: DateTime!, type: String!): [Enrollee],
    searchNewStarters(query: String!, level: String!, type: String!): [Enrollee],
    centralTeamCount: Int!
    profilePicture: Asset
    organizationCount: Int!,
    newStartersCount(date: DateTime!, type: String!, query: String): Int!,
    qualifiedLeaderBranchIds: [String],
    qualifiedOrgLeaderCount: Int!,
    newStartersCommissionEngine(level: String!, type: String!, limit: Int!, offset: Int!): [Enrollee],
    newStartersCountCommissionEngine(level: String!, type: String!): Int!,
    newStartersCountByLevel: [Stats],
    vanityUrl: String!,
    shopUrl: String!,
    shopLoginUrl: String!,
    customerNumber: String,
    canRegister: Boolean,
    firstName: String,
    lastName: String,
    birthDate: String,
    order(orderId: String!): Sale,
    searchOrders(query: String!, offset: Int, limit: Int, prefix: String, onlyPurchased: Boolean!, from: String, to: String): [Sale],
    searchOrdersCount(query: String!, prefix: String, onlyPurchased: Boolean!, from: String, to: String): Int!,
    searchOrdersSalesTotal(query: String!, prefix: String, onlyPurchased: Boolean!, from: String, to: String): String!,
    searchCustomersOrders(query: String!, offset: Int, limit: Int, type: String): [Sale],
    searchCustomersOrdersCount(query: String!, type: String): Int!,
    incomeSummary(from: DateTime!, to: DateTime!): Income,
    lexisNexisStatus: String,
    shadow: Boolean!,
    hidden: Boolean,
    commissionStatements: [CommissionStatement],
    balance: Stats,
    transactions (offset: Int, limit: Int): [Transaction],
    transactionsCount: Int!,
    participatedRuns: [Run],
    reports(runId: String): [Report],
    editable: Boolean!,
    eventPerformance: EventPerformance,
    newStarterTotals: [NewStarterTotals],
    soldOrdersCount: Int!,
    directHostsCount: Int!,
    hasReleventNewStarters: Boolean!,
    newStarterTotalsByMilestones: [NewStarterTotals],
    newStartersByMilestone(key: String!, type: String!, offset: Int, limit: Int): [Enrollee],
    newStartersByMilestoneCount(key: String!, type: String!, query: String): Int!,
    searchNewStartersByMilestone(key: String!, type: String!, query: String): [Enrollee],
    runTotalIncomeSummary(runId: String!): IncomeSummaryItem!,
    runTotalYearPVBreakdown(year: Int): [Stats],
    runTotalYearOVBreakdown(year: Int): [Stats],
    activeEventsCount: Int!,
    ownedEvents(active: Boolean): [Event],
    customerSpend(type: String): Float!,
    monthsSinceActive: Int!,
    monthsSincePv: Int!,
}`;

const volume = `type Volume {
    pv: Float!,
    gv: Float!,
    ov: Float!,
    spa_gv: Float!,
}`;

const eventPerformance = `type EventPerformance {
    tokens: String!,
    rewards: [EventPerformanceReward],
    tokenRewardUrl: String,
}`;

const eventPerformanceReward = `type EventPerformanceReward {
    rewardUrl: String!,
    expiryDate: String,
    unlockedTiers: [String],
}`;

const newStarterTotals = `type NewStarterTotals {
    level: NewStartersLevels!,
    value: String!,
    required: String!,
    status: Boolean
}`;

const incomeSummaryItem = `type IncomeSummaryItem {
    metricStats: [Stats],
    bonusStats: [Stats],
    milestoneStats: [Stats],
    saleStats: [Stats],
    payRank: Rank,
    rank: Rank!,
}`;

const enrolleeQuery = `
enrollee(id: String): Enrollee
`;

export {
    enrollee, enrolleeQuery, volume,
    eventPerformance, eventPerformanceReward,
    newStarterTotals, incomeSummaryItem,
};
