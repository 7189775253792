import { EventInputType, EventType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class EditEventQuery extends
    AbstractQueryResource<EditEventResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($id: String!, $event: EventEditInput!) {
            editEvent(id: $id, event: $event) {
                id,
                title,
                campaignDateFrom,
                campaignDateTo,
                eventDateFrom,
                eventDateTo,
                address,
                videoUrl,
                description,
                allowGuestsToInvite,
                canceled,
                type,
                hostess {
                    firstName,
                    lastName,
                    address,
                    phoneNumber,
                    email
                },
                ambassador {
                    firstName,
                    lastName,
                    address,
                    phoneNumber,
                    email
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'editEvent';
    }
}

export type EditEventParamsType = Partial<EventInputType>;

export type EditEventResultType = {
    editEvent: EventType;
}
