import { MeType } from '@/api/graphQL/graphNodes/types';
import env from '@/environment';

const me: MeType = {
    roles: [
        'ROLE_USER',
        'ROLE_ADMIN',
        env.VUE_APP_MOCK_HOSTESS === 'true' ? 'ROLE_HOSTESS' : 'ROLE_AMBASSADOR',
    ],
    userName: 'admin@plumtreesystems.com',
};

export { me };
