
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { CommissionLevelType } from '@/api/graphQL/graphNodes/types';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import system from '@/modules/System';
import currencyPrefix from '@/utils/currencyPrefix';

@Component
export default class TrackInfo extends Vue {
    @Prop() private currentLevel!: CommissionLevelType|null;

    @Prop() private nextLevel!: CommissionLevelType|null;

    @Prop() private currentPs!: number;

    @Prop({ default: false }) private isMobile!: boolean;

    @Get(system) private screenType!: string;

    get currencyPrefix(): string {
        return currencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get isSizeMobile() {
        return this.screenType === 'mobile';
    }

    get currentSales(): string {
        return `${this.currencyPrefix}${this.currentPs.toFixed(2)}`;
    }

    get salesRequiredForNextLevel(): string {
        if (this.nextLevel) {
            const salesRequired = this.nextLevel.sales - this.currentPs;
            return `${this.currencyPrefix}${salesRequired.toFixed(2)}`;
        }

        return '-';
    }

    get currentPotentialCommission(): string {
        if (this.currentLevel) {
            const potential = this.currentPs * (this.currentLevel.rate / 100);
            return `${this.currencyPrefix}${potential.toFixed(2)}`;
        }

        return '-';
    }

    get nextPotentialCommission(): string {
        if (this.nextLevel) {
            const potential = this.nextLevel.sales * (this.nextLevel.rate / 100);
            return `${this.currencyPrefix}${potential.toFixed(2)}`;
        }

        return '-';
    }

    get salesTotalPercentage(): number {
        if (this.nextLevel == null) {
            return 100;
        }

        return this.currentPs * (100 / this.nextLevel.sales);
    }

    levelRate(level: CommissionLevelType|null): string {
        return level ? `${level.rate}%` : '-';
    }

    levelSalesTotal(level: CommissionLevelType|null): string {
        return level
            ? `${this.currencyPrefix}${level.sales.toFixed(2)}`
            : '-';
    }
}
