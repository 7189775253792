
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import myHosts from '@/modules/MyHosts';
import system from '@/modules/System';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import HostsItem from './item.vue';

@Component({ components: { HostsItem } })
export default class HostsList extends Vue {
    @Get(myHosts) private hosts!: string[];

    @Get(myHosts, 'hostLoading') private loading!: boolean;

    @Get(myHosts) private expandedHosts!: string[];

    @Get(myHosts) private searchedHosts!: string[];

    @Get(myHosts) private offset!: number;

    @Get(myHosts) private total!: number;

    @Get(myHosts) private activeSponsorDetailsModal!: string;

    @Get(myHosts) private displaySearchResults!: boolean;

    @Get(myHosts) private limit!: number;

    @Get(myHosts) private loadingInBackground!: boolean;

    @Get(system) private screenType!: string;

    get displayHosts(): string[] {
        if (this.displaySearchResults) {
            return this.searchedHosts;
        }
        return this.hosts;
    }

    get displayLoadMore() {
        const { total, limit } = this;
        return total > limit;
    }

    isExpanded(id: string) {
        return this.expandedHosts.findIndex((item) => item === id) !== -1;
    }

    handleHostOpenToggle(id: string) {
        myHosts.toggleExpandedList(id);
    }

    handleSponsorDetailDialog(val: string) {
        myHosts.setActiveSponsorDetailsModal(val);
    }

    async handlePagination(params: PaginationParamsType) {
        try {
            const { offset } = params;
            await myHosts.getMyHosts({ loadPage: true, offset });
            scrollToTop('#app > div.MyHost__Scene.App__Layout > div > div.Scene__Content');
        // eslint-disable-next-line no-empty
        } finally {}
    }
}
