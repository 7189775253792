
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import rank from '@/modules/Rank';
import auth from '@/modules/Auth';
import dashboard from '@/modules/Dashboard';
import hostessDashboard from '@/modules/Dashboard/hostess';
import profile from '@/modules/Profile';
import { Impersonator } from '@plumtreesystems/utils';
import tokenThreshold from '@/modules/TokenThreshold';
import system from '@/modules/System';
import dateManager from '@/utils/time';
import { NSP_PERIOD_IN_DAYS } from '@/modules/Dashboard/constants';
import { CommissionLevelType, RankRequirementsType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import Skeleton from './DashboardSkeleton/index.vue';
import RankContainer from './RankContainer/index.vue';
import MonthPerformance from './MonthPerformance/index.vue';
import RecruitmentPerformance from './RecruitmentPerformance/index.vue';
import DashboardLinkInvite from './LinkInvite/index.vue';
import EventRewards from './EventRewards/index.vue';
import HostessEventRewards from './HostessEventRewards/index.vue';
import HostessTokenPerformance from './HostessTokenPerformance/index.vue';
import NSPMilestone from './NSPMilestone/index.vue';
import Commission from './Commission/index.vue';

@Component({
    components: {
        RankContainer,
        MonthPerformance,
        RecruitmentPerformance,
        DashboardLinkInvite,
        Skeleton,
        EventRewards,
        HostessEventRewards,
        HostessTokenPerformance,
        NSPMilestone,
        Commission,
    },
})
export default class Dashboard extends Vue {
    @Get(rank, 'loading') private ranksLoading!: boolean;

    @Get(dashboard, 'loading') private dashboardLoading!: boolean;

    @Get(profile, 'loading') private profileLoading!: boolean;

    @Get(hostessDashboard, 'loading') private hostessDashboardLoading;

    @Get(tokenThreshold, 'loading') private tokenThresholdLoading;

    @Get(profile) private loadingRewards!: boolean;

    @Get(system) private screenWidth!: number;

    @Get(dashboard, 'data.joinDate') private joinDate!: string;

    @Get(dashboard) private commissionLevels!: CommissionLevelType[];

    @Get(dashboard, 'data.stats') private stats!: RankRequirementsType[];

    get pvMetric() {
        return RankRequirementManager.rankRequirementMetricConstants.pv;
    }

    get pv(): number {
        return Number(this.stats.find((item) => item.metric === this.pvMetric)!.value);
    }

    get currentCommission(): CommissionLevelType|null {
        let commission: CommissionLevelType|null = null;

        this.commissionLevels.forEach((item) => {
            if (item.sales < this.pv) {
                commission = item;
            }
        });

        return commission;
    }

    get viewLoaded(): boolean {
        return !this.ranksLoading && !this.dashboardLoading
         && !this.profileLoading && !this.hostessDashboardLoading
         && !this.tokenThresholdLoading && !this.loadingRewards;
    }

    get daysFromJoin(): number {
        return dateManager.getDifference(this.joinDate, dateManager.getCurrentDate(), 'day');
    }

    get nspPeriodEnd(): number {
        return NSP_PERIOD_IN_DAYS;
    }

    get mobileLayout() {
        return this.screenWidth < 752;
    }

    async beforeMount() {
        if (!this.isHostess) {
            rank.getRanks();
            dashboard.getDashboardData();
        } else {
            profile.getProfile({
                isImpersonating: Impersonator.isImpersonating(),
                isHostess: this.isHostess,
            });
            tokenThreshold.getTokenThreshold();
            hostessDashboard.getDashboardData();
        }
    }

    get isAmbassador() {
        return auth.isAmbassador;
    }

    get isHostess() {
        return auth.isHostess;
    }
}
