
import { Vue, Component, Prop } from 'vue-property-decorator';
import newStarters from '@/modules/NewStarters';
import LegItem from '@/projectComponents/enrolleeLegItem/Item/index.vue';
import {
    NewStartersLevelsType, NewStartersType, NewStarterTotalsType, RankType,
} from '@/api/graphQL/graphNodes/types';
import { RankRequirementData } from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/types';
import { RequiredNewsStarterTotalsType } from '@/modules/NewStarters/types';
import Requirement from './Requirement/index.vue';

@Component({
    components: {
        LegItem,
        Requirement,
    },
})
export default class NewStarters extends Vue {
    @Prop({ required: true }) id!: string;

    @Prop({ required: true }) leg!: NewStartersType;

    @Prop({ required: true }) private ranks!: RankType[];

    @Prop({ required: true }) private expandedNewStarters!: string[];

    @Prop({ required: true }) private activeSponsorDetailsModal!: string;

    @Prop({ required: true }) private screenType!: string;

    @Prop({ required: true }) private levels!: NewStartersLevelsType[];

    @Prop({ required: true }) private newStartersTimeFrame!: string;

    @Prop({ required: true }) private impersonating!: boolean;

    get isSizeMobile() {
        return this.screenType === 'mobile';
    }

    get requiredLevels(): RequiredNewsStarterTotalsType {
        const current = this.leg.newStarterTotals
            .find((item) => item.level.key === this.newStartersTimeFrame)!;

        const next = this.leg.newStarterTotals
            .find((item) => (item.level.days > current.level.days)) || null;

        return {
            current,
            next,
        };
    }

    get currentBonus(): RankRequirementData[] {
        const { current } = this.requiredLevels;
        return [
            {
                label: current.level.label,
                metric: '',
                current: `${current.value}`,
                required: `${current.required}`,
            },
        ];
    }

    get nextBonus(): RankRequirementData[] {
        const { next } = this.requiredLevels;

        return next === null ? [] : [
            {
                label: next.level.label,
                metric: '',
                current: `${next.value}`,
                required: `${next.required}`,
            },
        ];
    }

    get currentIcon() {
        return this.iconResolver(this.requiredLevels.current);
    }

    get nextIcon() {
        return this.requiredLevels.next !== null
            ? this.iconResolver(this.requiredLevels.next!) : null;
    }

    iconResolver(totalItem: NewStarterTotalsType):
     { icon: string, areaLabel: string, class: string } {
        switch (totalItem.status) {
        case null:
            return {
                icon: 'clear',
                class: 'NewStartersContent__ClearIcon',
                areaLabel: 'Requirement still in progress',
            };
        case true:
            return {
                icon: 'done',
                class: '',
                areaLabel: 'Requirement fulfilled',
            };
        default:
            return {
                icon: 'clear',
                class: 'NewStartersContent__ClearIcon',
                areaLabel: 'Requirement unfulfilled',
            };
        }
    }

    getNewStartersExpanded(leg: NewStartersType) {
        return !!this.expandedNewStarters.find((item) => item === leg.id);
    }

    handleExpandedContentToggle(val: string) {
        newStarters.toggleExpandedList(val);
    }

    handleSponsorDetailDialog(val: string) {
        newStarters.setActiveSponsorDetailsModal(val);
    }
}
