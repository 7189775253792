import { LabelType } from './types';

export const gbLabels: () => LabelType = () => ({
    bankSortCode: 'Bank Sort Code',
    bankAccountNumber: 'Account Number',
});

export const ieLabels: () => LabelType = () => ({
    bankSortCode: 'Bank SWIFT Code',
    bankAccountNumber: 'Bank IBAN Number',
});
