
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { RankRequirementData } from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/types';
import Requirement from '@/views/NewStarters/Content/Requirement/index.vue';
import system from '@/modules/System';
import { NewStarterTotalsType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {
        Requirement,
    },
})
export default class NSPMilestone extends Vue {
    @Prop() private daysFromJoin!: number;

    @Get(dashboard, 'data.newStarterTotalsByMilestones') private newStarterTotalsByMilestones!: NewStarterTotalsType[];

    @Get(system) private screenType!: string;

    get isSizeMobile() {
        return this.screenType === 'mobile';
    }

    get currentNSP(): RankRequirementData[] {
        const currentNSP = this.newStarterTotalsByMilestones
            .find((item) => item.level.days > this.daysFromJoin)!;
        return [
            {
                label: currentNSP.level.label,
                metric: '',
                current: currentNSP.value,
                required: currentNSP.required,
            },
        ];
    }
}
