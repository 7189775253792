import API from '@/api/graphQL';
import { GetCustomerOrdersParamsType, GetCustomersOrdersParamsType } from '@/api/graphQL/graphNodes/GetCustomerOrdersQuery';
import { GetMyCustomersParamsType } from '@/api/graphQL/graphNodes/GetMyCustomersQuery';
import { GetHostParamsType } from '@/api/graphQL/graphNodes/GetHostQuery';
import { DownlineInputProfileType } from '@/api/graphQL/graphNodes/types';
import { GetCustomerOrderDetailsParamsType } from '@/api/graphQL/graphNodes/GetCustomerOrderDetailsQuery';

export default class MyCustomersRepository {
    static getMyCustomers(params: GetMyCustomersParamsType) {
        return API
            .getCustomers()
            .query(params);
    }

    static getMyCustomer(params: GetHostParamsType) {
        return API
            .getHost()
            .query(params);
    }

    static updateCustomer(id: string, profile: DownlineInputProfileType) {
        return API
            .updateDownline()
            .query({ id, profile });
    }

    static getCustomerOrders(data: GetCustomerOrdersParamsType, options = '') {
        return API
            .getCustomerOrders()
            .query(data, {}, options);
    }

    static getCustomersOrders(data: GetCustomersOrdersParamsType, options = '') {
        return API
            .getCustomerOrders()
            .query(data, {}, options);
    }

    static getCustomerOrderDetails(data: GetCustomerOrderDetailsParamsType) {
        return API
            .getCustomerOrderDetails()
            .query(data);
    }
}
