import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType } from '@plumtreesystems/utils';
import store from '@/store';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import { defaultNewHostess } from '@/modules/Calendar/defaults';
import { CreateEventHostessType } from '@/modules/Event/Events/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { ObjectPropertyType } from '@/modules/types';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import { CreateHostessResultType } from '@/api/graphQL/graphNodes/CreateHostessQuery';
import EventHostessRepository from './eventHostessRepository';
import formHostessValidation from '../services/formHostessValidation';
import eventCreate from '../EventCreate';

@Module({
    namespaced: true, dynamic: true, store, name: 'hostessCreate',
})
@AutoMutations
export class HostessCreate extends VuexModule {
    private hostess: CreateEventHostessType = defaultNewHostess();

    private formErrors: ErrorType = {};

    private loading: boolean = false;

    get dateWithOffset() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true'
            ? mockedDateManager.getCurrentDateWithOffset(10, dateManager.getDayTimeFormatUtc(), 'day')
            : dateManager.getCurrentDateWithOffset(10, dateManager.getDayTimeFormatUtc(), 'day');
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setFormErrors(payload: ErrorType) {
        this.formErrors = { ...payload };
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors[payload.key] = payload.val;
    }

    @Mutation
    public clearFormError() {
        this.formErrors = {};
    }

    @Mutation
    public setHostess(hostess: CreateEventHostessType) {
        this.hostess = { ...hostess };
    }

    @Mutation
    public clearHostess() {
        this.hostess = defaultNewHostess();
    }

    @Action()
    public validateForm() {
        this.clearFormError();

        const errorList = formHostessValidation(this.hostess);
        errorList.forEach((error) => this.setFormError(error));
    }

    @Action()
    async createHostess() {
        try {
            this.setLoading(true);

            this.validateForm();

            if (Object.keys(this.formErrors).length === 0) {
                const resData: CreateHostessResultType = await EventHostessRepository
                    .createHostess({ hostess: this.hostess });

                await eventCreate.setHostess(resData.inviteHostess);
                this.clearHostess();
            }
        } catch (e) {
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(HostessCreate);
