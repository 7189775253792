import API from '@/api/graphQL';

export default class ProfileRepository {
    static getProfile() {
        return API
            .getProfile()
            .query({}, {}, { type: '' });
    }

    static getProfilePerformance() {
        return API
            .getProfilePerformance()
            .query();
    }

    static getDirectDownlineCount() {
        return API
            .getDirectDownlineCount()
            .query();
    }

    static updateProfile(profile) {
        return API
            .updateProfile()
            .query({ profile });
    }

    static uploadProfileImage(data) {
        return API
            .uploadProfilePicture()
            .query(data, { formData: true });
    }
}
