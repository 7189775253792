import API from '@/api/graphQL';
import { CreateHostessParamsType } from '@/api/graphQL/graphNodes/CreateHostessQuery';

export default class EventHostessRepository {
    static createHostess(data: CreateHostessParamsType) {
        return API
            .createHostess()
            .query(data);
    }
}
