
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { RankRequirementsType, RankType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class PBV extends Vue {
    @Get(dashboard, 'data.stats') private stats!: RankRequirementsType[];

    @Prop() private requirements!: RankRequirementType[];

    @Prop() private rank!: RankType;

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(
            RankRequirementManager.rankRequirementMetricConstants.pv,
        ).title;
    }

    // get display150req(): boolean {
    //     const { label } = this.rank;
    //     return label === 'Party Ambassador' || label === 'Digital Ambassador';
    // }

    get pbvRequirement(): string|null {
        const pv = this.requirements
            .find((item) => item.metric === this.metric);

        return pv ? pv.value : null;
    }

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.pv;
    }

    get pv(): number {
        return Number(this.stats.find((item) => item.metric === this.metric)!.value);
    }

    // get tooltipText(): string {
    //     if (this.display150req) {
    //         return 'Ambassador qualification requires minimum 150 Own Sales';
    //     }

    //     return `Own Sales is equal to total order less discount:
    //      ${this.rank.label} qualification is ${this.pbvRequirement}`;
    // }

    get progressValue(): number|null {
        const x = this.pv * 100;

        if (this.requirements.length === 0) {
            return null;
        }

        if (!this.pbvRequirement) {
            return null;
        }

        if (Number(this.pbvRequirement) === 0) {
            return 0;
        }

        return x / Number(this.pbvRequirement);
    }

    get tooltip() {
        let text = '';

        switch (this.rank.power) {
        case 10:
            text = 'You need £50+ in own commissionable sales to unlock your variable commission';
            break;
        case 20:
            text = 'You need £250+ in own commissionable sales to earn additional mentor commission';
            break;
        case 30:
        default:
            text = 'You need £500+ in own commissionable sales to earn additional mentor commission';
            break;
        }

        return text;
    }
}
