import { ProfileType } from '@/api/graphQL/graphNodes/types';
import { FIELD_REQUIRED_MESSAGE, INVALID_EMAIL_ADDRESS } from '@/utils/messages/formValidation';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { validateEmail } from '@/utils/email-validation';
import { ValidationErrorType } from '../../types';

const profileFormValidation:
    (val: ProfileType) =>
    ValidationErrorType[] = (val) => {
        const errors: ValidationErrorType[] = [];

        if (!val.email) {
            errors.push({ key: 'email', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!validateEmail(val.email)) {
            errors.push({ key: 'email', val: INVALID_EMAIL_ADDRESS });
        }

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.address) {
            errors.push({ key: 'address', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.country) {
            errors.push({ key: 'country', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.phoneNumber) {
            errors.push({ key: 'phoneNumber', val: FIELD_REQUIRED_MESSAGE });
        }

        if (val.country === COUNTRY_SELECT_OPTIONS.gb && !val.postCode) {
            errors.push({ key: 'postCode', val: FIELD_REQUIRED_MESSAGE });
        }

        return errors;
    };

export default profileFormValidation;
