import { ResolverTermsAndConditionsType } from '../types';
import { assets } from './assets';

const termsAndConditions: () => ResolverTermsAndConditionsType = () => ({
    id: '0',
    createdAt: '2020-05-21',
    content: assets.find((asset) => asset.id === '12')!,
});

export { termsAndConditions };
