
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import marketingMaterialCategories from '@/modules/MarketingMaterial/categories';
import marketingMaterial from '@/modules/MarketingMaterial';
import Content from './Content/index.vue';
import Header from './Header/index.vue';

@Component({
    components: {
        Content,
        Header,
    },
})
export default class MarketingMaterial extends Vue {
    @Get(marketingMaterial) private searchDisplay!: boolean;

    @Get(marketingMaterial) private searchText!: string;

    @Get(marketingMaterial) private loading!: boolean;

    @Get(marketingMaterial) private loaded!: boolean;

    get displayContent(): boolean {
        return this.$route.name === 'marketingMaterial' && this.searchText !== ''
        && (this.loading || this.loaded);
    }

    handleSearchToggle() {
        marketingMaterial.toggleSearchDisplay();

        if (!this.searchDisplay && this.searchText !== '') {
            marketingMaterial.setSearchText('');
            marketingMaterial.getFiles();
        }
    }

    beforeMount() {
        if (this.$route.name !== 'marketingMaterialCategory' && this.searchText !== '') {
            marketingMaterial.getFiles();
        }
        marketingMaterialCategories.getAssetsCategories();
    }
}
