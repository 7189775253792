import parentObject from './reusable/listItemEnrolleeParent';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { GenealogyEnrolleeType } from './types';

export default class GetGenealogyEnrolleeQuery extends
    AbstractQueryResource<GetGenealogyEnrolleeResultType> {
    protected getQuery(options): string {
        const { withParent = true } = options;

        return `query ${this.getName()}($id: String!) {
            enrollee(id: $id) {
                id,
                leaderLegsCount,
                ambassadorLegsCount,
                email,
                phoneNumber,
                secondPhoneNumber,
                secondAddress,
                county,
                country,
                town,
                postCode,
                ${withParent ? `${parentObject.getQuery()},` : ''}
                address,
                firstName,
                lastName,
                personalRecruitsCount,
                rank {
                    id,
                    label,
                    power,
                },
                stats {
                    metric,
                    value,
                },
                monthsSincePv
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getGenealogyEnrollee';
    }
}

export type GetGenealogyEnrolleeParamsType = {
    id: string;
}

export type GetGenealogyEnrolleeResultType = {
    enrollee: GenealogyEnrolleeType;
}
