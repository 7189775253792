import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import componentsControl from '@/modules/ComponentsControls';
import { BankDetailsType, EventPerformanceRewardType, ProfileType } from '@/api/graphQL/graphNodes/types';
import { UpdateProfileResultType } from '@/api/graphQL/graphNodes/UpdateProfileQuery';
import { GetProfileResultType } from '@/api/graphQL/graphNodes/GetProfileQuery';
import { GetDirectDownlineCountResultType } from '@/api/graphQL/graphNodes/GetDirectDownlineCountQuery';
import Vue from 'vue';
import { UPDATED_SUCCESSFULLY } from '@/utils/messages/formValidation';
import { UploadProfilePictureType } from '@/api/graphQL/graphNodes/UploadProfilePictureQuery';
import { FileType } from '@/components/fileArea/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import profileFormValidation from './services/profileFormValidation';
import { LabelType, ObjectPropertyType } from '../types';
import { profile as defaultProfile, defaultBankDetails, defaultProfilePictureData } from './defaults';
import { gbLabels, ieLabels } from '../labels';
import { COUNTRY_SELECT_OPTIONS, FORM_ERROR_TOOLTIP } from '../constants';
import ProfileRepository from './services/profileRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'profile',
})
@AutoMutations
export class Profile extends VuexModule {
    private originalData: ProfileType = defaultProfile();

    private data: ProfileType = defaultProfile();

    private bankDetails: BankDetailsType = defaultBankDetails();

    private profilePicture: UploadProfilePictureType = defaultProfilePictureData();

    private shopUrl: string = '';

    private vanityUrl: string = '';

    private shopLoginUrl: string = '';

    private labels: LabelType = { ...gbLabels() };

    private loading: boolean = false;

    private imageDialogOpen: boolean = false;

    private profilePicUpload: FileType|null = null;

    private cameraDialogOpen: boolean = false;

    private formErrors: ErrorType = {};

    private displayTooltip: boolean = false;

    private rewards: EventPerformanceRewardType[] = [];

    private loadingRewards: boolean = false;

    private directDownlineCount: number = 0;

    get getProfileData(): ProfileType {
        return this.data;
    }

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public setLabels(payload: LabelType) {
        this.labels = { ...payload };
    }

    @Mutation
    public removeFormError(key) {
        const { formErrors } = this;
        delete formErrors[key];
        this.formErrors = { ...formErrors };
    }

    @Mutation
    public setProfileData(data: Partial<ProfileType>) {
        this.data = { ...defaultProfile(), ...data };
        this.originalData = { ...defaultProfile(), ...data };
    }

    @Mutation
    public setBankDetails(data: BankDetailsType) {
        this.bankDetails = { ...defaultBankDetails(), ...data };
    }

    @Mutation
    public setFormData(data: Partial<ProfileType>) {
        this.data = { ...this.data, ...data };
    }

    @Mutation
    public setProfilePicture(val: UploadProfilePictureType) {
        this.profilePicture = { ...val };
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public resetDataToOriginal() {
        this.data = { ...this.originalData };
        this.bankDetails = { ...defaultBankDetails() };
    }

    @Mutation
    public setShopUrl(val: string) {
        this.shopUrl = val;
    }

    @Mutation
    public setVanityUrl(val: string) {
        this.vanityUrl = val;
    }

    @Mutation
    public setShopLoginUrl(val: string) {
        this.shopLoginUrl = val;
    }

    @Mutation
    public setImageDialogOpen(val: boolean) {
        this.imageDialogOpen = val;
    }

    @Mutation
    public setCameraDialogOpen(val: boolean) {
        this.cameraDialogOpen = val;
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors[payload.key] = payload.val;
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public setRewards(reward: EventPerformanceRewardType[] = []) {
        this.rewards = [...reward];
    }

    @Mutation
    public setLoadingRewards(val: boolean) {
        this.loadingRewards = val;
    }

    @Mutation
    public setDirectDownlineCount(val: number) {
        this.directDownlineCount = val;
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public validateInvitationForm() {
        this.clearFormErrors();
        const formErrors = profileFormValidation(this.data);
        formErrors.forEach((error) => this.setFormError(error));
    }

    @Action()
    public async getRewards() {
        try {
            this.setLoadingRewards(true);

            const result = await ProfileRepository
                .getProfilePerformance();

            this.setRewards(result.profile.eventPerformance.rewards);
        } finally {
            this.setLoadingRewards(false);
        }
    }

    @Action()
    public async getProfile(data: { isImpersonating: boolean, isHostess: boolean }) {
        try {
            const { isImpersonating, isHostess } = data;
            this.setLoading(true);

            if (isHostess) {
                this.getRewards();
            }

            const result: GetProfileResultType = await ProfileRepository
                .getProfile() as GetProfileResultType;
            const { rank } = result.profile;

            // @ts-ignore
            Vue.$gtag.set({
                Rank: { label: rank.label, id: rank.id },
            });

            if (result.profile.profilePicture !== null) {
                this.setProfilePicture(result.profile.profilePicture);
            } else {
                this.setProfilePicture(defaultProfilePictureData());
            }

            const formattedData = ObjectProcessor.removeEmptyProperties(result.profile);
            if (!isImpersonating) {
                const { bankSortCode } = result.profile.bankDetails;

                const formattedBankSortCode = bankSortCode === null ? '' : bankSortCode.replace(/-/g, '');
                this.setBankDetails(ObjectProcessor.removeEmptyProperties({
                    ...formattedData.bankDetails, bankSortCode: formattedBankSortCode,
                }));
            }

            this.setProfileData(formattedData);

            if (result.profile.country === COUNTRY_SELECT_OPTIONS.ie) {
                this.setLabels(ieLabels());
            } else {
                this.setLabels(gbLabels());
            }
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async updateProfile(isHostess: boolean = false) {
        try {
            this.validateInvitationForm();

            if (Object.keys(this.formErrors).length === 0) {
                this.setLoading(true);

                const {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    id, parentId, profilePicture, rank,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    paidForKit, joinDate, kitPurchaseDate, ...data
                } = this.data;
                let bankDetailsData = {
                    ...defaultBankDetails(),
                };

                const { bankAccountName, bankAccountNumber, bankSortCode } = this.bankDetails;

                const formattedBankSortCode = bankSortCode!.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');

                bankDetailsData = {
                    bankAccountName,
                    bankAccountNumber,
                    bankSortCode: formattedBankSortCode,
                };

                const result: UpdateProfileResultType = await ProfileRepository
                    .updateProfile({
                        ...data,
                        bankDetails: isHostess ? null : bankDetailsData,
                    });
                const formattedData = ObjectProcessor.removeEmptyProperties(result.updateProfile);

                this.setProfileData(formattedData);
                componentsControl.showSuccessMessage({ message: UPDATED_SUCCESSFULLY });
            } else {
                this.displayFormErrorsTooltip();
            }
        } catch (e) {
            this.displayFormErrorsTooltip();
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async getDirectDownlineCount() {
        try {
            const result: GetDirectDownlineCountResultType = await ProfileRepository
                .getDirectDownlineCount();

            this.setDirectDownlineCount(result.profile.directDownlineCount);
        } catch (e) {
            ErrorsProcessor.process(e);
        }
    }
}

export default getModule(Profile);
