
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import incomeSummary from '@/modules/IncomeSummary';
import { StatsType } from '@/api/graphQL/graphNodes/types';
import currencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class RunTotalYearPVBreakdownChart extends Vue {
    @Get(incomeSummary) private runTotalYearOVBreakdown!: StatsType[];

    get options() {
        return {
            chart: {
                id: 'runTotalYearPVBreakdownChartId',
                toolbar: {
                    show: false,
                    tools: {
                        download: false,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                    },
                },
            },
            colors: ['#8b82f6'],
            dataLabels: {
                style: {
                    fontSize: '16px',
                    fontWeight: 400,
                    colors: ['#424242'],
                },
                dropShadow: {
                    enabled: false,
                },
            },
            // TODO: should work if time in interval is not consistent
            xaxis: {
                categories: this.runTotalYearOVBreakdown.map(((item) => item.metric)),
            },
            tooltip: {
                y: {
                    formatter: (val) => `${this.currencyPrefix} ${val}`,
                },
            },
        };
    }

    get series() {
        return [
            {
                name: 'Network Sales',
                data: this.runTotalYearOVBreakdown.map((item) => Number(item.value)),
            },
        ];
    }

    get currencyPrefix(): string {
        return currencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }
}
