
import { Vue, Component } from 'vue-property-decorator';
import eventEdit from '@/modules/Event/EventEdit';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import EventEditForm from '@/views/Events/Components/EventForm/index.vue';
import { ObjectPropertyType } from '@/modules/types';
import { EventType } from '@/api/graphQL/graphNodes/types';
import { ErrorType } from '@plumtreesystems/utils';
import HostessDisplay from './hostess/index.vue';

@Component({
    components: {
        EventEditForm,
        HostessDisplay,
    },
})
export default class EditEvent extends Vue {
    @Get(eventEdit) private loading!: boolean;

    @Sync(eventEdit) private eventData!: EventType;

    @Sync(eventEdit) private descriptionDelta!: EventType;

    @Get(eventEdit) private formErrors!: ErrorType;

    beforeMount() {
        eventEdit.getEvent(this.$route.params.id);
    }

    handleInput(value: ObjectPropertyType) {
        eventEdit.setEventProperty(value);
    }

    async handleSave() {
        try {
            await eventEdit.updateEvent();
            this.$router.push({ name: 'eventView', params: { id: this.$route.params.id } });
        // eslint-disable-next-line no-empty
        } finally {}
    }
}
