
import { RankType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LeaderLegsStatusTooltipContent extends Vue {
    @Prop() private taRank!: RankType;

    get ps(): string {
        if (this.taRank.requirements === null) {
            return '';
        }

        const metric = this.taRank.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.pv);

        return metric ? metric.value : '';
    }

    get gv(): string {
        if (this.taRank.requirements === null) {
            return '';
        }

        const metric = this.taRank.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.gv);

        return metric ? metric.value : '';
    }

    get personalRecruits(): string {
        if (this.taRank.requirements === null) {
            return '';
        }

        const metric = this.taRank.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.personalRecruits);

        return metric ? metric.value : '';
    }
}
