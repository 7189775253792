
import { Vue, Component, Prop } from 'vue-property-decorator';
import requirementLeaderLegs from '@/modules/LeaderLegs/requirementLeaderLegs';
import requirementLeaderLegsLeg from '@/modules/LeaderLegs/requirementLeaderLegsLeg';
import nextRankResolver from '@/modules/Rank/services/nextRankResolver';
import { CGet, Get } from '@/utils/vuex-module-mutators';
import { GenealogyEnrolleeType, LeaderLegsType, RankType } from '@/api/graphQL/graphNodes/types';
import LeaderLegsStatusTooltipContent from '@/projectComponents/leaderLegsStatusTooltipContent/index.vue';
import calculateStatus from './services/calculateStatus';

@Component({
    components: {
        LeaderLegsStatusTooltipContent,
        // Because of recursion vue needs to looped component like lambda function
        // fix src: https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
        LegItem: () => import('@/projectComponents/enrolleeLegItem/Item/index.vue'),
    },
})
export default class RankRequirementLeaderLegDetailsItem extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop() private ranks!: RankType[];

    @CGet(requirementLeaderLegsLeg) private data!: GenealogyEnrolleeType;

    @CGet(requirementLeaderLegsLeg) private loading!: boolean;

    @Get(requirementLeaderLegs) private expandedLeaderLegs!: string[];

    get nextRank(): RankType|null {
        return nextRankResolver(this.data.rank.power);
    }

    get teamAmbassador(): RankType {
        return this.ranks
            .find((rankItem: RankType) => rankItem.label === 'Team Ambassador')!;
    }

    get calculatedStatus() {
        return calculateStatus(this.teamAmbassador, this.data);
    }

    getLeaderLegsExpanded(leg: LeaderLegsType) {
        return !!this.expandedLeaderLegs.find((item) => item === leg.id);
    }

    handleExpandedContentToggle(val: string) {
        requirementLeaderLegs.toggleExpandedList(val);
    }
}
