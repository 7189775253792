// eslint-disable-next-line import/no-cycle
import { defaultEnrollee } from '@/modules/Calendar/defaults';
import { LEXIS_NEXIS_STATUS } from '@/modules/Register/constants';
import { ResolverEnrolleeType, ResolverEventType } from './types';

export const defaultResolverEnrollee: () => ResolverEnrolleeType = () => ({
    ...defaultEnrollee(),
    totals: {
        gv: 0,
        spa_gv: 0,
        ov: 0,
        pv: 0,
    },
    parentId: null,
    directDownline: [],
    leaderLegs: [],
    risingStars: [],
    customers: [],
    qualifiedAmbassadorsCountByLevel: null,
    activeAmbassadorsCountByLevel: null,
    newAmbassadorsPersonal: 0,
    newAmbassadorsInCentralTeam: 0,
    newAmbassadorsOrganizational: 0,
    newCustomersInCentralTeam: 0,
    ambassadorKit: 0,
    qualifiedLeaderBranchIds: [],
    qualifiedOrgLeaderCount: 0,
    id: '',
    payRankId: '',
    uplineLeaderId: null,
    personal: [],
    centralTeam: [],
    organisational: [],
    vanityUrl: '',
    shopUrl: '',
    customerNumber: '',
    canRegister: true,
    lexisNexisStatus: LEXIS_NEXIS_STATUS.none,
    shadow: false,
    hidden: false,
});

export const defaultResolverEvent: () => ResolverEventType = () => ({
    address: '',
    allowGuestsToInvite: false,
    ambassador: '',
    campaignDateFrom: '',
    campaignDateTo: '',
    canceled: false,
    confirmed: false,
    description: '',
    eventDateFrom: '',
    eventDateTo: '',
    hostess: '',
    id: '',
    invitations: [],
    shopUrl: '',
    title: '',
    type: '',
});
