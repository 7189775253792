var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"Navigation__Container"},[_c('div',{staticClass:"Navigation__LogoSection"},[(_vm.withLogo)?_c('div',{staticClass:"Navigation__Logo"},[_c('img',{attrs:{"src":`${_vm.baseUrl}img/as-logo.png`,"alt":"Ann Summers Logo"}})]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(`${_vm.impersonating ? "You're impersonating as " : ""}${_vm.firstName} ${_vm.lastName}`)+" ")]),_c('span',{staticClass:"Navigation__Id"},[_vm._v("#"+_vm._s(_vm.id))])]),(!_vm.isHostess && !_vm.impersonating)?_c('Section',{attrs:{"title":""}},[_c('NavItem',{attrs:{"title":"Shop Now","icon":"shopping_basket","externalLink":_vm.shopLoginUrl,"disabled":!_vm.unlockedAfterStage(1)}})],1):_vm._e(),_c('Section',{attrs:{"title":"Home"}},[_c('NavItem',{attrs:{"title":"Dashboard","icon":"dashboard","route":{
                name: 'dashboard',
            }}})],1),(!_vm.impersonating)?_c('Section',{attrs:{"title":"My Own Business"}},[_c('NavItem',{attrs:{"title":"Orders","icon":"shopping_cart","route":{
                name: 'orders',
            }}}),(_vm.isAmbassador)?_c('NavItem',{attrs:{"title":"My Customers","icon":"group","route":{
                name: 'myCustomers',
            }}}):_vm._e()],1):_vm._e(),_c('Section',{attrs:{"title":"VIP Links"}},[(!_vm.impersonating && _vm.isAmbassador)?_c('NavItem',{attrs:{"title":"Create a VIP Link","icon":"event","route":{
                name: 'createEvent',
            }}}):_vm._e(),_c('NavItem',{attrs:{"title":"My VIP Links","icon":"date_range","route":{
                name: 'eventCalendar',
            }}})],1),_c('Section',{attrs:{"title":"My Network","displayTooltip":_vm.noPersonalRecruits,"tooltipText":_vm.noPersonalRecruitsTooltip}},[_c('NavItem',{attrs:{"title":"New Starters","icon":"fiber_new","route":{
                name: 'newStarters',
            },"disabled":_vm.noPersonalRecruits}}),_c('NavItem',{attrs:{"title":"Rising Stars","icon":"star_outline","route":{
                name: 'risingStars',
            },"disabled":_vm.noPersonalRecruits}})],1),(!_vm.impersonating)?_c('Section',{attrs:{"title":"Join My Network"}},[(_vm.isAmbassador)?_c('NavItem',{attrs:{"title":"Join My Network","icon":"person_add","route":{
                name: 'invite',
            }}}):_vm._e()],1):_vm._e(),_c('Section',{attrs:{"title":"My Earnings"}},[_c('NavItem',{attrs:{"title":"Income Summary","icon":"description","route":{
                name: 'incomeSummary',
            }}}),_c('NavItem',{attrs:{"title":_vm.storeCreditLabel,"imageUrl":_vm.sisterPayIcon,"route":{
                name: 'sisterPay',
            }}}),_c('NavItem',{attrs:{"title":"Reports","icon":"assessment","route":{
                name: 'reports',
            }}}),_c('NavItem',{attrs:{"title":"Planning Centre","icon":"description","route":{
                name: 'planningCentre',
            }}})],1),_c('Section',{attrs:{"title":"Business Tools"}},[(!_vm.impersonating)?_c('NavItem',{attrs:{"title":"Thrive","imageUrl":_vm.thriveIcon,"externalLink":_vm.trainingUrl}}):_vm._e(),_c('NavItem',{attrs:{"title":"Help Centre","icon":"live_help","route":{
                name: 'helpAndSupport',
            }}}),(_vm.isDevelopmentEnvironment)?_c('NavItem',{attrs:{"title":"*** UI Components","route":{
                name: 'uiComponents',
            }}}):_vm._e()],1),(!!_vm.impersonating)?_c('Section',{attrs:{"title":""}},[(_vm.impersonating)?_c('NavItem',{attrs:{"title":"End impersonation","icon":"exit_to_app","route":{
                name: 'endImpersonation',
            }}}):_vm._e()],1):_vm._e(),(!_vm.impersonating)?_c('Section',{attrs:{"title":"My Profile"}},[_c('NavItem',{attrs:{"title":"My Profile","icon":"account_circle","route":{
                name: 'profile',
            }}}),_c('NavItem',{attrs:{"title":"Log out","icon":"exit_to_app","route":{
                name: 'logout',
            }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }