
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { Debounced } from '@/utils/debounced';
import myHosts from '@/modules/MyHosts';
import system from '@/modules/System';
import { SelectOptionsType } from '@/components/select/types';
import { MyHostsType } from '@/api/graphQL/graphNodes/types';

@Component
export default class MyHostsHeader extends Vue {
    @Sync(myHosts) private searchQuery!: string;

    @Get(myHosts) private searchLoading!: boolean;

    @Get(myHosts) private minSearchLength!: number;

    @Get(myHosts) private searchedOptions!: MyHostsType[];

    @Get(myHosts) private displaySearch!: boolean;

    @Get(system) private screenType!: string;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedOptions.map((host) => ({
            name: `${host.firstName} ${host.lastName}`,
            value: `${host.firstName} ${host.lastName}`,
        }));
    }

    get showSearch() {
        return this.screenType !== 'mobile' || this.displaySearch;
    }

    get headerContainerClasses() {
        return [
            'pts-layout',
            'pts-gutter',
            'MyHostsHeader__HeaderContainer',
            { 'MyHostsHeader__HeaderContainer--show': this.showSearch },
        ];
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-50',
            'pts-small-size-100',
            'MyHostsHeader__Container',
            { 'MyHostsHeader__Container--show': this.showSearch },
        ];
    }

    handleSelect(val) {
        myHosts.setSearchQuery(val.value);
        myHosts.setDisplaySearchResults(true);
        myHosts.searchHosts({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            myHosts.setDisplaySearchResults(false);
            myHosts.setSearchedOptions([]);
            myHosts.clearSearchedHosts();
            myHosts.getMyHosts({});
        }
        if (this.searchQuery.length >= this.minSearchLength) {
            myHosts.searchHosts();
        }
    }
}
