
import { Vue, Component } from 'vue-property-decorator';
import OrdersList from '@/projectComponents/OrdersList/index.vue';
import { Get } from '@/utils/vuex-module-mutators';
import customerOrders from '@/modules/MyCustomers/customersOrders';
import { BaseOrderType, EnrolleeCustomerOrderType } from '@/api/graphQL/graphNodes/types';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { scrollToTop } from '@plumtreesystems/utils';
import system from '@/modules/System';
import { PaginationParamsType } from '@/components/pagination/types';

@Component({
    components: {
        OrdersList,
    },
})
export default class MyCustomersOrderList extends Vue {
    @Get(customerOrders) private searchedOrders!: BaseOrderType[];

    @Get(customerOrders) private ordersObject!: EnrolleeCustomerOrderType;

    @Get(customerOrders) private displaySearchResults!: boolean;

    @Get(customerOrders) private expandedOrders!: string[];

    @Get(customerOrders) private offset!: number;

    @Get(customerOrders) private limit!: number;

    @Get(customerOrders) private orderTotal!: number;

    @Get(customerOrders) private loadingInBackground!: boolean;

    @Get(system) private screenType!: string;

    get displayOrders(): BaseOrderType[] {
        if (this.displaySearchResults) {
            return this.searchedOrders;
        }

        return this.ordersObject.searchCustomersOrders;
    }

    get country() {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    handleOrderOpenToggle(id: string) {
        if (this.isMobile) {
            const order = this.ordersObject.searchCustomersOrders.find((item) => item.id === id)!;
            this.$router.push({ name: 'viewCustomersCustomerOrderDetails', params: { id: order.customer.id, orderId: id } });
        } else {
            customerOrders.toggleExpandedOrdersList(id);
        }
    }

    async handlePagination(params: PaginationParamsType) {
        try {
            const { offset } = params;
            await customerOrders.getCustomerOrders({ loadPage: true, offset });
            scrollToTop('#app > div.MyCustomers__Scene.App__Layout > div > div.Scene__Content > div > div.MyCustomersHeader__Content');
        // eslint-disable-next-line no-empty
        } finally {}
    }
}
