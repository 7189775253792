const val: { options: any, getQuery: () => string} = {
    options: null,
    getQuery: () => (`
        parent {
            id,
            firstName,
            lastName,
            address,
            secondPhoneNumber,
            secondAddress,
            county,
            country,
            town,
            postCode,
            email,
            phoneNumber,
            rank {
                id,
                label,
                power,
            },
            stats {
                metric,
                value,
            },
        }`
    ),
};

export default val;
