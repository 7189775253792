
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import imageResizeProcessor from '@/modules/Profile/services/imageResizeProcessor';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import profile from '@/modules/Profile';
import profilePicture from '@/modules/Profile/profilePicture';
import { FileType } from '@/components/fileArea/types';
import env from '@/environment';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import PersonalDetails from '@/projectComponents/personalDetails/index.vue';
import {
    COUNTRY_SELECT_OPTIONS, TOOLTIP_NAME_OF_ACCOUNT_TOOLTIP,
    TOOLTIP_TO_CHANGE_FIRST_NAME_CONTACT_HQ_TOOLTIP,
    TOOLTIP_TO_CHANGE_LAST_NAME_CONTACT_HQ_TOOLTIP,
} from '@/modules/constants';
import { gbLabels, ieLabels } from '@/modules/labels';
import { NATIONAL_NUMBER_MESSAGE, BIRTHDATE_MESSAGE } from '@/utils/messages/formValidation';
import { BankDetailsType, ProfilePictureType, ProfileType } from '@/api/graphQL/graphNodes/types';
import { LabelType } from '@/modules/types';
import UploadDialogContent from '@/projectComponents/uploadDialog/index.vue';
import Camera from '@/projectComponents/camera/index.vue';
import { TEXT_FIELD_DISPLAY_TYPE } from '@/components/textField/constants';
import auth from '@/modules/Auth';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import { CountriesType } from '@/modules/Event/types';
import countries from '@/modules/Countries';

@Component({
    components: {
        UploadDialogContent,
        Camera,
        FormErrorTooltip,
        PersonalDetails,
        FormFieldTooltip,
    },
})
export default class ProfileContent extends Vue {
    @Get(profile, 'data') private formData!: ProfileType;

    @Get(profile) private bankDetails!: BankDetailsType;

    @Get(profile) private profilePicture!: null|ProfilePictureType;

    @Get(profile) private imageDialogOpen!: boolean;

    @Sync(profile) private labels!: LabelType;

    @Get(profilePicture) private isCameraLoading!: boolean;

    @Get(profilePicture) private image!: string;

    @Get(profilePicture, 'loading') private uploadLoading!: boolean;

    @Get(profile) private formErrors!: ErrorType;

    @Get(profile) private profilePicUpload!: FileType|null;

    @Get(profilePicture) private isPhotoTaken!: boolean;

    @Get(profilePicture) private isShotPhoto!: boolean;

    @Get(profilePicture) private displayWidth!: number;

    @Get(profilePicture) private displayHeight!: number;

    @Get(profilePicture) private cameraWidth!: number;

    @Get(profilePicture) private cameraHeight!: number;

    @Get(profilePicture) private cameraNotPermitted!: boolean;

    @Get(profile) private cameraDialogOpen!: boolean;

    @Get(profile) private displayTooltip!: boolean;

    @Get(countries) private countries!: CountriesType[];

    get firstNameTooltip(): string {
        return TOOLTIP_TO_CHANGE_FIRST_NAME_CONTACT_HQ_TOOLTIP;
    }

    get lastNameTooltip(): string {
        return TOOLTIP_TO_CHANGE_LAST_NAME_CONTACT_HQ_TOOLTIP;
    }

    get gbCountry(): string {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    get imgUrl(): string {
        return `${this.awsProfile ? '' : this.serverUrl}${this.link}`;
    }

    get serverUrl(): string {
        return env.VUE_APP_SERVER_URL;
    }

    get nameOfAccountTooltip(): string {
        return TOOLTIP_NAME_OF_ACCOUNT_TOOLTIP;
    }

    get awsProfile(): boolean {
        if (this.profilePicture !== null) {
            const provider = this.profilePicture.context.find((item) => item.metric === 'provider');
            if (provider && provider.value === 'aws_profile') {
                return true;
            }
        }
        return false;
    }

    get link(): string {
        return this.profilePicture ? this.profilePicture.link : '';
    }

    get iban(): string {
        return TEXT_FIELD_DISPLAY_TYPE.iban;
    }

    get sortCodeType(): string {
        return TEXT_FIELD_DISPLAY_TYPE.sortCode;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    async handleProfileUpdate() {
        await profile.updateProfile(this.isHostess);
    }

    getHelperText(val: string) {
        if (val === 'birthDate') {
            return BIRTHDATE_MESSAGE;
        }
        return NATIONAL_NUMBER_MESSAGE;
    }

    handleImageUploadModalOpen() {
        profile.setImageDialogOpen(true);
    }

    handleCameraModalOpen() {
        profile.setCameraDialogOpen(true);
    }

    handleImageUploadModalClose() {
        profile.setImageDialogOpen(false);
        profilePicture.clearImageData();
    }

    handleReupload() {
        profilePicture.clearImageData();
    }

    setDimensions(val: {width: number, height: number}) {
        profilePicture.setDisplayWidth(val.width);
        profilePicture.setDisplayHeight(val.height);
    }

    async handleProfileUpload() {
        // @ts-ignore
        const croppedImg = this.$refs.upload.$refs.uploadContent.$refs.cropArea.$refs.cropper
            .getCroppedCanvas().toDataURL();
        profilePicture.uploadProfilePicture({ img: croppedImg });
    }

    async onFileAdd(files: FileType[]) {
        const file64 = await imageResizeProcessor(files[0].file);
        profilePicture.setImage(file64);
    }

    createCameraElement() {
        profilePicture.setIsCameraLoading(true);
        profilePicture.setCameraNotPermitted(false);

        const params = {
            audio: false,
            video: true,
        };

        // @ts-ignore
        const constraints = params;

        // @ts-ignore
        window.constraints = params;
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                const streamSettings = stream.getVideoTracks()[0].getSettings();
                const { width, height } = streamSettings;

                if (width && height) {
                    profilePicture.setCameraWidth(width);
                    profilePicture.setCameraHeight(height);

                    // @ts-ignore
                    this.$refs.camera.setFittingResolution(width, height);
                }

                profilePicture.setIsCameraLoading(false);
                // @ts-ignore
                this.$refs.camera.$refs.camera.srcObject = stream;
            })
            .catch(() => {
                profilePicture.setCameraNotPermitted(true);
            })
            .finally(() => {
                profilePicture.setIsCameraLoading(false);
            });
    }

    takePhoto() {
        if (!this.isPhotoTaken) {
            profilePicture.setIsShotPhoto(true);

            const FLASH_TIMEOUT = 50;

            setTimeout(() => {
                profilePicture.setIsShotPhoto(false);
            }, FLASH_TIMEOUT);
        }
        profilePicture.setIsPhotoTaken(!this.isPhotoTaken);

        // @ts-ignore
        const getPhoto = this.$refs.camera.getPhotoRef(this.isPhotoTaken);
        profilePicture.setImage(getPhoto);
    }

    @Watch('cameraDialogOpen')
    cameraDialogOpenChange() {
        if (this.cameraDialogOpen) {
            this.startCamera();
        }
    }

    handleCameraModalClose() {
        profile.setCameraDialogOpen(false);
    }

    handleCountryChange(val: string) {
        if (val === COUNTRY_SELECT_OPTIONS.ie) {
            profile.setLabels(ieLabels());
        } else {
            profile.setLabels(gbLabels());
        }
    }

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.formData);

        if (key === 'country') {
            this.handleCountryChange(value);
        }

        if (key === 'country' && value === COUNTRY_SELECT_OPTIONS.ie) {
            profile.removeFormError('postCode');
        }

        profile.removeFormError(key);
        profile.setFormData(res);
    }

    handleBankValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.bankDetails);
        profile.removeFormError(key);
        profile.setBankDetails(res);
    }

    async handleCameraUpload() {
        // @ts-ignore
        const croppedImg = this.$refs.camera.getCroppedImg();
        await profilePicture.uploadProfilePicture({ img: croppedImg });
        this.handleCameraModalClose();
    }

    async startCamera() {
        await profilePicture.clearImageData();
        this.createCameraElement();
    }
}
