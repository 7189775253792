
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class PlanningCentreCardItem extends Vue {
    @Prop() private label!: string;

    @Prop() private value!: number|string;

    @Prop({ default: '' }) private displayType!: string;

    @Prop({ default: -1 }) private percents!: number;

    get previewValue(): number|string {
        if (this.displayType === 'money') {
            return `£ ${this.format2DecimalPoints(Number(this.value) / 100)}`;
        }

        return this.value;
    }

    get withPercents(): boolean {
        return this.percents > -1;
    }

    get displayPercents(): string {
        const { percents } = this;
        return percents > 0 ? `${percents}%` : `${percents}`;
    }

    get labelClasses() {
        return [
            'pts-layout-item',
            this.withPercents ? 'pts-size-65' : 'pts-size-75',
            'PlanningCentreCardItem__Label',
        ];
    }

    format2DecimalPoints(val) {
        return Number(val).toFixed(2);
    }
}
