import { EnrolleeCustomerOrderType, EnrolleeOrderType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetCustomerOrdersQuery extends
    AbstractQueryResource<GetCustomerOrdersResultType|GetCustomersOrdersResultType> {
    protected getQuery(options: string): string {
        const paramType = options === 'allCustomersOrder' ? '$type: String!' : '$id: String!, $prefix: String, $onlyPurchased: Boolean!, $from: String, $to: String';
        const queryName = options === 'allCustomersOrder' ? 'searchCustomersOrders' : 'searchOrders';
        const countQueryName = options === 'allCustomersOrder' ? 'searchCustomersOrdersCount' : 'searchOrdersCount';
        const parameters = options === 'allCustomersOrder' ? 'query: $query, type: $type'
            : 'query: $query, prefix: $prefix, onlyPurchased: $onlyPurchased, from: $from, to: $to';
        const queryHead = options === 'allCustomersOrder' ? `profile {
            ` : `enrollee(id: $id) {
                id,
                firstName,
                lastName,`;

        return `query ${this.getName()}($query: String!, $offset: Int, $limit: Int, ${paramType}) {
            ${queryHead}
                ${queryName}(offset: $offset, limit: $limit, ${parameters}) {
                    id,
                    value,
                    grossValue,
                    commissionableValue,
                    psc,
                    date,
                    shippingCost,
                    discount,
                    products {
                        id,
                        price,
                        description,
                        quantity,
                        total,
                        category,
                        discounts,
                        cv,
                    },
                    enrollee {
                        id,
                        firstName,
                        lastName,
                    },
                    customer {
                        id,
                        firstName,
                        lastName,
                    },
                },
                ${countQueryName}(${parameters}),
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getCustomerOrders';
    }
}

export type GetCustomerOrdersResultType = {
    enrollee: EnrolleeOrderType;
}

export type GetCustomersOrdersResultType = {
    profile: EnrolleeCustomerOrderType;
}

export type GetCustomerOrdersParamsType = {
    id: string;
    offset: number;
    limit: number;
    query: string;
    prefix?: string|null;
    onlyPurchased: boolean;
    from?: string|null;
    to?: string|null;
}

export type GetCustomersOrdersParamsType = {
    offset: number;
    limit: number;
    query: string;
    type: string;
}
