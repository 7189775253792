const incomePlannerEarning = `type IncomePlannerEarning {
    discountPv: Int!,
    discountScc: Int!,
    discountVipScc: Int!,
    salesBonus: Int!,
    levelBonus: Int!,
    pv: String!,
    scc: String!,
    vipScc: String!,
    salesBonusAmount: String!,
    levelBonusAmount: String!,
    total: String!,
}`;

const incomePlannerQualification = `type IncomePlannerQualification {
    pv: Int!,
    customerVolume: Int!,
    vipCustomerVolume: Int!,
    qnv: Int!,
}`;
const incomePlanner = `type IncomePlan {
    id: String!,
    title: String!,
    pv: Int!,
    averageQPA: Int!,
    qpaCount: Int!,
    avgCustomerVolume: Int!,
    avgVipCustomerVolume: Int!,
    customersCount: Int!,
    vipCustomersCount: Int!,
    rank: String!,
    earnings: IncomePlannerEarning,
    qualification:IncomePlannerQualification,
}`;

const incomePlannerQuery = `
    incomePlans: [IncomePlan]!
`;

export {
    incomePlanner, incomePlannerQuery, incomePlannerEarning, incomePlannerQualification,
};
