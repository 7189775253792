import { HostessRewardsType, InvitationToEventEnrolleeType } from '@/api/graphQL/graphNodes/types';

const defaultInvitationToEventEnrollee: () => InvitationToEventEnrolleeType = () => ({
    firstName: '',
    email: '',
    id: '',
    lastName: '',
    phoneNumber: '',
});

const defaultHostessRewards: () => HostessRewardsType = () => ({
    label: '0',
    thresholdEUR: 0,
    thresholdGBP: 0,
    rewards: [],
    bonusTokens: '0',
    rewardsAsset: null,
});

export { defaultInvitationToEventEnrollee, defaultHostessRewards };
