
import { Vue, Component, Prop } from 'vue-property-decorator';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import { LegType } from '@/modules/types';

@Component({
    components: {
        PropertyInfoDisplay,
    },
})
export default class DownlineContactsModalView extends Vue {
    @Prop() private leg!: LegType;

    @Prop({ default: false }) private fullWidth!: boolean;

    get withSecondPhoneNumber(): boolean {
        const { secondPhoneNumber } = this.leg;
        return !!secondPhoneNumber && secondPhoneNumber !== '';
    }

    get address(): string {
        const {
            address, secondAddress, postCode,
            town, county, country,
        } = this.leg;

        let res = address;

        if (!!secondAddress && secondAddress !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${secondAddress}`;
        }

        if (!!postCode && postCode !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${postCode}`;
        }

        if (!!county && county !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${county}`;
        }

        if (!!town && town !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${town}`;
        }

        if (!!country && country !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${country}`;
        }

        return res;
    }

    checkIfDataAvailable(data: any): boolean {
        if (!data || data === '') {
            return false;
        }

        return true;
    }
}
