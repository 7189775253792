import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { HostessDashboardType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetHostessDashboardResultType } from '@/api/graphQL/graphNodes/GetHostessDashboardQuery';
import dashboardRepository from './services/dashboardRepository';
import { initialHostessDashboardData } from './defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'hostessDashboard',
})
@AutoMutations
export class HostessDashboard extends VuexModule {
    private data: HostessDashboardType = initialHostessDashboardData();

    private loading: boolean = false;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setDashboardData(val: HostessDashboardType) {
        this.data = { ...val };
    }

    @Action()
    public async getDashboardData() {
        try {
            this.setLoading(true);
            const res: GetHostessDashboardResultType = await dashboardRepository.getHostessData();

            this.setDashboardData(res.profile);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(HostessDashboard);
