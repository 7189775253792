<template>
    <UILayout
        title="Loader"
        route="uiComponentsLoader"
        id="loaderSubscene"
    >
        <div class="Ui__Card md-elevation-4 pts-layout pts-gutter">
            <div class="pts-layout-item pts-size-100">
                <h3>Loader</h3>

                <div class="pts-layout-item pts-layout pts-gutter">
                    <div class="pts-layout-item">
                        <Loader />
                    </div>
                </div>
            </div>
        </div>
    </UILayout>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UILoader extends Vue {}
</script>
