
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import myHosts from '@/modules/MyHosts';
import tokenThreshold from '@/modules/TokenThreshold';
import MyHostsHeader from './MyHostsHeader/index.vue';
import HostsList from './HostsList/index.vue';

@Component({
    components: {
        MyHostsHeader,
        HostsList,
        Skeleton,
    },
})
export default class MyHosts extends Vue {
    @Get(myHosts) private hostLoading!: boolean;

    @Get(myHosts) private searchLoading!: boolean;

    @Get(myHosts) private offset!: number;

    @Get(system) private screenType!: string;

    @Get(tokenThreshold, 'loading') private tokenThresholdLoading;

    get contentLoaded(): boolean {
        return !this.hostLoading && !this.searchLoading && !this.tokenThresholdLoading;
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    handleSearchToggle() {
        myHosts.toggleShowHostSearch();
    }

    mounted() {
        const { offset } = this;
        myHosts.getMyHosts({ offset });
        tokenThreshold.getTokenThreshold();
    }

    beforeDestroy() {
        myHosts.clearSearchedHosts();
        myHosts.setSearchedOptions([]);
        myHosts.clearExpandedHostsList();
        myHosts.setDisplaySearchResults(false);
        myHosts.setSearchQuery('');
    }
}
