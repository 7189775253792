export const REGISTERED_MESSAGE: string = 'Registered successfully';

export const FIELD_REQUIRED_MESSAGE: string = 'This field is required';

export const INVALID_EMAIL_ADDRESS: string = 'Invalid email address';

export const AGE_RESTRICTION_MESSAGE: string = 'We require users to be 18 years old or over';

export const INVITATION_SENT_SUCCESSFULLY: string = 'Invitation sent successfully.';

export const UPDATED_SUCCESSFULLY: string = 'Updated successfully';

export const NATIONAL_NUMBER_MESSAGE: string = 'This field is part of our KYC process';

export const BIRTHDATE_MESSAGE: string = 'We\'re asking for your date of birth because you must be aged 18 or over to join Connect. We\'ll also send you something special on your birthday!';

export const CAMPAIGN_SHOULD_START_BEFORE_END: string = 'The campaign start date must be before the end date';

export const EVENT_SHOULD_START_AFTER_CAMPAIGN_START: string = 'VIP Link can only start after the campaign begins';

export const EVENT_SHOULD_START_BEFORE_CAMPAIGN_ENDS: string = 'VIP Link can only start before the campaign ends';

export const EVENT_SHOULD_END_AFTER_CAMPAIGN_STARTS: string = 'VIP Link can only end after the campaign begins';

export const EVENT_SHOULD_END_BEFORE_CAMPAIGN_ENDS: string = 'VIP Link can\'t end after the campaign ends';

export const EVENT_SHOULD_START_BEFORE_IT_ENDS: string = 'VIP Link can only end after it begins';
