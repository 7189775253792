
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { RisingStarsType } from '@/api/graphQL/graphNodes/types';
import risingStars from '@/modules/RisingStars';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import impersonation from '@/modules/Impersonation';
import LegItem from './item.vue';

@Component({
    components: {
        LegItem,
    },
})
export default class LegsList extends Vue {
    @Get(risingStars) private legs!: RisingStarsType[];

    @Get(risingStars) private searchedLegs!: RisingStarsType[];

    @Get(risingStars) private legsCount!: number;

    @Get(risingStars) private loadingInBackground!: boolean;

    @Get(risingStars) private offset!: number;

    @Get(risingStars) private limit!: number;

    @Get(risingStars) private displaySearchResults!: boolean;

    get displayLoadMore(): boolean {
        if (this.displaySearchResults) {
            return false;
        }
        return this.limit <= this.legsCount;
    }

    get displayLegs(): RisingStarsType[] {
        if (this.displaySearchResults) {
            return this.searchedLegs;
        }
        return this.legs;
    }

    get impersonating(): boolean {
        return impersonation.impersonating;
    }

    async handlePagination(props: PaginationParamsType) {
        const { offset } = props;
        await risingStars.getRisingStars({ loadPage: true, offset });
        scrollToTop('#app > div.App__Layout > div > div.Scene__Content');
    }
}
