import { BUSINESS_SHAPE_TYPE } from '@/modules/BusinessShape/constants';
import mockedDateManager from '@/utils/mocked-date-manager';
import { RankRequirementType, ResolverAmbassadorsCountStatsType } from '../types';

const ambassadorCountStats: ResolverAmbassadorsCountStatsType[] = [
    {
        enrollee: '0',
        type: BUSINESS_SHAPE_TYPE.active,
        date: '2020-08-08',
        stats: [
            {
                metric: '1',
                value: '8',
            },
            {
                metric: '2',
                value: '23',
            },
            {
                metric: '3',
                value: '5',
            },
        ],
    },
    {
        enrollee: '0',
        type: BUSINESS_SHAPE_TYPE.active,
        date: '2020-07-08',
        stats: [],
    },
    {
        enrollee: '0',
        type: BUSINESS_SHAPE_TYPE.qualified,
        date: '2020-08-08',
        stats: [
            {
                metric: '1',
                value: '8',
            },
            {
                metric: '2',
                value: '20',
            },
            {
                metric: '3',
                value: '2',
            },
        ],
    },
    {
        enrollee: '0',
        type: BUSINESS_SHAPE_TYPE.qualified,
        date: '2020-07-08',
        stats: [],
    },
    {
        enrollee: '5',
        type: BUSINESS_SHAPE_TYPE.active,
        date: '2020-08-08',
        stats: [
            {
                metric: '1',
                value: '6',
            },
        ],
    },
    {
        enrollee: '5',
        type: BUSINESS_SHAPE_TYPE.active,
        date: '2020-07-08',
        stats: [],
    },
    {
        enrollee: '5',
        type: BUSINESS_SHAPE_TYPE.qualified,
        date: '2020-08-08',
        stats: [
            {
                metric: '1',
                value: '5',
            },
        ],
    },
    {
        enrollee: '5',
        type: BUSINESS_SHAPE_TYPE.qualified,
        date: '2020-07-08',
        stats: [],
    },
    {
        enrollee: '12',
        type: BUSINESS_SHAPE_TYPE.active,
        date: '2020-08-08',
        stats: [
            {
                metric: '1',
                value: '5',
            },
        ],
    },
    {
        enrollee: '12',
        type: BUSINESS_SHAPE_TYPE.active,
        date: '2020-07-08',
        stats: [],
    },
    {
        enrollee: '12',
        type: BUSINESS_SHAPE_TYPE.qualified,
        date: '2020-08-08',
        stats: [
            {
                metric: '1',
                value: '5',
            },
        ],
    },
    {
        enrollee: '12',
        type: BUSINESS_SHAPE_TYPE.qualified,
        date: '2020-07-08',
        stats: [],
    },
    {
        enrollee: '18',
        type: BUSINESS_SHAPE_TYPE.active,
        date: '2020-08-08',
        stats: [
            {
                metric: '1',
                value: '6',
            },
        ],
    },
    {
        enrollee: '18',
        type: BUSINESS_SHAPE_TYPE.active,
        date: '2020-07-08',
        stats: [],
    },
    {
        enrollee: '18',
        type: BUSINESS_SHAPE_TYPE.qualified,
        date: '2020-08-08',
        stats: [
            {
                metric: '1',
                value: '5',
            },
        ],
    },
    {
        enrollee: '18',
        type: BUSINESS_SHAPE_TYPE.qualified,
        date: '2020-07-08',
        stats: [],
    },
    {
        enrollee: '25',
        type: BUSINESS_SHAPE_TYPE.active,
        date: '2020-08-08',
        stats: [
            {
                metric: '1',
                value: '6',
            },
            {
                metric: '2',
                value: '5',
            },
        ],
    },
    {
        enrollee: '25',
        type: BUSINESS_SHAPE_TYPE.active,
        date: '2020-07-08',
        stats: [],
    },
    {
        enrollee: '25',
        type: BUSINESS_SHAPE_TYPE.qualified,
        date: '2020-08-08',
        stats: [
            {
                metric: '1',
                value: '5',
            },
            {
                metric: '2',
                value: '2',
            },
        ],
    },
    {
        enrollee: '25',
        type: BUSINESS_SHAPE_TYPE.qualified,
        date: '2020-07-08',
        stats: [],
    },
];

const ambassadorCountStat:
    (type: string, id: string, date: { from: string, to: string })
    => RankRequirementType[] = (type, id, date) => {
        const dateFrom = date.from
            ? mockedDateManager.getFirstDayOfLastMonth()
            : mockedDateManager.getFirstDayOfThisMonth();

        const dateTo = date.to
            ? mockedDateManager.getLastDayOfLastMonth()
            : mockedDateManager.getLastDayOfThisMonth();

        const stats = ambassadorCountStats.find((stat) => stat.enrollee === id
            && stat.type === type && dateFrom <= stat.date && stat.date <= dateTo);
        if (stats) {
            return stats.stats;
        }
        return [];
    };

export { ambassadorCountStats, ambassadorCountStat };
