import { RankType } from '@/api/graphQL/graphNodes/types';
import { LegType } from '@/modules/types';
import resolveStatusType from '@/projectComponents/enrolleeLegItem/Item/services/resolveStatusType';

const calculateStatus: (teamAmbassador: RankType|undefined, data: LegType)
 => string = (teamAmbassador, data) => {
     if (!teamAmbassador) {
         return '';
     }

     let sum = 0;
     let count = 0;
     if (teamAmbassador.requirements === null) {
         return '';
     }

     teamAmbassador.requirements.forEach((requirement) => {
         const stat = data.stats.find((item) => item.metric === requirement.metric);

         if (stat) {
             const statVal = Number(stat.value);
             const requirementVal = Number(requirement.value);
             if (statVal >= requirementVal) {
                 sum += 100;
             } else {
                 sum += Math.round((statVal * 100) / requirementVal);
             }
             count += 1;
         }
     });

     const percentage: number = sum > 0 && count > 0 ? Math.round((sum / count) * 100) / 100 : 0;
     return resolveStatusType(percentage);
 };

export default calculateStatus;
