<template>
    <UILayout
        title="Clipboard"
        route="uiComponentsClipboards"
        id="clipboardsSubscene"
    >
        <div class="pts-layout pts-gutter">
            <div class="pts-layout-item pts-size-100">
                <div class="Ui__Card md-elevation-4 pts-gutter">
                    <div class="pts-layout-item pts-layout pts-gutter">
                        <div class="
                            pts-layout-item pts-size-20 pts-small-size-30 pts-xsmall-size-100
                        ">
                            <Clipboard
                                id="clipboard1"
                                url="some url"
                            />
                        </div>
                        <div class="
                            pts-layout-item pts-size-20 pts-small-size-30 pts-xsmall-size-100
                        ">
                            <Clipboard
                                id="clipboard2"
                                url="some very long text with some additional sentences"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </UILayout>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UIClipboards extends Vue {}
</script>
