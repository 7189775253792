import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetTokenThresholdResultType } from '@/api/graphQL/graphNodes/GetTokenThresholdQuery';
import TokenThresholdRepository from './services/tokenThresholdRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'tokenThreshold',
})
@AutoMutations
export class TokenThreshold extends VuexModule {
    private threshold: number = 0;

    private loading: boolean = false;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setTokenThreshold(val: number) {
        this.threshold = val;
    }

    @Action()
    public async getTokenThreshold() {
        try {
            this.setLoading(true);

            const result: GetTokenThresholdResultType = await TokenThresholdRepository
                .getTokenThreshold() as GetTokenThresholdResultType;
            const { tokenThreshold } = result;

            this.setTokenThreshold(tokenThreshold);
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(TokenThreshold);
