import env from '@/environment';
import { AbstractResource, Methods } from './AbstractResource';
// eslint-disable-next-line import/no-cycle
import {
    ErrorResource, GetIncomePlans, SendPublicInvitation,
    SendEventInvite,
} from './internal';

export default class RootResource extends AbstractResource<{}> {
    private path = env.VUE_APP_SERVER_URL;

    protected getAllowedMethods(): Methods[] {
        return [];
    }

    protected getPath(): string {
        return this.path;
    }

    public registerError() {
        return new ErrorResource(this);
    }

    public getIncomePlans() {
        return new GetIncomePlans(this);
    }

    public sendPublicInvitation() {
        return new SendPublicInvitation(this);
    }

    public sendEventInvite() {
        return new SendEventInvite(this);
    }
}
