const ambassadorInviteMutations = 'inviteAmbassador (email: String!, firstName: String!, lastName: String!): Invitation';

const registerInput = `input ProfileRegisterInput {
    firstName: String!,
    lastName: String!,
    email: String!,
    password: String,
    repeatPassword: String,
    phoneNumber: String,
    address: String,
    secondAddress: String,
    birthDate: String,
    postCode: String,
    town: String,
    county: String,
    country: String,
    termsChecked: Boolean,
    bankDetails: RegisterBankDetailsInput,
}`;

const hostessRegisterInput = `input HostessRegisterInput {
    firstName: String!,
    lastName: String!,
    email: String!,
    birthDate: String!,
    password: String!,
    repeatPassword: String!,
    country: String!,
    address: String,
    phoneNumber: String,
    termsChecked: Boolean!
}`;

const registerBankDetailsInput = `input RegisterBankDetailsInput {
    bankAccountName: String,
    bankAccountNumber: String,
    bankSortCode: String,
}`;

const registerMutations = `
    registerAmbassador (register: ProfileRegisterInput): Enrollee
    registerHostess (register: HostessRegisterInput): Enrollee
    registerCustomer (register: HostessRegisterInput): Enrollee
`;

export {
    ambassadorInviteMutations, registerMutations, registerInput, registerBankDetailsInput,
    hostessRegisterInput,
};
