
import { Vue, Component } from 'vue-property-decorator';
import Search from '../../Search/index.vue';

@Component({
    components: {
        Search,
    },
})
export default class CategoryDisplayHeader extends Vue {
    get searchClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
        ];
    }
}
