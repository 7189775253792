
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { CommissionLevelType, GenealogyEnrolleeType } from '@/api/graphQL/graphNodes/types';
import system from '@/modules/System';
import nextRankResolver from '@/modules/Rank/services/nextRankResolver';
import RankRequirementManager from '@/utils/rankRequirementManager';
import TrackInfo from './track.vue';

@Component({
    components: {
        TrackInfo,
    },
})
export default class TrackCommission extends Vue {
    @Prop() private levels!: CommissionLevelType[];

    @Prop() private enrollee!: GenealogyEnrolleeType;

    @Get(system) private screenType!: string;

    get isSizeMobile() {
        return this.screenType === 'mobile';
    }

    get pv() {
        return Number(this.enrollee.stats
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.pv)!.value);
    }

    get nextRank(): string {
        const rank = nextRankResolver(this.enrollee.rank.power);
        return rank ? rank.label : '-';
    }

    get currentLevel(): CommissionLevelType|null {
        let nextIndex = this.levels.findIndex((level) => level.sales > this.pv);

        if (nextIndex === -1 && this.pv > this.levels[0].sales) {
            nextIndex = this.levels.length;
        }
        if (nextIndex > 0) {
            return this.levels[nextIndex - 1];
        }

        return null;
    }

    get nextLevel(): CommissionLevelType|null {
        const current = this.currentLevel;
        if (current) {
            const next = this.levels.find((level) => level.level > current.level);

            if (next) {
                return next;
            }

            return null;
        }
        return this.levels.find((level) => level.level === '1')!;
    }
}
