import { QUALIFIED_PERSONAL_RECRUIT_PS } from '@/modules/RisingStars/constants';
import { LegType } from '@/modules/types';
import resolveStatusType from '@/projectComponents/enrolleeLegItem/Item/services/resolveStatusType';

const calculateStatus: (data: LegType)
=> string = (data) => {
    const pv = data.stats.find((stat) => stat.metric === 'pv');
    if (pv) {
        const value = Number(pv.value);
        const percentage = value >= QUALIFIED_PERSONAL_RECRUIT_PS
            ? 100
            : (value * 100) / QUALIFIED_PERSONAL_RECRUIT_PS;
        return resolveStatusType(percentage);
    }

    return resolveStatusType(0);
    //  const { id } = data.rank;
    //  const rank = ranks.find((item) => item.id === id);

    //  let percentage = 0;

    //  if (rank && rank.requirements) {
    //      let total = 0;
    //      let count = 0;

    //      rank.requirements.forEach((item) => {
    //          const stat = data.stats.find((statItem) => statItem.metric === item.metric);
    //          if (stat) {
    //              count += 1;

    //              const x = Math.floor(Number(stat.value) * 100) / Number(item.value);
    //              total += x > 100 ? 100 : x;
    //          }
    //      });

    //      percentage = total / count;
    //  } else {
    //      percentage = 100;
    //  }

    //  return resolveStatusType(percentage);
};

export default calculateStatus;
