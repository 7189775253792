
import { Vue, Component } from 'vue-property-decorator';
import eventCreate from '@/modules/Event/EventCreate';
import { EVENT_TYPE } from '@/modules/Event/constants';

import OptionItemLayout from '@/projectComponents/optionItemLayout/index.vue';
import { Get } from '@/utils/vuex-module-mutators';
import env from '@/environment';

@Component({
    components: {
        OptionItemLayout,
    },
})
export default class CreateEventType extends Vue {
    @Get(eventCreate, 'eventData.type') private type!: string;

    get eventPartyType() {
        return EVENT_TYPE.party;
    }

    get eventVipLinkShareType() {
        return EVENT_TYPE.vipLinkShare;
    }

    get eventOneOnOneType() {
        return EVENT_TYPE.oneOnOne;
    }

    get isCustomEvent(): boolean {
        return this.type !== '' && this.type !== EVENT_TYPE.party
         && this.type !== EVENT_TYPE.private;
    }

    get baseUrl() {
        return env.BASE_URL;
    }

    get eventsTypesUnlocked() {
        return true;
    }

    handleTypeSelect(type: string) {
        eventCreate.clearEventCreate();
        eventCreate.setEventType(type);
        this.$router.push({ name: 'createEventDetails' });
    }
}
