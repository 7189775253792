
import { Vue, Component, Prop } from 'vue-property-decorator';
import { RankType } from '@/api/graphQL/graphNodes/types';
import rank from '@/modules/Rank';
import { Get } from '@/utils/vuex-module-mutators';
import { rank as defaultRank } from '@/modules/Rank/defaults';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import BonusQualifiedTeam from './BonusQualifiedTeam/index.vue';
import CTBV from './CTBV/index.vue';
import OBV from './OBV/index.vue';
import PBV from './PBV/index.vue';
import PersonalRecruits from './PersonalRecruits/index.vue';
import Rank from './Rank/index.vue';
import AmbassadorKit from './AmbassadorKit/index.vue';
import QualifiedLeaderLegs from './QualifiedLeaderLegs/index.vue';
import QualifiedLeaders from './QualifiedLeaders/index.vue';
import StatItemLayout from './StatItemLayout/index.vue';
import SPAGV from './SPAGV/index.vue';
import QNV from './QNV/index.vue';
import Commission from './Commission/index.vue';

@Component({
    components: {
        BonusQualifiedTeam,
        CTBV,
        OBV,
        PBV,
        PersonalRecruits,
        Rank,
        AmbassadorKit,
        QualifiedLeaderLegs,
        QualifiedLeaders,
        StatItemLayout,
        SPAGV,
        QNV,
        Commission,
    },
})
export default class Stats extends Vue {
    @Prop({ required: true }) private rank!: RankType|null;

    @Prop({ required: true }) private rankTitle!: string;

    @Prop({ default: false }) private nextRank!: boolean;

    @Get(rank) private ranks!: RankType[];

    get formattedRank(): RankType {
        let res = { ...defaultRank() };

        if (this.rank) {
            res = { ...this.rank };
        }

        if (!res.requirements || res.requirements === null) {
            res = { ...res, requirements: [] };
        }

        if (!res.titleRequirements || res.titleRequirements === null) {
            res = { ...res, titleRequirements: [] };
        }

        return res;
    }

    get requirements(): RankRequirementType[] {
        return this.nextRank ? this.formattedRank.titleRequirements!
            : this.formattedRank.requirements!;
    }

    get teamAmbassador(): RankType | undefined {
        return this.ranks.find((rankItem) => rankItem.label === 'Team Ambassador');
    }

    // get belowTa() {
    //     if (this.teamAmbassador && this.rank && this.teamAmbassador.power <= this.rank.power) {
    //         return true;
    //     }

    //     return false;
    // }

    get emptyElementsAmount(): number {
        return 0;
        // const { belowTa, formattedRank } = this;
        // const emptyConst = belowTa ? 2 : 3;

        // if (formattedRank.requirements) {
        //     const requirements = formattedRank.requirements
        //         .filter((item) => item.metric !== 'gv'
        //  && item.metric !== 'pv' && item.metric !== 'ov');

        //     return emptyConst - requirements.length;
        // }

        // return emptyConst;
    }
}
