
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankType } from '@/api/graphQL/graphNodes/types';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class BonusQualifiedTeam extends Vue {
    @Get(dashboard, 'data.ambassadorLegsCount') private ambassadorLegsCount!: number;

    @Prop() private rank!: RankType;

    @Prop() private requirements!: RankRequirementType[];

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(
            RankRequirementManager.rankRequirementMetricConstants.ambassadorLegs,
            this.ambassadorLegsCount,
        ).title;
    }

    get ambassadorLegsCountRequirement(): RankRequirementType | null {
        const requirement = this.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.ambassadorLegs);

        return requirement || null;
    }

    get progressValue(): number|null {
        const x = this.ambassadorLegsCount * 100;
        const ambassadorLegsCount = this.ambassadorLegsCountRequirement;

        if (!ambassadorLegsCount) {
            return null;
        }

        if (Number(ambassadorLegsCount.value) === 0) {
            return 0;
        }

        return x / Number(ambassadorLegsCount.value);
    }

    get displayData() {
        const requirement = this.ambassadorLegsCountRequirement;

        if (!requirement) {
            return false;
        }

        return true;
    }
}
