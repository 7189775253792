
import { Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import Vue from 'vue';
import calendar from '@/modules/Calendar';
import system from '@/modules/System';
import { CALENDAR_TIME_FRAME_OPTIONS, EVENT_TYPE } from '@/modules/Calendar/constants';
import SkeletonDay from '../../EventsSkeleton/Calendar/Day/index.vue';
import SkeletonMonth from '../../EventsSkeleton/Calendar/Month/index.vue';
import SkeletonWeek from '../../EventsSkeleton/Calendar/Week/index.vue';
import MonthView from './MonthView/index.vue';
import WeekView from './WeekView/index.vue';
import DayView from './DayView/index.vue';

@Component({
    components: {
        MonthView,
        WeekView,
        DayView,
        SkeletonDay,
        SkeletonMonth,
        SkeletonWeek,
    },
})
export default class CalendarContent extends Vue {
    @Get(system) private screenType!: string;

    @Get(calendar) private calendarTimeFrame!: string;

    @Get(calendar) private isLoading!: boolean;

    @Get(calendar) private eventsTypeFilter!:string ;

    get monthActive(): boolean {
        return CALENDAR_TIME_FRAME_OPTIONS.month === this.calendarTimeFrame;
    }

    get weekActive(): boolean {
        return CALENDAR_TIME_FRAME_OPTIONS.week === this.calendarTimeFrame;
    }

    get dayActive(): boolean {
        return CALENDAR_TIME_FRAME_OPTIONS.day === this.calendarTimeFrame;
    }

    get containerWithoutPadding(): boolean {
        return this.screenType === 'mobile'
         && this.calendarTimeFrame === CALENDAR_TIME_FRAME_OPTIONS.day;
    }

    get desktopClasses() {
        return [
            'md-elevation-4',
            { CalendarContent__DesktopContainer: this.monthActive },
        ];
    }

    get containerClasses() {
        return [
            'CalendarContent__ContentContainer',
            { CalendarContent__WeekContainer: this.weekActive },
            { 'CalendarContent__ContentContainer--noPadding': this.containerWithoutPadding },
        ];
    }

    get calendarTypeClasses() {
        const typeClass = ['CalendarContent__ContentContainerWrapper'];

        switch (this.eventsTypeFilter) {
        case EVENT_TYPE.organisational: {
            typeClass.push('CalendarContent__OrganisationalItem');
            break;
        }
        case EVENT_TYPE.personal: {
            typeClass.push('CalendarContent__PersonalItem');
            break;
        }
        default: {
            typeClass.push('CalendarContent__PersonalItem');
            break;
        }
        }

        return typeClass;
    }
}
