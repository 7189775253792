
import { Component, Vue } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import newStarters from '@/modules/NewStarters';
import FileViewer from '@/projectComponents/fileViewer/index.vue';
import { AssetType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {
        FileViewer,
    },
})
export default class NewStartersFab extends Vue {
    @Get(newStarters) private dialogOpen!: boolean;

    @Get(newStarters) private nspFlyer!: AssetType|null;

    handleFabClick() {
        newStarters.toggleDialogOpen();
    }

    handleDialogClose() {
        newStarters.toggleDialogOpen();
    }
}
