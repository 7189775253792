import { LocalStorageManager } from '@plumtreesystems/utils';

const storeVersion: string = '1.1.6';

const excludedModules: string[] = [
    'componentsControls',
    'componentsSandbox',
    'componentsSandboxExpandableItem',
    'system',
    'impersonationControls',
    'eventInvitation',
];

export default new LocalStorageManager(storeVersion, excludedModules);
