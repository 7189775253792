
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { Debounced } from '@/utils/debounced';
import myCustomers from '@/modules/MyCustomers';
import profile from '@/modules/Profile';
import { SelectOptionsType } from '@/components/select/types';
import { MyCustomersType } from '@/api/graphQL/graphNodes/types';

@Component
export default class MyCustomersCustomersSearch extends Vue {
    @Sync(myCustomers) private customersSearchQuery!: string;

    @Get(myCustomers) private customersSearchLoading!: boolean;

    @Get(myCustomers) private minSearchLength!: number;

    @Get(myCustomers) private searchedCustomersOptions!: MyCustomersType[];

    @Get(profile, 'originalData.id') private myId!: string;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedCustomersOptions.map((customer) => ({
            name: `${customer.firstName} ${customer.lastName}`,
            value: `${customer.firstName} ${customer.lastName}`,
        }));
    }

    handleSelect(val) {
        myCustomers.setCustomerSearchQuery(val.value);
        myCustomers.setDisplayCustomersSearchResults(true);

        this.$emit('onSelect');
    }

    @Debounced(1000)
    handleChange() {
        if (this.customersSearchQuery === '') {
            myCustomers.setDisplayCustomersSearchResults(false);
            myCustomers.setSearchedCustomersOptions([]);
            myCustomers.getMyCustomers({
                sellerId: this.myId,
            });
        }

        if (this.customersSearchQuery.length >= this.minSearchLength) {
            myCustomers.searchCustomers({
                sellerId: this.myId,
            });
        }
    }
}
