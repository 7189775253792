import dateManager from '@/utils/time';
import { NewStarterTotalsType, NewStartersLevelsType, StatsType } from '@/api/graphQL/graphNodes/types';
import { ResolverEnrolleeType } from '../types';
import { enrollee } from './enrollee';
import { newStartersLevels } from './newStartersLevels';

const newStartersByLevel: StatsType[] = [
    {
        metric: `${newStartersLevels()[0].key}_personal`,
        value: '1',
    },
    {
        metric: `${newStartersLevels()[1].key}_personal`,
        value: '3',
    },
    {
        metric: `${newStartersLevels()[2].key}_personal`,
        value: '4',
    },
    {
        metric: `${newStartersLevels()[3].key}_personal`,
        value: '6',
    },
    {
        metric: `${newStartersLevels()[4].key}_personal`,
        value: '7',
    },
    {
        metric: `${newStartersLevels()[5].key}_personal`,
        value: '8',
    },
    {
        metric: `${newStartersLevels()[6].key}_personal`,
        value: '10',
    },
    {
        metric: `${newStartersLevels()[0].key}_organisational`,
        value: '3',
    },
    {
        metric: `${newStartersLevels()[1].key}_organisational`,
        value: '4',
    },
    {
        metric: `${newStartersLevels()[2].key}_organisational`,
        value: '6',
    },
    {
        metric: `${newStartersLevels()[3].key}_organisational`,
        value: '7',
    },
    {
        metric: `${newStartersLevels()[4].key}_organisational`,
        value: '9',
    },
    {
        metric: `${newStartersLevels()[5].key}_organisational`,
        value: '11',
    },
    {
        metric: `${newStartersLevels()[6].key}_organisational`,
        value: '15',
    },
];

const sortNewStartersData = (newStartersData: ResolverEnrolleeType[]) => {
    const inSSPeriod: ResolverEnrolleeType[] = newStartersData
        .filter((starter: ResolverEnrolleeType) => {
            const { joinDate, kitPurchaseDate } = starter;

            return dateManager
                .isAfter(
                    `${dateManager.getDateTime(kitPurchaseDate || joinDate, dateManager.getDateFormat())} 00:00`,
                    `${dateManager.getCurrentDateWithOffset(-29, dateManager.getDateFormat())} 00:00`,
                );
        }).sort((a, b) => (dateManager
            .isBefore(
                b.kitPurchaseDate || b.joinDate,
                a.kitPurchaseDate || a.joinDate,
            ) ? 1 : -1));

    const afterSSPeriod: ResolverEnrolleeType[] = newStartersData
        .filter((starter: ResolverEnrolleeType) => {
            const { joinDate, kitPurchaseDate } = starter;

            return dateManager
                .isBefore(
                    `${dateManager.getDateTime(kitPurchaseDate || joinDate, dateManager.getDateFormat())} 00:00`,
                    dateManager.getCurrentDateWithOffset(-28, dateManager.getDateFormat()),
                );
        }).sort((a, b) => (dateManager
            .isBefore(
                a.kitPurchaseDate || a.joinDate,
                b.kitPurchaseDate || b.joinDate,
            ) ? 1 : -1));

    return [...inSSPeriod, ...afterSSPeriod];
};

const newStarters: (enrolleeId: string, params: { level: string, type: string,
    limit?: number, offset?: number, query?: string})
=> ResolverEnrolleeType[] = (enrolleeId, params) => {
    const {
        limit, offset, level, query,
    } = params;

    const selectedLevel = newStartersLevels().find((item) => item.key === level)!;
    const dateWithOffset = dateManager
        .getDateWithOffset(-selectedLevel.days, dateManager.getCurrentDate(), dateManager.getDayTimeFormat(), 'day');

    const enrolleeItem: ResolverEnrolleeType = enrollee(enrolleeId);

    let res = enrolleeItem.directDownline.map((element) => enrollee(element))
        .filter((element) => dateManager
            .isAfter(element.kitPurchaseDate, dateWithOffset));

    if (query) {
        res = res.filter((item) => `${item.firstName} ${item.lastName}`.toLowerCase().includes(query.toLowerCase()));
    }

    if (params.type === 'personal') {
        if (limit !== undefined && offset !== undefined) {
            return sortNewStartersData(res).splice(offset, limit);
        }
        return sortNewStartersData(res);
    }

    let downlineRes:ResolverEnrolleeType[] = [];

    res.forEach((element) => {
        element.directDownline.forEach((directItem) => {
            const resItem = newStarters(directItem, { level: params.level, type: params.type });
            downlineRes = [...downlineRes, enrollee(directItem), ...resItem]
                .filter((resItemElement) => dateManager
                    .isAfter(resItemElement.kitPurchaseDate, dateWithOffset));
        });
    });

    const result = sortNewStartersData([...res, ...downlineRes]);
    if (limit !== undefined && offset !== undefined) {
        return result.splice(offset, limit);
    }

    return result;
};

const newStarterTotals: (enrolleeItem: ResolverEnrolleeType)
=> NewStarterTotalsType[] = (enrolleeItem) => {
    const levels = newStartersLevels();

    return levels.map((item) => {
        // Status is at random, because it depends on a date
        let status: boolean|null = true;

        if (item.days >= 150) {
            status = null;
        } else if (item.days >= 90) {
            status = false;
        }

        return {
            level: item as NewStartersLevelsType,
            required: item.target,
            value: enrolleeItem.totals.pv,
            status,
        };
    });
};

export { newStarters, newStarterTotals, newStartersByLevel };
