import { Impersonator } from '@plumtreesystems/utils';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { ProfileType } from './types';

export default class GetProfileQuery extends
    AbstractQueryResource<GetProfileResultType> {
    protected getQuery(): string {
        const bankDetails = `
            bankDetails {
                bankAccountName,
                bankAccountNumber,
                bankSortCode,
            },
        `;

        return `query ${this.getName()} {
            profile {
                id,
                firstName,
                lastName,
                phoneNumber,
                email,
                address,
                secondAddress,
                birthDate,
                postCode,
                town,
                county,
                country,
                parentId,
                joinDate,
                rank {
                    id,
                    label,
                },
                ${Impersonator.isImpersonating() ? '' : bankDetails}
                profilePicture {
                    link,
                    context {
                        metric,
                        value
                    }
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getProfile';
    }
}

export type GetProfileResultType = {
    profile: ProfileType;
}

export type ProfileParamsType = {
    id: string;
}
