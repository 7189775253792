
import { MyCustomersType } from '@/api/graphQL/graphNodes/types';
import { Get } from '@/utils/vuex-module-mutators';
import { Vue, Component, Prop } from 'vue-property-decorator';
import system from '@/modules/System';
import CurrencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class CustomersListOwnedEvents extends Vue {
    @Get(system) private screenType!: string;

    @Prop({ required: true }) private customer!: MyCustomersType;

    get reward() {
        return 100;
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    handleClick(id) {
        this.$router.push({ name: 'eventView', params: { id } });
    }

    calculateProgress(val: number, max: number) {
        const a = val * 100;

        return a / max;
    }
}
