import dateManager from '@/utils/time';
import { ResolverEventPerformanceRewardsType } from '../types';

const defaultEventPerformanceRewards: ResolverEventPerformanceRewardsType = {
    enrolleeId: '0',
    rewards: [
        {
            expiryDate: dateManager.getDateWithOffset(0, dateManager.getCurrentDate()),
            rewardUrl: '',
            unlockedTiers: [],
        },
    ],
};

const eventPerformanceRewards: ResolverEventPerformanceRewardsType[] = [
    {
        enrolleeId: '0',
        rewards: [
            {
                expiryDate: dateManager.getDateWithOffset(5, dateManager.getCurrentDate()),
                rewardUrl: '',
                unlockedTiers: [
                    '1', '2', '3',
                ],
            },
        ],
    }, {
        enrolleeId: '1',
        rewards: [
            {
                expiryDate: dateManager.getDateWithOffset(60, dateManager.getCurrentDate()),
                rewardUrl: '',
                unlockedTiers: [
                    '1', '2', '3',
                ],
            },
        ],
    }, {
        enrolleeId: '2',
        rewards: [
            {
                expiryDate: dateManager.getDateWithOffset(48, dateManager.getCurrentDate()),
                rewardUrl: '',
                unlockedTiers: [
                    '1', '2', '3',
                ],
            },
        ],
    }, {
        enrolleeId: '3',
        rewards: [
            {
                expiryDate: dateManager.getDateWithOffset(17, dateManager.getCurrentDate()),
                rewardUrl: '',
                unlockedTiers: [
                    '1', '2', '3',
                ],
            },
        ],
    }, {
        enrolleeId: '4',
        rewards: [
            {
                expiryDate: dateManager.getDateWithOffset(26, dateManager.getCurrentDate()),
                rewardUrl: '',
                unlockedTiers: [
                    '1', '2', '3',
                ],
            },
        ],
    }, {
        enrolleeId: '5',
        rewards: [
            {
                expiryDate: dateManager.getDateWithOffset(35, dateManager.getCurrentDate()),
                rewardUrl: '',
                unlockedTiers: [
                    '1', '2', '3',
                ],
            },
        ],
    }, {
        enrolleeId: '6',
        rewards: [
            {
                expiryDate: dateManager.getDateWithOffset(15, dateManager.getCurrentDate()),
                rewardUrl: '',
                unlockedTiers: [
                    '1', '2', '3',
                ],
            },
        ],
    }, {
        enrolleeId: '7',
        rewards: [
            {
                expiryDate: dateManager.getDateWithOffset(21, dateManager.getCurrentDate()),
                rewardUrl: '',
                unlockedTiers: [
                    '1', '2',
                ],
            },
        ],
    }, {
        enrolleeId: '9',
        rewards: [
            {
                expiryDate: dateManager.getDateWithOffset(23, dateManager.getCurrentDate()),
                rewardUrl: '',
                unlockedTiers: [
                    '1',
                ],
            },
        ],
    }, {
        enrolleeId: '12',
        rewards: [
            {
                expiryDate: dateManager.getDateWithOffset(25, dateManager.getCurrentDate()),
                rewardUrl: '',
                unlockedTiers: [
                    '1',
                ],
            },
        ],
    },
];

const getEventPerformanceRewards: (id: string) => ResolverEventPerformanceRewardsType = (id) => {
    const performanceReward = eventPerformanceRewards.find((reward) => reward.enrolleeId === id);

    return performanceReward || defaultEventPerformanceRewards;
};
export { eventPerformanceRewards, getEventPerformanceRewards };
