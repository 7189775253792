
import { SelectOptionsType } from '@/components/select/types';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { Vue, Component, Prop } from 'vue-property-decorator';
import planningCentre from '@/modules/PlanningCentre';
import { IncomePlanType } from '@/api/graphQL/graphNodes/types';

@Component
export default class PlanningCentreHeaderWrapper extends Vue {
    @Prop() private plan!: IncomePlanType;

    @Sync(planningCentre) private selectedPlan!: string;

    @Get(planningCentre) private plans!: IncomePlanType[];

    get searchSelectOptions(): SelectOptionsType[] {
        return this.plans.map((item) => ({
            name: item.title,
            value: item.id,
        }));
    }

    format2DecimalPoints(val) {
        return Number(val).toFixed(2);
    }

    handleSelect(val: string) {
        planningCentre.setSelectedPlan(val);
    }
}
