import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import noPermissionRepository from './services/noPermissionRepository';
import { LEXIS_NEXIS_STATUS } from '../Register/constants';

@Module({
    namespaced: true, dynamic: true, store, name: 'underInspection',
})
@AutoMutations
export class UnderInspection extends VuexModule {
    private lexisNexisStatus: string|null = LEXIS_NEXIS_STATUS.fail;

    private loading: boolean = false;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLexisNexisStatus(val: string|null) {
        this.lexisNexisStatus = val;
    }

    @Action()
    public clearData() {
        this.setLoading(false);
    }

    @Action()
    public async getStatus() {
        try {
            this.setLoading(true);
            const res = await noPermissionRepository.getRegistrationStatus();
            this.setLexisNexisStatus(res.profile.lexisNexisStatus);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(UnderInspection);
