import Vue from 'vue';
import VueRouter from 'vue-router';

import env from '@/environment';
import authenticator from '@/modules/Auth/services/authenticator';
import auth from '@/modules/Auth';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import authLocalStorageManager from '@/modules/Auth/services/authLocalStorageManager';
import routes from './routes';
import { availableRoute } from './services/availableRoute';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: env.BASE_URL,
    routes: routes(),
});

const publicRoutes = [
    'login', 'auth', 'authError', 'error', 'noPermission',
    'register', 'registerAdditionalDetails', 'sendRegistration', 'hostessRegistration',
    'eventInvite', 'eventInvitationView', 'customerRegistration',
    'planningCentre',
];

router.beforeEach((to, from, next) => {
    if (to.name === 'noPermission' && from.name === 'error') {
        next(false);
    } else if (authLocalStorageManager.getAuthToken() === null
    && !publicRoutes.includes(to.name || '')) {
        next(false);
        authenticator.logout(true, 'login');
    } else if (
        !authenticator.authTokenFromStorage
        && !publicRoutes.includes(to.name || '')
        && env.VUE_APP_MOCK_AUTHENTICATOR === 'false'
    ) {
        next('/login');
    } else if (!availableRoute(to.name!)) {
        const error = new Error('route is not allowed');
        ErrorsProcessor.process(error);
    } else {
        next();
    }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach((to, from) => {
    if (!authenticator.getAuthenticated && !auth.authenticationInProgress
        && to.name && !['auth', 'register', 'registerAdditionalDetails', 'sendRegistration',
        'hostessRegistration', 'customerRegistration', 'eventInvite', 'eventInvitationView',
        'planningCentre'].includes(to.name)) {
        authenticator.handleUserDataLoading(to);
    }
});

authenticator.setRouter(router);
ErrorsProcessor.setRouter(router);

export default router;
