
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DownlineLegType, RankType } from '@/api/graphQL/graphNodes/types';
import { Get } from '@/utils/vuex-module-mutators';
import requirementLeaderLegs from '@/modules/LeaderLegs/requirementLeaderLegs';
import requirementRisingStars from '@/modules/RisingStars/requirementRisingStars';
import system from '@/modules/System';
import RankRequirementItem from './item.vue';
import { RankRequirementData } from './types';
import LeaderLegsDetails from './LeaderLegsDetails/index.vue';
import AmbassadorLegsDetails from './AmbassadorLegsDetails/index.vue';

@Component({
    components: {
        RankRequirementItem,
        LeaderLegsDetails,
        AmbassadorLegsDetails,
    },
})
export default class RankRequirements extends Vue {
    @Prop() private leg!: DownlineLegType;

    @Prop() private requirements!: RankRequirementData[];

    @Prop() private nextRank!: RankType|null;

    @Prop() private ranks!: RankType[];

    @Prop({ default: true }) private withRankRequirementsDetails!: boolean;

    @Get(requirementLeaderLegs, 'activeLegDetailsDialog') private activeLeaderLegsLegDetailsDialog!: string;

    @Get(requirementLeaderLegs, 'loading') private requirementLeaderLegsLoading!: boolean;

    @Get(requirementRisingStars, 'activeLegDetailsDialog') private activeRisingStarsLegDetailsDialog!: string;

    @Get(requirementRisingStars, 'loading') private requirementRisingStarsLoading!: boolean;

    @Get(system) private screenType!: string;

    get isSizeMobile() {
        return this.screenType === 'mobile';
    }

    get leaderLegsDialogOpen() {
        return this.activeLeaderLegsLegDetailsDialog !== '';
    }

    closeLeaderLegsDialog() {
        requirementLeaderLegs.setActiveLegDetailsDialog('');
    }

    get risingStarsDialogOpen() {
        return this.activeRisingStarsLegDetailsDialog !== '';
    }

    closeRisingStarsDialog() {
        requirementRisingStars.setActiveLegDetailsDialog('');
    }
}
