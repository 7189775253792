import { RankBaseType, RankType, RunTotalIncomeSummaryRankType } from '@/api/graphQL/graphNodes/types';

const rank: () => RankType = () => ({
    id: '-1',
    requirements: [],
    titleRequirements: [],
    label: '',
    power: -1,
});

const defaultMinimalRank: () => RankBaseType = () => ({
    id: '',
    label: '',
    power: -1,
});

const defaultIncomeSummaryRank: () => RunTotalIncomeSummaryRankType = () => ({
    ...defaultMinimalRank(),
    titleRequirements: [],
});

export { rank, defaultMinimalRank, defaultIncomeSummaryRank };
