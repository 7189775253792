
import { Vue, Component, Prop } from 'vue-property-decorator';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { EventViewType, InvitationToEventType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import {
    EVENT_ATTENDANCE_STATUS, EVENT_DATE_FORMAT, EVENT_TIME_FORMAT,
} from '@/modules/Event/constants';
import EventGuests from './guests.vue';

@Component({
    components: {
        PropertyInfoDisplay,
        EventGuests,
    },
})
export default class EventInfoView extends Vue {
    @Prop({ required: true }) private event!: EventViewType;

    @Prop({ default: [] }) private invitations!: InvitationToEventType[];

    get campaignDate() {
        const dateFrom = dateManager.getDateTime(this.event.campaignDateFrom, `${EVENT_DATE_FORMAT} ${EVENT_TIME_FORMAT}`);
        let dateTo = dateManager.getDateTime(this.event.campaignDateTo, `${EVENT_DATE_FORMAT} ${EVENT_TIME_FORMAT}`);

        if (dateManager.getDateTime(this.event.campaignDateFrom, EVENT_DATE_FORMAT)
            === dateManager.getDateTime(this.event.campaignDateTo, EVENT_DATE_FORMAT)) {
            dateTo = dateManager.getDateTime(this.event.campaignDateTo, EVENT_TIME_FORMAT);
            return `${dateFrom} - ${dateTo}`;
        }

        return `${dateFrom} - ${dateTo}`;
    }

    get eventDate() {
        const dateFrom = dateManager.getDateTime(this.event.eventDateFrom, `${EVENT_DATE_FORMAT} ${EVENT_TIME_FORMAT}`);
        let dateTo = dateManager.getDateTime(this.event.eventDateTo, `${EVENT_DATE_FORMAT} ${EVENT_TIME_FORMAT}`);

        if (dateManager.getDateTime(this.event.eventDateFrom, EVENT_DATE_FORMAT)
            === dateManager.getDateTime(this.event.eventDateTo, EVENT_DATE_FORMAT)) {
            dateTo = dateManager.getDateTime(this.event.eventDateTo, EVENT_TIME_FORMAT);
            return `${dateFrom} - ${dateTo}`;
        }

        return `${dateFrom} - ${dateTo}`;
    }

    get eventGuests() {
        return `${this.invitations.length} invites sent`;
    }

    get eventGuestsStatus() {
        const { invitations } = this;

        const goingGuests: number = invitations
            .filter((invitation) => invitation.attending === EVENT_ATTENDANCE_STATUS.yes)
            .length;

        const tentativeGuests: number = invitations
            .filter((invitation) => invitation.attending === EVENT_ATTENDANCE_STATUS.tentative)
            .length;

        const awaitingGuests: number = invitations
            .filter((invitation) => !invitation.attending)
            .length;

        const declinedGuests: number = invitations
            .filter((invitation) => invitation.attending === EVENT_ATTENDANCE_STATUS.declined)
            .length;

        return `${goingGuests} yes, ${tentativeGuests} tentative, ${awaitingGuests} awaiting, ${declinedGuests} declined`;
    }

    get descriptionHtml() {
        const converter = new QuillDeltaToHtmlConverter(
            JSON.parse(this.event.description),
        );
        return converter.convert();
    }

    get titleClasses() {
        return [
            'EventView__Title',
            { 'EventView__EventTitle--cancelled': this.event.canceled },
        ];
    }
}
