<template>
    <UILayout
        title="Link buttons"
        route="uiComponentsLinkButtons"
        id="linkButtonsSubscene"
    >
        <div class="Ui__Card md-elevation-4 pts-layout pts-gutter">
            <div class="pts-layout-item pts-size-100">
                <h3>Link buttons</h3>

                <div class="pts-layout-item pts-layout pts-gutter">
                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <LinkButton
                            text="New button"
                            type="contained"
                        />
                    </div>

                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <LinkButton
                            text="With href"
                            type="outlined"
                            href="#"
                        />
                    </div>

                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <LinkButton
                            text="With target"
                            type="text"
                            target="_blank"
                        />
                    </div>
                </div>

                <h5>Disabled</h5>

                <div class="pts-layout-item pts-layout pts-gutter">
                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <LinkButton
                            text="New button"
                            type="contained"
                            :disabled="true"
                            target="_blank"
                        />
                    </div>

                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <LinkButton
                            text="New button"
                            type="outlined"
                            :disabled="true"
                        />
                    </div>

                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <LinkButton
                            text="New button"
                            type="text"
                            :disabled="true"
                        />
                    </div>
                </div>

                <h5>With icon</h5>

                <div class="pts-layout-item pts-layout pts-gutter">
                    <div class="
                        pts-layout-item pts-size-30 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <LinkButton
                            text="New button"
                            type="contained"
                            icon="category"
                        />
                    </div>

                    <div class="
                        pts-layout-item pts-size-30 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <LinkButton
                            text="New button"
                            type="outlined"
                            icon="category"
                        />
                    </div>

                    <div class="
                        pts-layout-item pts-size-30 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <LinkButton
                            text="New button"
                            type="text"
                            icon="category"
                        />
                    </div>
                </div>
            </div>
        </div>
    </UILayout>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UILinkButtons extends Vue {}
</script>
