
import { Vue, Component } from 'vue-property-decorator';
import { EventPerformanceRewardType } from '@/api/graphQL/graphNodes/types';
import profile from '@/modules/Profile';
import { Get } from '@/utils/vuex-module-mutators';
import dateTimeManager from '@/utils/time';

@Component({})
export default class HostessEventRewards extends Vue {
    @Get(profile) private rewards!: EventPerformanceRewardType[];

    get formattedRewards(): (null|EventPerformanceRewardType)[] {
        const res: (null|EventPerformanceRewardType)[] = [...this.rewards];
        const maxLength = 3;
        const { length } = res;

        if (res.length < maxLength) {
            for (let index = 0; index < maxLength - length; index++) {
                res.push(null);
            }
        }

        return res;
    }

    calculateRewardClasses(item: null|EventPerformanceRewardType): string[] {
        const res = [
            'HostessEventRewards',
        ];

        if (!item) {
            res.push('HostessEventRewards--unavailable');
        }

        return res;
    }

    formatRewardTiers(reward: EventPerformanceRewardType): string {
        let res = '';
        reward.unlockedTiers.forEach((item, index) => {
            res = `${res}${index === 0 ? '' : ','} ${item}`;
        });

        return res;
    }

    handleClaimReward(reward: EventPerformanceRewardType) {
        window.open(reward.rewardUrl);
    }

    calculateDaysLeft(reward: EventPerformanceRewardType) {
        return dateTimeManager.getDifference(dateTimeManager.getCurrentDate(), reward.expiryDate!, 'day');
    }
}
