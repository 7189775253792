
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import eventView from '@/modules/Event/EventView';
import { HostessRewardsType } from '@/api/graphQL/graphNodes/types';
import tokenThreshold from '@/modules/TokenThreshold';
import Parameter from './parameter/index.vue';

@Component({
    components: {
        Parameter,
    },
})
export default class HostRewardsAndTokens extends Vue {
    @Get(eventView) private hostessRewards!: HostessRewardsType[];

    @Get(eventView, 'eventData.calendarEventPerformance.eventTotal') private eventTotal!: string;

    @Get(eventView, 'eventData.calendarEventPerformance.tokens') private tokens!: string;

    @Get(tokenThreshold) private threshold!: number;

    get hostRewardsMaxVal() {
        return this.hostessRewards.length;
    }

    get hostRewardsVal() {
        let res = 0;

        this.hostessRewards.forEach((item) => {
            if (item.thresholdGBP < (Number(this.eventTotal) / 100)) {
                res += 1;
            }
        });

        return res;
    }
}
