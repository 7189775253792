import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import { ActionMenuOptionType } from '@/components/actionMenu/types';
import { FileType, FileAreaCategoryType } from '@/components/fileArea/types';
import { MapMarkerType } from '@/components/map/types';
import { HydraPaginationType } from '@plumtreesystems/utils';
import { SelectOptionsType } from '@/components/select/types';
import store from '@/store';
import { EditorContentType } from '@/components/textField/types';
import { SwitcherOptionsType } from '@/components/switcher/types';
// const store = require('@/store').default;

@Module({
    namespaced: true, dynamic: true, store, name: 'componentsSandbox',
})
@AutoMutations
export class ComponentsSandbox extends VuexModule {
    name: string = 'John Doe';

    iban: string = '123456789123456789';

    date: null = null;

    dateInput: null = null;

    minDate: string = '2020-05-11T12:12+0000';

    maxDate: string = '2020-05-17T12:12+0000';

    actionMenuOptions: ActionMenuOptionType[] = [
        {
            label: 'Edit',
        },
        {
            label: 'Delete',
        },
    ];

    actionMenuOptionsWithIcons: ActionMenuOptionType[] = [
        {
            label: 'Edit',
            icon: 'create',
        },
        {
            label: 'Delete',
            icon: 'delete',
        },
    ];

    actionMenuOptionsWithDisabled: ActionMenuOptionType[] = [
        {
            label: 'Edit',
            icon: 'create',
        },
        {
            label: 'Delete',
            icon: 'delete',
            disabled: true,
        },
    ];

    fileAreaFiles: FileType[] = [];

    fileAreaCategories: FileAreaCategoryType[] = [
        {
            value: 'first',
            name: 'First',
            icon: 'category',
        },
        {
            value: 'second',
            name: 'Second',
        },
    ];

    lastAddedFileId: number = 1;

    actionMenuSelection: string = '';

    defaultDialogOpen: boolean = false;

    customDialogOpen: boolean = false;

    progressBarValue: number = 50;

    googleMapMarkers: MapMarkerType[] = [
        {
            position: {
                lat: 37.189419,
                lng: -98.273420,
            },
            id: 'first',
            data: 'Lorem ipsum',
        },
    ];

    currentPage: number = 1;

    pageOffset: number = 0;

    totalPageItems: number = 99;

    paginationHydra: HydraPaginationType = {
        first: 1,
        last: 3,
        current: null,
        filters: {},
    };

    radioChecked: boolean = false;

    searchSelectInput: string = '';

    searchSelectSelected: string = '';

    searchSelectOptions: SelectOptionsType[] = [
        {
            name: 'John Doe',
            value: 'john doe',
        },
        {
            name: 'John Clark',
            value: 'john clark',
        },
        {
            name: 'Jack Morgan',
            value: 'jack morgan',
        },
        {
            name: 'Kayne Rune',
            value: 'kayne Rune',
        },
    ];

    switcherOptions: SwitcherOptionsType[] = [
        {
            label: 'All Orders',
            value: 0,
        },
        {
            label: 'My Orders',
            value: 1,
        },
    ];

    customInlineEditOpen: boolean = false;

    createInlineEditOpen: boolean = false;

    editInlineEditOpen: boolean = false;

    removeInlineEditOpen: boolean = false;

    inlineEditOpen: boolean = false;

    selectSelected: string = '';

    activeTab: string = '';

    activeSwitcherItem: number = 0;

    textEditorContent: string = '';

    textEditorDisabledContent: string = '';

    textEditorContentWithError: string = '';

    textEditorContentWithHelper: string = '';

    textEditorOptions: object = {
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ header: 1 }, { header: 2 }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ direction: 'rtl' }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['clean'],
                ['link', 'image', 'video'],
            ],
        },
        placeholder: 'This is a placeholder...',
    };

    textContentDelta: EditorContentType = { ops: [] };

    textContent: string = '';

    @Mutation
    setActionMenuSelection(value: string) {
        this.actionMenuSelection = value;
    }

    @Mutation
    toggleDefaultDialogOpen() {
        this.defaultDialogOpen = !this.defaultDialogOpen;
    }

    @Mutation
    toggleCustomDialogOpen() {
        this.customDialogOpen = !this.customDialogOpen;
    }

    @Mutation
    setTextContentDelta(value: EditorContentType) {
        this.textContentDelta = value;
    }

    @Mutation
    addFilesToFileArea(files: FileType[]) {
        files.forEach((file) => {
            this.lastAddedFileId += 1;
            this.fileAreaFiles.push({
                category: file.category,
                file: file.file,
                fileId: String(this.lastAddedFileId),
            });
        });
    }

    @Mutation
    setName(value: string) {
        this.name = value;
    }

    @Mutation
    removeFileFromFileArea(index: number) {
        this.fileAreaFiles.splice(index, 1);
    }

    @Mutation
    changeCurrentPage(page: number) {
        this.currentPage = page;
    }

    @Mutation
    setSearchSelectSelected(value: string) {
        this.searchSelectSelected = value;
        this.searchSelectInput = '';
    }

    @Mutation
    toggleCustomInlineEditOpen() {
        this.customInlineEditOpen = !this.customInlineEditOpen;
    }

    @Mutation
    toggleCreateInlineEditOpen() {
        this.createInlineEditOpen = !this.createInlineEditOpen;
    }

    @Mutation
    toggleEditInlineEditOpen() {
        this.editInlineEditOpen = !this.editInlineEditOpen;
    }

    @Mutation
    toggleRemoveInlineEditOpen() {
        this.removeInlineEditOpen = !this.removeInlineEditOpen;
    }

    @Mutation
    toggleInlineEditOpen() {
        this.inlineEditOpen = !this.inlineEditOpen;
    }

    @Mutation
    setSelectSelected(val: string) {
        this.selectSelected = val;
    }

    @Mutation
    setActiveTab(val: string) {
        this.activeTab = val;
    }

    @Mutation
    setCurrentPageOffset(val: number) {
        this.pageOffset = val;
    }

    @Mutation
    setActiveSwitcherItem(val: number) {
        this.activeSwitcherItem = val;
    }
}

export default getModule(ComponentsSandbox);
