// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { IncomeSummaryCommissionRunsType } from './types';

export default class GetIncomeSummaryQuery extends
    AbstractQueryResource<GetParticipatedRunsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} { 
            profile {
                participatedRuns {
                    id,
                    label,
                },
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getParticipatedRuns';
    }
}

export type GetParticipatedRunsResultType = {
    profile: {
        participatedRuns: IncomeSummaryCommissionRunsType[];
    }
}
