
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { timeOptions, getNextHighestIndex } from './services/index';
import Select from '../select/select.vue';
import { SelectOptionsType } from '../select/types';
import { DATE_PICKER_TYPES } from './constants';

@Component({ components: { Select } })
export default class DatePickerTimeSelectComponent extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ required: true }) private value!: string;

    @Prop({ default: 'Time' }) private label!: string;

    @Prop() private timeType!: string;

    @Prop() private helperText!: string;

    @Prop() private error!: string;

    @Prop() timeSelectInterval!: number;

    private innerVal = this.value;

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    get timeOptions(): SelectOptionsType[] {
        return timeOptions(this.timeSelectInterval);
    }

    get displayTimeSelect() {
        return this.timeType === this.dateTypes.timeSelect;
    }

    get selectedScrollPosition(): number {
        return this.timeOptions.map((e) => e.name).indexOf(this.innerVal);
    }

    get formatValue() {
        if (this.displayTimeSelect) {
            const closestTime = this.formatClosestTimeValue(this.value);

            // Check for empty string in case of date not entered
            return this.value == null || this.value === '' ? '' : closestTime;
        }
        return this.value;
    }

    formatClosestTimeValue(val: string) {
        const timeOptionValues = timeOptions(this.timeSelectInterval).map((e) => e.name);
        const closestMinuteIndex = getNextHighestIndex(timeOptionValues, val);
        return timeOptionValues[closestMinuteIndex];
    }

    handleTimeUpdate(val: string) {
        this.$emit('onTimeUpdate', val);
    }

    scrollToSelectedValue() {
        const scroller = this.$el.querySelector('.SelectField__SelectOptionsContainer')?.firstElementChild;
        const listElement = this.$el.querySelector('.SelectField__SelectOptionsContainer > ul > li')?.clientHeight;
        scroller!.scrollTop = this.selectedScrollPosition * listElement!;
    }

    mounted() {
        this.scrollToSelectedValue();
        if (this.displayTimeSelect) {
            this.setVal(this.formatValue);
        }
    }

    @Watch('value')
    setVal(val: string) {
        if (this.displayTimeSelect && val !== '') {
            const closestTime = this.formatClosestTimeValue(val);
            this.handleTimeUpdate(closestTime);
            this.innerVal = closestTime;
        } else {
            this.innerVal = val;
        }
    }
}
