
import { Vue, Component, Prop } from 'vue-property-decorator';
import Requirement from '@/views/NewStarters/Content/Requirement/index.vue';
import { CommissionLevelType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {
        Requirement,
    },
})
export default class DashboardCommission extends Vue {
    @Prop({ required: true }) private commission!: CommissionLevelType;
}
