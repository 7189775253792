// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { EventViewType } from './types';

export default class GetEventQuery extends AbstractQueryResource<GetEventResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($id: String!, $offset: Int, $limit: Int) {
            event(id: $id) {
                id,
                title,
                description,
                confirmed,
                address,
                videoUrl
                campaignDateFrom,
                campaignDateTo,
                eventDateFrom,
                eventDateTo,
                canceled,
                type,
                ambassador {
                    id,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    address
                },
                hostess {
                    id,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    address
                },
                allowGuestsToInvite,
                invitations {
                    id,
                    enrollee {
                        id,
                        firstName,
                        lastName,
                        phoneNumber,
                        email,
                    },
                    attending,
                    name,
                    email,
                    type
                },
                eventOrders(offset: $offset, limit: $limit) {
                    id,
                    shippingCost,
                    discount,
                    products {
                        id,
                        quantity,
                        description,
                        price,
                        category,
                        total,
                        discounts,
                        cv,
                    },
                    enrollee {
                        id,
                        firstName,
                        lastName,
                    },
                    customer {
                        id,
                        firstName,
                        lastName,
                    },
                    grossValue,
                    commissionableValue,
                    date,
                    psc,
                }
                eventOrdersCount,
                customerShopUrl,
                eventCode,
                calendarEventPerformance {
                    tokens,
                    eventTotal,
                },
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getEvent';
    }
}

export type GetEventResultType = {
    event: EventViewType;
}

export type GetEventParamsType = {
    id: string;
    offset?: number;
    limit?: number;
}
