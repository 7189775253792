import { CommissionLevelType } from '@/api/graphQL/graphNodes/types';

const risingStarsCommissionLevels: () => CommissionLevelType[] = () => [
    {
        level: '1',
        rate: 20,
        sales: 100,
    },
    {
        level: '2',
        rate: 25,
        sales: 200,
    },
    {
        level: '3',
        rate: 30,
        sales: 1500,
    },
    {
        level: '4',
        rate: 35,
        sales: 4000,
    },
];

export { risingStarsCommissionLevels };
