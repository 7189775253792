// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetRegistrationStatusQuery extends
    AbstractQueryResource<GetRegistrationStatusResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                lexisNexisStatus,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getRegistrationStatus';
    }
}

export type GetRegistrationStatusResultType = {
    profile: {
        lexisNexisStatus: string|null;
    };
}
