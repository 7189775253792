

import { Get } from '@/utils/vuex-module-mutators';
import { Component, Vue, Prop } from 'vue-property-decorator';
import myCustomers from '@/modules/MyCustomers';
import { CUSTOMER_TYPE_VIP, CUSTOMER_TYPE_STANDARD } from '@/modules/MyCustomers/messages';
import { CUSTOMERS_TYPE } from '@/modules/MyCustomers/constants';

@Component
export default class CustomerTypeContainer extends Vue {
    @Get(myCustomers) private customerType!: string;

    @Prop() private isLoading!: boolean;

    get customerVipLabel() {
        return CUSTOMER_TYPE_VIP;
    }

    get customerStandardLabel() {
        return CUSTOMER_TYPE_STANDARD;
    }

    get standardValue() {
        return CUSTOMERS_TYPE.standard;
    }

    get vipValue() {
        return CUSTOMERS_TYPE.host;
    }

    handleClick(value: string) {
        myCustomers.setCustomerType(value);
        this.$emit('select');
    }
}
