
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankRequirementsType, RankType } from '@/api/graphQL/graphNodes/types';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class QNV extends Vue {
    @Get(dashboard, 'data.stats') private stats!: RankRequirementsType[];

    @Prop() private rank!: RankType;

    @Prop() private requirements!: RankRequirementType[];

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(
            RankRequirementManager.rankRequirementMetricConstants.qnv,
        ).title;
    }

    get qnvRequirement(): string|null {
        const qnv = this.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.qnv);

        return qnv ? qnv.value : null;
    }

    get qnv() {
        const stat = this.stats.find((statItem) => statItem.metric === RankRequirementManager
            .rankRequirementMetricConstants.qnv)!.value;

        return Number(stat);
    }

    get progressValue(): number|null {
        const x = this.qnv * 100;

        if (this.requirements.length === 0) {
            return null;
        }

        if (!this.qnvRequirement) {
            return null;
        }

        if (Number(this.qnvRequirement) === 0) {
            return 0;
        }

        return x / Number(this.qnvRequirement);
    }

    get tooltip() {
        let text = '';

        switch (this.rank.power) {
        case 10:
            text = 'This is not required at this level';
            break;
        case 20:
            text = 'You need £1000+ in qualified commissionable sales to unlock this level';
            break;
        case 30:
        default:
            text = 'You need £3000+ in qualified commissionable sales to unlock this level';
            break;
        }

        return text;
    }
}
