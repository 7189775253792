const newStartersLevels = `type NewStartersLevels {
    key: String!,
    label: String!,
    days: Int!,
}`;

const newStartersLevelsQuery = `
    newStartersLevels: [NewStartersLevels]
`;

export {
    newStartersLevels, newStartersLevelsQuery,
};
