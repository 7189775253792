
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CreateEventType } from '@/modules/Event/Events/types';
import { SelectOptionsType } from '@/components/select/types';
import { EVENT_TYPE, MAX_CAMPAIGN_LENGTH } from '@/modules/Event/constants';
import eventTypeLabelResolver from '@/modules/Event/services/eventTypeLabelResolver';
import dateManager from '@/utils/time';
import { DATE_PICKER_TYPES } from '@/components/datePicker/constants';
import resetDateOutOfTimeFrame from '@/modules/Event/services/resetDateOutOfTimeFrame';
import { timeOptions } from '@/components/datePicker/services';

@Component
export default class EventCreateForm extends Vue {
    @Prop() private value!: CreateEventType;

    @Prop() private formErrors!: any;

    @Prop({ default: 'Add a description' }) private descriptionPlaceholder!: string;

    handleValue(value: string, name: string) {
        let resVal = value;
        if (['eventDateFrom', 'eventDateTo', 'campaignDateFrom', 'campaignDateTo'].includes(name)) {
            const timeSelectOptions = timeOptions().map((item) => ({
                numericVal: Number(String(item.value!).replace(':', '')),
                original: item.value!,
            }));

            const currentTime = Number(dateManager.getDateTime(value, 'HHmm'));
            const currentTimeZone = dateManager.getDateTime(value, 'ZZ');

            const time = timeSelectOptions.find((item) => item.numericVal >= currentTime);
            resVal = `${dateManager.getDateTime(value, dateManager.getDateFormat())} ${time?.original}${currentTimeZone}`;

            const resetList: string[] = resetDateOutOfTimeFrame(name, resVal, this.value);

            resetList.forEach((item) => this.$emit('input', { key: item, value: '' }));
        }

        const res = { key: name, val: resVal };
        this.$emit('input', res);
    }

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    get minEventDate() {
        const { eventDateFrom, campaignDateFrom } = this.value;
        return eventDateFrom || campaignDateFrom;
    }

    get maxEventDate() {
        const { campaignDateTo } = this.value;
        return campaignDateTo;
    }

    get descriptionEditorOptions() {
        return {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                ],
            },
            placeholder: this.descriptionPlaceholder,
        };
    }

    get customEvent(): boolean {
        return this.value.type !== EVENT_TYPE.party && this.value.type !== EVENT_TYPE.oneOnOne
            && this.value.type !== EVENT_TYPE.vipLinkShare;
    }

    get options(): SelectOptionsType[] {
        return [
            {
                name: eventTypeLabelResolver(EVENT_TYPE.showcase),
                value: EVENT_TYPE.showcase,
            },
            {
                name: eventTypeLabelResolver(EVENT_TYPE.getTogether),
                value: EVENT_TYPE.getTogether,
            },
            {
                name: eventTypeLabelResolver(EVENT_TYPE.braFit),
                value: EVENT_TYPE.braFit,
            },
            {
                name: eventTypeLabelResolver(EVENT_TYPE.campaignLaunch),
                value: EVENT_TYPE.campaignLaunch,
            },
            {
                name: eventTypeLabelResolver(EVENT_TYPE.generalMeeting),
                value: EVENT_TYPE.generalMeeting,
            },
            {
                name: eventTypeLabelResolver(EVENT_TYPE.custom),
                value: EVENT_TYPE.custom,
            },
        ];
    }

    get lastAvailableCampaignDate() {
        const { campaignDateFrom } = this.value;
        if (campaignDateFrom !== '') {
            return dateManager.getDateWithOffset(
                MAX_CAMPAIGN_LENGTH - 1, campaignDateFrom, dateManager.getDayTimeFormatUtc(),
            );
        }

        return '';
    }
}
