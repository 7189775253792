
import { Vue, Component, Prop } from 'vue-property-decorator';
import system from '@/modules/System';
import { Get } from '@/utils/vuex-module-mutators';
import tokenThreshold from '@/modules/TokenThreshold';
import { TOOLTIP_TOKEN_PERFORMANCE } from '@/modules/constants';

@Component
export default class HostRewardsAndTokensParameter extends Vue {
    @Prop({ default: '' }) private title!: string;

    @Prop({ default: '' }) private value!: number;

    @Prop({ default: '' }) private maxValue!: number;

    @Prop({ default: false }) private customLabel!: boolean;

    @Prop({ default: false }) private customLabelContent!: string;

    @Prop({ default: '' }) private tooltipType!: string;

    @Get(system) screenType!: string;

    @Get(tokenThreshold) private threshold!: number;

    get progressVal(): number {
        return (this.value * 100) / this.maxValue;
    }

    get tooltipText(): string {
        switch (this.tooltipType) {
        case 'hostTokenTotal':
            return TOOLTIP_TOKEN_PERFORMANCE(this.threshold);

        default:
            return '';
        }
    }
}
