
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { IncomePlanType } from '@/api/graphQL/graphNodes/types';
import CardItem from '../Components/cardItem.vue';
import DisplayItem from '../Components/displayItem.vue';

@Component({
    components: {
        CardItem,
        DisplayItem,
    },
})
export default class PlanningCentreEarnings extends Vue {
    @Prop() private plan!: IncomePlanType;
}
