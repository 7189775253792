
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import rank from '@/modules/Rank';
import dashboard from '@/modules/Dashboard';
import { RankType } from '@/api/graphQL/graphNodes/types';
import Stats from '../Stats/index.vue';

@Component({
    components: {
        Stats,
    },
})
export default class RankContainer extends Vue {
    @Get(dashboard, 'data.rank') private rank!: RankType|null;

    @Get(rank) private ranks!: RankType[];

    @Get(dashboard) private activeTab!: string;

    get level1(): RankType {
        const { ranks } = this;

        return ranks.find((rankItem) => rankItem.power === 10)!;
    }

    get level2(): RankType {
        const { ranks } = this;

        return ranks.find((rankItem) => rankItem.power === 20)!;
    }

    get level3(): RankType {
        const { ranks } = this;

        return ranks.find((rankItem) => rankItem.power === 30)!;
    }

    tabChanged(val: string) {
        dashboard.setActiveTab(val);
    }
}
