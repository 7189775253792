
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import incomeSummary from '@/modules/IncomeSummary';
import {
    CommissionLevelType,
    IncomeSummaryCommissionRunsType, RankBaseType, RunTotalIncomeSummaryRankType,
    RunTotalIncomeSummaryType, StatsType, StatsWithLabelType,
} from '@/api/graphQL/graphNodes/types';
import {
    INCOME_SUMMARY_OWN_SALES, INCOME_SUMMARY_QUALIFIED_PERSONAL_RECRUITS,
    INCOME_SUMMARY_TOTAL_NETWORK_SALES,
} from '@/modules/IncomeSummary/constants';
import CurrencyPrefix from '@/utils/currencyPrefix';
import Requirement from '@/views/NewStarters/Content/Requirement/index.vue';
import { RankRequirementData } from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/types';
import system from '@/modules/System';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import SalesFromChart from './SalesFromChart/index .vue';

@Component({
    components: {
        SalesFromChart,
        Requirement,
    },
})
export default class IncomeSummaryTotals extends Vue {
    @Get(incomeSummary) private runTotalIncomeSummary!: RunTotalIncomeSummaryType;

    @Get(incomeSummary) private selectedTimeFrame!: string;

    @Get(incomeSummary) private commissionRuns!: IncomeSummaryCommissionRunsType[];

    @Get(incomeSummary) private rank!: RunTotalIncomeSummaryRankType;

    @Get(incomeSummary) private payRank!: RankBaseType;

    @Get(incomeSummary, 'runTotalIncomeSummary.metricStats') private metricStats!: StatsType[];

    @Get(incomeSummary) private risingStarsCommissionLevels!: CommissionLevelType[];

    @Get(system) screenType!: string;

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get getOptions(): SelectOptionsType[] {
        return this.commissionRuns.map((item) => ({
            name: item.label,
            value: item.id,
        }));
    }

    get ownSales() {
        const metric = this.runTotalIncomeSummary.metricStats
            .find((item) => item.metric === INCOME_SUMMARY_OWN_SALES);
        return metric ? metric.value : '';
    }

    get qualifiedPersonalRecruits() {
        const metric = this.runTotalIncomeSummary.metricStats
            .find((item) => item.metric === INCOME_SUMMARY_QUALIFIED_PERSONAL_RECRUITS);
        return metric ? metric.value : '';
    }

    get totalNetworkSales() {
        const metric = this.runTotalIncomeSummary.metricStats
            .find((item) => item.metric === INCOME_SUMMARY_TOTAL_NETWORK_SALES);
        return metric ? metric.value : '';
    }

    get salesCommission(): number {
        let commission: number = 0;
        const { ownSales } = this;

        this.risingStarsCommissionLevels.forEach((item) => {
            if (item.sales < Number(ownSales)) {
                commission = item.rate;
            }
        });

        return commission;
    }

    get isSizeMobile() {
        return this.screenType === 'mobile';
    }

    get displayTitleQualifications(): boolean {
        return this.titleQualifications.length > 0;
    }

    get titleQualifications(): RankRequirementData[] {
        if (this.rank.titleRequirements === null) {
            return [];
        }

        return this.rank.titleRequirements.map((item) => {
            const stat = this.metricStats.find((statItem) => statItem.metric === item.metric)!;

            return {
                label: RankRequirementManager.getRankRequirementLabel(item.metric).title,
                metric: '',
                current: `${stat.value}`,
                required: `${item.value}`,
            };
        });
    }

    get bonusStatsList() {
        const { bonusStats } = this.runTotalIncomeSummary;
        const keys = bonusStats
            .map((stat) => stat.metric)
            .filter(this.onlyUnique);

        return keys.map((key) => {
            let res: StatsWithLabelType = {
                label: '',
                metric: '',
                value: '0',
            };

            bonusStats
                .filter((item) => item.metric === key)
                .forEach((item) => {
                    res = {
                        label: item.label,
                        metric: item.metric,
                        value: `${Number(res.value) + Number(item.value)}`,
                    };
                });

            return res;
        });
    }

    get payRankDisplay(): string {
        if (this.payRank === null) {
            return '-';
        }

        return this.payRank.label;
    }

    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    handleSelect(val: string) {
        incomeSummary.setSelectedTimeFrame(val);
        incomeSummary.getTotals();
    }

    format2DecimalPoints(val) {
        return Number(val).toFixed(2);
    }
}
