
import IconButton from '@/components/iconButton/iconButton.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
    components: {
        IconButton,
    },
})
export default class DatePickerClearButtonComponent extends Vue {
    handleClick() {
        this.$emit('clear');
    }
}
