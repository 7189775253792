
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class PlanningCentreCardItem extends Vue {
    @Prop() private label!: string;

    @Prop() private tooltipText!: string;
}
