
import { Vue, Component, Prop } from 'vue-property-decorator';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import { EventEnrolleeType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {
        PropertyInfoDisplay,
    },
})
export default class EventInvitationHostess extends Vue {
    @Prop({ required: true }) private hostess!: EventEnrolleeType;
}
