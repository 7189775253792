
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DownlineExplorerType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';

@Component
export default class DownlineCurrentPerformance extends Vue {
    @Prop() private leg!: DownlineExplorerType;

    get qnv() {
        return this.leg.stats.find((stat) => stat.metric === RankRequirementManager
            .rankRequirementMetricConstants.qnv)?.value;
    }

    get pv() {
        return this.leg.stats.find((stat) => stat.metric === RankRequirementManager
            .rankRequirementMetricConstants.pv)?.value;
    }
}
