// eslint-disable-next-line import/no-cycle
import API from '@/api/graphQL';
import { GetGenealogyEnrolleeParamsType } from '@/api/graphQL/graphNodes/GetGenealogyEnrolleeQuery';
import { GetRisingStarsByIdParamsType } from '@/api/graphQL/graphNodes/GetRisingStarsByIdQuery';
import { RisingStarsParamsType, RisingStarsSearchParamsType } from '@/api/graphQL/graphNodes/GetRisingStarsQuery';

export default class RisingStarsRepository {
    static getRisingStars(params: RisingStarsParamsType) {
        return API
            .getRisingStars()
            .query(params, {}, { type: '' });
    }

    static searchRisingStars(params: RisingStarsSearchParamsType) {
        return API
            .getRisingStars()
            .query(params, {}, { type: 'search' });
    }

    static getRisingStarsById(params: GetRisingStarsByIdParamsType) {
        return API
            .getRisingStarsById()
            .query(params);
    }

    static getLeg(params: GetGenealogyEnrolleeParamsType) {
        return API
            .getGenealogyEnrollee()
            .query(params);
    }
}
