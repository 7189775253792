
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import myCustomers from '@/modules/MyCustomers';
import { CUSTOMERS_TYPE } from '@/modules/MyCustomers/constants';
import system from '@/modules/System';
import { MyCustomersType } from '@/api/graphQL/graphNodes/types';
import Contacts from '@/projectComponents/enrolleeLegItem/Item/Contacts/index.vue';
import DetailsDialog from '@/projectComponents/enrolleeLegItem/DetailsDialogContent/index.vue';
import OwnedEvents from './OwnedEvents/index.vue';

@Component({
    components: {
        Contacts,
        DetailsDialog,
        OwnedEvents,
    },
})
export default class CustomersListItem extends Vue {
    @Get(system) private screenType!: string;

    @Get(myCustomers) private customerType!: string;

    @Get(myCustomers) private selectedCustomerList!: string;

    @Prop() private id!: string;

    @Prop() private myId!: string;

    @Prop({ required: true }) private customer!: MyCustomersType;

    @Prop() private expandedList!: string[];

    @Prop({ default: true }) private withSponsorDetails!: boolean;

    @Prop({ default: '' }) private activeSponsorDetailsModal!: string;

    get expanded(): boolean {
        const { id } = this.customer;
        return this.expandedList.findIndex((item) => item === id) !== -1;
    }

    get sponsorDetailsOpen(): boolean {
        return this.activeSponsorDetailsModal === this.customer.id;
    }

    get displayOrdersButton(): boolean {
        // return this.selectedCustomerList === CUSTOMER_LIST_TYPE.orders
        //     && this.customer.searchOrdersCount > 0;
        return this.customer.searchOrdersCount > 0;
    }

    get displayEditButton(): boolean {
        // const { customer, myId } = this;
        // return this.selectedCustomerList === CUSTOMER_LIST_TYPE.addressBook
        //     && customer.parent.id === myId
        //     && customer.editable
        //     && myId !== customer.id;
        return false;
    }

    get customerTypeVip(): string {
        return CUSTOMERS_TYPE.host;
    }

    handleOpenSponsor(id: string) {
        this.$emit('activeSponsorDetailsId', id);
    }

    handleExpandedToggle() {
        this.$emit('expandedToggle', this.customer.id);
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    handleOrders(id: string) {
        this.$router.push({ name: 'myCustomerOrders', params: { id } });
    }

    handleEditCustomer(id: string) {
        this.$router.push({ name: 'editMyCustomer', params: { id } });
    }
}
