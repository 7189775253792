import { MAX_CAMPAIGN_LENGTH } from '@/modules/Event/constants';
import dateManager from '@/utils/time';
import { CreateEventType } from '../Events/types';

const resetDateOutOfTimeFrame: (name: string, val: string, data: CreateEventType)
 => string [] = (name, val, data) => {
     const res: string[] = [];
     if (name === 'campaignDateFrom' && val) {
         const endDate = dateManager.getDateWithOffset(
             MAX_CAMPAIGN_LENGTH - 1, val, dateManager.getDayTimeFormatUtc(),
         );

         if (data.campaignDateTo !== '' && (
             dateManager.isAfter(data.campaignDateTo, endDate)
              || dateManager.isBefore(data.campaignDateTo, val))) {
             res.push('campaignDateTo');
         }
         if (data.eventDateFrom !== '' && (
             dateManager.isAfter(data.eventDateFrom, endDate)
              || dateManager.isBefore(data.eventDateFrom, val))) {
             res.push('eventDateFrom');
         }
         if (data.eventDateTo !== '' && (
             dateManager.isAfter(data.eventDateTo, endDate)
              || dateManager.isBefore(data.eventDateTo, val))) {
             res.push('eventDateTo');
         }
     }

     if (name === 'campaignDateTo' && val) {
         const startDate = data.campaignDateFrom !== '' ? data.campaignDateFrom : dateManager.getDateWithOffset(
             -(MAX_CAMPAIGN_LENGTH - 1), val, dateManager.getDayTimeFormatUtc(),
         );

         if (data.campaignDateFrom !== '' && (
             dateManager.isAfter(data.campaignDateFrom, val)
            || dateManager.isBefore(data.campaignDateFrom, startDate))) {
             res.push('campaignDateFrom');
         }

         if (data.eventDateFrom !== '' && (
             dateManager.isAfter(data.eventDateFrom, val)
          || dateManager.isBefore(data.eventDateFrom, startDate))) {
             res.push('eventDateFrom');
         }

         if (data.eventDateTo !== '' && (
             dateManager.isAfter(data.eventDateTo, val)
          || dateManager.isBefore(data.eventDateTo, startDate))) {
             res.push('eventDateTo');
         }
     }

     if (name === 'eventDateFrom' || name === 'eventDateTo') {
         let startDate = data.campaignDateFrom;
         let endDate = data.campaignDateTo;

         if (startDate && endDate) {
             startDate = dateManager.getDateWithOffset(
                 -(MAX_CAMPAIGN_LENGTH - 1), endDate, dateManager.getDayTimeFormatUtc(),
             );
         }

         if (startDate && endDate) {
             endDate = dateManager.getDateWithOffset(
                 MAX_CAMPAIGN_LENGTH - 1, startDate, dateManager.getDayTimeFormatUtc(),
             );
         }

         if (name === 'eventDateFrom' && val && startDate && endDate && data.eventDateTo) {
             if (dateManager.isAfter(val, data.eventDateTo) && val !== data.eventDateTo) {
                 res.push('eventDateTo');
             }
         }

         if (name === 'eventDateTo' && val && startDate && endDate && data.eventDateFrom) {
             if (dateManager.isBefore(val, data.eventDateFrom) && val !== data.eventDateFrom) {
                 res.push('eventDateFrom');
             }
         }
     }

     return res;
 };

export default resetDateOutOfTimeFrame;
