import {
    Mutation, getModule, VuexModule, Module,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import store from '@/store';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import { StatsType, TransactionsType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetSisterCreditParamsType } from '@/api/graphQL/graphNodes/GetSisterCreditQuery';
import SisterCreditRepository from './services/sisterCreditRepository';
import { initialBalanceData, initialTransaction } from './defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'sisterCredit',
})
@AutoMutations
export class SisterCredit extends VuexModule {
    private transactions: TransactionsType[] = [];

    private transaction: TransactionsType = initialTransaction();

    private balance: StatsType = initialBalanceData();

    private loading: boolean = false;

    private loadingInBackground: boolean = false;

    private limit: number = 10;

    private offset: number = 0;

    private total: number = 0;

    @Mutation
    setTransactions(data: TransactionsType[]) {
        this.transactions = [...data];
    }

    @Mutation
    setTransaction(data: TransactionsType) {
        this.transaction = data;
    }

    @Mutation
    setBalance(data: StatsType) {
        this.balance = { ...data };
    }

    @Mutation
    setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    setOffset(val: number) {
        this.offset = val;
    }

    @Mutation
    setTotal(val: number) {
        this.total = val;
    }

    @Action()
    public async getSisterCreditData(payload: { loadPage?: boolean, offset?: number }) {
        const { loadPage = false, offset = 0 } = payload;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const params: GetSisterCreditParamsType = {
                offset,
                limit: this.limit,
            };
            const { profile } = await SisterCreditRepository.getSisterCredit(params);

            this.setTransactions(profile.transactions);
            this.setBalance(profile.balance);

            this.setOffset(offset);
            this.setTotal(profile.transactionsCount);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
            this.setLoadingInBackground(false);
        }
    }
}

export default getModule(SisterCredit);
