<template>
    <UILayout
        title="Tags"
        route="uiComponentsTags"
        id="tagsSubscene"
    >
        <div class="Ui__Card md-elevation-4 pts-layout pts-gutter">
            <div class="pts-layout-item pts-size-100">
                <h3>Tags</h3>

                <div class="pts-layout-item pts-size-100 pts-layout pts-gutter">
                    <div class="pts-layout-item pts-size-10 pts-small-size-20 pts-xsmall-size-50">
                        <Tag
                            text="Tag"
                        />
                    </div>
                    <div class="pts-layout-item pts-size-15 pts-small-size-30 pts-xsmall-size-50">
                        <Tag
                            text="Action"
                            actionIcon="cancel"
                        />
                    </div>
                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-35 pts-xsmall-size-50
                    ">
                        <Tag
                            text="Thumbnail"
                            thumbnail="category"
                        />
                    </div>
                    <div class="pts-layout-item pts-size-15 pts-xsmall-size-50">
                        <Tag
                            text="Disabled"
                            :disabled="true"
                        />
                    </div>
                </div>
                <div class="pts-layout-item pts-size-100 pts-layout pts-gutter">
                    <div class="pts-layout-item pts-size-10 pts-small-size-20 pts-xsmall-size-50">
                        <Tag
                            text="Tag"
                            type="outlined"
                        />
                    </div>
                    <div class="pts-layout-item pts-size-15 pts-small-size-30 pts-xsmall-size-50">
                        <Tag
                            text="Action"
                            type="outlined"
                            actionIcon="cancel"
                        />
                    </div>
                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-35 pts-xsmall-size-50
                    ">
                        <Tag
                            text="Thumbnail"
                            type="outlined"
                            thumbnail="category"
                        />
                    </div>
                    <div class="pts-layout-item pts-size-15 pts-xsmall-size-50">
                        <Tag
                            text="Disabled"
                            type="outlined"
                            :disabled="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </UILayout>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UITags extends Vue {}
</script>
