
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import Skeleton from '@/projectComponents/skeleton/Files/index.vue';
import reports from '@/modules/Reports';
import profile from '@/modules/Profile';
import { AssetType, ReportType, RunsType } from '@/api/graphQL/graphNodes/types';
import { SelectOptionsType } from '@/components/select/types';
import env from '@/environment';
import AuthLocalStorageManager from '@/modules/Auth/services/authLocalStorageManager';
import { Impersonator } from '@plumtreesystems/utils';

@Component({
    components: {
        Skeleton,
    },
})
export default class Reports extends Vue {
    @Get(reports) private loading!: boolean;

    @Get(reports) private runs!: RunsType[];

    @Get(reports) private run!: string|null;

    @Get(reports) private reports!: ReportType[];

    @Get(profile, 'originalData.firstName') private firstName!: string;

    @Get(profile, 'originalData.lastName') private lastName!: string;

    get serverUrl(): string {
        return env.VUE_APP_SERVER_URL;
    }

    get reportsList(): {item: ReportType, comingSoon: boolean}[] {
        // const {
        //     firstName, lastName, run, runs,
        // } = this;

        // const fullName = (`${firstName}-${lastName}`);
        const res = this.reports.map((report) => ({
            item: report,
            comingSoon: false,
        }));

        // if (run === runs[0].id) {
        //     res.push({
        //         comingSoon: true,
        //         item: {
        //             fileName: `${fullName}_NEW_BUSINESS_SNAPSHOT.csv`,
        //             downloadUrl: '',
        //         },
        //     }, {
        //         comingSoon: true,
        //         item: {
        //             fileName: `${fullName}_RISK_OF_LEAVING_SNAPSHOT.csv`,
        //             downloadUrl: '',
        //         },
        //     });
        // }

        return res;
    }

    get getRuns(): SelectOptionsType[] {
        return this.runs
            .map((run) => ({
                name: run.label,
                value: run.id,
            }));
    }

    get token() {
        if (Impersonator.isImpersonating()) {
            return Impersonator.getImpersonateToken();
        }

        return AuthLocalStorageManager.getAuthToken();
    }

    downloadUrl(url: string) {
        return `${this.serverUrl}${url}&token=${this.token}`;
    }

    formateFile(report: ReportType): Partial<AssetType> {
        return {
            link: report.downloadUrl,
            fileName: report.fileName,
            context: [],
            title: report.fileName,
            originalFileName: report.fileName,
        };
    }

    handleSelect(val) {
        reports.setRun(val);
        this.loadReports();
    }

    loadReports() {
        reports.getReports();
    }

    mounted() {
        this.loadReports();
    }

    beforeDestroy() {
        reports.setRun(null);
    }
}
