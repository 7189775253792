// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { MyHostDetailsType } from './types';

export default class GetHostDetailsQuery extends
    AbstractQueryResource<GetHostDetailsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($id: String) {
            enrollee(id: $id) {
                eventPerformance {
                    tokens
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getHostDetails';
    }
}

export type GetHostDetailsResultType = {
    enrollee: MyHostDetailsType
}

export type GetHostDetailsParamsType = {
    id: string;
}
