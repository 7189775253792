const transaction = `type Transaction {
    date: String!
    refId: String!
    description: String!
    type: String!
    amount: Float!
    currency: String!
    sale: Sale
}`;

export { transaction };
