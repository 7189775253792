import { validateEmail } from '@/utils/email-validation';
import { FIELD_REQUIRED_MESSAGE, INVALID_EMAIL_ADDRESS } from '@/utils/messages/formValidation';
import { EventErrorType } from '../types';

const formHostessValidation: (val: any) => EventErrorType[] = (val) => {
    const errors: EventErrorType[] = [];

    if (!val.email) {
        errors.push({ key: 'invitations[0].email', val: FIELD_REQUIRED_MESSAGE });
    }

    if (!validateEmail(val.email)) {
        errors.push({ key: 'email', val: INVALID_EMAIL_ADDRESS });
    }

    if (!val.firstName) {
        errors.push({ key: 'invitations[0].firstName', val: FIELD_REQUIRED_MESSAGE });
    }

    if (!val.lastName) {
        errors.push({ key: 'invitations[0].lastName', val: FIELD_REQUIRED_MESSAGE });
    }

    return errors;
};

export default formHostessValidation;
