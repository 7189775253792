import API from '@/api/graphQL';
import { GetAssetsCategoriesParamsType } from '@/api/graphQL/graphNodes/GetAssetsCategoriesQuery';
import { GetMarketingMaterialParamsType } from '@/api/graphQL/graphNodes/GetMarketingMaterialQuery';

export default class MarketingMaterialRepository {
    static getMarketingMaterial(params: GetMarketingMaterialParamsType) {
        return API
            .getMarketingMaterial()
            .query(params);
    }

    static getCategories() {
        return API
            .getAssetsCategories()
            .query({ type: 'marketing' } as GetAssetsCategoriesParamsType);
    }
}
