// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { AssetType, NewStartersType } from './types';
import parentObject from './reusable/listItemEnrolleeParent';

export default class GetNewStartersQuery extends
    AbstractQueryResource<GetNewStartersResultType|GetNewStartersSearchResultType> {
    protected getQuery(options = { type: '' }): string {
        const { type } = options;
        return `query ${this.getName()}($level: String!, $type: String!, $tag: String!${type === '' ? ', $offset: Int!, $limit: Int!' : ', $query: String!'}) {
            profile {
                ${type === '' ? 'newStartersCommissionEngine: newStartersByMilestone' : 'searchNewStarters: searchNewStartersByMilestone'}(key: $level, type: $type, ${type === '' ? 'offset: $offset, limit: $limit' : 'query: $query'}) {
                    id,
                    email,
                    phoneNumber,
                    address,
                    secondPhoneNumber,
                    secondAddress,
                    county,
                    country,
                    town,
                    postCode,
                    ${parentObject.getQuery()},
                    firstName,
                    lastName,
                    kitPurchaseDate,
                    joinDate,
                    rank {
                        id,
                        label,
                        power,
                    },
                    newStarterTotals: newStarterTotalsByMilestones {
                        level {
                            key,
                            label,
                            days,
                        },
                        value,
                        required,
                        status,
                    },
                },
                ${type === '' ? 'newStartersCountCommissionEngine: newStartersByMilestoneCount(key: $level, type: $type),' : ''}
            },
            nspFlyer: assetByTag(tag: $tag) {
                id,
                category,
                link,
                fileName,
                context {
                    metric,
                    value,
                },
                title,
                originalFileName,
                uploadDate,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getNewStarters';
    }
}

export type GetNewStartersResultType = {
    profile: {
        newStartersCommissionEngine: NewStartersType[];
        newStartersCountCommissionEngine: number;
    };
    nspFlyer: AssetType;
}

export type GetNewStartersSearchResultType = {
    profile: {
        searchNewStarters: NewStartersType[];
    };
    nspFlyer: AssetType;
}

export type NewStartersParamsType = {
    level: string;
    type: string;
    offset?: number;
    limit?: number;
    tag: string;
}

export type NewStartersSearchParamsType = NewStartersParamsType & {
    query: string;
}
