

import { Component, Vue } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import env from '@/environment';
import calendar from '@/modules/Calendar';

@Component
export default class ColorExplain extends Vue {
    @Get(calendar) isLoading!: boolean;

    get pictureBaseUrl() {
        return `${env.BASE_URL}img/icons/`;
    }

    get explainButtonIcon() {
        if (this.isLoading) {
            return `${this.pictureBaseUrl}key_disabled.svg`;
        }

        return `${this.pictureBaseUrl}key.svg`;
    }
}
