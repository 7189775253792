import { EVENT_TYPE } from '@/modules/Calendar/constants';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { CommissionLevelType, DashboardType, NewStartersLevelsType } from './types';

export default class GetDashboardQuery extends
    AbstractQueryResource<GetDashboardResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($dateFrom: DateTime!, $dateTo: DateTime!, $lastMonthDateFrom: DateTime!, $lastMonthDateTo: DateTime!) {
            profile {
                ambassadorLegsCount,
                personalRecruitsCount,
                vanityUrl,
                rank {
                    id,
                    label,
                    power,
                    requirements {
                        metric,
                        value
                    },
                    titleRequirements {
                        metric,
                        value
                    }
                },
                paidForKit,
                qualifiedLeaderLegsCount,
                newStartersCountByLevel {
                    metric,
                    value
                },
                joinDate,
                newCustomersInCentralTeam(from: $dateFrom, to: $dateTo),
                qualifiedOrgLeaderCount,
                soldOrdersCount,
                directHostsCount,
                directDownlineCountThisMonth: directDownlineCount(from: $dateFrom),
                directDownlineCount,
                hasReleventNewStarters,
                newStarterTotalsByMilestones {
                    level {
                        key,
                        label,
                        days,
                    },
                    value,
                    required,
                    status,
                },
                stats {
                    metric,
                    value,
                }
            },
            thisMonth: eventsTotals(type: "${EVENT_TYPE.personal}") {
                finishedEvents,
                bookedEvents,
                projectedSales,
                avgSalesPerEvent,
                totalSales,
                totalEvents
            },
            lastMonth: eventsTotals(dateFrom: $lastMonthDateFrom, dateTo: $lastMonthDateTo, type: "${EVENT_TYPE.personal}") {
                finishedEvents,
            },
            newStartersLevels {
                key,
                label,
                days
            },
            risingStarsCommissionLevels {
                level,
                rate,
                sales
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getDashboard';
    }
}

export type GetDashboardResultType = {
    profile: DashboardType;
    thisMonth: {
        finishedEvents: number;
        bookedEvents: number;
        projectedSales: string;
        avgSalesPerEvent: string;
        totalSales: number;
        totalEvents: number;
    };
    lastMonth: {
        finishedEvents: number;
    };
    newStartersLevels: NewStartersLevelsType[];
    risingStarsCommissionLevels: CommissionLevelType[];
}

export type GetDashboardParamsType = {
    dateFrom: string;
    dateTo: string;
    lastMonthDateFrom: string;
    lastMonthDateTo: string;
}
