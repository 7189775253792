
import { DownlineProfileType } from '@/api/graphQL/graphNodes/types';
import { ErrorType } from '@plumtreesystems/utils';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { NATIONAL_NUMBER_MESSAGE, BIRTHDATE_MESSAGE } from '@/utils/messages/formValidation';
import {
    COUNTRY_SELECT_OPTIONS, TOOLTIP_FIRST_NAME_TOOLTIP,
    TOOLTIP_LAST_NAME_TOOLTIP,
} from '@/modules/constants';
import { SelectOptionsType } from '@/components/select/types';
import { DATE_PICKER_TYPES } from '@/components/datePicker/constants';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import { CountriesType } from '@/modules/Event/types';

@Component({
    components: {
        FormFieldTooltip,
    },
})
export default class PersonalDetails extends Vue {
    @Prop() private idTag!: string;

    @Prop() private formErrors!: ErrorType;

    @Prop() private formData!: DownlineProfileType;

    @Prop({ default: false }) private isCustomer!: boolean;

    @Prop({ default: false }) private isHost!: boolean;

    @Prop({ default: false }) disableNameInputs!: boolean;

    @Prop({ default: false }) disableEmailInput!: boolean;

    @Prop({ default: '' }) customFirstNameTooltip!: string;

    @Prop({ default: '' }) customLastNameTooltip!: string;

    @Prop({ default: [] }) countries!: CountriesType[];

    get firstNameTooltip(): string {
        const { customFirstNameTooltip } = this;

        if (customFirstNameTooltip !== '') {
            return customFirstNameTooltip;
        }

        return TOOLTIP_FIRST_NAME_TOOLTIP;
    }

    get lastNameTooltip(): string {
        const { customLastNameTooltip } = this;

        if (customLastNameTooltip !== '') {
            return customLastNameTooltip;
        }

        return TOOLTIP_LAST_NAME_TOOLTIP;
    }

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    get countrySelectOptions(): SelectOptionsType[] {
        return this.countries.map((item) => ({
            name: item.longLabel,
            value: item.shortLabel,
        }));
    }

    get gbCountry(): string {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    handleValue($event, value, key) {
        this.$emit('handleValue', $event, value, key);
    }

    getHelperText(val: string) {
        if (val === 'birthDate') {
            return BIRTHDATE_MESSAGE;
        }
        return NATIONAL_NUMBER_MESSAGE;
    }
}
