
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankRequirementsType, RankType } from '@/api/graphQL/graphNodes/types';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class PersonalRecruits extends Vue {
    @Get(dashboard, 'data.stats') private stats!: RankRequirementsType[];

    @Prop() private requirements!: RankRequirementType[];

    @Prop() private rank!: RankType;

    get personalRecruitsCount(): number {
        return Number(this.stats.find((item) => item.metric === this.metric)!.value);
    }

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.personalRecruits;
    }

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(this.metric).title;
    }

    get personalRecruitsCountRequirement(): RankRequirementType | null {
        const requirement = this.requirements.find((item) => item.metric === this.metric);

        return requirement || null;
    }

    get progressValue(): number|null {
        const x = this.personalRecruitsCount * 100;
        const personalRecruitsCount = this.personalRecruitsCountRequirement;

        if (!personalRecruitsCount) {
            return null;
        }

        if (Number(personalRecruitsCount.value) === 0) {
            return 0;
        }

        return x / Number(personalRecruitsCount.value);
    }

    get tooltip() {
        let text = '';

        switch (this.rank.power) {
        case 10:
            text = 'This is not required at this level';
            break;
        case 20:
            text = 'You need 2 Qualified Consultants in the month to unlock this level. Qualified = Your Consultant has £100+ sales in the month';
            break;
        case 30:
        default:
            text = 'You need 5 Qualified Consultants in the month to unlock this level. Qualified = Your Consultant has £100+ sales in the month';
            break;
        }

        return text;
    }
}
