export const COUNTRY_SELECT_OPTIONS = {
    gb: 'GB',
    ie: 'ie',
};

export const FORM_ERROR_TOOLTIP = {
    timeOutInterval: 4000,
    errorMessage: 'There are incomplete required fields',
};

export const BUTTON_DELAY_TIME: number = 150;
export const EXPANSION_DELAY_TIME: number = 350;

export const TOOLTIP_FIRST_NAME_TOOLTIP = 'Your First name as it appears on official documents. Please do not use abbreviations or nicknames.';
export const TOOLTIP_LAST_NAME_TOOLTIP = 'Your Surname as it appears on official documents.';
export const TOOLTIP_TO_CHANGE_FIRST_NAME_CONTACT_HQ_TOOLTIP = 'To change your first name please contact HQ';
export const TOOLTIP_TO_CHANGE_LAST_NAME_CONTACT_HQ_TOOLTIP = 'To change your last name please contact HQ';
export const TOOLTIP_BIRTH_DATE_TOOLTIP = 'Enter year first, then select month/date by scrolling through calendar.';
export const TOOLTIP_NAME_OF_ACCOUNT_TOOLTIP = 'Enter full name as it appears on your bank card i.e. Miss Ann Summers.';
export const TOOLTIP_TOKEN_PERFORMANCE = (threshold) => `You need ${threshold} tokens to qualify for a free kit`;
export const TOOLTIP_SOMETHING_WENT_WRONG = 'Something went wrong, please try again.';

export const ASSET_TAG_NSP_FLYER = 'NEW_STARTER_PROGRAMME_FLYER';
