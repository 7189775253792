
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { EventOrderType } from '@/api/graphQL/graphNodes/types';
import CurrencyPrefix from '@/utils/currencyPrefix';
import myCustomers from '@/modules/MyCustomers';
import eventView from '@/modules/Event/EventView';
import auth from '@/modules/Auth';
import system from '@/modules/System';
import RankRequirementsItem from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/item.vue';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import HostRewardsAndTokens from './HostRewardsAndTokens/index.vue';
import OrderDetails from './OrderDetails/index.vue';
import HostessRewards from './HostesRewards/index.vue';
import HostRewardTable from './HostRewardTable/index.vue';

@Component({
    components: {
        OrderDetails,
        HostessRewards,
        RankRequirementsItem,
        HostRewardsAndTokens,
        HostRewardTable,
        FormFieldTooltip,
    },
})
export default class EventOrdersView extends Vue {
    @Prop({ required: true }) private orders!: EventOrderType[];

    @Prop({ required: true }) private ended!: boolean;

    @Prop({ required: true }) private selfHosted!: boolean;

    @Get(eventView) private showOrderDetails!: boolean;

    @Get(eventView, 'eventData.customerShopUrl') private customerShopUrl!: string;

    @Get(system) private screenType!: string;

    get title() {
        return 'VIP Customer Rewards & Token Totals';
    }

    get ordersGrossValue(): string {
        return this.orders
            .reduce((total, order) => total + Number(order.grossValue), 0)
            .toFixed(2);
    }

    get ordersTableClasses() {
        return [
            { EventOrdersView__HostessOrderItem: this.isHostess },
        ];
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get ordersTotalCommisionable() {
        return this.orders
            .reduce((total, order) => total + Number(order.commissionableValue), 0)
            .toFixed(2);
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    get totalOrderValueTooltipMessage(): string {
        return 'The values shown in this column are the total product value of the order before IBA discount';
    }

    mounted() {
        eventView.setDisplayCustomerDetails(false);
        eventView.setShowHostessBonusDetails(false);
    }

    beforeDestroy() {
        eventView.setShowOrderDetails(false);
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    handleShowOrderDetails(order: EventOrderType) {
        if (!this.isHostess) {
            myCustomers.setProvideOrderDetails(order);
            eventView.toggleShowOrderDetails();
        }
    }

    handleDialogClose() {
        eventView.toggleShowOrderDetails();
    }
}
