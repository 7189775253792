
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import sisterCredit from '@/modules/SisterCredit';
import { StatsType } from '@/api/graphQL/graphNodes/types';
import env from '@/environment';
import Skeleton from './SisterPaySkeleton/index.vue';
import Transactions from './Transactions/index.vue';
import Balance from './Balance/index.vue';

@Component({
    components: {
        Transactions,
        Balance,
        Skeleton,
    },
})
export default class SisterCredit extends Vue {
    @Get(sisterCredit) private balance!: StatsType;

    @Get(sisterCredit) private loading!: boolean;

    get storeCreditLabel(): string {
        return env.VUE_APP_DISABLE_LOGO === 'true'
            ? 'Sister Pay'
            : 'Connect Pay';
    }

    mounted() {
        sisterCredit.getSisterCreditData({ offset: 0 });
    }
}
