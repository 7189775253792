
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import risingStars, { RisingStars } from '@/modules/RisingStars';
import system from '@/modules/System';
import { Debounced } from '@/utils/debounced';
import { RisingStarsType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {},
})
export default class RisingStarsHeader extends Vue {
    @Get(risingStars) private sortType!: string;

    @Sync(risingStars) private searchQuery!: string;

    @Get(risingStars) private searchLoading!: boolean;

    @Get(risingStars) private minSearchLength!: number;

    @Get(risingStars) private searchedLegsOptions!: RisingStarsType[];

    @Get(risingStars) private displaySearchResults!: boolean;

    @Get(risingStars) private displaySearch!: boolean;

    @Get(system) private screenType!: string;

    @Prop() private loading!: boolean;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedLegsOptions.map((leg) => ({
            name: `${leg.firstName} ${leg.lastName}`,
            value: `${leg.firstName} ${leg.lastName}`,
        }));
    }

    get sortOptions(): SelectOptionsType[] {
        return [
            {
                name: 'Top Sellers',
                value: RisingStars.SORT_TYPES.top_sellers,
            },
            {
                name: 'Network Builders',
                value: RisingStars.SORT_TYPES.team_builders,
            },
            {
                name: 'Inactives',
                value: RisingStars.SORT_TYPES.inactives,
            },
        ];
    }

    get showSearch() {
        return this.screenType !== 'mobile' || this.displaySearch;
    }

    get searchContainerClasses() {
        return [
            'RisingStarsHeader__Container',
            'pts-layout-item',
            'pts-size-50',
            'pts-small-size-100',
            { 'RisingStarsHeader__Container--show': this.showSearch },
        ];
    }

    get searchContainerElementClasses() {
        return [
            'RisingStarsHeader__ContainerElement',
            { 'RisingStarsHeader__ContainerElement--show': this.showSearch },
        ];
    }

    async handleSortTypeChange(val) {
        risingStars.setSortType(val);
        await risingStars.getRisingStars({ offset: 0 });
    }

    handleSelect(val) {
        risingStars.setSearchQuery(val.value);
        risingStars.setDisplaySearchResults(true);
        risingStars.search({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            risingStars.setDisplaySearchResults(false);
            risingStars.setSearchedLegs([]);
            risingStars.setSearchedOptionsLegs([]);
        }
        if (this.searchQuery.length >= this.minSearchLength) { risingStars.search(); }
    }
}
