import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { IncomePlanType } from '@/api/graphQL/graphNodes/types';
import PlanningCentreRepository from './services/PlanningCentreRepository';
import { defaultIncomePlan } from './defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'planningCentre',
})
@AutoMutations
export class Orders extends VuexModule {
    private loading: boolean = false;

    private plans: IncomePlanType[] = [];

    private selectedPlan: string = '';

    get plan(): IncomePlanType {
        const res = this.plans.find((item) => item.id === this.selectedPlan);
        return res || defaultIncomePlan();
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setPlans(val: IncomePlanType[]) {
        this.plans = [...val];
    }

    @Mutation
    public setSelectedPlan(val: string) {
        this.selectedPlan = val;
    }

    @Action()
    async getPlans() {
        try {
            this.setLoading(true);

            const incomePlans: IncomePlanType[] = await PlanningCentreRepository.getPlans();
            this.setPlans(incomePlans);

            if (this.selectedPlan === '' && incomePlans[0]) {
                this.setSelectedPlan(incomePlans[0].id);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(Orders);
