
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import newStarters, { NewStarters } from '@/modules/NewStarters';
import { DashboardRecruitmentType } from '@/api/graphQL/graphNodes/types';
import CurrencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class RecruitmentPerformance extends Vue {
    @Get(dashboard) private recruitmentData!: DashboardRecruitmentType[];

    @Get(dashboard, 'data.performanceData.totalSales') private totalSales!: number;

    @Get(dashboard, 'data.performanceData.totalEvents') private totalEvents!: number;

    @Get(dashboard, 'data.directHostsCount') private directHostsCount!: number;

    @Get(dashboard, 'data.soldOrdersCount') private soldOrdersCount!: number;

    @Get(dashboard, 'data.directDownlineCountThisMonth') private directDownlineCountThisMonth!: number;

    @Get(dashboard, 'data.directDownlineCount') private directDownlineCount!: number;

    @Get(dashboard, 'data.hasReleventNewStarters') private hasReleventNewStarters!: boolean;

    get withRecruitmentPerformance(): boolean {
        return this.withSoldOrdersCount || this.withDirectHostsCount
         || this.withTotalEvents || this.withTotalSales;
    }

    get personalType() {
        return NewStarters.NEW_STARTERS_TYPE.personal;
    }

    get organisationalType() {
        return NewStarters.NEW_STARTERS_TYPE.organisational;
    }

    get withSoldOrdersCount() {
        return this.soldOrdersCount > 0;
    }

    get withDirectHostsCount() {
        return this.directHostsCount > 0;
    }

    get withTotalEvents(): boolean {
        return this.totalEvents > 0;
    }

    get withTotalSales(): boolean {
        return this.totalSales > 0;
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    handleOpenNewStarters(params: { type: string, timeframe: string }) {
        newStarters.setSearchQuery('');
        newStarters.setSearchedLegs([]);
        newStarters.setSearchedOptionsLegs([]);
        newStarters.setType(params.type);
        newStarters.setNewStartersTimeFrame(params.timeframe);
        this.redirectToNewstarters();
    }

    redirectToNewstarters() {
        this.$router.push({ name: 'newStarters' });
    }
}
