import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, AssetType } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { NewStartersType } from '@/api/graphQL/graphNodes/types';
import { ASSET_TAG_NSP_FLYER } from '@/modules/constants';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import {
    GetNewStartersResultType, GetNewStartersSearchResultType, NewStartersParamsType,
    NewStartersSearchParamsType,
} from '@/api/graphQL/graphNodes/GetNewStartersQuery';
import newStartersRepository from './services/newStartersRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'newStarters',
})
@AutoMutations
export class NewStarters extends VuexModule {
    static readonly NEW_STARTERS_TYPE = {
        personal: 'personal',
        central: 'central_team',
        organisational: 'organisational',
    }

    private starters: NewStartersType[] = [];

    private type: string = NewStarters.NEW_STARTERS_TYPE.personal;

    private loaded: boolean = false;

    private loading: boolean = false;

    private loadingInBackground: boolean = false;

    private activeLegDetailsDialog: string = '';

    private expandedNewStarters: string[] = [];

    private newStartersTimeFrame: string = '';

    private activeSponsorDetailsModal: string = '';

    private searchQuery: string = '';

    private searchLoading: boolean = false;

    private searchedLegsOptions: NewStartersType[] = [];

    private searchedLegs: NewStartersType[] = [];

    private minSearchLength: number = 3;

    private displaySearchResults: boolean = false;

    private displaySearch: boolean = false;

    private count: number = 0;

    private limit: number = 20;

    private offset: number = 0;

    private dialogOpen: boolean = false;

    private nspFlyer: AssetType|null = null;

    get getNewStartersTimeFrame() {
        return this.newStartersTimeFrame;
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setType(val: string) {
        this.type = val;
    }

    @Mutation
    public setActiveLegDetailsDialog(val: string) {
        this.activeLegDetailsDialog = val;
    }

    @Mutation
    public setNewStartersTimeFrame(val: string) {
        this.newStartersTimeFrame = val;
    }

    @Mutation
    public setStarters(val: NewStartersType[]) {
        this.starters = [...val];
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedNewStarters.find((item) => item === val)) {
            const index = this.expandedNewStarters.findIndex((item) => item === val);
            this.expandedNewStarters.splice(index, 1);
        } else {
            this.expandedNewStarters.push(val);
        }
    }

    @Mutation
    clearExpandedNewStarters() {
        this.expandedNewStarters = [];
    }

    @Mutation
    setActiveSponsorDetailsModal(val: string) {
        this.activeSponsorDetailsModal = val;
    }

    @Mutation
    setSearchLoading(val: boolean) {
        this.searchLoading = val;
    }

    @Mutation
    setSearchedLegs(val: NewStartersType[]) {
        this.searchedLegs = val;
    }

    @Mutation
    setSearchedOptionsLegs(val: NewStartersType[]) {
        this.searchedLegsOptions = val;
    }

    @Mutation
    setSearchQuery(val: string) {
        this.searchQuery = val;
    }

    @Mutation
    setDisplaySearchResults(val: boolean) {
        this.displaySearchResults = val;
    }

    @Mutation
    toggleShowSearch() {
        this.displaySearch = !this.displaySearch;
    }

    @Mutation
    public setOffset(val:number) {
        this.offset = val;
    }

    @Mutation
    public setCount(val: number) {
        this.count = val;
    }

    @Mutation
    public toggleDialogOpen() {
        this.dialogOpen = !this.dialogOpen;
    }

    @Mutation
    setNspFlyer(val: AssetType) {
        this.nspFlyer = val;
    }

    @Action()
    public async getData(data?: {offset?: number, loadInBackground: boolean}) {
        const offset = data && data.offset ? data.offset : 0;
        const loadInBackground = data && data.loadInBackground ? data.loadInBackground : false;

        try {
            if (loadInBackground) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const params: NewStartersParamsType = {
                level: this.newStartersTimeFrame,
                type: this.type,
                offset,
                limit: this.limit,
                tag: ASSET_TAG_NSP_FLYER,
            };

            const res: GetNewStartersResultType = await newStartersRepository
                .getNewStarters(params) as GetNewStartersResultType;

            const { newStartersCommissionEngine, newStartersCountCommissionEngine } = res.profile;

            if (res.nspFlyer && !this.nspFlyer) {
                this.setNspFlyer(res.nspFlyer);
            }
            this.setCount(newStartersCountCommissionEngine);

            this.setStarters(newStartersCommissionEngine);
            this.setOffset(offset);
            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (loadInBackground) {
                this.setLoadingInBackground(false);
            } else {
                this.setLoading(false);
            }
        }
    }

    @Action()
    async search(data: { query?: string|null, selectedSearch?: boolean }
    = { query: null, selectedSearch: false }) {
        const { query, selectedSearch } = data;

        try {
            if (selectedSearch) {
                this.setSearchLoading(true);
            }

            const params: NewStartersSearchParamsType = {
                level: this.newStartersTimeFrame,
                type: this.type,
                query: query || query === '' ? query : this.searchQuery,
                tag: ASSET_TAG_NSP_FLYER,
            };

            const result: GetNewStartersSearchResultType = await newStartersRepository
                .searchNewStarters(params) as GetNewStartersSearchResultType;

            const { searchNewStarters } = result.profile;

            if (result.nspFlyer && !this.nspFlyer) {
                this.setNspFlyer(result.nspFlyer);
            }

            if (selectedSearch) {
                this.setSearchedLegs(searchNewStarters);
            } else {
                this.setSearchedOptionsLegs(searchNewStarters);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setSearchLoading(false);
            }
        }
    }
}

export default getModule(NewStarters);
