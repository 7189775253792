import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import {
    AssetType, CommissionLevelType, CommissionStatementsType, IncomeSummaryCommissionRunsType,
    RankBaseType, RunTotalIncomeSummaryRankType, RunTotalIncomeSummaryType, StatsType,
} from '@/api/graphQL/graphNodes/types';
import { GetIncomeSummaryParamsType, GetIncomeSummaryResultType } from '@/api/graphQL/graphNodes/GetIncomeSummaryQuery';
import { GetCommissionStatementsResultType } from '@/api/graphQL/graphNodes/GetCommissionStatementsQuery';
import { GetParticipatedRunsResultType } from '@/api/graphQL/graphNodes/GetParticipatedRunsQuery';
import dateManager from '@/utils/time';
import { GetIncomeSummaryRunTotalsParamsType, GetIncomeSummaryRunTotalsResultType } from '@/api/graphQL/graphNodes/GetIncomeSummaryRunTotalsQuery';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import IncomeSummaryRepository from './services/incomeSummaryRepository';
import { defaultRunTotalIncomeSummary } from './defaults';
import { defaultIncomeSummaryRank } from '../Rank/defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'incomeSummary',
})
@AutoMutations
export class IncomeSummary extends VuexModule {
    private selectedTimeFrame: string = '';

    private yearSelected: number = Number(env.VUE_APP_MOCK_GRAPHQL === 'true'
        ? mockedDateManager.getCurrentDate('YYYY')
        : dateManager.getCurrentDate('YYYY'));

    private loading: boolean = false;

    private files: CommissionStatementsType[] = [];

    private loadingInBackground: boolean = false;

    private statementsLoading: boolean = false;

    private runTotalsLoading: boolean = false;

    private previewOpen: boolean = false;

    private previewFileId: string = '';

    private commissionRuns: IncomeSummaryCommissionRunsType[] = [];

    private loadingCommissionRuns: boolean = false;

    private runTotalIncomeSummary: RunTotalIncomeSummaryType = defaultRunTotalIncomeSummary();

    private runTotalYearOVBreakdown: StatsType[] = [];

    private runTotalYearPVBreakdown: StatsType[] = [];

    private rank: RunTotalIncomeSummaryRankType = defaultIncomeSummaryRank();

    private payRank: RankBaseType|null = null;

    private risingStarsCommissionLevels: CommissionLevelType[] = [];

    get fileList(): AssetType[] {
        return this.files.map((file) => ({
            id: file.id,
            title: file.label,
            context: [{
                metric: 'Content-Type',
                value: 'application/pdf',
            }],
            originalFileName: `${file.label}.pdf`,
            category: '',
            subCategory: null,
            priority: 0,
            link: file.downloadUrl,
            fileName: file.label,
            uploadDate: '',
        }));
    }

    get yearsOptions(): number[] {
        const onlyUnique = (value, index, array) => array.indexOf(value) === index;

        const all: number[] = this.commissionRuns.map((item) => {
            const labelLength = item.label.length;
            return Number(item.label.slice(labelLength - 4));
        });
        return all.filter(onlyUnique);
    }

    @Mutation
    public setSelectedTimeFrame(val: string) {
        this.selectedTimeFrame = val;
    }

    @Mutation
    public setFiles(val: CommissionStatementsType[]) {
        this.files = [...val];
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setStatementsLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setPreviewOpen(val: boolean) {
        this.previewOpen = val;
    }

    @Mutation
    public setPreviewFileId(val: string) {
        this.previewFileId = val;
    }

    @Mutation
    public setCommissionRuns(val: IncomeSummaryCommissionRunsType[] = []) {
        this.commissionRuns = [...val];
    }

    @Mutation
    public setRunTotalIncomeSummary(val: RunTotalIncomeSummaryType) {
        this.runTotalIncomeSummary = { ...val };
    }

    @Mutation
    public setRunTotalYearOVBreakdown(val: StatsType[] = []) {
        this.runTotalYearOVBreakdown = [...val];
    }

    @Mutation
    public setRunTotalYearPVBreakdown(val: StatsType[] = []) {
        this.runTotalYearPVBreakdown = [...val];
    }

    @Mutation
    public setLoadingCommissionRuns(val: boolean) {
        this.loadingCommissionRuns = val;
    }

    @Mutation
    public setYearSelected(val: number) {
        this.yearSelected = val;
    }

    @Mutation
    public setRunTotalsLoading(val: boolean) {
        this.runTotalsLoading = val;
    }

    @Mutation
    public setRank(val: RunTotalIncomeSummaryRankType) {
        this.rank = { ...val };
    }

    @Mutation
    public setPayRank(val: RankBaseType|null) {
        if (val === null) {
            this.payRank = val;
        } else {
            this.payRank = { ...val };
        }
    }

    @Mutation
    public setRisingStarsCommissionLevels(val: CommissionLevelType[] = []) {
        this.risingStarsCommissionLevels = [...val];
    }

    @Action()
    public async getTotals() {
        try {
            this.setLoading(true);

            const params: GetIncomeSummaryParamsType = {
                runId: this.selectedTimeFrame,
            };

            const res: GetIncomeSummaryResultType = await IncomeSummaryRepository
                .getAnnualTotals(params);

            this.setRunTotalIncomeSummary(res.profile.runTotalIncomeSummary);
            this.setRank(res.profile.runTotalIncomeSummary.rank);
            this.setPayRank(res.profile.runTotalIncomeSummary.payRank);
            this.setRisingStarsCommissionLevels(res.risingStarsCommissionLevels);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async getRunTotals() {
        try {
            this.setRunTotalsLoading(true);

            const params: GetIncomeSummaryRunTotalsParamsType = {
                year: this.yearSelected,
            };

            const res: GetIncomeSummaryRunTotalsResultType = await IncomeSummaryRepository
                .getIncomeSummaryRunTotals(params);

            this.setRunTotalYearOVBreakdown(res.profile.runTotalYearOVBreakdown);
            this.setRunTotalYearPVBreakdown(res.profile.runTotalYearPVBreakdown);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setRunTotalsLoading(false);
        }
    }

    @Action()
    public async getParticipatedRuns() {
        try {
            this.setLoadingCommissionRuns(true);

            const res: GetParticipatedRunsResultType = await IncomeSummaryRepository
                .getParticipatedRuns();

            const { participatedRuns } = res.profile;

            if (this.selectedTimeFrame === '') {
                const size = participatedRuns.length;

                if (size > 0) {
                    this.setSelectedTimeFrame(participatedRuns[size - 1].id);
                }
            }

            this.setCommissionRuns(participatedRuns);

            if (!this.yearsOptions.includes(this.yearSelected) && this.yearsOptions.length > 0) {
                this.setYearSelected(this.yearsOptions[0]);
            }

            this.getStatements();
            this.getRunTotals();
            this.getTotals();
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingCommissionRuns(false);
        }
    }

    @Action()
    public async getStatements() {
        try {
            this.setStatementsLoading(true);

            const res: GetCommissionStatementsResultType = await IncomeSummaryRepository
                .getCommissionStatements();

            const { commissionStatements } = res.profile;

            if (commissionStatements) {
                this.setFiles(commissionStatements);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setStatementsLoading(false);
        }
    }
}

export default getModule(IncomeSummary);
