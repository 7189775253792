
import { StatsType } from '@/api/graphQL/graphNodes/types';
// import { currencySign } from '@/modules/SisterCredit/helpers';
import { Vue, Component, Prop } from 'vue-property-decorator';
import currencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class SisterCreditBalance extends Vue {
    @Prop({ required: true }) private balance!: StatsType;

    formatCents(unformatted) {
        return Number(unformatted).toFixed(2);
    }

    get currencyPrefix() {
        return currencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }
}
