import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { GetCustomerOrderDetailsParamsType } from '@/api/graphQL/graphNodes/GetCustomerOrderDetailsQuery';
import { OrderType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { initialOrder } from './defaults';
import MyCustomersRepository from './services/MyCustomersRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'myCustomerOrderDetails',
})
@AutoMutations
export class MyCustomerOrderDetails extends VuexModule {
    private customerOrder: OrderType = initialOrder();

    private loading: boolean = false;

    @Mutation
    public setCustomerOrder(data: OrderType = initialOrder()) {
        this.customerOrder = data;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Action()
    public async getCustomerOrder(data: {
        enrolleeId: string,
        orderId: string,
    }) {
        const { enrolleeId, orderId } = data;

        try {
            this.setLoading(true);

            const params: GetCustomerOrderDetailsParamsType = {
                id: enrolleeId,
                orderId,
            };

            const result = await MyCustomersRepository.getCustomerOrderDetails(params);
            this.setCustomerOrder(result.enrollee.order);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(MyCustomerOrderDetails);
