import { StatsType, StatsWithLabelType } from '@/api/graphQL/graphNodes/types';
import mockedDateManager from '@/utils/mocked-date-manager';
import { ResolverEnrolleeType } from '../types';

const calculateMetricStats = (enrollee: ResolverEnrolleeType): StatsWithLabelType[] => {
    const pvInXAchieversCount = Math.floor((enrollee.totals.pv * 3.14) / 100);

    return [
        {
            metric: 'pv',
            value: `${enrollee.totals.pv}`,
            label: 'Own Sales',
        },
        {
            metric: 'level',
            value: '2',
            label: '',
        },
        {
            metric: 'qnv',
            value: `${enrollee.totals.ov + 2}`,
            label: 'Total Network Sales',
        },
        {
            metric: 'qualified_personal_legs',
            value: `${enrollee.ambassadorLegsCount}`,
            label: 'Qualified Network Consultants',
        },
        {
            metric: 'ov',
            value: `${enrollee.totals.ov}`,
            label: '',
        },
        {
            metric: 'pv_in',
            value: `${enrollee.totals.pv}`,
            label: '',
        },
        {
            metric: 'pv_in_x_achievers_count',
            value: `${pvInXAchieversCount > 5 ? pvInXAchieversCount - 5 : pvInXAchieversCount}`,
            label: '',
        },
        {
            metric: 'exempt_ps',
            value: '',
            label: '',
        },
        {
            metric: 'maternity_leave_date',
            value: '',
            label: '',
        },
        {
            metric: 'maternity_return_date',
            value: '',
            label: '',
        },
        {
            metric: 'in_grace',
            value: '',
            label: '',
        },
        {
            metric: 'active_on_file',
            value: '',
            label: '',
        },
        {
            metric: 'months_since_paid_as_title',
            value: '0',
            label: '',
        },
        {
            metric: 'ambassadorKit',
            value: `${enrollee.ambassadorKit}`,
            label: '',
        },
        {
            metric: 'spa_gv',
            value: `${enrollee.totals.spa_gv}`,
            label: '',
        },
    ];
};

const calculateBonusStats = (enrollee: ResolverEnrolleeType): StatsWithLabelType[] => [
    {
        metric: 'ac',
        value: `${Math.floor(enrollee.totals.pv / 3)}`,
        label: 'Additional Commissions 1',
    },
];

const calculateMilestoneStats = (enrollee: ResolverEnrolleeType): StatsWithLabelType[] => [
    {
        metric: 'pv_in_14',
        value: `${Math.floor(enrollee.totals.pv / 5)}`,
        label: 'New Starter Programme 14 days',
    },
];

const calculateSaleStats = (enrollee: ResolverEnrolleeType): StatsWithLabelType[] => [
    {
        metric: 'event_sales',
        value: `${Math.floor((Number(enrollee.totals.pv) / 6) * 3)}`,
        label: 'VIP Customer Link',
    },
    {
        metric: 'customer_sales',
        value: `${Math.floor((Number(enrollee.totals.pv) / 6) * 2)}`,
        label: 'Webshop (Customer)',
    },
    {
        metric: 'self_sales',
        value: `${Math.floor((Number(enrollee.totals.pv) / 6) * 1)}`,
        label: 'Webshop (Me)',
    },
];

const calculateRunTotalYearPVBreakdown = (): StatsType[] => [
    {
        metric: mockedDateManager.getCurrentDateWithOffset(-5, 'YYYY-MM', 'M'),
        value: '23.5',
    },
    {
        metric: mockedDateManager.getCurrentDateWithOffset(-4, 'YYYY-MM', 'M'),
        value: '25.5',
    },
    {
        metric: mockedDateManager.getCurrentDateWithOffset(-3, 'YYYY-MM', 'M'),
        value: '21.7',
    },
    {
        metric: mockedDateManager.getCurrentDateWithOffset(-2, 'YYYY-MM', 'M'),
        value: '22.8',
    },
    {
        metric: mockedDateManager.getCurrentDateWithOffset(-1, 'YYYY-MM', 'M'),
        value: '31.4',
    },
];

const calculateRunTotalYearOVBreakdown = (): StatsType[] => [
    {
        metric: mockedDateManager.getCurrentDateWithOffset(-5, 'YYYY-MM', 'M'),
        value: '117.3',
    },
    {
        metric: mockedDateManager.getCurrentDateWithOffset(-4, 'YYYY-MM', 'M'),
        value: '128.1',
    },
    {
        metric: mockedDateManager.getCurrentDateWithOffset(-3, 'YYYY-MM', 'M'),
        value: '130.0',
    },
    {
        metric: mockedDateManager.getCurrentDateWithOffset(-2, 'YYYY-MM', 'M'),
        value: '112.9',
    },
    {
        metric: mockedDateManager.getCurrentDateWithOffset(-1, 'YYYY-MM', 'M'),
        value: '114.2',
    },
];

export {
    calculateMetricStats, calculateBonusStats, calculateMilestoneStats, calculateSaleStats,
    calculateRunTotalYearPVBreakdown, calculateRunTotalYearOVBreakdown,
};
