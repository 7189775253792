
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

enum ButtonTypeEnum {
    'contained',
    'text',
    'outlined'
}

@Component
export default class Button extends Vue {
    @Prop({ default: '' }) private text!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({ default: '' }) private customImage!: string;

    @Prop({ default: false }) private loading!: boolean;

    @Prop({
        default: ButtonTypeEnum.contained,
        // @ts-ignore
        validator: (val) => ButtonTypeEnum[val] !== undefined,
    }) private type!: ButtonTypeEnum;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: false }) private invertedColors!: boolean;

    get elementClasses() {
        return [
            'Button',
            `Button--${this.type}`,
            this.disabled ? 'Button--disabled' : 'Ripple',
            this.icon ? 'Button--withIcon' : '',
            this.text === '' && this.icon !== '' ? 'Button__IconButton' : '',
            { 'Button--inverted': this.invertedColors },
        ];
    }

    handleClick() {
        if (!this.loading) {
            this.$emit('click');
        }
    }
}
