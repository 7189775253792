
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import orders from '@/modules/Orders';
import OrdersList from '@/projectComponents/OrdersList/index.vue';
import { OrderType } from '@/api/graphQL/graphNodes/types';
import system from '@/modules/System';
import { scrollToTop } from '@plumtreesystems/utils';
import { PaginationParamsType } from '@/components/pagination/types';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import OrdersTotal from './ordersTotal.vue';
import Header from './Header/index.vue';
import Skeleton from './OrdersSkeleton/index.vue';

@Component({
    components: {
        OrdersList,
        Header,
        Skeleton,
        OrdersTotal,
    },
})
export default class Orders extends Vue {
    @Get(orders) private loaded!: boolean;

    @Get(orders) private loading!: boolean;

    @Get(orders) private expandedOrders!: string[];

    @Get(orders) private searchedOrders!: OrderType[];

    @Get(orders, 'orders') private ordersList!: OrderType[];

    @Get(orders) private offset!: number;

    @Get(orders) private searchLoading!: boolean;

    @Get(orders) private total!: number;

    @Get(orders) private limit!: number;

    @Get(orders) private displaySearchResults!: boolean;

    @Get(orders) private loadingInBackground!: boolean;

    @Get(orders) private salesTotal!: string;

    @Get(system) private screenType!: string;

    get contentLoaded(): boolean {
        return this.loading || this.searchLoading;
    }

    get country() {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    mounted() {
        const { offset } = this;
        orders.getOrders({ offset });
    }

    handleOrderOpenToggle(id: string) {
        if (this.isMobile) {
            this.$router.push({ name: 'viewOrderDetails', params: { id } });
        } else {
            orders.toggleExpandedList(id);
        }
    }

    handleSearchToggle() {
        orders.toggleShowSearch();
    }

    get displayOrders(): OrderType[] {
        if (this.displaySearchResults) {
            return this.searchedOrders;
        }
        return this.ordersList;
    }

    get isResponsive() {
        return this.screenType === 'mobile' || this.screenType === 'tablet';
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    async handlePagination(params: PaginationParamsType) {
        try {
            const { offset } = params;
            await orders.getOrders({ loadPage: true, offset });
            scrollToTop('#app > div.App__Layout > div > div.Scene__Content.Scene__Content--noPadding');
        // eslint-disable-next-line no-empty
        } finally {}
    }

    beforeDestroy() {
        orders.setSearchedOrders([]);
        orders.setSearchedOrdersOptions([]);
        orders.setOrders([]);
        orders.clearExpandedOrdersList();
        orders.setLoaded(false);
        orders.setDisplaySearchResults(false);
        orders.setSearchQuery('');
        orders.clearFilterData();
    }

    @Watch('screenType')
    setVal(val: string) {
        if (val === 'mobile') {
            orders.clearExpandedOrdersList();
        }
    }
}
