import { IncomePlanType } from '@/api/graphQL/graphNodes/types';

const incomePlannerPlans: () => IncomePlanType[] = () => [
    {
        id: '1',
        title: 'Good plan',
        // inputs
        pv: 300000, // Personal Sales
        averageQPA: 30500, // Average Personal Commissionable Sales of your  QPAs
        qpaCount: 15, // Number of QPAs
        avgCustomerVolume: 15000, // Average Customer Sale
        avgVipCustomerVolume: 5000, // Average VIP Customer Sale
        customersCount: 5, // Number of Customers
        vipCustomersCount: 26, // Number of VIP Customers
        output: {
            rank: 'Affiliate Leader',
            earnings: {
                // Discount on Personal Sales
                discountPv: 25,
                // Sales Commission on Customers
                discountScc: 25,
                // Sales Commission on VIP Customers
                discountVipScc: 15,
                // Sales Bonus
                salesBonus: 10,
                // Level Bonus
                levelBonus: 6,
                pv: '75000',
                scc: '18750',
                vipScc: '19500',
                salesBonusAmount: '50500',
                levelBonusAmount: '57750',
                total: '221500',
            },
            qualification: {
                // Personal Commissionable Sales
                pv: 505000,
                // Customer Sales
                customerVolume: 75000,
                // VIP Customer Sales
                vipCustomerVolume: 130000,
                // Qualified Network Commissionable Sales
                qnv: 962500,
            },
        },
    },
    {
        id: '2',
        title: 'Less good plan',
        pv: 150000,
        averageQPA: 21000,
        qpaCount: 5,
        avgCustomerVolume: 8000,
        avgVipCustomerVolume: 2000,
        customersCount: 3,
        vipCustomersCount: 8,
        output: {
            rank: 'Affiliate',
            earnings: {
                discountPv: 25,
                discountScc: 25,
                discountVipScc: 15,
                salesBonus: 5,
                levelBonus: 3,
                pv: '37500',
                scc: '6000',
                vipScc: '2400',
                salesBonusAmount: '9500',
                levelBonusAmount: '8850',
                total: '64250',
            },
            qualification: {
                pv: 190000,
                customerVolume: 24000,
                vipCustomerVolume: 16000,
                qnv: 295000,
            },
        },
    },
];

export { incomePlannerPlans };
