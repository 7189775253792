
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankRequirementsType } from '@/api/graphQL/graphNodes/types';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class CTBV extends Vue {
    @Get(dashboard, 'data.stats') private stats!: RankRequirementsType[];

    @Prop() private requirements!: RankRequirementType[];

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(this.metric).title;
    }

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.gv;
    }

    get ctbvRequirement(): string | null {
        const gv = this.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.gv);

        return gv ? gv.value : null;
    }

    get gv(): number {
        return Number(this.stats.find((item) => item.metric === this.metric)!.value);
    }

    get progressValue(): number|null {
        const x = this.gv * 100;

        if (this.requirements.length === 0) {
            return null;
        }

        if (!this.ctbvRequirement) {
            return null;
        }

        if (Number(this.ctbvRequirement) === 0) {
            return 0;
        }

        return x / Number(this.ctbvRequirement);
    }

    get displayData(): boolean {
        if (!this.ctbvRequirement) {
            return false;
        }

        return true;
    }
}
