import rankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementDataType, RankRequirementType } from '../types';

const rankRequirementList: RankRequirementDataType[] = [
    // {
    //     id: '00',
    //     values: [
    //         {
    //             metric: rankRequirementManager.rankRequirementMetricConstants.ambassadorKit,
    //             value: '1',
    //         },
    //     ],
    // },
    {
        id: '0',
        values: [],
    },
    {
        id: '1',
        values: [],
    },
    {
        id: '2',
        values: [],
    },
    {
        id: '3',
        values: [],
    },
    {
        id: '4',
        values: [
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.pv,
                value: '500',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.qnv,
                value: '1500',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.personalRecruits,
                value: '2',
            },
        ],
    },
    {
        id: '5',
        values: [
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.pv,
                value: '500',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.qnv,
                value: '3000',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.personalRecruits,
                value: '5',
            },
        ],
    },
];

const rankRequirement: (id: string) => RankRequirementType[] = (id) => {
    const rankRequirementItem = rankRequirementList
        .find((item: RankRequirementDataType) => item.id === id);
    if (rankRequirementItem) {
        return rankRequirementItem.values;
    }

    return [];
};

export { rankRequirement };
