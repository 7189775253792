import { BUSINESS_SHAPE_TYPE } from '@/modules/BusinessShape/constants';
import { StatsType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetAmbassadorsCountsQuery extends
    AbstractQueryResource<GetAmbassadorsCountsResultType> {
    private activeCurrentMonthQuery = `
        activeAmbassadorsCountByLevel {
            metric,
            value
        },
    `;

    private qualifiedCurrentMonthQuery = `
        qualifiedAmbassadorsCountByLevel {
            metric,
            value
        },
    `;

    private activeLastMonthQuery = `
        activeAmbassadorsCountByLevel (from: $from, to: $to) {
            metric,
            value
        },
    `;

    private qualifiedLastMonthQuery = `
        qualifiedAmbassadorsCountByLevel (from: $from, to: $to) {
            metric,
            value
        },
    `;

    protected getQuery(options: string): string {
        const query = `query ${this.getName()}($id: String!, $from: DateTime!, $to: DateTime!) {
            currentMonth: enrollee (id: $id) {
                ${options === BUSINESS_SHAPE_TYPE.active ? this.activeCurrentMonthQuery : ''}
                ${options === BUSINESS_SHAPE_TYPE.qualified ? this.qualifiedCurrentMonthQuery : ''}
            },
            lastMonth: enrollee (id: $id) {
                ${options === BUSINESS_SHAPE_TYPE.active ? this.activeLastMonthQuery : ''}
                ${options === BUSINESS_SHAPE_TYPE.qualified ? this.qualifiedLastMonthQuery : ''}
            }
        }`;

        return query;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getAmbassadorsCounts';
    }
}

export type GetAmbassadorsCountsResultType = {
    currentMonth: GetAmbassadorsCountByLevelType;
    lastMonth: GetAmbassadorsCountByLevelType;
}

export type GetAmbassadorsCountByLevelType = {
    activeAmbassadorsCountByLevel?: StatsType[];
    qualifiedAmbassadorsCountByLevel?: StatsType[];
}

export type AmbassadorsCountsParamsType = {
    id: string;
    from: string;
    to: string;
}
