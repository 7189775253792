
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { Debounced } from '@/utils/debounced';
import orders from '@/modules/Orders';
import system from '@/modules/System';
import { SelectOptionsType } from '@/components/select/types';
import { OrdersFilterType, OrderType } from '@/api/graphQL/graphNodes/types';
import Switcher from '@/components/switcher/switcher.vue';
import Select from '@/components/select/select.vue';
import { SwitcherOptionsType } from '@/components/switcher/types';
import { DATE_PICKER_TYPES } from '@/components/datePicker/constants';
import { ORDER_TYPES } from '@/modules/Orders/constants';
import { ObjectProcessor } from '@plumtreesystems/utils';
import profile from '@/modules/Profile';
import dateManager from '@/utils/time';

@Component({ components: { Switcher, Select } })
export default class OrdersHeader extends Vue {
    @Sync(orders) private searchQuery!: string;

    @Sync(orders) private activeSwitcherItem!: number;

    @Get(orders) private searchLoading!: boolean;

    @Get(orders) private loading!: boolean;

    @Get(orders) private minSearchLength!: number;

    @Get(orders) private searchedOrdersOptions!: OrderType[];

    @Get(orders) private displaySearchResults!: boolean;

    @Get(orders) private displaySearch!: boolean;

    @Get(orders) private filterData!: OrdersFilterType;

    @Get(system) private screenType!: string;

    @Get(profile, 'originalData.joinDate') private joinDate!: string;

    get contentLoaded(): boolean {
        return this.loading || this.searchLoading;
    }

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    get switcherOptions(): SwitcherOptionsType[] {
        return [
            { label: 'All Orders', value: false },
            { label: 'My Orders', value: true },
        ];
    }

    get typeSelectOptions(): SelectOptionsType[] {
        return [
            { name: 'All', value: ORDER_TYPES.all },
            { name: ORDER_TYPES.manual, value: ORDER_TYPES.manual },
            { name: ORDER_TYPES.dskit, value: ORDER_TYPES.dskit },
            { name: ORDER_TYPES.dscus, value: ORDER_TYPES.dscus },
            { name: ORDER_TYPES.dsweb, value: ORDER_TYPES.dsweb },
            { name: ORDER_TYPES.dsvip, value: ORDER_TYPES.dsvip },
        ];
    }

    get monthSelectOptions(): SelectOptionsType[] {
        const difference = dateManager.getDifference(this.joinDate, dateManager.getCurrentDate(), 'month');
        const res: SelectOptionsType[] = [];

        for (let i = 0; i <= difference; i++) {
            res.push({
                name: dateManager.getDateWithOffset(-i, dateManager.getCurrentDate(), 'YYYY MMMM', 'month'),
                value: dateManager.getDateWithOffset(-i, dateManager.getCurrentDate(), 'YYYY-MM', 'month'),
            });
        }

        res.push({
            name: 'All',
            value: null,
        });

        return res.reverse();
    }

    get searchOptions(): SelectOptionsType[] {
        return this.searchedOrdersOptions.map((order) => ({
            name: `#${order.id}`,
            value: `#${order.id}`,
        }));
    }

    get showSearch() {
        return this.screenTypes || this.displaySearch;
    }

    get screenTypes() {
        return !(this.screenType === 'mobile' || this.screenType === 'tablet');
    }

    get headerContainerClasses() {
        return [
            'pts-layout',
            'pts-gutter',
            'OrdersHeader__HeaderContainer',
            { 'OrdersHeader__HeaderContainer--show': this.showSearch },
        ];
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
            'OrdersHeader__Container',
            { 'OrdersHeader__Container--show': this.showSearch },
        ];
    }

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.filterData);
        orders.setFilterData(res);
        orders.getOrders({});
    }

    handleSelect(val) {
        orders.setSearchQuery(val.value);
        orders.setDisplaySearchResults(true);
        orders.search({ query: this.searchQuery, selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            orders.setDisplaySearchResults(false);
            orders.setSearchedOrdersOptions([]);
            orders.setSearchedOrders([]);
            orders.getOrders({});
        }
        if (this.searchQuery.length >= this.minSearchLength) {
            orders.search({ query: this.searchQuery });
        }
    }
}
