
import { Vue, Component } from 'vue-property-decorator';
import hostessDashboard from '@/modules/Dashboard/hostess';
import tokenThreshold from '@/modules/TokenThreshold';
import { Get } from '@/utils/vuex-module-mutators';
import { HostessDashboardType } from '@/api/graphQL/graphNodes/types';
import { TOOLTIP_TOKEN_PERFORMANCE } from '@/modules/constants';
import system from '@/modules/System';

@Component({})
export default class HostessTokenPerformance extends Vue {
    @Get(hostessDashboard) private data!: HostessDashboardType;

    @Get(tokenThreshold) private threshold!: number;

    @Get(system) screenType!: string;

    get tooltipText() {
        return TOOLTIP_TOKEN_PERFORMANCE(this.threshold);
    }

    get progressVal(): number {
        return (Number(this.data.eventPerformance.tokens) * 100) / this.threshold;
    }

    get canRedeem(): boolean {
        return this.progressVal < 100;
    }

    get progressBarSize(): string {
        return this.screenType === 'mobile' ? 'medium' : 'large';
    }

    handleRedeem() {
        const { tokenRewardUrl } = this.data.eventPerformance;

        window.open(tokenRewardUrl!);
    }
}
