// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { CommissionLevelType, RisingStarsType } from './types';

export default class GetRisingStarsQuery extends
    AbstractQueryResource<GetRisingStarsResultType|GetRisingStarsSearchResultType> {
    protected getQuery(options = { type: '' }): string {
        const { type } = options;
        return `query ${this.getName()}(${type === '' ? '$sort: String!, $limit: Int!, $offset: Int!' : '$query: String!'}, $type: String) {
            profile {
                ${type === '' ? 'risingStars(sort: $sort, limit: $limit, offset: $offset, type: $type)'
        : 'searchRisingStars(query: $query, type: $type)'} {
                    id,
                    firstName,
                    lastName,
                    rank {
                        id,
                        label,
                        power,
                    },
                    stats {
                        metric,
                        value,
                    },
                },
                ${type === '' ? 'risingStarsCount (type: $type)' : ''}
            },
            risingStarsCommissionLevels {
                level,
                rate,
                sales
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getRisingStars';
    }
}

export type GetRisingStarsResultType = {
    profile: {
        risingStars: RisingStarsType[];
        risingStarsCount: number;
    };
    risingStarsCommissionLevels: CommissionLevelType[];
}
export type GetRisingStarsSearchResultType = {
    profile: {
        searchRisingStars: RisingStarsType[];
    };
    risingStarsCommissionLevels: CommissionLevelType[];
}

export type RisingStarsParamsType = {
    sort: string;
    limit: number;
    offset: number;
    type: string;
}

export type RisingStarsSearchParamsType = {
    query: string;
    type: string;
}
