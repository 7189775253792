export const EVENT_TYPE = {
    party: 'party',
    custom: 'custom',
    private: 'private',
    showcase: 'showcase',
    getTogether: 'getTogether',
    braFit: 'braFit',
    campaignLaunch: 'campaignLaunch',
    generalMeeting: 'generalMeeting',
    vipLinkShare: 'vipLinkShare',
    oneOnOne: 'oneOnOne',
};

export const EVENT_HOST_TYPE = {
    undefined: -1,
    me: 0,
    known: 1,
    new: 2,
    no: 3,
};

export const MAX_CAMPAIGN_LENGTH: number = 10;

export const EVENT_ATTENDANCE_STATUS = {
    tentative: 'tentative_invitation_response',
    declined: 'declined_invitation_response',
    yes: 'accepted_invitation_response',
};

export const EVENT_INVITATION_TYPE = {
    guest: 'guest_invitation',
    hostess: 'hostess_invitation',
    ambassador: 'ambassador_invitation',
};

export const EVENT_DATE_FORMAT = 'dddd, MMMM D';

export const EVENT_TIME_FORMAT = 'HH:mm';
