
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class ProgressBarCircular extends Vue {
    @Prop({ default: 0 }) private value!: number;

    @Prop() private ariaLabel!: string;

    @Prop({ default: false }) private customLabel!: boolean;

    @Prop({ default: '' }) private customLabelContent!: string;

    @Prop({ default: true }) private displayPercentsSymbol!: boolean;

    @Prop({ default: '' }) private subLabel!: string;

    @Prop({ default: '' }) private size!: string;

    @Prop({ required: true }) private screenType!: string;

    get displayValue() {
        const res = this.value > 100 ? 100 : this.value;
        return `${Math.floor(res)}`;
    }

    get elementContentClasses() {
        return [
            'ProgressBarCircular__Content',
            this.size ? `ProgressBarCircular--${this.size}` : '',
        ];
    }

    get r():number {
        const overflow = this.stroke / 2;
        return this.width - overflow;
    }

    get stroke() {
        switch (this.size) {
        case 'large':
            if (this.screenType === 'desktop') {
                return 20;
            }

            if (this.screenType === 'tablet') {
                return 20;
            }

            return 10;
        case 'medium':
            return 10;
        case 'small':
            return 4;
        default:
            return 3;
        }
    }

    get width() {
        switch (this.size) {
        case 'large':
            return 128;
        case 'medium':
            return 64;
        case 'small':
            return 32;
        default:
            return 24;
        }
    }

    get offsetStrokeValue() {
        return (this.width - (this.stroke / 2)) * 2 * Math.PI;
    }

    setProgressVal() {
        const circle:SVGCircleElement = this.$refs.fill as SVGCircleElement;

        const circumference = this.r * 2 * Math.PI;
        const normalisedValue = this.value > 100 ? 100 : this.value;

        circle.style.strokeDashoffset = `${circumference - (normalisedValue / 100) * circumference}`;
        circle.style.strokeDasharray = `${circumference}, ${circumference}`;
    }

    init() {
        this.setProgressVal();
    }

    mounted() {
        this.init();
    }

    @Watch('value')
    onScreenSizeChange() {
        this.setProgressVal();
    }
}
