
import { OrderType } from '@/api/graphQL/graphNodes/types';
import {
    Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import profile from '@/modules/Profile';
import myCustomerDetails from '@/modules/MyCustomers/orderDetails';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import { Get } from '@/utils/vuex-module-mutators';
import CurrencyPrefix from '@/utils/currencyPrefix';
import system from '@/modules/System';
import ProductItem from '@/projectComponents/OrdersList/ProductItem/index.vue';
import dateManager from '@/utils/time';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import env from '@/environment';
import { RouteType } from '@/modules/System/types';

@Component({ components: { ProductItem, Skeleton } })
export default class CustomerOrderDetails extends Vue {
    @Prop() private returnRoute!: RouteType;

    @Get(myCustomerDetails, 'customerOrder') private order!: OrderType;

    @Get(myCustomerDetails) private loading!: boolean;

    @Get(profile, 'originalData.id') private profileId!: string;

    @Get(system) private screenType!: string;

    get orderDate(): string {
        return dateManager.getDateTime(this.order.date, dateManager.getDayTimeFormatUK());
    }

    get orderId() {
        const { orderId } = this.$route.params;
        return orderId;
    }

    get country() {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(this.country);
    }

    get commissionableOrder() {
        if (this.order.enrollee.id === this.profileId) {
            return true;
        }
        return false;
    }

    get paymentMethod() {
        if (this.order.paymentInfo) {
            let res = '';
            res += this.order.paymentInfo.map((item) => item.paymentMethod).join(', ');
            return res;
        }

        return '-';
    }

    get storeCreditLabel(): string {
        return env.VUE_APP_DISABLE_LOGO === 'true'
            ? 'Sister Pay'
            : 'Connect Pay';
    }

    mounted() {
        if (this.screenType !== 'mobile') {
            this.$router.push(this.returnRoute);
        }
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    handleOpen() {
        if (this.order.id === '') {
            const { id, orderId } = this.$route.params;
            myCustomerDetails.getCustomerOrder({ enrolleeId: id, orderId });
        }
    }

    beforeDestroy() {
        myCustomerDetails.setCustomerOrder();
    }

    @Watch('screenType')
    setVal(val: string) {
        if (val !== 'mobile') {
            this.$router.push(this.returnRoute);
        }
    }
}
