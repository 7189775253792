import ActionMenu from './actionMenu/actionMenu.vue';
import Button from './button/button.vue';
import Clipboard from './clipboard/clipboard.vue';
import DatePicker from './datePicker/datePicker.vue';
import Dialog from './dialog/dialog.vue';
import DrawerFab from './drawer/drawerFab.vue';
import Drawer from './drawer/drawer.vue';
import ExpansionItem from './expansionItem/expansionItem.vue';
import File from './file/file.vue';
import FileUploadArea from './fileArea/fileUploadArea.vue';
import FileArea from './fileArea/fileArea.vue';
import FocusLock from './focusLock/focusLock.vue';
import Icon from './icon/icon.vue';
import IconButton from './iconButton/iconButton.vue';
import ImageCrop from './imageCrop/imageCrop.vue';
import InlineEditElement from './inlineEditElement/inlineEditElement.vue';
import LinkButton from './linkButton/linkButton.vue';
import LoaderWithOverlay from './loaderWithOverlay/loaderWithOverlay.vue';
import Loader from './loader/loader.vue';
import GoogleMapLoader from './map/GoogleMapLoader.vue';
import GoogleMapMarker from './map/GoogleMapMarker.vue';
import Pagination from './pagination/pagination.vue';
import PaginationHydra from './paginationHydra/paginationHydra.vue';
import ProgressBar from './progressBar/progressBar.vue';
import ProgressBar2 from './progressBar2/progressBar2.vue';
import ProgressBarCircular from './progressBarCircular/progressBarCircular.vue';
import RadioOption from './radioOption/radioOption.vue';
import Scene from './scene/scene.vue';
import SearchSelect from './searchSelect/searchSelect.vue';
import Select from './select/select.vue';
import SnackbarMessages from './snackbarMessages/snackbarMessages.vue';
import SubScene from './subScene/subScene.vue';
import Tabs from './tabs/tabs.vue';
import Tab from './tabs/tab.vue';
import Tag from './tag/tag.vue';
import TextField from './textField/textField.vue';
import TimezoneSelect from './timezoneSelect/timezoneSelect.vue';
import WarningButton from './warningButton/warningButton.vue';
import Fab from './fab/fab.vue';
import Switcher from './switcher/switcher.vue';

export default {
    ActionMenu,
    Button,
    Clipboard,
    DatePicker,
    Dialog,
    Drawer,
    DrawerFab,
    File,
    FileUploadArea,
    FileArea,
    FocusLock,
    ExpansionItem,
    Icon,
    IconButton,
    ImageCrop,
    InlineEditElement,
    LinkButton,
    Loader,
    LoaderWithOverlay,
    GoogleMapLoader,
    GoogleMapMarker,
    Pagination,
    PaginationHydra,
    ProgressBar,
    ProgressBar2,
    ProgressBarCircular,
    RadioOption,
    Scene,
    SearchSelect,
    Select,
    SnackbarMessages,
    SubScene,
    Tabs,
    Tab,
    Tag,
    TextField,
    TimezoneSelect,
    WarningButton,
    Fab,
    Switcher,
};
