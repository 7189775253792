
import { OrderPaymentInfoType } from '@/api/graphQL/graphNodes/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import env from '@/environment';

@Component
export default class OrderDetails extends Vue {
    @Prop() private currencyPrefix!: string;

    @Prop() private value!: string;

    @Prop() private name!: string;

    @Prop() private commissionableValue!: string;

    @Prop() private psc!: string;

    @Prop() private shippingCost!: string;

    @Prop() private discount!: string;

    @Prop() private date!: string;

    @Prop({ default: false }) private commissionableOrder!: boolean;

    @Prop() private paymentMethods!: OrderPaymentInfoType[]|null;

    @Prop() private manual!: boolean;

    @Prop() private isReturn!: boolean;

    @Prop() private referenceId!: string|null;

    @Prop() private orderNumber!: string;

    @Prop() private comment!: string|null;

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    get isOrdersView() {
        return this.$route.name === 'orders';
    }

    get paymentMethod() {
        if (this.paymentMethods) {
            let res = '';
            res += this.paymentMethods.map((item) => item.paymentMethod).join(', ');
            return res;
        }

        return '-';
    }

    get storeCreditLabel(): string {
        return env.VUE_APP_DISABLE_LOGO === 'true'
            ? 'Sister Pay'
            : 'Connect Pay';
    }
}
