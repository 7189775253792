
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { TOOLTIP_SOMETHING_WENT_WRONG } from '@/modules/constants';
import componentsControls from '@/modules/ComponentsControls';
import { EVENT_CREATED_MESSAGE } from '@/modules/Event/Events/messages';
import eventCreate from '@/modules/Event/EventCreate';
import { EVENT_HOST_TYPE } from '@/modules/Event/constants';
import { CreateEventResultType } from '@/api/graphQL/graphNodes/CreateEventQuery';
import NoHostess from '@/views/Events/Components/HostContent/noHostess.vue';
import HostessMe from '@/views/Events/Components/HostContent/hostessMe.vue';
import KnownHostess from '@/views/Events/Components/HostContent/knownHostess.vue';
import NewHostess from '@/views/Events/Components/HostContent/newHostess.vue';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import { ErrorType } from '@plumtreesystems/utils';
import env from '@/environment';
import { CreateEventHostessType } from '@/modules/Event/Events/types';
import EventCreateForm from './eventCreateForm.vue';

@Component({
    components: {
        HostessMe,
        KnownHostess,
        NewHostess,
        EventCreateForm,
        NoHostess,
        FormErrorTooltip,
    },
})
export default class EventCreateView extends Vue {
    @Get(eventCreate) private eventHostType!: number;

    @Get(eventCreate) private hostess!: CreateEventHostessType;

    @Get(eventCreate) private loading!: boolean;

    @Get(eventCreate) private displayTooltip!: boolean;

    @Get(eventCreate, 'eventFormErrors') private formErrors!: ErrorType;

    get eventHostMe() {
        return EVENT_HOST_TYPE.me;
    }

    get eventHostKnown() {
        return EVENT_HOST_TYPE.known;
    }

    get eventHostNew() {
        return EVENT_HOST_TYPE.new;
    }

    get eventNoHost() {
        return EVENT_HOST_TYPE.no;
    }

    get errorTooltipMessage() {
        if (this.formErrors.title !== undefined || this.formErrors.address !== undefined) {
            return '';
        }

        return TOOLTIP_SOMETHING_WENT_WRONG;
    }

    async beforeMount() {
        if (this.hostess === null || this.hostess.firstName === '') {
            this.$router.push({ name: 'createEventHostess' });
        }
    }

    async handleSave() {
        try {
            const eventData: CreateEventResultType = await eventCreate.eventFormSubmit();
            eventCreate.clearEventCreate();
            this.$router.push({ name: 'eventView', params: { id: eventData.createEvent.id } });
            componentsControls.showSuccessMessage({ message: EVENT_CREATED_MESSAGE });
        // eslint-disable-next-line no-empty
        } catch (e) {}
    }

    unlockedAfterStage(stage: number): boolean {
        return Number(env.VUE_APP_STAGE) > stage;
    }

    beforeDestroy() {
        eventCreate.setDisplayTooltip(false);
    }
}
