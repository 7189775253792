
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import eventView from '@/modules/Event/EventView';
import { HostessRewardsType } from '@/api/graphQL/graphNodes/types';
import { AssetType } from '@plumtreesystems/utils';
import FileViewer from '@/projectComponents/fileViewer/index.vue';

@Component({
    components: {
        FileViewer,
    },
})
export default class RewardDialog extends Vue {
    @Get(eventView) private displayTier!: string|null;

    @Get(eventView) private hostessRewards!: HostessRewardsType[];

    get file(): AssetType|null {
        const tier = this.hostessRewards.find((reward) => reward.label === this.displayTier);

        if (tier) {
            return tier.rewardsAsset;
        }

        return null;
    }
}
