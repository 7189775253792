
import { EventEnrolleeType, InvitationToEventEnrolleeType, InvitationToEventType } from '@/api/graphQL/graphNodes/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import profile from '@/modules/Profile';
import eventEdit from '@/modules/Event/EventEdit';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { ErrorType } from '@plumtreesystems/utils';
import { HostessFormType } from '@/modules/Event/types';
import SelectHostessType from './selectHostessType/index.vue';
import KnownHostessSelect from './knownHostessSelect/index.vue';
import NewHostessForm from '../../Components/HostessForm/index.vue';

@Component({
    components: {
        SelectHostessType,
        KnownHostessSelect,
        NewHostessForm,
    },
})
export default class HostessBlock extends Vue {
    @Prop({ required: true }) private hostess!: EventEnrolleeType;

    @Prop({ required: true }) private invitations!: InvitationToEventType[];

    @Sync(eventEdit) private editModalOpen!: boolean;

    @Sync(eventEdit, 'hostessSelectStep') private step!: string;

    @Get(eventEdit) private formErrors!: ErrorType;

    @Get(profile, 'data.id') private id!: string;

    get isMe(): boolean {
        return this.hostess && this.id === this.hostess.id;
    }

    get displayHostess(): InvitationToEventEnrolleeType | null {
        if (this.hostess) {
            return this.hostess;
        }

        const invited: InvitationToEventType|undefined = this.invitations
            .find((invite) => invite.enrollee && invite.enrollee.id === this.id);

        return invited ? invited.enrollee : null;
    }

    handleCloseDialog() {
        eventEdit.closeModalAfterEdit();
    }

    handleOpenDialog() {
        eventEdit.setEditModalOpen(true);
    }

    handleInputUpdate(val: HostessFormType) {
        eventEdit.setHostessData(val);
    }
}
