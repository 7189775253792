
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { AssetCategoryType, AssetType } from '@/api/graphQL/graphNodes/types';
import marketingMaterial from '@/modules/MarketingMaterial';
import marketingMaterialCategories from '@/modules/MarketingMaterial/categories';
import env from '@/environment';
import Skeleton from '@/projectComponents/skeleton/Files/index.vue';
import { assetFileResolver, getType } from '@/projectComponents/fileViewer/service/fileResolvers';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import MarketingMaterialPreview from '../Preview/index.vue';

@Component({
    components: {
        MarketingMaterialPreview,
        Skeleton,
    },
})
export default class MarketingMaterialContent extends Vue {
    @Get(marketingMaterial, 'loading') private loadingMarketingMaterial!: boolean;

    @Get(marketingMaterialCategories, 'loading') private loadingMarketingMaterialCategories!: boolean;

    @Get(marketingMaterialCategories) private categories!: AssetCategoryType[];

    @Get(marketingMaterial) private loadingInBackground!: boolean;

    @Get(marketingMaterial) private files!: AssetType[];

    @Get(marketingMaterial) private previewOpen!: boolean;

    @Get(marketingMaterial) private offset!: number;

    @Get(marketingMaterial) private limit!: number;

    @Get(marketingMaterial) private itemsTotal!: number;

    get serverUrl(): string {
        return env.VUE_APP_SERVER_URL;
    }

    get galleryEmpty(): boolean {
        return !(this.files.length > 0);
    }

    get categoryTitle() {
        const res = this.categories.find((category) => category.id === this.id);
        return res ? res.name : '';
    }

    get loading() {
        return this.loadingMarketingMaterial || this.loadingMarketingMaterialCategories;
    }

    get id() {
        return this.$route.params.id;
    }

    openPreview(id: string): void {
        marketingMaterial.setPreviewOpen(true);
        marketingMaterial.setPreviewFileId(id);
    }

    handleDialogClose(): void {
        marketingMaterial.setPreviewOpen(false);
        marketingMaterial.setPreviewFileId('');
    }

    checkIfDownloadable(file: AssetType): boolean {
        const type = file.context.find((item) => item.metric === 'Content-Type');

        return !(!type || type.value === 'external/video');
    }

    isPreviewAvailable(file: AssetType): boolean {
        const type = getType(file);

        if (!type) {
            return false;
        }

        const previewType = assetFileResolver(type.value);

        return previewType !== '';
    }

    async handlePagination(props: PaginationParamsType) {
        const { offset } = props;
        let subCategoryId: string|null = null;

        if (this.$route.name === 'marketingMaterialCategory') {
            subCategoryId = this.$route.params.id;
        }

        await marketingMaterial.getFiles({ subCategoryId, loadPage: true, offset });
        scrollToTop('#app > div.App__Layout > div.SubScene__Container.SubScene__Container--open > div.SubScene__Content.SubScene__Content--noPadding > div > div.CategoryDisplayHeader__Content');
        scrollToTop('#app > div.App__Layout > div > div.Scene__Content.Scene__Content--noPadding > div > div.MarketingMaterialHeader__Content');
    }
}
