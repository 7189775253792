
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import marketingMaterial from '@/modules/MarketingMaterial';
import OptionItemLayout from '@/projectComponents/optionItemLayout/index.vue';
import Skeleton from '@/projectComponents/skeleton/IconButtons/index.vue';
import { AssetCategoryType } from '@/api/graphQL/graphNodes/types';
import marketingMaterialCategories from '@/modules/MarketingMaterial/categories';
import env from '@/environment';
import Search from '../Search/index.vue';

@Component({
    components: {
        OptionItemLayout,
        Search,
        Skeleton,
    },
})
export default class MarketingMaterialHeader extends Vue {
    @Get(marketingMaterial) private loading!: boolean;

    @Get(marketingMaterial) private searchDisplay!: boolean;

    @Sync(marketingMaterial) private searchText!: string;

    @Get(marketingMaterialCategories) private categories!: AssetCategoryType[];

    @Get(marketingMaterialCategories, 'loading') private loadingCategories!: boolean;

    get searchClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
            'MarketingMaterialHeader__SearchContainer',
            { 'MarketingMaterialHeader__SearchContainer--show': this.searchDisplay },
        ];
    }

    get serverUrl() {
        return env.VUE_APP_SERVER_URL;
    }

    openCategory(categoryId) {
        marketingMaterial.setSearchText('');
        this.$router.push({ name: 'marketingMaterialCategory', params: { id: categoryId } });
    }

    iconUrl(category: AssetCategoryType):string {
        if (category.icon) {
            return `${this.serverUrl}${category.icon.link}`;
        }

        return '';
    }
}
