// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { HostessRewardsType } from './types';

export default class GetHostessRewardsQuery
    extends AbstractQueryResource<GetHostessRewardsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            hostessRewards {
                label,
                thresholdEUR,
                thresholdGBP,
                rewards,
                bonusTokens,
                rewardsAsset {
                    id,
                    category,
                    link,
                    fileName,
                    context {
                        metric,
                        value,
                    },
                    title,
                    originalFileName,
                    uploadDate,
                },
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getHostessRewards';
    }
}

export type GetHostessRewardsResultType = {
    hostessRewards: HostessRewardsType[];
}
