import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { AssetCategoryType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import marketingMaterialRepository from './services/marketingMaterialRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'marketingMaterialCategories',
})
@AutoMutations
export class MarketingMaterialCategories extends VuexModule {
    private categories: AssetCategoryType[] = [];

    private loaded: boolean = false;

    private loading: boolean = false;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setCategories(val: AssetCategoryType[]) {
        this.categories = [...val];
    }

    @Action()
    public async getAssetsCategories() {
        try {
            this.setLoading(true);
            const res = await marketingMaterialRepository.getCategories();
            this.setCategories(res.assetCategories);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(MarketingMaterialCategories);
