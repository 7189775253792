import { FIELD_REQUIRED_MESSAGE } from '@/utils/messages/formValidation';
import { EventErrorType } from '../types';

const formEventValidation: (val: any) => EventErrorType[] = (val) => {
    const errors: EventErrorType[] = [];

    if (!val.title) {
        errors.push({ key: 'title', val: FIELD_REQUIRED_MESSAGE });
    }

    if (!val.address) {
        errors.push({ key: 'address', val: FIELD_REQUIRED_MESSAGE });
    }

    // if (!val.campaignDateFrom) {
    //     errors.push({ key: 'campaignDateFrom', val: FIELD_REQUIRED_MESSAGE });
    // }

    // if (!val.campaignDateTo) {
    //     errors.push({ key: 'campaignDateTo', val: FIELD_REQUIRED_MESSAGE });
    // }

    // if (!val.eventDateFrom) {
    //     errors.push({ key: 'eventDateFrom', val: FIELD_REQUIRED_MESSAGE });
    // }

    // if (!val.eventDateTo) {
    //     errors.push({ key: 'eventDateTo', val: FIELD_REQUIRED_MESSAGE });
    // }

    // if (val.campaignDateFrom && val.campaignDateTo
    //     && dateManager.isAfter(val.campaignDateFrom, val.campaignDateTo)) {
    //     errors.push({ key: 'campaignDateTo', val: CAMPAIGN_SHOULD_START_BEFORE_END });
    // }

    // if (val.campaignDateFrom && val.eventDateFrom
    //     && dateManager.isAfter(val.campaignDateFrom, val.eventDateFrom)) {
    //     errors.push({ key: 'eventDateFrom', val: EVENT_SHOULD_START_AFTER_CAMPAIGN_START });
    // }

    // if (val.campaignDateTo && val.eventDateFrom
    //     && dateManager.isAfter(val.eventDateFrom, val.campaignDateTo)) {
    //     errors.push({ key: 'eventDateFrom', val: EVENT_SHOULD_START_BEFORE_CAMPAIGN_ENDS });
    // }

    // if (val.campaignDateFrom && val.eventDateTo
    //     && dateManager.isBefore(val.eventDateTo, val.campaignDateFrom)) {
    //     errors.push({ key: 'eventDateTo', val: EVENT_SHOULD_END_AFTER_CAMPAIGN_STARTS });
    // }

    // if (val.campaignDateTo && val.eventDateTo
    //     && dateManager.isAfter(val.eventDateTo, val.campaignDateTo)) {
    //     errors.push({ key: 'eventDateTo', val: EVENT_SHOULD_END_BEFORE_CAMPAIGN_ENDS });
    // }

    // if (val.eventDateFrom && val.eventDateTo
    //     && dateManager.isAfter(val.eventDateFrom, val.eventDateTo)) {
    //     errors.push({ key: 'eventDateTo', val: EVENT_SHOULD_START_BEFORE_IT_ENDS });
    // }

    return errors;
};

export default formEventValidation;
