
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Get } from '@/utils/vuex-module-mutators';
import planningCentre from '@/modules/PlanningCentre';
import { IncomePlanType } from '@/api/graphQL/graphNodes/types';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import Header from './Header/index.vue';
import Earnings from './Earnings/index.vue';
import Inputs from './Inputs/index.vue';
import Qualification from './Qualification/index.vue';

@Component({
    components: {
        Header,
        Earnings,
        Inputs,
        Qualification,
        Skeleton,
    },
})
export default class PlanningCentre extends Vue {
    @Get(planningCentre) private loading!: boolean;

    get plan(): IncomePlanType {
        return planningCentre.plan;
    }

    beforeMount() {
        planningCentre.getPlans();
    }
}
