// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { CommissionLevelType, RunTotalIncomeSummaryType } from './types';

export default class GetIncomeSummaryQuery extends
    AbstractQueryResource<GetIncomeSummaryResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($runId: String!) { 
            profile {
                runTotalIncomeSummary(runId: $runId) {
                    metricStats {
                        metric,
                        value,
                    },
                    bonusStats {
                        metric,
                        value,
                        label,
                    },
                    milestoneStats {
                        metric,
                        value,
                        label,
                    },
                    saleStats {
                        metric,
                        value,
                        label,
                    },
                    payRank {
                        id,
                        label,
                        power,
                    },
                    rank {
                        id,
                        label,
                        power,
                        titleRequirements {
                            metric,
                            value,
                        },
                    },
                },
            },
            risingStarsCommissionLevels {
                level,
                rate,
                sales
            },
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getIncomeSummary';
    }
}

export type GetIncomeSummaryResultType = {
    profile: {
        runTotalIncomeSummary: RunTotalIncomeSummaryType;
    }
    risingStarsCommissionLevels: CommissionLevelType[];
}

export type GetIncomeSummaryParamsType = {
    runId: string;
}
