
import Vue from 'vue';
import Component from 'vue-class-component';
import authenticator from '@/modules/Auth/services/authenticator';

@Component
export default class Auth extends Vue {
    async created() {
        await authenticator.finishLogin();
        this.$router.push({ name: 'dashboard' })
            .catch(() => {});
    }
}
