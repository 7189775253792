
import { Vue, Component } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class EventRewards extends Vue {
    get title() {
        return '';
    }
}
