

import { Get } from '@/utils/vuex-module-mutators';
import { Component, Vue, Prop } from 'vue-property-decorator';
import myCustomers from '@/modules/MyCustomers';
import { CUSTOMER_ADDRESS_BOOK, CUSTOMER_ORDERS } from '@/modules/MyCustomers/messages';
import { CUSTOMER_LIST_TYPE } from '@/modules/MyCustomers/constants';

@Component
export default class DirectContainer extends Vue {
    @Get(myCustomers) private selectedCustomerList!: string;

    @Prop() private isLoading!: boolean;

    get customerAddressBookLabel() {
        return CUSTOMER_ADDRESS_BOOK;
    }

    get customerOrdersLabel() {
        return CUSTOMER_ORDERS;
    }

    get customerListBook() {
        return CUSTOMER_LIST_TYPE.addressBook;
    }

    get customerListOrders() {
        return CUSTOMER_LIST_TYPE.orders;
    }

    handleClick(value: string) {
        myCustomers.setSelectedCustomerList(value);
        this.$emit('select');
    }
}
