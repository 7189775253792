
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import myCustomers from '@/modules/MyCustomers';
import dateManager from '@/utils/time';
import profile from '@/modules/Profile';
import Product from '@/projectComponents/OrdersList/Product/item.vue';
import system from '@/modules/System';
import OrderHeaderTitle from '@/projectComponents/orderHeaderTitle/index.vue';
import { BaseOrderType } from '@/api/graphQL/graphNodes/types';
import CurrencyPrefix from '@/utils/currencyPrefix';
import OrderValues from '@/projectComponents/orderValues/index.vue';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component({
    components: {
        Product,
        OrderHeaderTitle,
        OrderValues,
    },
})
export default class OrderDetails extends Vue {
    @Get(myCustomers, 'provideOrderDetails') private order!: BaseOrderType;

    @Get(system) private screenType!: string;

    @Get(profile, 'data.id') private profileId!: string;

    get country() {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(this.country);
    }

    get commissionableOrder() {
        if (this.order.enrollee.id === this.profileId) {
            return true;
        }
        return false;
    }

    get customerFullName() {
        if (this.order.customer) {
            const { customer } = this.order;
            return `${customer.firstName} ${customer.lastName}`;
        }
        return '';
    }

    get orderDate(): string {
        return dateManager.getDateTime(this.order.date, dateManager.getDayTimeFormatUK());
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }
}
