import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import store from '@/store';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import { EventType } from '@/api/graphQL/graphNodes/types';
import { initialEventData } from '@/modules/Event/Events/defaults';
import EventRepository from '@/modules/Event/Events/services/eventRepository';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { ObjectPropertyType } from '@/modules/types';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import profile from '@/modules/Profile';
import env from '@/environment';
import { defaultEnrollee } from '@/modules/Calendar/defaults';
import { EditEventParamsType } from '@/api/graphQL/graphNodes/EditEventQuery';
import { EventFormType, HostessFormType } from '../types';
import formEventValidation from '../services/formEventValidation';
import formHostessValidation from '../services/formHostessValidation';
import formatEventDates from '../services/formatEventDates';
import dateAccountedToWinterTime from '../services/dateAccountedToWinterTime';

export const EVENT_HOSTESS_SELECT_STEP = {
    type: 'type',
    known: 'known',
    new: 'new',
};

@Module({
    namespaced: true, dynamic: true, store, name: 'eventEdit',
})
@AutoMutations
export class EventEdit extends VuexModule {
    private eventData: EventFormType = initialEventData();

    private originalEventData: EventFormType = initialEventData();

    private loading: boolean = false;

    private hostessSelectStep: string = EVENT_HOSTESS_SELECT_STEP.type;

    private formErrors: ErrorType = {};

    private editModalOpen: boolean = false;

    private filter: string = '';

    get currentDay() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true'
            ? mockedDateManager.getCurrentDate(dateManager.getDateFormat())
            : dateManager.getCurrentDate(dateManager.getDateFormat());
    }

    @Mutation
    public setEventData(val: EventType) {
        const mergedObject = ObjectProcessor.objectMerge(initialEventData(), val);
        // sets hours to current time according to timezone
        this.eventData = formatEventDates(mergedObject);
    }

    @Mutation
    public setEventProperty(value: ObjectPropertyType) {
        const { key, val } = value;
        this.eventData[key] = val;
    }

    @Mutation
    public setOriginalEventData(val: EventType) {
        const mergedObject = ObjectProcessor.objectMerge(initialEventData(), val);
        this.originalEventData = formatEventDates(mergedObject);
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setEditModalOpen(val: boolean) {
        this.editModalOpen = val;
    }

    @Mutation
    public setHostessToEvent(data: HostessFormType) {
        this.eventData.hostess = { ...data };
    }

    @Mutation
    public setHostessSelectStep(val: string) {
        this.hostessSelectStep = val;
    }

    @Mutation
    public setHostessData(val: HostessFormType) {
        this.eventData.hostess = { ...this.eventData.hostess, ...val };
    }

    @Mutation
    public clearHostessData() {
        this.eventData.hostess = { ...defaultEnrollee() };
    }

    @Mutation
    public setEventFormError(payload: ObjectPropertyType) {
        this.formErrors[payload.key] = payload.val;
    }

    @Mutation
    public clearFormError() {
        this.formErrors = {};
    }

    @Mutation
    public setEventFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Action()
    public async setHostessMe() {
        const data = profile.getProfileData;
        this.setHostessToEvent(data as HostessFormType);
    }

    @Action()
    public closeModalAfterEdit() {
        this.setEditModalOpen(false);
        this.setHostessSelectStep(EVENT_HOSTESS_SELECT_STEP.type);
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Action()
    public async getEvent(id: string) {
        try {
            this.setLoading(true);
            const { event } = await EventRepository.getEvent({ id });
            const eventData = ObjectProcessor.removeEmptyProperties(event);
            this.setEventData(eventData);
            this.setOriginalEventData(eventData);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public validateEventForm() {
        this.clearFormError();

        const hostessErrors = formHostessValidation(this.eventData.hostess);

        if (hostessErrors.length > 0) {
            this.setHostessSelectStep(EVENT_HOSTESS_SELECT_STEP.new);
            this.setEditModalOpen(true);
        }

        const errorList = [
            ...formEventValidation(this.eventData),
            ...hostessErrors,
        ];

        errorList.forEach((error) => this.setEventFormError(error));
    }

    @Action()
    async updateEvent(): Promise<void> {
        this.validateEventForm();

        if (Object.keys(this.formErrors).length === 0) {
            try {
                this.setLoading(true);
                const eventData = {
                    ...this.eventData,
                    eventDateFrom: dateAccountedToWinterTime(this.eventData.eventDateFrom),
                    eventDateTo: dateAccountedToWinterTime(this.eventData.eventDateTo),
                    campaignDateFrom: dateAccountedToWinterTime(this.eventData.campaignDateFrom),
                    campaignDateTo: dateAccountedToWinterTime(this.eventData.campaignDateTo),
                };

                const data = ObjectProcessor
                    .updatedFields(this.originalEventData, eventData) as EditEventParamsType;

                await EventRepository.editEvent(this.eventData.id, data);
            } catch (e) {
                const errors = ErrorsProcessor.process(e);
                this.setFormErrors(errors.form);
                throw e;
            } finally {
                this.setLoading(false);
            }
        } else {
            throw new Error();
        }
    }
}

export default getModule(EventEdit);
