
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { RankType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class QualifiedLeader extends Vue {
    @Get(dashboard, 'data.qualifiedOrgLeaderCount') private qualifiedOrgLeaderCount!: number;

    @Prop() private rank!: RankType;

    @Prop() private ranks!: RankType[];

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(
            RankRequirementManager.rankRequirementMetricConstants.qualifiedLeaders,
        ).title;
    }

    get teamAmbassador(): RankType | undefined {
        return this.ranks.find((rankItem) => rankItem.label === 'Team Ambassador');
    }

    get displayData() {
        if (this.teamAmbassador && this.teamAmbassador.power <= this.rank.power) {
            return true;
        }

        return false;
    }
}
