export const ORDER_TYPES = {
    manual: 'MANUAL',
    ppkit: 'PP-KIT',
    ppweb: 'PP-WEB',
    pphos: 'PP-HOS',
    ppcus: 'PP-CUS',
    ppret: 'PP-RET',
    ppexc: 'PP-EXC',
    ppamb: 'PP-AMB',
    dskit: 'DS-KIT',
    dscus: 'DS-CUS',
    dsweb: 'DS-WEB',
    dsvip: 'DS-VIP',
    all: null,
};
