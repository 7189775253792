import { RankType } from '@/api/graphQL/graphNodes/types';
import rank from '../index';

const nextRank: (power: number) => RankType|null = (power) => {
    const nextRankItem = rank.allRanks.find((item) => item.power > power);

    if (nextRankItem) {
        return nextRankItem;
    }

    return null;
};

export default nextRank;
