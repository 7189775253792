import {
    profileInput, profileQuery, profileMutations,
    profilePictureInput, customerInput,
} from './profile';
import {
    bankDetails, bankDetailsInput, bankDetailsQuery, bankDetailsMutations,
} from './bankDetails';
import {
    rank, rankQuery,
} from './rank';
import {
    enrollee, enrolleeQuery, volume, eventPerformance,
    eventPerformanceReward, newStarterTotals, incomeSummaryItem,
} from './enrollee';
import { exploreEnrollee, exploreEnrolleeQuery } from './exploreEnrollee';
import { stats } from './stats';
import { rankRequirements } from './rankRequirements';
import {
    event, eventEditInput, eventCreateInput, eventQuery, eventMutations, eventGuest,
    hostessInvitationInput, eventInvitation, guestInvitationInput, calendarEventPerformance,
} from './event';
import { me, meQuery } from './me';
import {
    sale, customerOrderQuery, product, paymentInfo,
} from './customerOrders';
import { eventsStatisticsQuery, eventStatistics } from './eventsStatistics';
import { impersonateEnrolleeQuery } from './impersonateEnrollee';
import {
    ambassadorInviteMutations, registerMutations,
    registerInput, registerBankDetailsInput,
    hostessRegisterInput,
} from './ambassadorInvites';
import {
    asset, assetsQuery, searchAssetsQuery, searchAssetsCountQuery,
} from './asset';
import { assetCategories, assetCategoriesQuery } from './assetCategories';
import { calendar, calendarDailyQuery, calendarQuery } from './calendar';
import { termsAndConditions, termsAndConditionsQuery } from './termsAndConditions';
import { income } from './incomeSummary';
import { registerPassportInput, registerDriverLicenseInput, registerLexisNexisMutations } from './register';
import { commissionStatement } from './commissionStatements';
import { transaction } from './transaction';
import { hostessReward, hostessRewardQuery } from './hostessReward';
import { run } from './runs';
import { report } from './reports';
import { tokenThresholdQuery } from './tokenThreshold';
import {
    incomePlanner, incomePlannerQuery, incomePlannerEarning, incomePlannerQualification,
} from './incomePlanner';
import { commissionLevel, risingStarsCommissionLevelsQuery } from './commissionLevel';
import { newStartersLevels, newStartersLevelsQuery } from './newStarters';

export default `
scalar DateTime

${newStartersLevels}
${newStarterTotals}
${hostessReward}
${bankDetails}
${rank}
${incomeSummaryItem}
${volume}
${run}
${report}
${commissionStatement}
${eventPerformanceReward}
${eventPerformance}
${enrollee}
${exploreEnrollee}
${stats}
${rankRequirements}
${me}
${sale}
${paymentInfo}
${product}
${eventStatistics}
${hostessInvitationInput}
${event}
${eventGuest}
${eventInvitation}
${calendarEventPerformance}
${assetCategories}
${asset}
${calendar}
${termsAndConditions}
${income}
${transaction}
${incomePlannerEarning}
${incomePlannerQualification}
${incomePlanner}
${commissionLevel}

${customerInput}
${profileInput}
${profilePictureInput}
${bankDetailsInput}
${eventEditInput}
${eventCreateInput}
${guestInvitationInput}
${registerInput}
${registerBankDetailsInput}
${registerPassportInput}
${registerDriverLicenseInput}
${hostessRegisterInput}

# the schema allows the following query:
type Query {
    ${newStartersLevelsQuery}
    ${profileQuery}
    ${bankDetailsQuery}
    ${rankQuery}
    ${enrolleeQuery}
    ${exploreEnrolleeQuery}
    ${meQuery}
    ${customerOrderQuery}
    ${eventQuery}
    ${eventsStatisticsQuery}
    ${impersonateEnrolleeQuery}
    ${assetsQuery}
    ${assetCategoriesQuery}
    ${searchAssetsQuery}
    ${searchAssetsCountQuery}
    ${calendarQuery}
    ${calendarDailyQuery}
    ${termsAndConditionsQuery}
    ${hostessRewardQuery}
    ${tokenThresholdQuery}
    ${incomePlannerQuery}
    ${risingStarsCommissionLevelsQuery}
}

# this schema allows the following mutation:
type Mutation {
    ${profileMutations}
    ${bankDetailsMutations}
    ${eventMutations}
    ${ambassadorInviteMutations}
    ${registerMutations}
    ${registerLexisNexisMutations}
}`;
