
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import eventInvitationModule from '@/modules/Event/EventInvitation';
import { EventViewType, InvitationType } from '@/api/graphQL/graphNodes/types';
import { EVENT_ATTENDANCE_STATUS, EVENT_INVITATION_TYPE } from '@/modules/Event/constants';
import eventView from '@/modules/Event/EventView';
import env from '@/environment';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import EventInfo from './EventInfo/eventInfo.vue';
import HostessInfo from './EventInfo/hostessInfo.vue';
import AmbassadorInfo from './EventInfo/ambassadorInfo.vue';
import InvitationFrom from './InvitationInfo/invitationFrom.vue';
import InvitationAttendance from './InvitationInfo/invitationAttendance.vue';
import CustomerSignUp from './CustomerSignUp/index.vue';
import ShareEventInvitation from './ShareInvitation/index.vue';
import BackToApp from './BackToApp/index.vue';

@Component({
    components: {
        EventInfo,
        HostessInfo,
        AmbassadorInfo,
        InvitationFrom,
        InvitationAttendance,
        CustomerSignUp,
        ShareEventInvitation,
        BackToApp,
    },
})
export default class EventInvitationView extends Vue {
    @Get(eventInvitationModule) private loading!: boolean;

    @Get(eventInvitationModule) private eventData!: EventViewType;

    @Get(eventView) private eventInvitationLink!: string;

    @Get(eventView) private inviteLinkLoading!: boolean;

    @Get(eventView) private inviteLinkLoadingInBackground!: boolean;

    @Get(eventInvitationModule) private invitationData!: InvitationType;

    @Get(eventInvitationModule) private shadowEnrollee!: boolean;

    get loaded() {
        return !(this.inviteLinkLoading || this.loading);
    }

    get isResponded() {
        return this.invitationData.attending !== null;
    }

    get isDeclined() {
        return this.invitationData.attending === EVENT_ATTENDANCE_STATUS.declined;
    }

    get isGuest() {
        return this.invitationData.type === EVENT_INVITATION_TYPE.guest;
    }

    get isHostess() {
        return this.invitationData.type === EVENT_INVITATION_TYPE.hostess;
    }

    get displaySignUp() {
        // TODO uncomment to enable customer registration

        // return this.invitationData.type === EVENT_INVITATION_TYPE.guest
        //     && this.shadowEnrollee === true
        //     && this.isResponded
        //     && !this.isDeclined;
        return false;
    }

    get enrolleeResponded() {
        const { enrollee } = this.invitationData;
        if (enrollee) {
            if (!this.shadowEnrollee && this.isResponded && !this.isDeclined) {
                return true;
            }
        }

        return false;
    }

    get currentDayTime() {
        return env.VUE_APP_MOCK_GRAPHQL === 'true'
            ? mockedDateManager.getCurrentDate(dateManager.getDayTimeFormatUtc())
            : dateManager.getCurrentDate(dateManager.getDayTimeFormatUtc());
    }

    get eventEnded(): boolean {
        return dateManager
            .isAfter(this.currentDayTime, this.eventData.campaignDateTo);
    }

    get displayShareEventInvitation(): boolean {
        return this.eventData.allowGuestsToInvite && !this.eventEnded && this.eventData.confirmed
         && !this.inviteLinkLoadingInBackground;
    }

    @Watch('eventData.confirmed')
    setVal(next: boolean, prev: boolean) {
        if (
            next
            && !prev
            && (!this.isGuest || this.eventData.allowGuestsToInvite)
        ) {
            this.getInvitationLink(true);
        }
    }

    getInvitationLink(loadInBackground: boolean) {
        const { id } = this.$route.params;
        const token = this.$route.query.token.toString();
        eventView.getGuestEventInvitationLink({ id, token, loadInBackground });
    }

    async created() {
        const { id } = this.$route.params;
        const token = this.$route.query.token.toString();
        await eventInvitationModule.getEventInvitation({ id, token });

        if (this.eventData.confirmed
        && (this.isHostess || this.eventData.allowGuestsToInvite) && !this.eventEnded) {
            this.getInvitationLink(false);
        }

        // if (this.isHostess && this.shadowEnrollee === true) {
        //     this.$router.push({ name: 'hostessRegistration', query: { token, event: id } });
        // }
    }
}
