// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { ProfileRewardsType } from './types';

export default class GetProfilePerformanceQuery extends
    AbstractQueryResource<GetProfilePerformanceResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                eventPerformance {
                    rewards {
                        rewardUrl,
                        expiryDate,
                        unlockedTiers,
                    },
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getProfilePerformance';
    }
}

export type GetProfilePerformanceResultType = {
    profile: {
        eventPerformance: ProfileRewardsType;
    };
}
