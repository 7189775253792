import { CreateEventHostessType } from '@/modules/Event/Events/types';
import { HostessInvitationInputType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class CreateHostessQuery extends AbstractQueryResource<CreateHostessResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($hostess: HostessInvitationType) {
            inviteHostess(hostessInvitation: $hostess) {
                id,
                email,
                firstName,
                lastName,
                phoneNumber,
                address,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'createHostess';
    }
}

export type CreateHostessParamsType = {
    hostess: HostessInvitationInputType,
};

export type CreateHostessResultType = {
    inviteHostess: CreateEventHostessType;
}
