
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import myCustomers from '@/modules/MyCustomers';
import { CUSTOMERS_TYPE } from '@/modules/MyCustomers/constants';
import system from '@/modules/System';
import profile from '@/modules/Profile';
import { MyCustomersType } from '@/api/graphQL/graphNodes/types';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import currencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import CustomerItem from './item.vue';

@Component({ components: { CustomerItem } })
export default class CustomersList extends Vue {
    @Get(myCustomers) private customers!: MyCustomersType[];

    @Get(myCustomers, 'customersLoading') private loading!: boolean;

    @Get(myCustomers) private expandedCustomers!: string[];

    @Get(myCustomers) private offset!: number;

    @Get(myCustomers) private total!: number;

    @Get(myCustomers) private activeSponsorDetailsModal!: string;

    @Get(myCustomers) private displayCustomersSearchResults!: boolean;

    @Get(myCustomers) private limit!: number;

    @Get(myCustomers) private loadingInBackground!: boolean;

    @Get(myCustomers) private customerSpend!: number;

    @Get(myCustomers) private customerType!: string;

    @Get(myCustomers) private searchQuery!: string;

    @Get(system) private screenType!: string;

    @Get(profile, 'originalData.id') private myId!: string;

    get displayLoadMore() {
        const { total, limit } = this;
        return total > limit;
    }

    get country() {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    get currencyPrefix() {
        return currencyPrefix.resolvePrefix(this.country);
    }

    get salesTotal() {
        return Number(this.customerSpend).toFixed(2);
    }

    get vipCustomerLabel(): string {
        return this.customerType === CUSTOMERS_TYPE.host ? 'VIP Customers' : 'customers';
    }

    getExpandedVal(id: string) {
        return !!this.expandedCustomers.find((item) => item === id);
    }

    handleCustomerOpenToggle(id: string) {
        myCustomers.toggleExpandedList(id);
    }

    handleSponsorDetailDialog(val: string) {
        myCustomers.setActiveSponsorDetailsModal(val);
    }

    async handlePagination(params: PaginationParamsType) {
        try {
            const { offset } = params;

            if (this.searchQuery !== '') {
                await myCustomers.searchCustomers({
                    offset,
                    sellerId: this.myId,
                });
            } else {
                await myCustomers.getMyCustomers({
                    offset,
                    sellerId: this.myId,
                });
            }

            scrollToTop('#app > div.MyCustomers__Scene.App__Layout > div > div.Scene__Content > div > div.MyCustomersHeader__Content');
        // eslint-disable-next-line no-empty
        } finally {}
    }
}
