
import { Vue, Prop, Component } from 'vue-property-decorator';

import OptionItemLayout from '@/projectComponents/optionItemLayout/index.vue';

@Component({
    components: {
        OptionItemLayout,
    },
})
export default class KnownHostessContent extends Vue {
    @Prop({ default: false }) private active!: boolean;

    @Prop({ default: 'A known VIP Customer' }) private caption!: string;
}
