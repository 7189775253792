// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetDirectDownlineCountQuery extends
    AbstractQueryResource<GetDirectDownlineCountResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                directDownlineCount
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getDirectDownlineCount';
    }
}

export type GetDirectDownlineCountResultType = {
    profile: {
        directDownlineCount: number;
    }
}
