
import { Vue, Component, Prop } from 'vue-property-decorator';
import currencyPrefix from '@/utils/currencyPrefix';

@Component
export default class OrdersTotal extends Vue {
    @Prop({ required: true }) private total!: string;

    @Prop() private country!: string;

    get currencyPrefix() {
        return currencyPrefix.resolvePrefix(this.country);
    }
}
