import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { AssetType } from '@/api/graphQL/graphNodes/types';
import { GetHelpAndSupportAssetsResultType } from '@/api/graphQL/graphNodes/GetHelpAndSupportAssetsQuery';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import helpAndSupportRepository from './services/HelpAndSupportRepository';
import { TERMS_AND_CONDITIONS_TYPE } from '../Profile/constants';

@Module({
    namespaced: true, dynamic: true, store, name: 'helpAndSupportAssets',
})
@AutoMutations
export class HelpAndSupportAssets extends VuexModule {
    private files: AssetType[] = [];

    private loaded: boolean = false;

    private loading: boolean = false;

    private loadingInBackground: boolean = false;

    private previewOpen: boolean = false;

    private previewFileId: string = '';

    private searchDisplay = false;

    private searchText = '';

    private offset = 0;

    private limit = 20;

    private itemsTotal = 0;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setFiles(val: AssetType[]) {
        this.files = [...val];
    }

    @Mutation
    public setPreviewOpen(val: boolean) {
        this.previewOpen = val;
    }

    @Mutation
    public setPreviewFileId(val: string) {
        this.previewFileId = val;
    }

    @Mutation
    public toggleSearchDisplay() {
        this.searchDisplay = !this.searchDisplay;
    }

    @Mutation
    public setSearchDisplay(val: boolean) {
        this.searchDisplay = val;
    }

    @Mutation
    public setTotal(val: number) {
        this.itemsTotal = val;
    }

    @Mutation
    public setOffset(val:number) {
        this.offset = val;
    }

    @Mutation
    public setSearchText(val: string) {
        this.searchText = val;
    }

    @Action()
    public async getFiles(props: {
            loadPage?: boolean,
            offset?: number
        }) {
        const { loadPage, offset } = props;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const offsetVal = offset || offset === 0 ? offset : this.offset;

            const res: GetHelpAndSupportAssetsResultType = await helpAndSupportRepository
                .getAssets({
                    query: this.searchText,
                    category: 'help_support',
                    offset: offsetVal,
                    limit: this.limit,
                });

            this.setFiles(res.searchAssets);
            this.setTotal(res.searchAssetsCount);
            this.setOffset(offsetVal);
            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingInBackground(false);
            this.setLoading(false);
        }
    }

    @Action()
    public async getHostessFiles(props: {
            loadPage?: boolean,
        }) {
        const { loadPage } = props;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const offsetVal = 0;

            const res = await helpAndSupportRepository
                .getHostessAssets({
                    type: TERMS_AND_CONDITIONS_TYPE.hostess,
                });

            this.setFiles([res.termsAndConditions.content]);
            this.setTotal(1);
            this.setOffset(offsetVal);
            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingInBackground(false);
            this.setLoading(false);
        }
    }

    @Action()
    public clearData() {
        this.setFiles([]);
        this.setLoaded(false);
        this.setOffset(0);
        this.setSearchText('');
        this.setSearchDisplay(false);
    }
}
export default getModule(HelpAndSupportAssets);
