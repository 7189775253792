import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import {
    GetOrdersParamsType, GetOrdersResultType,
} from '@/api/graphQL/graphNodes/GetOrdersQuery';
import { OrdersFilterType, OrderType } from '@/api/graphQL/graphNodes/types';
import OrdersRepository from './services/ordersRepository';
import { defaultFilterData } from './defaults';
import filterResolver from './services/filtersResolver';

@Module({
    namespaced: true, dynamic: true, store, name: 'orders',
})
@AutoMutations
export class Orders extends VuexModule {
    private loaded: boolean = false;

    private loading: boolean = false;

    private loadingInBackground: boolean = false;

    private searchQuery: string = '';

    private searchLoading: boolean = false;

    private displaySearch: boolean = false;

    private displaySearchResults: boolean = false;

    private searchedOrdersOptions: OrderType[] = [];

    private searchedOrders: OrderType[] = [];

    private limit: number = 10;

    private offset: number = 0;

    private total: number = 0;

    private orders: OrderType[] = [];

    private minSearchLength: number = 3;

    private expandedOrders: string[] = [];

    private filterData: OrdersFilterType = defaultFilterData();

    private salesTotal: string = '0.00';

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setFilterData(data: OrdersFilterType) {
        this.filterData = { ...this.filterData, ...data };
    }

    @Mutation
    setSearchLoading(val: boolean) {
        this.searchLoading = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    setSearchQuery(val: string) {
        this.searchQuery = val;
    }

    @Mutation
    setDisplaySearchResults(val: boolean) {
        this.displaySearchResults = val;
    }

    @Mutation
    toggleShowSearch() {
        this.displaySearch = !this.displaySearch;
    }

    @Mutation
    setSearchedOrdersOptions(val: OrderType[]) {
        this.searchedOrdersOptions = val;
    }

    @Mutation
    public setOffset(val: number) {
        this.offset = val;
    }

    @Mutation
    public setTotal(val: number) {
        this.total = val;
    }

    @Mutation
    public setOrders(val: OrderType[]) {
        this.orders = val;
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedOrders.find((item) => item === val)) {
            const index = this.expandedOrders.findIndex((item) => item === val);
            this.expandedOrders.splice(index, 1);
        } else {
            this.expandedOrders.push(val);
        }
    }

    @Mutation
    clearExpandedOrdersList() {
        this.expandedOrders = [];
    }

    @Mutation
    public clearFilterData() {
        this.filterData = { ...defaultFilterData() };
    }

    @Mutation
    setSearchedOrders(val: OrderType[]) {
        this.searchedOrders = val;
    }

    @Mutation
    setSalesTotal(val: string) {
        this.salesTotal = val;
    }

    @Action()
    async search(data: { query?: string|null, selectedSearch?: boolean, offset?: number }
    = { query: null, selectedSearch: false }) {
        const { query, selectedSearch, offset = 0 } = data;

        try {
            if (selectedSearch) {
                this.setSearchLoading(true);
            }

            const { limit } = this;

            const searchParams: GetOrdersParamsType = filterResolver(
                this.filterData,
                query ? query.replace('#', '') : '',
                limit,
                offset,
            );

            const result: GetOrdersResultType = await OrdersRepository
                .getOrders(searchParams) as GetOrdersResultType;

            const {
                searchOrders,
                searchOrdersCount,
                searchOrdersSalesTotal,
            } = result.profile;

            if (selectedSearch) {
                this.setSearchedOrders(searchOrders);
                this.setTotal(searchOrdersCount);
                this.setSalesTotal(searchOrdersSalesTotal);
            } else {
                this.setSearchedOrdersOptions(searchOrders);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setSearchLoading(false);
            }
        }
    }

    @Action()
    public async getOrders(data: {loadPage?: boolean, offset?: number }) {
        const { loadPage = false, offset = 0 } = data;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const searchParams = filterResolver(this.filterData, '', this.limit, offset);

            const res: GetOrdersResultType = await OrdersRepository
                .getOrders(searchParams) as GetOrdersResultType;

            const { searchOrders, searchOrdersCount, searchOrdersSalesTotal } = res.profile;
            this.setOrders(searchOrders);
            this.setSalesTotal(searchOrdersSalesTotal);

            this.setOffset(offset);
            this.setTotal(searchOrdersCount);
            this.clearExpandedOrdersList();

            if (!loadPage) {
                this.setLoaded(true);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        } finally {
            this.setLoadingInBackground(false);
            this.setLoading(false);
        }
    }
}

export default getModule(Orders);
