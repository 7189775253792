
import { Vue, Component } from 'vue-property-decorator';
import profile from '@/modules/Profile';
import auth from '@/modules/Auth';
import authLocalStorageManager from '@/modules/Auth/services/authLocalStorageManager';
import impersonate from '@/modules/Impersonation';
import { Get } from '@/utils/vuex-module-mutators';
import env from '@/environment';
import { NO_PERSONAL_RECRUITS_TOOLTIP_MESSAGE } from '@/modules/Navigation/messages';
import NavItem from './Item/index.vue';
import Section from './Section/index.vue';

@Component({
    components: {
        NavItem,
        Section,
    },
})
export default class Navigation extends Vue {
    @Get(profile, 'originalData.firstName') private firstName!: string;

    @Get(profile, 'originalData.lastName') private lastName!: string;

    @Get(profile, 'originalData.id') private id!: string;

    @Get(impersonate) private token!: string|null;

    @Get(profile) private shopLoginUrl!: string;

    @Get(profile) private directDownlineCount!: number;

    async mounted() {
        if (authLocalStorageManager.getAuthToken() !== null) {
            await profile.getDirectDownlineCount();
        }
    }

    get isDevelopmentEnvironment(): boolean {
        return env.VUE_APP_DEV === 'true';
    }

    get impersonating(): boolean {
        return impersonate.impersonating;
    }

    get isAmbassador(): boolean {
        return auth.isAmbassador;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    get baseUrl() {
        return env.BASE_URL;
    }

    get iconBaseUrl() {
        return `${this.baseUrl}img/icons/`;
    }

    get sisterPayIcon() {
        return env.VUE_APP_DISABLE_LOGO === 'true'
            ? `${this.iconBaseUrl}sister_pay_icon.svg`
            : `${this.iconBaseUrl}connect_pay.png`;
    }

    get thriveIcon() {
        return `${this.iconBaseUrl}thrive.png`;
    }

    get trainingUrl(): string {
        return env.VUE_APP_TRAINING_URL;
    }

    get noPersonalRecruits(): boolean {
        return this.directDownlineCount === 0;
    }

    get noPersonalRecruitsTooltip(): string {
        return NO_PERSONAL_RECRUITS_TOOLTIP_MESSAGE;
    }

    get withLogo(): boolean {
        return env.VUE_APP_DISABLE_LOGO === 'false';
    }

    get storeCreditLabel(): string {
        return env.VUE_APP_DISABLE_LOGO === 'true'
            ? 'Sister Pay'
            : 'Connect Pay';
    }

    unlockedAfterStage(stage: number): boolean {
        return Number(env.VUE_APP_STAGE) > stage;
    }
}
