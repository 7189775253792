

import { Component, Vue } from 'vue-property-decorator';
import TimeFrameSelect from './TimeFrameSelect/index.vue';

@Component({
    components: {
        TimeFrameSelect,
    },
})
export default class ViewControls extends Vue {}
