
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { Debounced } from '@/utils/debounced';
import customersOrders from '@/modules/MyCustomers/customersOrders';
import { SelectOptionsType } from '@/components/select/types';
import { BaseOrderType } from '@/api/graphQL/graphNodes/types';

@Component
export default class MyCustomersOrdersSearch extends Vue {
    @Sync(customersOrders) private searchQuery!: string;

    @Get(customersOrders) private searchLoading!: boolean;

    @Get(customersOrders) private minSearchLength!: number;

    @Get(customersOrders) private searchedOrdersOptions!: BaseOrderType[];

    get searchOptions(): SelectOptionsType[] {
        return this.searchedOrdersOptions.map((order) => ({
            name: `${order.id}`,
            value: `${order.id}`,
        }));
    }

    handleSelect(val) {
        customersOrders.setSearchQuery(val.value);
        customersOrders.setDisplaySearchResults(true);
        this.$emit('onSelect');
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            customersOrders.setDisplaySearchResults(false);
            customersOrders.setSearchedOrdersOptions([]);
            customersOrders.getCustomerOrders({});
        }

        if (this.searchQuery.length >= this.minSearchLength) {
            customersOrders.searchOrders();
        }
    }
}
