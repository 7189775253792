

import { Component, Vue } from 'vue-property-decorator';
import TypeFilter from './TypeFilters/index.vue';
import ColorExplain from './ColorExplainContainer/index.vue';

@Component({
    components: {
        TypeFilter,
        ColorExplain,
    },
})
export default class FilterContainer extends Vue {}
