const bankDetails = `type BankDetails {
    bankAccountName: String,
    bankAccountNumber: String,
    bankSortCode: String,
}`;

const bankDetailsInput = `input BankDetailsInput {
    bankAccountName: String,
    bankAccountNumber: String,
    bankSortCode: String,
}`;

const bankDetailsQuery = 'bankDetails: BankDetails';

const bankDetailsMutations = 'updateBankDetails (bankDetails: BankDetailsInput): BankDetails';

export {
    bankDetails, bankDetailsInput, bankDetailsQuery, bankDetailsMutations,
};
