import { IncomeSummaryType, RunTotalIncomeSummaryType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import { defaultMinimalRank } from '../Rank/defaults';

const defaultRunTotalIncomeSummary: () => RunTotalIncomeSummaryType = () => ({
    bonusStats: [],
    metricStats: [],
    milestoneStats: [],
    saleStats: [],
    payRank: null,
    rank: {
        ...defaultMinimalRank(),
        titleRequirements: [],
    },
});

const defaultData: () => IncomeSummaryType = () => ({
    additional: 0,
    adjustments: 0,
    centralTeam: 0,
    gv: 0,
    ov: 0,
    pv: 0,
    productivity: 0,
    total: 0,
    title: {
        label: '',
    },
    leadership1: 0,
    leadership2: 0,
    leadership3: 0,
    leadership4: 0,
});

const defaultCurrentMonth: () => string = () => (
    env.VUE_APP_MOCK_GRAPHQL === 'true'
        ? mockedDateManager.getCurrentDate('YYYY-MM')
        : dateManager.getCurrentDate('YYYY-MM')
);

export { defaultData, defaultCurrentMonth, defaultRunTotalIncomeSummary };
