import { defaultData } from '@/modules/IncomeSummary/defaults';
import { rank as defaultRank } from '@/modules/Rank/defaults';
import { enrollee as findEnrollee } from './enrollee';
import { rank } from './ranks';
import { ResolverIncomeBonusesType, ResolverIncomeSummaryType } from '../types';

const defaultBonus = () => ({
    enrollee: '',
    centralTeam: 0,
    leadership1: 0,
    leadership2: 0,
    leadership3: 0,
    leadership4: 0,
    additional: 0,
    productivity: 0,
    adjustments: 0,
    tax: 0,
});

const bonuses: ResolverIncomeBonusesType[] = [
    {
        enrollee: '0',
        centralTeam: 443.72,
        leadership1: 414.25,
        leadership2: 0,
        leadership3: 0,
        leadership4: 0,
        additional: 0,
        productivity: 100,
        adjustments: 213,
        tax: 0,
    },
    {
        enrollee: '5',
        centralTeam: 202.38,
        leadership1: 0,
        leadership2: 0,
        leadership3: 0,
        leadership4: 0,
        additional: 0,
        productivity: 0,
        adjustments: 112,
        tax: 0,
    },
    {
        enrollee: '12',
        centralTeam: 114.00,
        leadership1: 0,
        leadership2: 0,
        leadership3: 0,
        leadership4: 0,
        additional: 0,
        productivity: 0,
        adjustments: 93,
        tax: 0,
    },
    {
        enrollee: '18',
        centralTeam: 196.98,
        leadership1: 0,
        leadership2: 0,
        leadership3: 0,
        leadership4: 0,
        additional: 0,
        productivity: 0,
        adjustments: 150,
        tax: 0,
    },
    {
        enrollee: '25',
        centralTeam: 185.40,
        leadership1: 219.44,
        leadership2: 0,
        leadership3: 0,
        leadership4: 0,
        additional: 0,
        productivity: 0,
        adjustments: 0,
        tax: 0,
    },
    {
        enrollee: '29',
        centralTeam: 0,
        leadership1: 0,
        leadership2: 0,
        leadership3: 0,
        leadership4: 0,
        additional: 67.25,
        productivity: 0,
        adjustments: 0,
        tax: 0,
    },
    {
        enrollee: '31',
        centralTeam: 202.56,
        leadership1: 0,
        leadership2: 0,
        leadership3: 0,
        leadership4: 0,
        additional: 66.15,
        productivity: 0,
        adjustments: 100,
        tax: 0,
    },
    {
        enrollee: '37',
        centralTeam: 0,
        leadership1: 0,
        leadership2: 0,
        leadership3: 0,
        leadership4: 0,
        additional: 75.60,
        productivity: 0,
        adjustments: 0,
        tax: 0,
    },
];

const calculateTotal: (data) => number = (data) => {
    const values = Object.values(data);
    let res = 0;
    values.forEach((val) => {
        res += Number(val);
    });

    return res;
};

const incomeSummary: (year: string, id: string) => ResolverIncomeSummaryType = (year, id) => {
    const calculations = bonuses.find((b) => b.enrollee === id);
    const calculatedBonuses = calculations || defaultBonus();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { enrollee, ...bonusData } = calculatedBonuses;
    const summaryEnrollee = findEnrollee(id);
    const rankEl = rank(summaryEnrollee.rankId) || defaultRank();

    const res: ResolverIncomeSummaryType = {
        ...defaultData(),
        gv: summaryEnrollee.totals.gv,
        pv: summaryEnrollee.totals.pv,
        ov: summaryEnrollee.totals.ov,
        title: rankEl,
        ...bonusData,
        total: calculateTotal(bonusData),
    };

    return res;
};

export { incomeSummary };
