
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';

@Component
export default class ChartSales extends Vue {
    @Get(dashboard, 'data.performanceData.avgSalesPerEvent') private avgSalesPerEvent!: number;

    @Get(dashboard, 'data.performanceData.projectedSales') private projectedSales!: number;

    @Get(dashboard, 'data.performanceData.totalSales') private totalSales!: number;

    format2DecimalPoints(val) {
        return Number(val).toFixed(2);
    }
}
