// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { HostessDashboardType } from './types';

export default class GetHostessDashboardQuery extends
    AbstractQueryResource<GetHostessDashboardResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            profile {
                eventPerformance {
                    tokens,
                    tokenRewardUrl
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getHostessDashboard';
    }
}

export type GetHostessDashboardResultType = {
    profile: HostessDashboardType;
}
