import API from '@/api/graphQL';

export default class NoPermissionRepository {
    static getTermsAndConditions() {
        return API
            .getTermsAndConditions()
            .query();
    }

    static updateTermsAndConditions() {
        return API
            .updateTermsAndConditions()
            .query();
    }

    static getProfileInfo() {
        return API
            .getProfileMinimal()
            .query();
    }

    static getRegistrationStatus() {
        return API
            .getRegistrationStatus()
            .query();
    }
}
