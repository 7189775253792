import { DownlineProfileType, MyHostDetailsType } from '@/api/graphQL/graphNodes/types';
import { COUNTRY_SELECT_OPTIONS } from '../constants';

const profile: () => DownlineProfileType = () => ({
    id: '',
    parentId: '',
    address: '',
    email: '',
    phoneNumber: '',
    rank: {
        id: '',
        label: '',
    },
    firstName: '',
    lastName: '',
    secondAddress: '',
    birthDate: '',
    postCode: '',
    town: '',
    county: '',
    country: COUNTRY_SELECT_OPTIONS.gb,
    hidden: false,
    profilePicture: {
        link: '',
        context: [],
    },
});

const defaultHostDetails: () => MyHostDetailsType = () => ({
    address: '',
    editable: false,
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    parent: {
        address: '',
        country: '',
        county: '',
        email: '',
        firstName: '',
        id: '',
        lastName: '',
        phoneNumber: '',
        postCode: '',
        rank: {
            id: '',
            label: '',
            power: 0,
        },
        secondAddress: '',
        secondPhoneNumber: '',
        town: '',
        stats: [],
    },
    phoneNumber: '',
    secondAddress: '',
    secondPhoneNumber: '',
    shadow: false,
    eventPerformance: {
        tokens: '0',
    },
});

export { profile, defaultHostDetails };
