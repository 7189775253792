<template>
    <Scene title="Ui Elements">
        <div class="pts-layout pts-gutter">
            <div class="pts-layout-item pts-size-100">
                <div class="Ui__Card md-elevation-4 pts-layout pts-gutter">
                    <div class="pts-layout-item pts-size-100">
                        <div>
                            <router-link :to="{ name: 'uiComponentsActionMenus' }">
                                Action menus
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsButtons' }">
                                Buttons
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsClipboards' }">
                                Clipboards
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsDatePickers' }">
                                Date pickers
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsDialogs' }">
                                Dialogs
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsExpansion' }">
                                Expansion
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsFileArea' }">
                                File area
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsFiles' }">
                                Files
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsIconButtons' }">
                                Icon buttons
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsInlineEdits' }">
                                Inline edits
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsLinkButtons' }">
                                Link buttons
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsLoader' }">
                                Loader
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsMap' }">
                                Map
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsPaginationHydra' }">
                                Hydra pagination
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsPagination' }">
                                Pagination
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsProgressBar' }">
                                Progress bar
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsProgressBar2' }">
                                Progress bar 2
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsProgressBarCircular' }">
                                Progress bar circular
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsRadioOptions' }">
                                Radio options
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsSearchSelects' }">
                                Search selects
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsSelects' }">
                                Selects
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsSnackbars' }">
                                Snackbars
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsSubsceneTest1' }">
                                Subscene without title
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsSubsceneTest2' }">
                                Subscene with title
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsSubsceneTest3' }">
                                Subscene with title and component in header
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsTabs' }">
                                Tabs
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsSwitcher' }">
                                Switcher
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsTags' }">
                                Tags
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsTextEditors' }">
                                Text editors
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsTextFields' }">
                                Text fields
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsTimezoneSelects' }">
                                Timezone selects
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsTooltips' }">
                                Tooltips
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'uiComponentsWarningButtons' }">
                                Warning buttons
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Scene>
</template>

<script>
export default {
    name: 'UIComponents',
};
</script>
