
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import eventView from '@/modules/Event/EventView';
import system from '@/modules/System';
import tokenThreshold from '@/modules/TokenThreshold';
import { HostessRewardsType } from '@/api/graphQL/graphNodes/types';
import CurrencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import RewardDialog from './RewardDialog/index.vue';

@Component({
    components: {
        RewardDialog,
    },
})
export default class HostRewardTable extends Vue {
    @Get(eventView) private hostessRewards!: HostessRewardsType[];

    @Get(eventView, 'eventData.calendarEventPerformance.tokens') private tokens!: string;

    @Get(eventView, 'eventData.calendarEventPerformance.eventTotal') private eventTotal!: string;

    @Get(eventView) private displayTier!: string|null;

    @Get(system) private screenType!: string;

    @Get(tokenThreshold) private threshold!: number;

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get formattedRewards() {
        return this.hostessRewards.map((reward: HostessRewardsType, index) => {
            const {
                thresholdGBP, rewards, bonusTokens, label,
            } = reward;
            const nextThreshold = this.hostessRewards[index + 1];
            const previousThreshold = this.hostessRewards[index - 1];
            const eventTotals = Number(this.eventTotal);
            const thresholdValue = reward.thresholdGBP * 100;

            let threshold;
            let value;

            if (nextThreshold) {
                threshold = `${this.currencyPrefix}${reward.thresholdGBP} - ${this.currencyPrefix}${nextThreshold.thresholdGBP - 1}`;
            } else {
                threshold = `${this.currencyPrefix}${reward.thresholdGBP} +`;
            }

            if (eventTotals > thresholdValue) {
                value = 100;
            } else {
                value = (eventTotals * 100) / thresholdValue;
            }

            const displayValue = thresholdValue - eventTotals;
            const current = reward.thresholdGBP > eventTotals / 100
             && (!previousThreshold || previousThreshold.thresholdGBP < eventTotals / 100);

            return {
                title: label,
                threshold,
                thresholdGBP,
                rewards,
                bonusTokens,
                salesValue: value,
                displayValue: Math.ceil(displayValue / 100),
                eventTotals: eventTotals / 100,
                current,
            };
        });
    }

    handleShowReward(tier) {
        eventView.setDisplayTier();

        eventView.setDisplayTier(tier);
    }

    closeRewardDialog() {
        eventView.setDisplayTier();
    }

    created() {
        this.closeRewardDialog();
    }
}
