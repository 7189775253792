// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetTokenThresholdQuery extends
    AbstractQueryResource<GetTokenThresholdResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            tokenThreshold
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getTokenThreshold';
    }
}

export type GetTokenThresholdResultType = {
    tokenThreshold: number;
}
