import { IncomePlanType } from '@/api/graphQL/graphNodes/types';

const defaultIncomePlan: () => IncomePlanType = () => ({
    id: '',
    title: '',
    averageQPA: 0,
    avgCustomerVolume: 0,
    avgVipCustomerVolume: 0,
    customersCount: 0,
    pv: 0,
    qpaCount: 0,
    vipCustomersCount: 0,
    output: {
        rank: '',
        earnings: {
            discountPv: 0,
            discountScc: 0,
            discountVipScc: 0,
            levelBonus: 0,
            levelBonusAmount: '0',
            pv: '0',
            salesBonus: 0,
            salesBonusAmount: '0',
            scc: '0',
            total: '0',
            vipScc: '0',
        },
        qualification: {
            customerVolume: 0,
            pv: 0,
            qnv: 0,
            vipCustomerVolume: 0,
        },
    },
});

export { defaultIncomePlan };
