import { ResolverNewStartersLevelsType } from '../types';

const newStartersLevels: () => ResolverNewStartersLevelsType[] = () => [
    {
        days: 7,
        key: 'nsp_7',
        label: 'NSP 7',
        target: 100,
    },
    {
        days: 14,
        key: 'nsp_14',
        label: 'NSP 14',
        target: 250,
    },
    {
        days: 30,
        key: 'nsp_30',
        label: 'NSP 30',
        target: 500,
    },
    {
        days: 60,
        key: 'nsp_60',
        label: 'NSP 60',
        target: 1100,
    },
    {
        days: 90,
        key: 'nsp_90',
        label: 'NSP 90',
        target: 1800,
    },
    {
        days: 120,
        key: 'nsp_120',
        label: 'NSP 120',
        target: 2600,
    },
    {
        days: 150,
        key: 'nsp_150',
        label: 'NSP 150',
        target: 3500,
    },
    {
        days: 180,
        key: 'nsp_180',
        label: 'NSP 180',
        target: 4500,
    },
];

export { newStartersLevels };
