import API from '@/api/graphQL';

export default class DetailsRepository {
    static getMe() {
        return API
            .getMe()
            .query();
    }

    static checkAmbassadorKit() {
        return API
            .getAmbassadorKit()
            .query();
    }
}
