const hostessReward = `type HostessReward {
    label: String!,
    thresholdEUR: Int!,
    thresholdGBP: Int!,
    rewards: [String]!,
    bonusTokens: String!,
    rewardsAsset: Asset,
}`;

const hostessRewardQuery = `
hostessRewards: [HostessReward]
`;

export { hostessReward, hostessRewardQuery };
