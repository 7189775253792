
import { Component, Prop, Vue } from 'vue-property-decorator';

enum ButtonTypeEnum {
    'contained',
    'text',
    'outlined'
}

@Component
export default class WarningButton extends Vue {
    @Prop({ required: true }) private text!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({
        default: ButtonTypeEnum.contained,
        // @ts-ignore
        validator: (val) => ButtonTypeEnum[val] !== undefined,
    }) private type!: ButtonTypeEnum;

    @Prop({ default: false }) private disabled!: boolean;

    get elementClasses() {
        return [
            'WarningButton',
            `WarningButton--${this.type}`,
            this.disabled ? 'WarningButton--disabled' : 'Ripple',
            this.icon ? 'WarningButton--withIcon' : '',
        ];
    }

    handleClick() {
        this.$emit('click');
    }
}
