import { MyHostDetailsType } from '@/api/graphQL/graphNodes/types';
import store from '@/store';
import { CollectionModule, CollectionVuexModule } from '@/utils/vuex-module-mutators';
import { getModule, Mutation } from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { defaultHostDetails } from './defaults';
import myHostsRepository from './services/MyHostsRepository';

export class Host {
    id: string = '';

    data: MyHostDetailsType = defaultHostDetails();

    loading: boolean = false;
}

@CollectionModule({
    namespaced: true, dynamic: true, store, name: 'myHostsHost', item: Host,
})
class MyHostsHostModule extends CollectionVuexModule<Host> {
    @Mutation
    public setLoading(params: { id: string, val: boolean }) {
        const { id, val } = params;

        if (this.collection[id]) {
            this.collection[id].loading = val;
        }
    }

    @Mutation
    public setData(params: { id: string, val: MyHostDetailsType }) {
        const { id, val } = params;

        if (this.collection[id]) {
            this.collection[id].data = val;
        }
    }

    @Action()
    public async getHostDetails(params: { id: string }) {
        const { id } = params;

        try {
            this.setLoading({ id, val: true });
            const result = await myHostsRepository.getHostDetails({ id });
            this.setData({
                id,
                val: {
                    ...this.collection[id].data,
                    ...result.enrollee,
                },
            });
        } finally {
            this.setLoading({ id, val: false });
        }
    }
}

export default getModule(MyHostsHostModule);
