import { IncomePlanType } from '@/api/graphQL/graphNodes/types';
import { AbstractResource, Methods } from '../AbstractResource';

export default class GetIncomePlans extends AbstractResource<IncomePlanType[]> {
    protected noAuthentication: boolean = true;

    protected getAllowedMethods(): Methods[] {
        return [Methods.GET_ALL];
    }

    protected getPath(): string {
        return '/api/v1/income-plans';
    }

    public getIncomePlans(): Promise<IncomePlanType[]> {
        return this.getAll();
    }
}
