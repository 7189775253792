import API from '@/api/graphQL';
import { GetIncomeSummaryParamsType } from '@/api/graphQL/graphNodes/GetIncomeSummaryQuery';
import { GetIncomeSummaryRunTotalsParamsType } from '@/api/graphQL/graphNodes/GetIncomeSummaryRunTotalsQuery';

export default class IncomeSummaryRepository {
    static getCommissionStatements() {
        return API
            .getCommissionStatements()
            .query();
    }

    static getAnnualTotals(params: GetIncomeSummaryParamsType) {
        return API
            .getIncomeSummary()
            .query(params);
    }

    static getIncomeSummaryRunTotals(params: GetIncomeSummaryRunTotalsParamsType) {
        return API
            .getIncomeSummaryRunTotals()
            .query(params);
    }

    static getParticipatedRuns() {
        return API
            .getParticipatedRuns()
            .query();
    }
}
