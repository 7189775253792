
import { Vue, Component, Prop } from 'vue-property-decorator';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import { ErrorType } from '@plumtreesystems/utils';
import { InvitationFormType } from '@/modules/Invite/types';

//
//    NEEDS RESET RECAPTCHA IMPLEMENTATION IN PARENT USING REF.
//

@Component({
    components: {
        FormErrorTooltip,
    },
})
export default class EmailInvite extends Vue {
    @Prop({ default: 'Send' }) private buttonText!: string;

    @Prop() private value!: InvitationFormType;

    @Prop() private formErrors!: ErrorType;

    @Prop({ default: '' }) private title!: string;

    @Prop({ default: 'pts-size-75' }) private fieldSize!: string;

    @Prop({ default: false }) private loadRecaptchaScript!: boolean;

    @Prop({ default: false }) private loading!: boolean;

    @Prop() private displayTooltip!: boolean;

    @Prop({ default: '' }) private tooltipError!: boolean;

    updateFormData(key, val) {
        const res = { ...this.value, [key]: val };

        this.$emit('input', res);
    }

    async send() {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('login');

        this.updateFormData('captcha', token);

        this.$emit('send');
    }
}
