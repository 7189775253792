
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import incomeSummary from '@/modules/IncomeSummary';
import { RunTotalIncomeSummaryType } from '@/api/graphQL/graphNodes/types';
import currencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class SalesFromChart extends Vue {
    @Get(incomeSummary) private runTotalIncomeSummary!: RunTotalIncomeSummaryType;

    get options() {
        return {
            chart: {
                id: 'salesFromChartId',
            },
            labels: this.runTotalIncomeSummary.saleStats.map((item) => item.label),
            // legend: {
            //     show: false,
            // },
            dataLabels: {
                style: {
                    fontSize: '16px',
                    fontWeight: 400,
                    colors: ['#424242'],
                },
                // formatter(val, opts) {
                //     const name = opts.w.globals.labels[opts.seriesIndex];
                //     return [name, `${val.toFixed(1)}%`];
                // },
                formatter(val) {
                    return [`${val.toFixed(1)}%`];
                },
                dropShadow: {
                    enabled: false,
                },
            },
            colors: ['#ea5c64', '#00ED8E', '#8b82f6'],
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -10,
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: (val) => `${this.currencyPrefix} ${val}`,
                },
            },
        };
    }

    get series() {
        return this.runTotalIncomeSummary.saleStats.map((item) => Number(item.value));
    }

    get currencyPrefix(): string {
        return currencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }
}
