<template>
    <div class="RegisterCanNotRegister">
        <div
            class="Ui__Card
            md-elevation-4 pts-layout pts-layout-item
            pts-gutter RegisterCanNotRegister__Container"
        >
            <span>You don't have the permission to this page</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegisterCanNotRegister',
};
</script>

<style lang="scss">
    @import 'styles.scss';
</style>
