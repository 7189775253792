
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import myCustomers from '@/modules/MyCustomers';
import profile from '@/modules/Profile';
import customersOrders from '@/modules/MyCustomers/customersOrders';
import { CUSTOMER_LIST_TYPE } from '@/modules/MyCustomers/constants';
import CustomersHeader from './CustomersHeader/index.vue';
import CustomersList from './CustomersList/index.vue';
import OrderList from './OrderList/index.vue';

@Component({
    components: {
        CustomersHeader,
        CustomersList,
        OrderList,
        Skeleton,
    },
})
export default class MyCustomers extends Vue {
    @Get(myCustomers) private customersLoading!: boolean;

    @Get(myCustomers) private customersSearchLoading!: boolean;

    @Get(myCustomers) private offset!: number;

    @Get(customersOrders, 'offset') private ordersOffset!: number;

    @Get(myCustomers) private displayCustomersSearchResults!: boolean;

    @Get(myCustomers) private selectedCustomerList!: string;

    @Get(customersOrders, 'loading') private customerOrdersLoading!: boolean;

    @Get(customersOrders, 'searchLoading') private customerOrdersSearchLoading!: boolean;

    @Get(system) private screenType!: string;

    @Get(profile, 'originalData.id') private myId!: string;

    get contentLoaded(): boolean {
        return this.customersLoading || this.customersSearchLoading
        || this.customerOrdersLoading || this.customerOrdersSearchLoading;
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    get displayCustomers() {
        return this.selectedCustomerList === this.customerListBook;
    }

    get customerListBook() {
        return CUSTOMER_LIST_TYPE.addressBook;
    }

    handleSearchToggle() {
        myCustomers.toggleShowCustomersSearch();
    }

    mounted() {
        if (this.displayCustomers) {
            const { offset } = this;
            myCustomers.getMyCustomers({
                offset,
                sellerId: this.myId,
            });
        } else {
            const { ordersOffset } = this;
            customersOrders.getCustomerOrders({ offset: ordersOffset });
        }
    }

    beforeDestroy() {
        myCustomers.setCustomers([]);
        myCustomers.setSearchedCustomersOptions([]);
        myCustomers.clearExpandedCustomersList();
        myCustomers.setDisplayCustomersSearchResults(false);
        myCustomers.setCustomerSearchQuery('');

        customersOrders.setCustomerOrdersData();
        customersOrders.setSearchedOrdersOptions([]);
        customersOrders.clearExpandedOrdersList();
        customersOrders.setDisplaySearchResults(false);
        customersOrders.setSearchQuery('');
    }

    @Watch('screenType')
    setVal(val: string) {
        if (val === 'mobile') {
            myCustomers.clearExpandedOrdersList();
        }
    }
}
