
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SwitcherOptionsType } from './types';

@Component
export default class Switcher extends Vue {
    @Prop({ required: true }) private options!: SwitcherOptionsType[];

    @Prop({ required: true }) private value!: number|string|boolean;

    @Prop() private disabled!: boolean;

    elementClasses(option) {
        return [
            'Switcher__Item',
            { 'Switcher__Item--active': option.value === this.value },
            { 'Switcher__Item--disabled': option.value === this.value && this.disabled },
        ];
    }

    mounted() {
        this.selectSwitcher(this.options[0]);
    }

    selectSwitcher(option) {
        if (!this.disabled) {
            this.$emit('input', option.value);
        }
    }
}
