<template>
    <UILayout
        title="Tooltips"
        route="uiComponentsTooltips"
        id="tooltipsSubscene"
    >
        <div class="Ui__Card md-elevation-4 pts-layout pts-gutter">
            <div class="pts-layout-item pts-size-100">
                <h3>Tooltips</h3>

                <div class="pts-layout-item pts-layout pts-gutter">
                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <Button
                            text="Hover me!"
                            type="contained"
                            aria-describedby="tooltipForARIA"
                        />
                        <md-tooltip
                            id="tooltipForARIA"
                            role="tooltip"
                            md-direction="top"
                        >
                            Lorem ipsum
                        </md-tooltip>
                    </div>

                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <Button
                            text="Hover me!"
                            type="contained"
                        />
                        <md-tooltip md-direction="right">Lorem ipsum</md-tooltip>
                    </div>

                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <Button
                            text="Hover me!"
                            type="contained"
                        />
                        <md-tooltip md-direction="left">Lorem ipsum</md-tooltip>
                    </div>

                    <div class="
                        pts-layout-item pts-size-20 pts-small-size-50 pts-xsmall-size-100
                    ">
                        <Button
                            text="Hover me!"
                            type="contained"
                        />
                        <md-tooltip md-direction="bottom">Lorem ipsum</md-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </UILayout>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UITooltips extends Vue {}
</script>
