import { RankType } from '@/api/graphQL/graphNodes/types';

const ranks: RankType[] = [
    // {
    //     id: '00',
    //     power: 20,
    //     label: 'Digital Ambassador',
    //     requirements: [],
    //     titleRequirements: [],
    // },
    {
        id: '0',
        power: 0,
        label: 'Inactive',
        requirements: [],
        titleRequirements: [],
    },
    {
        id: '1',
        power: 1,
        label: 'Customer',
        requirements: [],
        titleRequirements: [],
    },
    {
        id: '2',
        power: 2,
        label: 'Host',
        requirements: [],
        titleRequirements: [],
    },
    {
        id: '3',
        power: 10,
        label: 'Level 1',
        requirements: [],
        titleRequirements: [],
    },
    {
        id: '4',
        power: 20,
        label: 'Level 2',
        requirements: [],
        titleRequirements: [],
    },
    {
        id: '5',
        power: 30,
        label: 'Level 3',
        requirements: [],
        titleRequirements: [],
    },
];

const rank:(id: string) => RankType = (id) => ranks.find((rankElement) => rankElement.id === id)!;
export { ranks, rank };
