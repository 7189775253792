
import {
    Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { OrderType } from '@/api/graphQL/graphNodes/types';
import profile from '@/modules/Profile';
import orders from '@/modules/Orders';
import { Get } from '@/utils/vuex-module-mutators';
import CurrencyPrefix from '@/utils/currencyPrefix';
import dateManager from '@/utils/time';
import system from '@/modules/System';
import ProductItem from '@/projectComponents/OrdersList/ProductItem/index.vue';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import env from '@/environment';

@Component({ components: { ProductItem } })
export default class OrderDetails extends Vue {
    @Get(orders) private orders!: OrderType[];

    @Get(profile, 'originalData.id') private profileId!: string;

    @Get(system) private screenType!: string;

    @Prop() private isHostess!: boolean;

    get country() {
        return COUNTRY_SELECT_OPTIONS.gb;
    }

    get orderId() {
        const { id } = this.$route.params;
        return id;
    }

    get orderDate(): string {
        return dateManager.getDateTime(this.order?.date, dateManager.getDayTimeFormatUK());
    }

    get order() {
        return this.orders.find((order) => order.id === this.orderId);
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(this.country);
    }

    get commissionableOrder() {
        if (this.order?.enrollee.id === this.profileId) {
            return true;
        }
        return false;
    }

    get paymentMethod() {
        if (this.order?.paymentInfo) {
            let res = '';
            res += this.order?.paymentInfo.map((item) => item.paymentMethod).join(', ');
            return res;
        }

        return '-';
    }

    get storeCreditLabel(): string {
        return env.VUE_APP_DISABLE_LOGO === 'true'
            ? 'Sister Pay'
            : 'Connect Pay';
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    @Watch('screenType')
    setVal(val: string) {
        if (val !== 'mobile') {
            this.$router.push({ name: 'orders' });
        }
    }
}
