
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Get, CGet } from '@/utils/vuex-module-mutators';
import { MyHostDetailsType } from '@/api/graphQL/graphNodes/types';
import tokenThreshold from '@/modules/TokenThreshold';
import Contacts from '@/projectComponents/enrolleeLegItem/Item/Contacts/index.vue';
import DetailsDialog from '@/projectComponents/enrolleeLegItem/DetailsDialogContent/index.vue';
import hostCollection from '@/modules/MyHosts/host';

@Component({
    components: {
        Contacts,
        DetailsDialog,
    },
})
export default class HostListItem extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop() private expanded!: boolean;

    @Prop({ default: true }) private withSponsorDetails!: boolean;

    @Prop() private screenType!: string;

    @Prop({ default: '' }) private activeSponsorDetailsModal!: string;

    @CGet(hostCollection) private data!: MyHostDetailsType;

    @CGet(hostCollection) private loading!: boolean;

    @Get(tokenThreshold) private threshold!: number;

    get sponsorDetailsOpen(): boolean {
        return this.activeSponsorDetailsModal === this.id;
    }

    get displayEditButton(): boolean {
        const { data } = this;
        return data.editable;
    }

    get progressVal(): number {
        return (Number(this.data.eventPerformance.tokens) * 100) / this.threshold;
    }

    handleOpenSponsor(id: string) {
        this.$emit('activeSponsorDetailsId', id);
    }

    handleExpandedToggle() {
        this.$emit('expandedToggle', this.id);
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    handleEditHost(id: string) {
        this.$router.push({ name: 'editMyHost', params: { id } });
    }

    @Watch('expanded')
    handleDetailsLoad(val) {
        const { id } = this;

        if (val) {
            hostCollection.getHostDetails({ id });
        }
    }
}
