export const EVENT_TYPE = {
    personal: 'personal',
    organisational: 'organisational',
    organisational_and_personal: 'organisational_and_personal',
};

export const CALENDAR_TIME_FRAME_OPTIONS = {
    month: 'month',
    week: 'week',
    day: 'day',
};
