import dateManager from '@/utils/time';
import { RegisterHostessType, RegisterAmbassadorType } from '@/api/graphQL/graphNodes/types';
import { FIELD_REQUIRED_MESSAGE } from '@/utils/messages/formValidation';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { ValidationErrorType } from '../../types';
import { PASSOWRD_REPEAT, AGE_RESTRICTION } from '../messages';

const registrationFormValidation:
    (val: RegisterAmbassadorType, passwordCheck: boolean) =>
    ValidationErrorType[] = (val, passwordCheck) => {
        const errors: ValidationErrorType[] = [];
        const currentDate = dateManager.getCurrentDate('YYYY-MM-DD');
        const selectedDate = dateManager.getDateWithOffset(18, val.birthDate, 'YYYY-MM-DD', 'y');

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.password && passwordCheck) {
            errors.push({ key: 'plainPassword', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.repeatPassword && passwordCheck) {
            errors.push({ key: 'repeatPassword', val: FIELD_REQUIRED_MESSAGE });
        }

        if (val.password !== val.repeatPassword && passwordCheck) {
            errors.push({ key: 'repeatPassword', val: PASSOWRD_REPEAT });
        }

        if (!val.address) {
            errors.push({ key: 'address', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.country) {
            errors.push({ key: 'country', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.termsChecked) {
            errors.push({ key: 'termsChecked', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.phoneNumber) {
            errors.push({ key: 'phoneNumber', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.birthDate) {
            errors.push({ key: 'birthDate', val: FIELD_REQUIRED_MESSAGE });
        }

        if (dateManager.isBefore(currentDate, selectedDate)) {
            errors.push({ key: 'birthDate', val: AGE_RESTRICTION });
        }

        if (val.country === COUNTRY_SELECT_OPTIONS.gb && !val.postCode) {
            errors.push({ key: 'postCode', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.bankDetails.bankAccountName) {
            errors.push({ key: 'bankDetails.bankAccountName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.bankDetails.bankAccountNumber) {
            errors.push({ key: 'bankDetails.bankAccountNumber', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.bankDetails.bankSortCode) {
            errors.push({ key: 'bankDetails.bankSortCode', val: FIELD_REQUIRED_MESSAGE });
        }

        return errors;
    };

const hostessRegistrationFormValidation:
    (val: RegisterHostessType) => ValidationErrorType[] = (val) => {
        const errors: ValidationErrorType[] = [];
        const currentDate = dateManager.getCurrentDate('YYYY-MM-DD');
        const selectedDate = dateManager.getDateWithOffset(18, val.birthDate, 'YYYY-MM-DD', 'y');

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.password) {
            errors.push({ key: 'plainPassword', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.repeatPassword) {
            errors.push({ key: 'repeatPassword', val: FIELD_REQUIRED_MESSAGE });
        } else if (val.password !== val.repeatPassword) {
            errors.push({ key: 'repeatPassword', val: PASSOWRD_REPEAT });
        }

        if (!val.termsChecked) {
            errors.push({ key: 'termsChecked', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.birthDate) {
            errors.push({ key: 'birthDate', val: FIELD_REQUIRED_MESSAGE });
        } else if (dateManager.isBefore(currentDate, selectedDate)) {
            errors.push({ key: 'birthDate', val: AGE_RESTRICTION });
        }

        if (!val.country) {
            errors.push({ key: 'country', val: FIELD_REQUIRED_MESSAGE });
        }

        return errors;
    };

export { registrationFormValidation, hostessRegistrationFormValidation };
