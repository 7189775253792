
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import incomeSummary from '@/modules/IncomeSummary';
import Skeleton from './IncomeSummarySkeleton/index.vue';
import Totals from './Totals/index.vue';
import Statements from './Statements/index.vue';
import RunTotal from './RunTotal/index.vue';

@Component({
    components: {
        Totals,
        Statements,
        RunTotal,
        Skeleton,
    },
})
export default class IncomeSummary extends Vue {
    @Get(incomeSummary) private loading!: boolean;

    @Get(incomeSummary) private loadingCommissionRuns!: boolean;

    @Get(incomeSummary) private statementsLoading!: boolean;

    @Get(incomeSummary) private runTotalsLoading!: boolean;

    @Get(incomeSummary) private selectedTimeFrame!: string;

    async beforeMount() {
        await incomeSummary.getParticipatedRuns();
    }

    get contentLoaded(): boolean {
        return !this.loading && !this.statementsLoading && !this.loadingCommissionRuns
        && !this.runTotalsLoading;
    }
}
