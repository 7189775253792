
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import marketingMaterial from '@/modules/MarketingMaterial';
import { Debounced } from '@/utils/debounced';
import OptionItemLayout from '@/projectComponents/optionItemLayout/index.vue';

@Component({
    components: {
        OptionItemLayout,
    },
})
export default class MarketingMaterialSearch extends Vue {
    @Get(marketingMaterial) private searchDisplay!: boolean;

    @Sync(marketingMaterial) private searchText!: string;

    get display(): boolean {
        return this.$route.name === 'marketingMaterialCategory' || this.searchDisplay;
    }

    get searchClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
            'MarketingMaterialHeader__SearchContainer',
            { 'MarketingMaterialHeader__SearchContainer--show': this.display },
        ];
    }

    @Debounced(1000)
    handleTextSearch(): void {
        if (this.searchText === '' && this.$route.name === 'marketingMaterial') {
            marketingMaterial.clearData();
        } else {
            this.loadFiles();
        }
    }

    loadFiles() {
        const { id } = this.$route.params;

        if (id) {
            marketingMaterial.getFiles({ subCategoryId: id });
        } else {
            marketingMaterial.getFiles();
        }
    }
}
