
import { Vue, Component } from 'vue-property-decorator';
import newStarters from '@/modules/NewStarters';
import newStartersLevels from '@/modules/NewStarters/levels';
import rank from '@/modules/Rank';
import { Get } from '@/utils/vuex-module-mutators';
import { NewStartersLevelsType, NewStartersType, RankType } from '@/api/graphQL/graphNodes/types';
import system from '@/modules/System';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import impersonation from '@/modules/Impersonation';
import NewStartersItem from './item.vue';

@Component({
    components: {
        NewStartersItem,
    },
})
export default class NewStartersContent extends Vue {
    @Get(newStarters) starters!: NewStartersType[];

    @Get(newStarters) private searchedLegs!: NewStartersType[];

    @Get(newStarters) loading!: boolean;

    @Get(newStarters) loadingInBackground!: boolean;

    @Get(rank) private ranks!: RankType[];

    @Get(rank, 'loading') private ranksLoading!: boolean;

    @Get(newStarters) private expandedNewStarters!: string[];

    @Get(newStarters) private activeSponsorDetailsModal!: string;

    @Get(newStarters) private displaySearchResults!: boolean;

    @Get(newStarters) private offset!: number;

    @Get(newStarters) private limit!: number;

    @Get(newStarters) private count!: number;

    @Get(newStarters) private newStartersTimeFrame!: string;

    @Get(system) private screenType!: string;

    @Get(newStartersLevels) private levels!: NewStartersLevelsType[];

    get displayLoadMore(): boolean {
        if (this.displaySearchResults) {
            return false;
        }
        return this.limit <= this.count;
    }

    get displayLegs(): NewStartersType[] {
        if (this.displaySearchResults) {
            return this.searchedLegs;
        }
        return this.starters;
    }

    get impersonating(): boolean {
        return impersonation.impersonating;
    }

    async handlePagination(props: PaginationParamsType) {
        const { offset } = props;
        await newStarters.getData({ loadInBackground: true, offset });
        scrollToTop('#app > div.App__Layout > div > div.Scene__Content.Scene__Content--noPadding > div > div.NewStartersHeader__Content');
    }
}
