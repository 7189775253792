
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { RankType } from '@/api/graphQL/graphNodes/types';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class AmbassadorKit extends Vue {
    @Get(dashboard, 'data.paidForKit') private paidForKit!: boolean;

    @Prop() private requirements!: RankRequirementType[];

    @Prop() private rank!: RankType;

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(
            RankRequirementManager.rankRequirementMetricConstants.ambassadorKit,
        ).title;
    }

    get displayData(): boolean {
        const ambassadorKit = this.requirements
            .find((item) => item.metric === RankRequirementManager
                .rankRequirementMetricConstants.ambassadorKit);

        if (!ambassadorKit) {
            return false;
        }

        return true;
    }
}
