
import { Component, Vue } from 'vue-property-decorator';
import underInspection from '@/modules/NoPermission/underInspection';
import { Get } from '@/utils/vuex-module-mutators';
import { LEXIS_NEXIS_STATUS } from '@/modules/Register/constants';
import authenticator from '@/modules/Auth/services/authenticator';
import authLocalStorageManager from '@/modules/Auth/services/authLocalStorageManager';

@Component
export default class NoPermissionUnderInspection extends Vue {
    @Get(underInspection) private loading!: boolean;

    @Get(underInspection) private lexisNexisStatus!: string|null;

    get contentText(): string {
        return 'Your details are under review';
    }

    async beforeMount() {
        await underInspection.getStatus();
        if (this.lexisNexisStatus === LEXIS_NEXIS_STATUS.none) {
            const token = authLocalStorageManager.getAuthToken();
            this.$router.push({
                name: 'register',
                query: {
                    token,
                    logged: 'true',
                },
            });
        } else if (this.lexisNexisStatus === LEXIS_NEXIS_STATUS.pass) {
            this.$router.push({
                name: 'dashboard',
            });
        }
    }

    handleLogOut() {
        authenticator.logout();
    }
}

