
import componentsSandbox from '@/modules/ComponentsSandbox';
import { SwitcherOptionsType } from '@/components/switcher/types';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { Component, Vue } from 'vue-property-decorator';
import UILayout from '../Layout/index.vue';

@Component({ components: { UILayout } })
export default class UISwitcher extends Vue {
    @Sync(componentsSandbox) private activeSwitcherItem!: number;

    @Get(componentsSandbox) private switcherOptions!: SwitcherOptionsType[];
}
