import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { NewStartersLevelsType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import newStartersRepository from './services/newStartersRepository';
import newStarters from './index';
import { defaultNewStartersLevels } from './defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'newStartersLevels',
})
@AutoMutations
export class NewStartersLevels extends VuexModule {
    private levels: NewStartersLevelsType[] = [];

    private loading: boolean = false;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLevels(val: NewStartersLevelsType[]) {
        this.levels = val;
    }

    @Action()
    public async getData() {
        try {
            this.setLoading(true);

            const res = await newStartersRepository
                .getNewStartersLevels();

            const selectedVal: string = newStarters.getNewStartersTimeFrame;

            if (selectedVal === '') {
                let firstLevel: NewStartersLevelsType = defaultNewStartersLevels();

                res.newStartersLevels.forEach((item) => {
                    if (firstLevel.key === '') {
                        firstLevel = item;
                    } else if (firstLevel.days > item.days) {
                        firstLevel = item;
                    }
                });

                if (firstLevel.key !== '') {
                    newStarters.setNewStartersTimeFrame(firstLevel.key);
                }
            }
            this.setLevels(res.newStartersLevels);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(NewStartersLevels);
