import { DashboardType, HostessDashboardType } from '@/api/graphQL/graphNodes/types';

const initialDashboardData: () => DashboardType = () => ({
    id: '0',
    ambassadorLegsCount: 0,
    personalRecruitsCount: 0,
    rankId: '0',
    vanityUrl: '',
    rank: {
        id: '0',
        power: 0,
        label: '',
        requirements: [],
        titleRequirements: [],
    },
    additionalCommission: '0.00',
    ctb: '0.00',
    leadershipBonus: '0.00',
    qualifiedLeaderLegsCount: 0,
    paidForKit: false,
    centralTeamBonus: '0.00',
    performanceData: {
        avgSalesPerEvent: '0.00',
        bookedEvents: 0,
        finishedEvents: 0,
        projectedSales: '0.00',
        totalSales: 0,
        lastMonthFinishedEvents: 0,
        totalEvents: 0,
    },
    newStartersCountByLevel: [],
    newCustomersInCentralTeam: 0,
    centralTeamCount: 0,
    organizationCount: 0,
    qualifiedOrgLeaderCount: 0,
    directHostsCount: 0,
    soldOrdersCount: 0,
    directDownlineCountThisMonth: 0,
    directDownlineCount: 0,
    hasReleventNewStarters: false,
    joinDate: '',
    newStarterTotalsByMilestones: [],
    stats: [],
});

const initialHostessDashboardData: () => HostessDashboardType = () => ({
    eventPerformance: {
        tokenRewardUrl: null,
        tokens: '0',
    },
});

export { initialDashboardData, initialHostessDashboardData };
