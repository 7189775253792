const stats = `type Stats {
    metric: String!,
    value: String!,
    label: String,
    status: Boolean,
}`;

export {
    stats,
};
