
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DownlineLegType } from '@/api/graphQL/graphNodes/types';
import Info from './Info/index.vue';
import Performance from './Performance/index.vue';

@Component({
    components: {
        Info,
        Performance,
    },
})
export default class DetailsDialogContent extends Vue {
    @Prop() private leg!: DownlineLegType;

    @Prop({ default: true }) private withSponsorDetails!: boolean;
}
