
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import eventCreate from '@/modules/Event/EventCreate';
import { CreateEventType } from '@/modules/Event/Events/types';
import EventForm from '@/views/Events/Components/EventForm/index.vue';
import { ErrorType } from '@plumtreesystems/utils';
import { ObjectPropertyType } from '@/modules/types';

@Component({
    components: {
        EventForm,
    },
})
export default class EventCreateForm extends Vue {
    @Sync(eventCreate) private eventData!: CreateEventType;

    @Get(eventCreate, 'eventFormErrors') private formErrors!: ErrorType;

    handleInput(value: ObjectPropertyType) {
        eventCreate.setEventProperty(value);
    }
}
