
import { TransactionsType } from '@/api/graphQL/graphNodes/types';
import { Vue, Component, Watch } from 'vue-property-decorator';
import sisterCredit from '@/modules/SisterCredit';
import { Get } from '@/utils/vuex-module-mutators';
import dateManager from '@/utils/time';
import system from '@/modules/System';
import { TRANSACTION_CHANGE_TYPE } from '@/modules/SisterCredit/constants';
// import { currencySign } from '@/modules/SisterCredit/helpers';
import currencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class TransactionSubscene extends Vue {
    @Get(sisterCredit) private transaction!: TransactionsType;

    @Get(system) private screenType!: string;

    transactionSign(type: string) {
        switch (type) {
        case TRANSACTION_CHANGE_TYPE.deposit:
            return '+';
        case TRANSACTION_CHANGE_TYPE.charge:
            return '-';
        default:
            return '';
        }
    }

    formatCents(unformatted) {
        return Number(unformatted).toFixed(2);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transactionCurrency(currency) {
        return currencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    formatDate(date) {
        return dateManager.getDateTime(date, dateManager.getDayTimeFormatUK());
    }

    @Watch('screenType')
    setVal(val: string) {
        if (val !== 'mobile') {
            this.$router.push({ name: 'sisterPay' });
        }
    }
}
