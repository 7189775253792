
import { Vue, Component, Prop } from 'vue-property-decorator';
import system from '@/modules/System';
import { Get } from '@/utils/vuex-module-mutators';

@Component
export default class StatItemLayout extends Vue {
    @Prop() private value!: number;

    @Prop({ default: null }) private progressValue!: number|null;

    @Prop({ default: '' }) private requirementType!: string;

    @Prop({ default: '' }) private displayValue!: string

    @Prop() private tooltipText!: string;

    @Prop({ default: true }) withTooltip!: boolean;

    @Prop() private title!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({ default: false }) private empty!: boolean;

    @Prop() private link!: any;

    @Prop({ default: false }) private customTooltip!: boolean;

    @Prop() private id!: string;

    @Prop({ default: '' }) private valueType!: string;

    @Get(system) private screenType!: string;

    get isDesktop(): boolean {
        return this.screenType === 'desktop';
    }

    get tooltipId() {
        return `statInfoTooltip_${this.id}`;
    }

    get formattedValue() {
        const { value, valueType } = this;

        if (valueType === 'withDecimal') {
            return value.toFixed(2);
        }
        return value;
    }

    get valueDisplay() {
        const { displayValue, formattedValue } = this;
        return displayValue !== '' ? displayValue : formattedValue;
    }
}
