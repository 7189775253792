
import { Vue, Component, Prop } from 'vue-property-decorator';
import RankRequirementsItem from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/item.vue';
import { RankRequirementData } from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/types';

@Component({
    components: {
        RankRequirementsItem,
    },
})
export default class NewStartersContentRequirements extends Vue {
    @Prop() private label!: string;

    @Prop() private requirements!: RankRequirementData[];

    @Prop() private tooltipText!: string;

    @Prop({ default: true }) private withTooltip!: string;

    @Prop() private isMobile!: boolean;

    @Prop() private id!: string;

    get tooltipId() {
        return `newStartersRequirementTooltip${this.id}`;
    }
}
