import API from '@/api/graphQL';
import { NewStartersParamsType, NewStartersSearchParamsType } from '@/api/graphQL/graphNodes/GetNewStartersQuery';

export default class NewStartersRepository {
    static getNewStarters(params: NewStartersParamsType) {
        return API
            .getNewStarters()
            .query(params, {}, { type: '' });
    }

    static getNewStartersLevels() {
        return API
            .getStartersLevels()
            .query();
    }

    static searchNewStarters(params: NewStartersSearchParamsType) {
        return API
            .getNewStarters()
            .query(params, {}, { type: 'search' });
    }
}
