
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import EventInvitationView from '@/modules/Event/EventInvitation';
import { EVENT_ATTENDANCE_STATUS } from '@/modules/Event/constants';

@Component
export default class EventInvitationAttendance extends Vue {
    @Prop({ required: true }) private attending!: string;

    @Prop({ required: true }) private shopUrl!: string;

    @Prop({ required: true }) private hostessInvitation!: boolean;

    @Get(EventInvitationView) private responseSending!: boolean;

    @Get(EventInvitationView) private declineModalOpen!: boolean;

    get isAccepted() {
        return this.attending === EVENT_ATTENDANCE_STATUS.yes;
    }

    get isDeclined() {
        return this.attending === EVENT_ATTENDANCE_STATUS.declined;
    }

    get isTentative() {
        return this.attending === EVENT_ATTENDANCE_STATUS.tentative;
    }

    get isResponded() {
        return this.attending !== null;
    }

    get attendingLabel() {
        switch (this.attending) {
        case EVENT_ATTENDANCE_STATUS.yes:
            return 'Accepted';
        case EVENT_ATTENDANCE_STATUS.declined:
            return 'Declined';
        case EVENT_ATTENDANCE_STATUS.tentative:
            return 'Tentative';
        default:
            return '';
        }
    }

    get attendingIcon() {
        switch (this.attending) {
        case EVENT_ATTENDANCE_STATUS.yes:
            return 'event_available';
        case EVENT_ATTENDANCE_STATUS.declined:
            return 'event_busy';
        case EVENT_ATTENDANCE_STATUS.tentative:
            return 'event_note';
        default:
            return '';
        }
    }

    handleAttendingSubmit(status: string) {
        const { hostessInvitation } = this;
        const token = this.$route.query.token.toString();

        switch (status) {
        case 'accept':
            EventInvitationView.respondToInvitation({
                attending: EVENT_ATTENDANCE_STATUS.yes,
                token,
                hostessInvitation,
            });
            break;
        case 'decline':
            EventInvitationView.respondToInvitation({
                attending: EVENT_ATTENDANCE_STATUS.declined,
                token,
                hostessInvitation,
            });
            break;
        case 'tentative':
            EventInvitationView.respondToInvitation({
                attending: EVENT_ATTENDANCE_STATUS.tentative,
                token,
                hostessInvitation,
            });
            break;
        default:
            break;
        }
    }

    handleDecline() {
        EventInvitationView.setDeclineModalOpen(false);
        this.handleAttendingSubmit('decline');
    }

    handleDeclineModalCancel() {
        EventInvitationView.setDeclineModalOpen(false);
    }

    handleDeclineModalOpen() {
        EventInvitationView.setDeclineModalOpen(true);
    }
}
