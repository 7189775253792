import { ResolverReportType } from '../types';

const reportList: ResolverReportType[] = [
    {
        downloadUrl: 'someLink',
        fileName: 'Genealogy',
        runId: '0',
    },
    {
        downloadUrl: 'someLink',
        fileName: 'Genealogy_snapshots',
        runId: '2',
    },
    {
        downloadUrl: 'someLink',
        fileName: 'new_business',
        runId: '0',
    },
    {
        downloadUrl: 'someLink',
        fileName: 'Genealogy',
        runId: '1',
    },
    {
        downloadUrl: 'someLink',
        fileName: 'new_business',
        runId: '1',
    },
    {
        downloadUrl: 'someLink',
        fileName: 'risk_of_leaving',
        runId: '0',
    },
    {
        downloadUrl: 'someLink',
        fileName: 'new_business_snapshot',
        runId: '2',
    },
    {
        downloadUrl: 'someLink',
        fileName: 'risk_of_leaving',
        runId: '1',
    },
    {
        downloadUrl: 'someLink',
        fileName: 'risk_of_leaving_snapshot',
        runId: '2',
    },
];

const reports: (runId: string|null) => ResolverReportType[] = (runId) => reportList
    .filter((item) => {
        if (runId === null) {
            return item.runId === '2';
        }

        return item.runId === runId;
    });

export { reports };
